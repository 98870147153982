import { observable, action } from 'mobx';
import { xor } from 'lodash/fp';
import {
    HeatmapPercentile,
    HeatmapScoreType,
} from 'components/Heatmap/HeatmapTypes';
import { E_TIME_PRESET_KEYS } from '../constants';
import moment from 'moment';
import { E_RISK_TYPE } from 'interfaces';

export class HeatmapStore {
    @observable public countriesList: number[] = [];
    @observable public title: string = '';
    @observable public selectedCountry: number = null;
    @observable public riskType: E_RISK_TYPE = E_RISK_TYPE.DIMENSIONS;
    @observable public scoreType: HeatmapScoreType = HeatmapScoreType.PULSE;
    @observable public selectedRisk: number = null;
    @observable public startDate: Date = moment()
        .startOf('day')
        .toDate();
    @observable public timeSpan: E_TIME_PRESET_KEYS =
        E_TIME_PRESET_KEYS.THREE_MONTHS;
    @observable public selectedPercentile: HeatmapPercentile = null;

    @action setCountriesList(countriesList: number[]) {
        this.countriesList = [...countriesList];
    }

    @action public toggleCountry(countryId: number) {
        this.countriesList = xor([countryId], this.countriesList);
    }

    @action public removeCountry(countryId: number) {
        this.countriesList = this.countriesList.filter(
            cid => cid !== countryId
        );
    }

    @action public setRiskType(riskType: E_RISK_TYPE) {
        this.riskType = riskType;
    }

    @action public setScoreType(scoreType: HeatmapScoreType) {
        this.scoreType = scoreType;
    }

    @action public setSelectedRisk(selectedRisk: number) {
        this.selectedRisk = selectedRisk;
    }

    @action public setSelectedPercentile(percentile: HeatmapPercentile) {
        this.selectedPercentile = percentile;
    }

    @action public resetSelection() {
        this.selectedRisk = null;
        this.selectedCountry = null;
    }

    @action setTitle(title: string) {
        this.title = title;
    }

    @action setSelectedCountry(countryId: number) {
        this.selectedCountry = countryId;
    }

    @action setStartDate(date: Date) {
        this.startDate = date;
    }

    @action setTimeSpan(timespan: E_TIME_PRESET_KEYS) {
        this.timeSpan = timespan;
    }
}

export default new HeatmapStore();
