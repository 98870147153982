import React from 'react';

export interface IGQScoreRiskItemProps {
    risk?: IRiskItem;
    onclick?: (riskId: number, notifyParent: boolean) => void;
    style?: React.CSSProperties;
    selected?: boolean;
}

export interface IRiskItem {
    dimension_id: number;
    relevance_value: number;
    dimension_name: string;
    relativeRelevancePercentage: number;
}

export default class ScoreRiskItem extends React.Component<
    IGQScoreRiskItemProps
> {
    public render() {
        const { risk } = this.props;
        const handleClick = (riskId: number): any => {
            this.props.onclick(riskId, true);
        };
        return (
            <div
                className={
                    this.props.selected
                        ? 'score-risk-item selected-risk'
                        : 'score-risk-item'
                }
                onClick={handleClick.bind(this, risk.dimension_id)}
                style={
                    this.props.selected
                        ? {}
                        : {
                              backgroundImage: `linear-gradient(to right, rgb(95, 112, 130) ${risk.relativeRelevancePercentage}% , #374B60 0% )`,
                          }
                }>
                {risk.dimension_name}
            </div>
        );
    }
}
