import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import chartStore, { maxRanges } from './ChartStore';
import GQTimelineHandle from '../GQChart/GQTimelineHandle';
import GQPopupStorage from '../GQPopup/GQPopupStorage';
import { MOMENTJS_DATE_DISPLAY_FORMAT } from '../../constants';
import { isHeadlessMode } from '../../services/configService';
export interface ISliderHorizontalProps {
    baseDate: moment.Moment;
    displayedMinX: moment.Moment;
    displayedMaxX: moment.Moment;
    minimumPossibleX: moment.Moment;
    maximumPossibleX: moment.Moment;
    onPan: (date: moment.Moment) => void;
    expanded?: boolean;
}

enum E_EdgeDrag {
    left = 1,
    right = 2,
}
interface ISliderHorizontalState {
    dragging: boolean;
    edgeDrag: E_EdgeDrag;
    rel: { x: number; date: moment.Moment };
}

export default class SliderHorizontal extends React.Component<
    ISliderHorizontalProps,
    ISliderHorizontalState
> {
    private parent: HTMLDivElement = null;
    constructor(props: ISliderHorizontalProps) {
        super(props);
        this.state = {
            dragging: false,
            edgeDrag: null,
            rel: null,
        };
    }

    public componentDidUpdate(
        props: ISliderHorizontalProps,
        state: ISliderHorizontalState
    ) {
        if (this.state.dragging && !state.dragging) {
            document.addEventListener('mousemove', this.onMouseMove as any);
            document.addEventListener('mouseup', this.onMouseUp as any);
        } else if (!this.state.dragging && state.dragging) {
            document.removeEventListener('mousemove', this.onMouseMove as any);
            document.removeEventListener('mouseup', this.onMouseUp as any);
        }
    }
    public render() {
        if (isHeadlessMode()) {
            return null;
        }
        const dragClass = classNames(['range-slider-draggable'], {
            dragged: this.state.dragging,
        });
        const maxRange = this.props.maximumPossibleX.diff(
            this.props.minimumPossibleX,
            'days'
        );
        const currentZoomedRange =
            this.props.displayedMaxX.diff(this.props.displayedMinX, 'days') +
            80;
        const width = (currentZoomedRange / maxRange) * 100;
        const left =
            Math.abs(
                this.props.displayedMinX.diff(
                    this.props.minimumPossibleX,
                    'days'
                ) / maxRange
            ) * 100;
        const pickerStyle: React.CSSProperties = {
            width: `${width}%`,
            left: `${left}%`,
        };
        return (
            <div
                className={
                    this.props.expanded
                        ? 'range-horizontal-slider expanded-slider'
                        : 'range-horizontal-slider'
                }
                ref={e => (this.parent = e)}>
                <div
                    className={dragClass}
                    style={{ ...pickerStyle }}
                    onMouseDown={this.onMouseDown}>
                    <GQTimelineHandle
                        id="leftHandle"
                        onMouseDown={this.onMouseDown}
                        onMouseUp={this.onMouseUp}
                    />
                    <GQTimelineHandle
                        id="rightHandle"
                        onMouseDown={this.onMouseDown}
                        onMouseUp={this.onMouseUp}
                    />
                </div>
            </div>
        );
    }

    private tooltipData(date: moment.Moment) {
        return (
            <span className="range-handle-tooltip-content">
                {date.format(MOMENTJS_DATE_DISPLAY_FORMAT)}
            </span>
        );
    }
    private addPopup(isLeft: boolean, el: HTMLDivElement) {
        GQPopupStorage.addData(
            isLeft ? 'left-handle-tooltip' : 'right-handle-tooltip',
            {
                element: el,
                orientation: isLeft ? 'top left' : 'top right',
                tooltipData: this.tooltipData(
                    isLeft ? this.props.displayedMinX : this.props.displayedMaxX
                ),
            }
        );
    }

    private removePopup(isLeft: boolean) {
        GQPopupStorage.addData(
            isLeft ? 'left-handle-tooltip' : 'right-handle-tooltip',
            null
        );
    }

    private onMouseUp = (e: MouseEvent | React.MouseEvent<HTMLDivElement>) => {
        this.removePopup(true);
        this.removePopup(false);
        this.setState({ dragging: false });
        chartStore.setState({
            isDragging: false,
        });
        e.stopPropagation();
        e.preventDefault();
    };

    private onMouseMove = (e: MouseEvent) => {
        if (!this.state.dragging) {
            return;
        }
        const daysMoved =
            ((e.pageX - this.state.rel.x) / this.parent.clientWidth) *
            this.props.maximumPossibleX.diff(
                this.props.minimumPossibleX,
                'days'
            );
        if (this.state.edgeDrag) {
            if (this.state.edgeDrag === E_EdgeDrag.left) {
                const newLeftDate = this.state.rel.date
                    .clone()
                    .add(Math.round(daysMoved), 'days');
                if (
                    newLeftDate.isBetween(
                        maxRanges.minBaseDate,
                        chartStore.minPossibleRangeLeftDate,
                        'days'
                    )
                ) {
                    chartStore.setNewRanges(
                        Math.abs(newLeftDate.diff(this.props.baseDate, 'days')),
                        chartStore.rangeRight
                    );
                    GQPopupStorage.updateData('left-handle-tooltip', {
                        tooltipData: this.tooltipData(chartStore.rangeLeftDate),
                    });
                }
                this.applyVirtualTodayBounds();
            } else {
                const newRightDate = this.state.rel.date
                    .clone()
                    .add(Math.round(daysMoved), 'days');
                if (
                    newRightDate.isBetween(
                        chartStore.minPossibleRangeRightDate,
                        maxRanges.maxBaseDate,
                        'days'
                    )
                ) {
                    chartStore.setNewRanges(
                        chartStore.rangeLeft,
                        Math.abs(newRightDate.diff(this.props.baseDate, 'days'))
                    );
                    GQPopupStorage.updateData('right-handle-tooltip', {
                        tooltipData: this.tooltipData(
                            chartStore.rangeRightDate
                        ),
                    });
                }
                this.applyVirtualTodayBounds();
            }
        } else {
            this.props.onPan(
                this.state.rel.date.clone().add(Math.round(daysMoved), 'days')
            );
        }
        e.stopPropagation();
        e.preventDefault();
    };

    private applyVirtualTodayBounds() {
        const maxVirtualTodayPossibleLeft = chartStore.baseDate
            .clone()
            .startOf('day')
            .subtract(chartStore.rangeLeft, 'days');
        const maxVirtualTodayPossibleRight = chartStore.baseDate
            .clone()
            .startOf('day')
            .add(chartStore.rangeRight, 'days');
        if (
            chartStore.virtualToday.isBefore(maxVirtualTodayPossibleLeft, 'd')
        ) {
            this.onVirualTodayChange(maxVirtualTodayPossibleLeft);
        }

        if (
            chartStore.virtualToday.isAfter(maxVirtualTodayPossibleRight, 'd')
        ) {
            this.onVirualTodayChange(maxVirtualTodayPossibleRight);
        }
    }

    private onVirualTodayChange = (date: moment.Moment) => {
        if (!date.isSame(chartStore.virtualToday, 'd')) {
            chartStore.setState({
                virtualToday: date.clone(),
            });
        }
    };

    private onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        if (e.button !== 0) {
            return;
        }
        let date: moment.Moment = null;
        let edgeDrag: E_EdgeDrag = null;
        switch (true) {
            case e.currentTarget.id === 'leftHandle':
                date = this.props.displayedMinX;
                edgeDrag = E_EdgeDrag.left;
                this.addPopup(true, e.currentTarget);
                break;
            case e.currentTarget.id === 'rightHandle':
                date = this.props.displayedMaxX;
                edgeDrag = E_EdgeDrag.right;
                this.addPopup(false, e.currentTarget);
                break;
            default:
                date = this.props.baseDate;
        }
        this.setState({
            dragging: true,
            edgeDrag,
            rel: {
                x: e.pageX,
                date: date.clone(),
            },
        });
        chartStore.setState({
            isDragging: true,
        });
        e.stopPropagation();
        e.preventDefault();
    };
}
