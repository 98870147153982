import * as _ from 'lodash';
import React from 'react';
import { inject, observer } from 'mobx-react';

import CWStore from '../../../../stores/CustomWeightStore';
import GQSettingsInput from '../../../GQInput/GQSettingsInput';
import {
    IPresetSub,
    IPreset,
    ICustomWeightTree,
    IActiveCountry,
} from '../../../../interfaces';
import GQCGCountryList from '../CountryGroups/Picker/GQCGCountryList';
import GQCGSelectedList from '../CountryGroups/Picker/GQCGSelectedList';
import IGQCWSlider from './GQCustomWeightsSlider';
import EventsService, {
    EventSubscription,
} from '../../../../services/EventsService';
import loading from '../../../../services/LoadingService';
import { IMobxRootState } from 'RootStore';

interface Props {
    sub: IPresetSub;
    viewingPreset: IPreset;
    activeTree: ICustomWeightTree[];
    activePath: number[];
    editingID: string;
    onChange: () => void;
    treeGen: (
        tree: ICustomWeightTree,
        width: number,
        depth: number,
        parent: ICustomWeightTree,
        id: string
    ) => JSX.Element;
    licensedCountriesArr?: IActiveCountry[];
}

interface State {
    showCountryList: boolean;
    filter: string;
}

@inject(({ countryStore }: IMobxRootState) => {
    return {
        licensedCountriesArr: countryStore.countries.licensedCountriesArr,
    };
})
@observer
export default class GQCWSubPreset extends React.Component<Props, State> {
    private focusTimer: any = null;
    private nameTimer: any = null;
    private countryTimer: any = null;
    private onClickListener: EventSubscription = null;
    private mounted = false;
    constructor(props: Props) {
        super(props);
        this.state = {
            showCountryList: false,
            filter: '',
        };
    }
    public componentDidMount() {
        this.mounted = true;
        this.onClickListener = EventsService.registerOnMouseLeftClick(() => {
            if (this.mounted) {
                this.setState({
                    showCountryList: false,
                });
            }
        });
    }
    public componentWillUnmount() {
        this.onClickListener.remove();
    }
    public render() {
        const { sub, licensedCountriesArr } = this.props;
        return (
            <div
                className="gq-cw-overview sub"
                key={sub.id}
                id={`gqCWTree:${sub.id}`}>
                <div className="gq-cw-header">
                    <GQSettingsInput
                        editable={this.props.viewingPreset.editable}
                        onChange={this.onNameChange}
                        placeholder={sub.name}
                    />
                    <div className="gq-cw-picker">
                        <div className="gq-cg-picker-bar">
                            <div className="gq-cg-search">
                                <i className="icon gqi-search" />
                                <input
                                    type="search"
                                    placeholder="Type country..."
                                    onChange={this.onFilterChange}
                                    onFocus={this.onSearchFocus}
                                    onBlur={this.onSearchBlur}
                                    onClick={this.stopPropagation}
                                />
                                <i className="icon gqi-dropdown" />
                                {this.state.showCountryList && (
                                    <GQCGCountryList
                                        show={this.state.showCountryList}
                                        list={
                                            licensedCountriesArr
                                                ? _.filter(
                                                      licensedCountriesArr,
                                                      c => {
                                                          const filterState =
                                                              (this.state
                                                                  .filter &&
                                                                  c.name
                                                                      .toLowerCase()
                                                                      .includes(
                                                                          this.state.filter.toLowerCase()
                                                                      )) ||
                                                              !this.state
                                                                  .filter;
                                                          return (
                                                              filterState &&
                                                              !CWStore.selectedCountries.includes(
                                                                  c.id
                                                              )
                                                          );
                                                      }
                                                  )
                                                : []
                                        }
                                        onChange={this.toggleId}
                                    />
                                )}
                            </div>
                            <GQCGSelectedList
                                selectedIds={CWStore.getCountriesBySub(sub.id)}
                                onClick={this.toggleId}
                            />
                        </div>
                    </div>
                </div>
                {this.props.treeGen(sub.tree, 100, 1, null, sub.id)}
                <div className="gq-cw-slider-container">
                    <IGQCWSlider
                        activeEntries={this.props.activeTree}
                        parentPath={this.props.activePath}
                        onChange={this.props.onChange}
                        presetId={
                            this.props.viewingPreset &&
                            this.props.viewingPreset.id
                        }
                        editable={
                            this.props.viewingPreset &&
                            this.props.viewingPreset.editable
                        }
                        show={this.props.editingID === sub.id}
                        childID={sub.id}
                    />
                </div>
            </div>
        );
    }
    private stopPropagation = (e: React.MouseEvent<HTMLInputElement>) => {
        e.stopPropagation();
    };
    private onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        clearTimeout(this.nameTimer);
        const val = e.currentTarget.value;
        this.nameTimer = setTimeout(() => {
            loading.start();
            const newSub = { ...this.props.sub };
            newSub.name = val;
            CWStore.updateSub(this.props.viewingPreset.id, newSub);
            loading.stop();
        }, 1500);
    };
    private onFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            filter: e.currentTarget.value,
        });
    };
    private onSearchFocus = () => {
        this.setState({
            showCountryList: true,
        });
    };
    private onSearchBlur = () => {
        this.focusTimer = setTimeout(() => {
            this.setState({
                showCountryList: false,
            });
        }, 200);
    };
    private toggleId = (id: number) => {
        clearTimeout(this.focusTimer);
        clearTimeout(this.countryTimer);
        CWStore.toggleSubCountry(this.props.sub.id, id);
        setTimeout(() => {
            const newSub = { ...this.props.sub };
            newSub.countries = CWStore.getCountriesBySub(newSub.id);
            CWStore.updateSub(this.props.viewingPreset.id, newSub);
        }, 500);
    };
}
