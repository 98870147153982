import { ReactNode, useEffect } from 'react';
import React from 'react';
import DriftChat from 'components/GQDriftChat/GQDriftChat';
import { isLocalhost } from 'services/configService';
import GQPopupManager from 'components/GQPopup/GQPopupManager';
import * as IPC from './components/eventsFeed/EventsWorkerIPC';
import rootStore, { IMobxRootState } from './RootStore';
import { inject } from 'mobx-react';

type Props = {
    children: ReactNode;
    didPreload?: boolean;
    activeGroupId?: string;
};

const PageContainer = inject(({ settingsStore }: IMobxRootState) => {
    return {
        activeGroupId: settingsStore.activeGroupId,
    };
})(({ children, didPreload, activeGroupId }: Props) => {
    useEffect(() => {
        const { eventsHandlerWorker } = rootStore.eventsFeedStore;
        eventsHandlerWorker.postMessage({
            code: IPC.E_EVENTS_WORKER_IPC_CODES.SET_ACTIVE_GROUP,
            activeGroupId,
        });
    }, [activeGroupId]);
    return (
        <div className="app">
            {!isLocalhost() && didPreload && <DriftChat appId="9uyfwsxe6kpa" />}
            <GQPopupManager />
            {children}
        </div>
    );
});

export default PageContainer;
