import { IUserSettings } from '../interfaces';
import { useEffect, useState } from 'react';
import APIService from 'services/APIService';

let userSettingsPromise: Promise<IUserSettings>;

const getUserSettings = (): Promise<IUserSettings> => {
    if (!userSettingsPromise) {
        userSettingsPromise = APIService.me();
    }

    return userSettingsPromise;
};

const useSettings = () => {
    const [settings, setSettings] = useState<IUserSettings>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        (async () => {
            try {
                const settings = await getUserSettings();
                setSettings(settings);
            } catch (e) {
                APIService.logout();
                // we don't have access to history.push,
                // so we must redirect using window.location.href
                window.location.href = '/';
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    return { settings, loading };
};

export default useSettings;
