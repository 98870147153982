import React from 'react';
import cx from 'classnames';
import moment from 'moment';
import { EventSubscription } from 'fbemitter';
import { E_TAB_FILTER } from './ActivityPageInterfaces';
import GQFlag from '../../GQFlag';
import GQButton from '../../GQButton/GQButton';
import GQPopupStorage from '../../GQPopup/GQPopupStorage';
import { GQMenu, GQMenuItem } from '../../GQMenu';
import {
    E_RISK_TYPE,
    IActiveDimension,
    IAPIActiveClientFacingIndicator,
    IBasicActivityRecord,
} from '../../../services/lib/APIServiceInterfaces';
import EventsService from '../../../services/EventsService';
import { get, flow, map, filter, uniq, join } from 'lodash/fp';

export interface IActivityRowProps {
    activeTab: E_TAB_FILTER;
    activity: IBasicActivityRecord;
    onSelect: (activity: IBasicActivityRecord) => void;
    onActivityDelete: (activity: IBasicActivityRecord) => void;
    onActivityEdit: (activity: IBasicActivityRecord) => void;
    onActivityDownload: (activity: IBasicActivityRecord) => void;
    onActivityBroadcast: (
        activity: IBasicActivityRecord,
        sendEmail: boolean
    ) => void;
    isActive: boolean;
    dimensions: {
        [dimensionId: number]: IActiveDimension;
    };
    activeClientFacingIndicators: IAPIActiveClientFacingIndicator;
    canShare: boolean;
    canDownloadData: boolean;
    rowHeight: any; // For virtual list rendering
}

interface IActivityRecordRowState {
    hovered: boolean;
    showMenu: boolean;
}

export default class ActivityRecordRow extends React.Component<
    IActivityRowProps,
    IActivityRecordRowState
> {
    private clickSub: EventSubscription;
    private mounted = false;
    constructor(props: IActivityRowProps) {
        super(props);
        this.state = {
            hovered: false,
            showMenu: false,
        };
    }
    public componentDidMount() {
        this.mounted = true;
        this.clickSub = EventsService.registerOnMouseLeftClick(this.hideMenu);
    }
    public componentWillUnmount() {
        this.mounted = false;

        if (this.clickSub) {
            this.clickSub.remove();
        }
    }
    public render() {
        return (
            <tr
                key={this.props.activity._id}
                className={cx([
                    'activity-history-record',
                    { active: this.props.isActive },
                ])}
                onClick={this.setActive}
                onMouseEnter={this.onHover}
                onMouseLeave={this.onHoverOut}
                onMouseOver={this.onHover}
                style={{ height: this.props.rowHeight || '100%' }}>
                <td className="activity-history-record-type">
                    <span>
                        {!this.props.activity.seenByUser && (
                            <div className="activity-history-record-type-notification" />
                        )}
                        <i
                            className={`icon ${this.resolveTypeIcon()} ${
                                4 > 5 ? 'notification n-s' : ''
                            }`}
                        />
                    </span>
                </td>

                <td className="activity-history-record-name">
                    <span>{this.props.activity.name}</span>
                </td>

                <td
                    className={cx('activity-history-record-created', {
                        unshared: !this.props.activity.shared,
                    })}>
                    {moment(
                        this.props.activity.shareDate ||
                            this.props.activity.date
                    ).format('MMM DD, YYYY')}
                </td>

                {this.renderCountriesAndDimensions()}

                <td>
                    <div className="activity-record-row-menu-button-container flex-container align-middle">
                        <GQButton
                            containerClassName="activity-record-row-menu-button"
                            noPadding
                            noBorder
                            icon="gqi-ellipsis-v"
                            onClick={this.showMenu}
                            style={{ paddingTop: 8 }}
                        />
                    </div>
                </td>
            </tr>
        );
    }

    private hideMenu = () => {
        GQPopupStorage.addData('activity-record-menu', null);
        if (this.mounted) {
            this.setState({
                showMenu: false,
                hovered: false,
            });
        }
    };

    private showMenu = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();

        this.setState({
            showMenu: true,
        });

        GQPopupStorage.addData('activity-record-menu', {
            element: e.currentTarget,
            orientation: 'top right',
            tooltipData: (
                <GQMenu>
                    <GQMenuItem
                        icon="gqi-edit"
                        onClick={this.onEditClick}
                        label="Edit"
                        disabled={!this.props.activity.canEdit}
                    />

                    {this.props.canShare && (
                        <GQMenuItem
                            icon="gqi-share-1"
                            colorIcon={
                                this.props.activity.shared ? '#17FF96' : null
                            }
                            onClick={this.onBroadcastClick(false)}
                            label={
                                !this.props.activity.shared
                                    ? 'Publish'
                                    : 'Unpublish'
                            }
                            disabled={!this.props.activity.createdByMe}
                        />
                    )}

                    {this.props.canShare && !this.props.activity.shared && (
                        <GQMenuItem
                            icon="gqi-email"
                            colorIcon={
                                this.props.activity.shared ? '#17FF96' : null
                            }
                            onClick={this.onBroadcastClick(true)}
                            label={'Email'}
                            disabled
                        />
                    )}
                    {this.props.canDownloadData && (
                        <GQMenuItem
                            icon="gqi-download"
                            onClick={this.onDownloadClick}
                            label="Download"
                        />
                    )}
                    <GQMenuItem
                        icon="gqi-goto"
                        label="Open as Daily"
                        href={get('dailyEmailTemplateURL', this.props.activity)}
                    />
                    <GQMenuItem
                        icon="gqi-goto"
                        label="Open as Weekly"
                        href={get(
                            'weeklyEmailTemplateURL',
                            this.props.activity
                        )}
                    />

                    <GQMenuItem
                        icon="gqi-pdf"
                        label="Export PDF"
                        href={get('pdfURL', this.props.activity)}
                    />

                    <GQMenuItem
                        icon="gqi-delete"
                        onClick={this.onDeleteClick}
                        label="Delete"
                        disabled={!this.props.activity.createdByMe}
                    />
                </GQMenu>
            ),
        });
    };

    private onEditClick = () => this.props.onActivityEdit(this.props.activity);

    private onBroadcastClick = (sendEmail: boolean) => {
        return () =>
            this.props.onActivityBroadcast(this.props.activity, sendEmail);
    };

    private onDownloadClick = () =>
        this.props.onActivityDownload(this.props.activity);

    private onDeleteClick = async () => {
        this.hideMenu();
        this.props.onActivityDelete(this.props.activity);
    };

    private onHover = () => {
        if (!this.state.hovered) {
            this.setState({
                hovered: true,
            });
        }
    };

    private onHoverOut = () => {
        if (this.state.hovered && !this.state.showMenu) {
            this.setState({
                hovered: false,
            });
        }
    };

    private resolveTypeIcon() {
        switch (this.props.activeTab) {
            case E_TAB_FILTER.MY:
                return 'gqi-user';
            case E_TAB_FILTER.GQ:
                return 'gqi-gq-1';
            default:
                return 'gqi-gq-1';
        }
    }

    private setActive = () => {
        this.props.onSelect(this.props.isActive ? null : this.props.activity);
    };

    private renderCountriesAndDimensions() {
        return (
            <td className="activity-history-record-selection">
                <span>
                    {flow(
                        get('activity.countries'),
                        map((country: number) => (
                            <GQFlag
                                key={country}
                                countryId={country}
                                getFlagFromAll={true}
                            />
                        ))
                    )(this.props)}
                    / {this.extractRisksNameList()}
                </span>
            </td>
        );
    }

    private extractRisksNameList() {
        return flow(
            get('activity.risks'),
            map((risk: { type: E_RISK_TYPE; id: number }) => {
                if (risk.type === E_RISK_TYPE.DIMENSIONS) {
                    return get(['dimensions', risk.id, 'lname'], this.props);
                }
                if (risk.type === E_RISK_TYPE.CLIENT_FACING_INDICATORS) {
                    return get(
                        [
                            'activeClientFacingIndicators',
                            risk.id,
                            'display_name',
                        ],
                        this.props
                    );
                }
                return null;
            }),
            filter(risk => !!risk),
            uniq,
            join(', ')
        )(this.props);
    }
}
