import { E_RISK_TYPE, VIEW } from 'interfaces';
import React from 'react';
import { IMobxRootState } from '../../RootStore';
import IndicatorsSidebarRiskPicker from 'components/sidebarRiskPicker/IndicatorsSidebarRiskPicker';
import EventsFeedDataProvider from 'components/eventsFeed/EventFeedDataProvider';
import ChartContainer from 'components/GQChart/ChartContainer';
import { inject } from 'mobx-react';
import useInitCountryDim from 'hooks/useInitCountryDim';
import useInitChart from 'hooks/useInitChart';

type Props = {
    height?: number;
};

export const WorldGraphPage = React.memo(() => {
    return (
        <React.Fragment>
            <EventsFeedDataProvider />
            <WorldGraph />
            <IndicatorsSidebarRiskPicker />
        </React.Fragment>
    );
});

export const WorldGraph = inject(({ chartStore }: IMobxRootState) => {
    return {
        height: chartStore.height,
    };
})(({ height }: Props) => {
    useInitChart();
    const ready = useInitCountryDim({
        riskType: E_RISK_TYPE.DIMENSIONS,
        multiCountry: true,
        multiRisk: false,
    });
    return (
        ready && (
            <ChartContainer
                expanded
                height={height}
                activeView={VIEW.WORLD_GRAPH}
                changeDataWhenDragStop={true}
            />
        )
    );
});
