import React from 'react';
import * as _ from 'lodash';
import GQFlag from '../GQFlag';
import { VIEW, IActiveCountry } from '../../interfaces';
import { GQutils } from '../../services/GQutils';
import ToggleComponentButton, {
    ToggleButtonProps,
} from 'components/shared/ToggleComponentButton';
import CountryPicker from 'components/CountryPicker/CountryPicker';
import { GQButton } from 'components/GQButton';

export interface IGQScoreCountriesProps {
    suggestedCountries: number[];
    currentView?: VIEW;
    selectCountry?: number;
    editMode: boolean;
    selectMode: boolean;
    onSelected?: (countryId: number) => void;
    countriesList: IActiveCountry[];
}
export interface IGQScoreCountriesState {
    selectedCountryId: number;
    selectMode: boolean;
    title: string;
    isSeeAll: boolean;
    selectedCountryName: any;
    customCountries: number[];
}

export default class ScoreCountries extends React.Component<
    IGQScoreCountriesProps,
    IGQScoreCountriesState
> {
    private toggleCountryPickerRef = null;
    constructor(props: IGQScoreCountriesProps) {
        super(props);
        this.state = {
            selectedCountryId: this.props.selectCountry,
            selectMode: this.props.suggestedCountries.length > 1,
            isSeeAll: false,
            selectedCountryName: '',
            customCountries: [],
            title:
                this.props.suggestedCountries.length > 1
                    ? 'Suggested countries'
                    : 'Selected country',
        };
        this.getCountryNameById(this.props.selectCountry);
    }

    public UNSAFE_componentWillReceiveProps(nextProps: IGQScoreCountriesProps) {
        if (
            JSON.stringify(nextProps.suggestedCountries) !==
                JSON.stringify(this.props.suggestedCountries) ||
            nextProps.selectCountry === 0
        ) {
            // move to another event
            this.setState({
                customCountries: [],
            });

            if (
                nextProps.suggestedCountries.length === 1 &&
                nextProps.suggestedCountries[0] !== this.props.selectCountry
            ) {
                //when event has only one country it shul be selected by default
                this.props.onSelected(nextProps.suggestedCountries[0]);
                this.setState({
                    selectedCountryId: nextProps.suggestedCountries[0],
                });
            }
        }
        if (this.props.selectCountry !== nextProps.selectCountry) {
            this.setState({
                selectedCountryId: nextProps.selectCountry,
            });
            this.getCountryNameById(nextProps.selectCountry);
        }
    }

    private renderTogglePickerButton({ isVisible }: ToggleButtonProps) {
        return (
            <div className="score-countries-toggle-picker-button">
                <div className="score-countries-see-all" id="seeAllCountries">
                    {isVisible ? 'Collapse all' : 'See all'}
                </div>
                <GQButton
                    icon="gqi-dropdown"
                    noBorder={true}
                    noPadding={true}
                    style={{
                        fontSize: '21px',
                        color: 'rgba(255,255,255,0.6)',
                        verticalAlign: 'middle',
                        marginLeft: '4px',
                        backgroundColor: 'rgba(255, 255, 254, 0.2)',
                        borderRadius: '50%',
                        width: '19px',
                        height: '19px',
                        marginTop: '1px',
                    }}
                />
            </div>
        );
    }
    public render() {
        const selectCountry = (id: number): any => {
            if (
                !this.state.customCountries.includes(id) &&
                !this.props.suggestedCountries.includes(id)
            ) {
                this.state.customCountries.push(id);
            }
            this.props.onSelected(id);
            seeAll(false);
        };
        const seeAll = (mode: boolean): any => {
            this.setState({
                isSeeAll: mode,
            });
        };
        const selectedCounteyStyle = {
            border: '1.5px solid #041f3c',
            boxShadow: '0 0 0 2px rgb(234, 180, 20)',
        };
        return (
            <div className="score-countries-container">
                <div className="score-countries-header">
                    <div className="score-countries-title">Select country</div>
                    <ToggleComponentButton
                        renderToggleButton={this.renderTogglePickerButton}
                        ref={ref => (this.toggleCountryPickerRef = ref)}>
                        <div className="score-countries-country-picker-container">
                            <CountryPicker
                                canSelectAllCountries={false}
                                countriesList={this.props.countriesList}
                                selectedCountriesList={[]}
                                isMultiSelect={false}
                                onCountrySelected={(countryId: number) => {
                                    selectCountry(countryId);
                                    this.toggleCountryPickerRef.hideComponent();
                                }}
                                horizontalAlignment="right"
                            />
                        </div>
                    </ToggleComponentButton>
                </div>
                <div className={'select-country-mode'}>
                    {_.map(this.props.suggestedCountries, country => {
                        return (
                            <div
                                onClick={selectCountry.bind(this, country)}
                                key={country}>
                                {' '}
                                <GQFlag
                                    countryId={country}
                                    key={country}
                                    className="score-select-country-flag"
                                    style={
                                        this.state.selectedCountryId === country
                                            ? selectedCounteyStyle
                                            : {}
                                    }
                                />{' '}
                            </div>
                        );
                    })}
                    {_.map(this.state.customCountries, country => {
                        return (
                            <div
                                onClick={selectCountry.bind(this, country)}
                                key={country}>
                                {' '}
                                <GQFlag
                                    countryId={country}
                                    key={country}
                                    className="score-select-country-flag"
                                    style={
                                        this.state.selectedCountryId === country
                                            ? selectedCounteyStyle
                                            : {}
                                    }
                                />{' '}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
    private getCountryNameById = async (id: number) => {
        try {
            const countryName = await GQutils.findCountryNameByID(id);
            this.setState({
                selectedCountryName: countryName,
            });
        } catch (err) {
        }
    };
}
