import React from 'react';

type Props = {
    isRadioButton: boolean;
    isSelected: boolean;
    onChange?: () => void;
};

const CheckBox = (props: Props) => {
    const { onChange, isSelected, isRadioButton } = props;
    return (
        <label className="checkbox-container">
            <input
                type="checkbox"
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                    e.stopPropagation();
                }}
                onChange={onChange}
                readOnly={!onChange}
                checked={isSelected}
            />
            <span
                className={
                    isRadioButton ? 'radioButton checkmark' : 'checkmark'
                }
            />
        </label>
    );
};

export default CheckBox;
