import React from 'react';
import { TR, TD, TBody } from '../InsightTemplateElements';
import InsightTemplateHtmlFooterLinks from './InsightTemplateHtmlFooterLinks';

const InsightTemplateHtmlFooter = () => {
    return (
        <React.Fragment>
            <TR>
                <TD
                    style={{ padding: 0, width: '100%', maxWidth: '600px' }}
                    width={600}>
                    <hr />
                </TD>
            </TR>
            <TR>
                <TD valign="top" align="center" style={{ padding: 0 }}>
                    <table style={{ width: 'auto' }}>
                        <TBody>
                            <TR>
                                <InsightTemplateHtmlFooterLinks />
                            </TR>
                        </TBody>
                    </table>
                </TD>
            </TR>
            <TR>
                <TD
                    style={{ padding: 0, width: '100%', maxWidth: '600px' }}
                    width={600}>
                    <hr />
                </TD>
            </TR>
            <TR>
                <TD width={600} style={{ width: '100%', maxWidth: '600px' }}>
                    <span
                        style={{
                            fontSize: '10px',
                            fontStyle: 'italic',
                            color: '#868383',
                        }}>
                        DISCLAIMER: Any opinions, estimates or projections
                        expressed herein may assume some economic, industry and
                        political considerations and constitute current
                        opinions, at the time of issuance, that are subject to
                        change. This information is being furnished to you for
                        informational purposes only, and on the condition that
                        it will not form a primary basis for any investment
                        decision and is intended for institutional clients only.
                        This report may contain links to third-party websites,
                        and GeoQuant Inc. is not responsible for their content
                        or any linked content contained therein; access to these
                        links is at your own risk. This message is intended only
                        for the use of the individuals to which it is addressed
                        and may contain information that is proprietary,
                        privileged or confidential. If you are not the named
                        addressee unauthorized use, disclosure or copying is
                        strictly prohibited, and may be unlawful. If you have
                        received this communication in error, please immediately
                        notify the sender by return e-mail, and delete the
                        original message and all copies from your system.
                    </span>
                </TD>
            </TR>
            <TR>
                <TD style={{ padding: 0 }} align="center" width={600}>
                    <span
                        style={{
                            fontSize: '12px',
                            color: '#868383',
                        }}>
                        Copyright © *|CURRENT_YEAR|* *|LIST:COMPANY|*, All
                        rights reserved.
                    </span>
                </TD>
            </TR>
            <TR>
                <TD style={{ padding: 0 }} align="center" width={600}>
                    <span
                        style={{
                            fontSize: '12px',
                            color: '#868383',
                        }}>
                        *|IFNOT:ARCHIVE_PAGE|* *|LIST:DESCRIPTION|* *|END:IF|*
                    </span>
                </TD>
            </TR>
        </React.Fragment>
    );
};

export default InsightTemplateHtmlFooter;
