import React from 'react';

export interface IAnimatedArrowProps {
    arrowMode?: 'up' | 'down';
    onClick?: () => void;
    show?: boolean;
}

export default class AnimatedArrow extends React.Component<
    IAnimatedArrowProps
> {
    public render() {
        return (
            <div
                className={
                    this.props.show
                        ? 'show animatedArrowContainer'
                        : 'hide animatedArrowContainer'
                }
                onClick={this.props.onClick}>
                <div className="animated-arrow-lines">
                    <div className={this.props.arrowMode + ' left'} />{' '}
                    <div className={this.props.arrowMode + ' right'} />
                </div>
            </div>
        );
    }
}
