import React from 'react';
import * as _ from 'lodash';
import { inject, observer } from 'mobx-react';
import TransitionGroup from 'react-addons-transition-group';
import { IActiveCountry, IActiveCountryMap } from '../../../../../interfaces';
import ListItem from './GQCGSelectedItem';
import GQCountryButton from '../../../../GQCountryPicker/GQCountryButton';
import { DeprecatedCSSProperties } from '../../../../../types/DeprecatedCSSProperties';
import { TweenMax } from 'gsap';
import { IMobxRootState } from 'RootStore';

interface IListItemStyle {
    top: React.CSSProperties['top'];
    left: React.CSSProperties['left'];
}
interface Props {
    selectedIds: number[];
    disabled?: boolean;
    style?: DeprecatedCSSProperties;
    onClick?: (id?: number) => void;
    useSelectable?: boolean;
    selected?: number[];
    licensedCountries?: IActiveCountryMap;
}

@inject(({ countryStore }: IMobxRootState) => {
    return {
        licensedCountries: countryStore.countries.licensedCountries,
    };
})
@observer
export default class GQCGSelectedList extends React.Component<Props> {
    private containerRef: HTMLDivElement = null;
    private anchorX: number = null;
    public componentDidUpdate(prevProps: Props) {
        const prevLen =
            (prevProps.selectedIds && prevProps.selectedIds.length) || 0;
        const nextLen =
            (this.props.selectedIds && this.props.selectedIds.length) || 0;
        TweenMax.fromTo(
            this.containerRef,
            0.3,
            { width: 28 * prevLen },
            { width: 28 * nextLen }
        );
    }
    public render() {
        const items: Array<IActiveCountry & IListItemStyle> = [];
        const { style } = this.props;
        if (this.props.selectedIds && this.props.licensedCountries) {
            let index = 0;
            const itemHeight = this.props.useSelectable ? 22 : 16;
            this.props.selectedIds.forEach((id, idx) => {
                items.push({
                    ...this.props.licensedCountries[id],
                    left: index * 28,
                    top:
                        style && style.height
                            ? Math.max(0, style.height - itemHeight) / 2
                            : (33 - itemHeight) / 2,
                });
                index += 1;
            });
        }
        return (
            <div
                className="gq-cg-selected-list"
                style={this.props.style}
                ref={el => (this.containerRef = el)}
                onMouseDown={this.onMouseDown}
                onClick={this.stopPropagation}>
                <TransitionGroup>
                    {_.map(items, c =>
                        this.props.useSelectable ? (
                            <GQCountryButton
                                key={c.id}
                                id={c.id}
                                containerStyle={{ position: 'absolute' }}
                                animStyle={{
                                    top: c.top,
                                    left: c.left,
                                }}
                                onClick={this.props.onClick}
                                isSelected={this.props.selected.includes(c.id)}
                            />
                        ) : (
                            <ListItem
                                key={c.id}
                                id={c.id}
                                name={c.name}
                                left={c.left}
                                top={c.top}
                                disabled={this.props.disabled}
                                onClick={this.props.onClick}
                            />
                        )
                    )}
                </TransitionGroup>
            </div>
        );
    }
    private onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        this.anchorX = e.pageX;
        window.addEventListener('mousemove', this.onMouseMove);
        window.addEventListener('mouseup', this.onMouseUp);
    };
    private onMouseMove = (e: MouseEvent) => {
        const el = this.containerRef;
        el.scrollLeft = this.anchorX - e.pageX;
        const mainEl = document.getElementById('mainApp');
        if (mainEl) {
            mainEl.classList.add('dragging');
        }
    };
    private onMouseUp = (e: MouseEvent) => {
        e.stopPropagation();
        window.removeEventListener('mousemove', this.onMouseMove);
        window.removeEventListener('mouseup', this.onMouseUp);
        const mainEl = document.getElementById('mainApp');
        if (mainEl) {
            mainEl.classList.remove('dragging');
        }
    };
    private stopPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
    };
}
