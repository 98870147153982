import React from 'react';
import * as ReactDOM from 'react-dom';
import cx from 'classnames';

import { Transition } from 'react-transition-group';
import { TweenLite } from 'gsap';

export enum E_MODAL_ALIGN {
    TOP = 'align-top',
    MIDDLE = 'align-middle',
    BOTTOM = 'align-bottom',
}

interface IGQModalProps {
    show: boolean;
    align?: E_MODAL_ALIGN;
    onClick?: () => void;
}

export default class GQModal extends React.Component<IGQModalProps> {
    public render() {
        return ReactDOM.createPortal(
            <Transition
                in={this.props.show}
                timeout={300}
                onEnter={this.onCoverEnter}
                onExit={this.onCoverExit}>
                <div
                    className={cx([
                        'gq-modal-container',
                        'flex-container',
                        'align-center-middle',
                        {
                            visible: this.props.show,
                        },
                    ])}
                    onClick={this.onClick}>
                    <div className="gq-modal-cover" />
                    {this.props.show && (
                        <div
                            className={cx([
                                'gq-modal-content flex-container align-center',
                                { [this.props.align]: !!this.props.align },
                            ])}>
                            {this.props.children}
                        </div>
                    )}
                </div>
            </Transition>,
            document.body
        );
    }
    private onClick = () => {
        if (this.props.onClick) {
            this.props.onClick();
        }
    };
    private onCoverEnter = (node: HTMLElement) => {
        TweenLite.set(node, { clearProps: 'all' });
        TweenLite.from(node, 0.3, { opacity: 0 });
    };
    private onCoverExit = (node: HTMLElement) => {
        TweenLite.set(node, { clearProps: 'all' });
        TweenLite.to(node, 0.3, { opacity: 0 });
    };
}
