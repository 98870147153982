import React from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import {
    DATE_FNS_DATE_DISPLAY_FORMAT,
    C_TIMELINE_MIN_DATE,
    C_TIMELINE_MAX_DATE,
    MOMENTJS_DATE_DISPLAY_FORMAT,
    TIME_WINDOW_PRESETS,
    E_TIME_PRESET_KEYS,
} from '../../constants';
import { invoke } from 'lodash/fp';
import { dateOrMinAllowed, dateOrMaxAllowed } from '../../utils/generalUtils';

interface IGQDateRangePickerProps {
    initialStartDate?: Date;
    initialEndDate?: Date;
    onChange: (startDate: Date, endDate: Date) => void;
    endDateVisible: boolean;
    allowPresetSelectionOutOfRange?: boolean;
    maxDate?: Date;
    minDate?: Date;
    presets: E_TIME_PRESET_KEYS[];
}

interface IGQDateRangePickerState {
    isOpened: boolean;
    dateChangeNotReported: boolean;
    startDate: Date;
    endDate: Date;
    selectedPreset: E_TIME_PRESET_KEYS;
}

export default class GQDateRangePicker extends React.Component<
    IGQDateRangePickerProps,
    IGQDateRangePickerState
> {
    private dateRangePickerRefNode: Node;

    public static defaultProps: Partial<IGQDateRangePickerProps> = {
        endDateVisible: true,
    };
    constructor(props: IGQDateRangePickerProps) {
        super(props);
        const startDate =
            props.initialStartDate ||
            moment()
                .subtract(90, 'days')
                .toDate();
        const endDate =
            props.initialEndDate ||
            moment()
                .add(90, 'days')
                .toDate();

        this.state = {
            isOpened: false,
            dateChangeNotReported: false,
            selectedPreset: null,
            startDate: dateOrMinAllowed(startDate, props.minDate),
            endDate: dateOrMaxAllowed(endDate, props.maxDate),
        };
    }

    public componentDidUpdate(prevProps: IGQDateRangePickerProps) {
        if (
            invoke('getTime', prevProps.initialStartDate) !==
                invoke('getTime', this.props.initialStartDate) ||
            invoke('getTime', prevProps.minDate) !==
                invoke('getTime', this.props.minDate) ||
            invoke('getTime', prevProps.initialEndDate) !==
                invoke('getTime', this.props.initialEndDate) ||
            invoke('getTime', prevProps.maxDate) !==
                invoke('getTime', this.props.maxDate)
        ) {
            let { startDate, endDate } = this.state;
            if (this.props.initialStartDate) {
                startDate = this.props.allowPresetSelectionOutOfRange
                    ? this.props.initialStartDate
                    : dateOrMinAllowed(
                          this.props.initialStartDate,
                          this.props.minDate
                      );
            }
            if (this.props.initialEndDate) {
                endDate = this.props.allowPresetSelectionOutOfRange
                    ? this.props.initialEndDate
                    : dateOrMaxAllowed(
                          this.props.initialEndDate,
                          this.props.maxDate
                      );
            }
            this.setState({
                startDate,
                endDate,
                selectedPreset: this.matchDatesRangeToPresets(
                    startDate,
                    endDate
                ),
            });
        }
    }

    public render() {
        return (
            <div
                className="top-nav-date-picker row"
                ref={node => (this.dateRangePickerRefNode = node)}>
                <div className="top-nav-date">
                    <div className="react-datepicker-wrapper">
                        <div
                            className="react-datepicker__input-container"
                            onClick={this.openDateRangePicker}>
                            <button className="react-datepicker__input">
                                {' '}
                                {moment(this.state.startDate).format(
                                    MOMENTJS_DATE_DISPLAY_FORMAT
                                )}{' '}
                                <i
                                    className="gqi-dropdown"
                                    style={{
                                        verticalAlign: 'sub',
                                    }}
                                />
                            </button>
                            <span className="seperator">|</span>
                            <button
                                className="react-datepicker__input"
                                style={{
                                    color: '#fff',
                                    fontSize: '14px',
                                    outline: 'none',
                                    fontWeight: 600,
                                    cursor: 'pointer',
                                }}>
                                {' '}
                                {moment(this.state.endDate).format(
                                    MOMENTJS_DATE_DISPLAY_FORMAT
                                )}{' '}
                                <i
                                    className="gqi-dropdown"
                                    style={{
                                        verticalAlign: 'sub',
                                    }}
                                />
                            </button>
                        </div>
                    </div>
                    {this.state.isOpened && (
                        <div className="date-range-picker-wrapper">
                            <div className="calendars-wrapper">
                                <DatePicker
                                    calendarClassName="start-date-picker"
                                    inline
                                    onChange={this.onStartDateChange}
                                    selected={this.state.startDate}
                                    selectsStart
                                    startDate={this.state.startDate}
                                    endDate={
                                        this.props.endDateVisible &&
                                        this.state.endDate
                                    }
                                    maxDate={
                                        this.props.maxDate ||
                                        C_TIMELINE_MAX_DATE.toDate()
                                    }
                                    minDate={
                                        this.props.minDate ||
                                        C_TIMELINE_MIN_DATE.toDate()
                                    }
                                    dateFormat={DATE_FNS_DATE_DISPLAY_FORMAT}
                                    showYearDropdown
                                    peekNextMonth={false}
                                    onClickOutside={this.handleClickOutside}
                                />
                                {this.props.endDateVisible && (
                                    <DatePicker
                                        calendarClassName="end-date-picker"
                                        inline
                                        onChange={this.onEndDateChange}
                                        selected={this.state.endDate}
                                        selectsEnd
                                        startDate={this.state.startDate}
                                        endDate={this.state.endDate}
                                        minDate={moment(this.state.startDate)
                                            .add(1, 'days')
                                            .toDate()}
                                        maxDate={
                                            this.props.maxDate ||
                                            C_TIMELINE_MAX_DATE.toDate()
                                        }
                                        dateFormat={
                                            DATE_FNS_DATE_DISPLAY_FORMAT
                                        }
                                        showYearDropdown
                                        peekNextMonth={false}
                                        onClickOutside={this.handleClickOutside}
                                    />
                                )}
                            </div>
                            <div className="date-range-presets-wrapper">
                                <div className="date-range-presets">
                                    {this.props.presets.map(preset => {
                                        return (
                                            <span
                                                className={`time-preset ${
                                                    this.state
                                                        .selectedPreset ===
                                                    preset
                                                        ? 'selected'
                                                        : ''
                                                }`}
                                                key={preset}
                                                onClick={() =>
                                                    this.onTimePresetSelected(
                                                        preset as E_TIME_PRESET_KEYS
                                                    )
                                                }>
                                                {preset}
                                            </span>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    private openDateRangePicker = () => {
        this.setState({ isOpened: true });
    };

    private handleClickOutside = (e: any) => {
        if (!this.dateRangePickerRefNode.contains(e.target as Node)) {
            this.closeDateRangePicker();
        }
    };

    private closeDateRangePicker = () => {
        if (this.state.dateChangeNotReported) {
            this.setState(
                {
                    isOpened: false,
                    dateChangeNotReported: false,
                },
                this.reportDateRangeChange
            );
        } else {
            this.setState({ isOpened: false });
        }
    };

    private onTimePresetSelected = (presetKey: E_TIME_PRESET_KEYS) => {
        const timePreset = TIME_WINDOW_PRESETS[presetKey];
        const newEndDate = moment(this.state.startDate)
            .add(timePreset.val as any, timePreset.type)
            .toDate();
        const endDate =
            newEndDate > C_TIMELINE_MAX_DATE.toDate()
                ? C_TIMELINE_MAX_DATE.toDate()
                : newEndDate;
        this.onEndDateChange(endDate);
    };

    private onStartDateChange = (startDate: Date) => {
        const selectedPreset = this.matchDatesRangeToPresets(
            startDate,
            this.state.endDate
        );
        const endDate =
            startDate >= this.state.endDate
                ? moment(startDate)
                      .add(1, 'days')
                      .toDate()
                : this.state.endDate;
        this.setState({
            startDate,
            endDate,
            selectedPreset,
            dateChangeNotReported: true,
        });
    };

    private onEndDateChange = (endDate: Date) => {
        const selectedPreset = this.matchDatesRangeToPresets(
            this.state.startDate,
            endDate
        );
        this.setState(
            {
                endDate,
                selectedPreset,
                dateChangeNotReported: false,
            },
            this.reportDateRangeChange
        );
    };

    private reportDateRangeChange() {
        this.props.onChange(this.state.startDate, this.state.endDate);
    }

    private matchDatesRangeToPresets(
        start: Date,
        end: Date
    ): E_TIME_PRESET_KEYS {
        const startDate = moment(start);
        const presetKey = Object.keys(TIME_WINDOW_PRESETS).find(
            timePresetKey => {
                const timePreset = TIME_WINDOW_PRESETS[timePresetKey];
                return (
                    startDate
                        .clone()
                        .add(timePreset.val as any, timePreset.type)
                        .toDate()
                        .toDateString() === end.toDateString()
                );
            }
        );
        return presetKey as E_TIME_PRESET_KEYS;
    }
}
