import * as _ from 'lodash';
import { action, observable, computed, toJS } from 'mobx';
import {
    IPreset,
    ICustomWeightTree,
    IPresetSub,
} from '../services/lib/APIServiceInterfaces';
import APIService from '../services/APIService';
import StorageService from '../services/StorageService';
import { ICWChangeNotification, E_CW_STATUS } from '../interfaces';
import EventsService from '../services/EventsService';
const NOTE_DISSMISS_KEY = 'CW:Note';

class CustomWeightStore {
    @observable public currentEditingId: string = null;
    @observable public presets: { [id: string]: IPreset } = {};
    @observable public activePreset: ICWChangeNotification;
    @observable public subCountries: Map<
        string,
        Map<number, boolean>
    > = new Map();
    constructor() {
        EventsService.registerOnCWChangeNotification(this.updateActivePreset);
    }

    @action public updateName(newName: string) {
        this.currentEditingPreset.name = newName;
    }

    @computed get hasActiveCustomWeight(): boolean {
        if (!this.activePreset) {
            return false;
        }
        return this.activePreset.status === E_CW_STATUS.READY;
    }

    @action public updateWeights(
        path: number[],
        weights: { [dimensionId: number]: number },
        childID?: string
    ) {
        let node: ICustomWeightTree = null;
        if (childID && this.currentEditingPreset.subs) {
            for (const sub of this.currentEditingPreset.subs) {
                if (sub.id === childID) {
                    node = this.getTreeNode(path, sub.tree);
                }
            }
        } else {
            node = this.getTreeNode(path, this.currentEditingPreset.tree);
        }
        _.forEach(weights, (weight: number, dId) => {
            node.children[Number(dId)].weight = weight;
        });
    }

    @action public setPresets(presets: IPreset[]) {
        const res: typeof CustomWeightStore.prototype.presets = {};
        _.forEach(presets, preset => {
            res[preset.id] = preset;
        });
        this.presets = res;
    }

    @action public overridePreset(preset: IPreset) {
        this.presets[preset.id] = preset;
    }

    @action public switchToActiveId() {
        for (const presetId in this.presets) {
            const preset = this.presets[presetId];
            if (preset.isActive) {
                this.currentEditingId = presetId;
                break;
            }
        }
    }

    @action public setCurrentEditingId(id: string) {
        this.currentEditingId = id;
    }

    @action public createSub(cb?: (id: string) => void) {
        if (!this.currentEditingPreset) {
            return;
        }
        const sub: IPreset['subs'][0] = {
            countries: [],
            id: `${this.currentEditingId}:${Date.now().toString()}`,
            name: 'new',
            tree: toJS(this.currentEditingPreset.tree),
        };
        const presetClone: IPreset = {
            ...this.currentEditingPreset,
            subs: [...this.currentEditingPreset.subs, sub],
        };
        this.overridePreset(presetClone);
        // Workaround to observers not being updated, maybe better to create preset object with constructor thats makes all presets observable
        // =====================================
        const newPresets = { ...this.presets };
        this.presets = newPresets;
        // =====================================
        this.saveCurrentPreset();
        if (cb) {
            cb(sub.id);
        }
    }

    @action public updateSub(presetID: string, sub: IPresetSub) {
        if (this.presets[presetID]) {
            if (this.presets[presetID].subs) {
                const presetClone = { ...this.presets[presetID] };
                for (let i = 0; i < presetClone.subs.length; i++) {
                    if (presetClone.subs[i].id === sub.id) {
                        presetClone.subs[i] = sub;
                        break;
                    }
                }
                this.overridePreset(presetClone);
                this.saveCurrentPreset();
            }
        }
    }

    @action public deleteSub(presetID: string, sub: IPresetSub) {
        if (this.presets[presetID]) {
            if (this.presets[presetID].subs) {
                const presetClone = { ...this.presets[presetID] };
                for (let i = 0; i < presetClone.subs.length; i++) {
                    if (presetClone.subs[i].id === sub.id) {
                        presetClone.subs = [
                            ...presetClone.subs.slice(0, i),
                            ...presetClone.subs.slice(
                                i + 1,
                                presetClone.subs.length
                            ),
                        ];
                        break;
                    }
                }
                this.overridePreset(presetClone);
                this.saveCurrentPreset();
            }
        }
    }

    @action public toggleSubCountry(subID: string, countryID: number) {
        if (!this.currentEditingPreset) {
            return;
        }
        const countries = this.getCountriesBySub(subID);
        const sub = this.getSub(subID);
        if (sub) {
            const set = new Set(countries);
            if (set.has(countryID)) {
                set.delete(countryID);
            } else {
                set.add(countryID);
            }
            sub.countries = Array.from(set);
        }
    }

    @computed public get currentEditingPreset(): IPreset {
        return this.presets[this.currentEditingId] || null;
    }

    @computed public get selectedCountries() {
        let ret: number[] = [];
        if (this.currentEditingPreset && this.currentEditingPreset.subs) {
            for (const sub of this.currentEditingPreset.subs) {
                ret = ret.concat([...sub.countries]);
            }
        }
        return _.uniq(ret);
    }

    public get noteDismissed() {
        return StorageService.get(NOTE_DISSMISS_KEY);
    }

    public setNoteDismissed() {
        StorageService.set(NOTE_DISSMISS_KEY, true);
    }

    public getCountriesBySub(id: string) {
        if (this.currentEditingPreset && this.currentEditingPreset.subs) {
            for (const sub of this.currentEditingPreset.subs) {
                if (sub.id === id) {
                    return sub.countries;
                }
            }
        }
        return [];
    }
    @action
    public updateActivePreset = (activePreset: ICWChangeNotification) => {
        if (
            this.activePreset === undefined ||
            !_.isEqual(activePreset, toJS(this.activePreset))
        ) {
            this.activePreset = activePreset;
        }
    };

    private getTreeNode(path: number[], tree: ICustomWeightTree) {
        const node: ICustomWeightTree = path.reduce((prev, indx) => {
            return prev.children[indx];
        }, tree);
        return node;
    }

    private saveCurrentPreset() {
        if (!this.currentEditingId || !this.presets[this.currentEditingId]) {
            return;
        }
        APIService.savePreset(this.presets[this.currentEditingId]);
    }

    private getSub(id: string) {
        if (this.currentEditingPreset && this.currentEditingPreset.subs) {
            for (const sub of this.currentEditingPreset.subs) {
                if (sub.id === id) {
                    return sub;
                }
            }
        } else {
            return null;
        }
    }
}

export default new CustomWeightStore();