import LRU from 'lru-cache';
const __cache = new LRU<string, any>({
    max: 30000,
    maxAge: 1000 * 60 * 30,
});

export class APICacheInstance {
    public static setValue<T>(key: string, value: T) {
        __cache.set(key, value);
    }

    public static getValue<T>(key: string): T {
        if (!__cache.has(key)) {
            return null;
        }

        return __cache.get(key);
    }

    public static hasValue(key: string): boolean {
        return __cache.has(key);
    }

    public static deleteValue(key: string) {
        __cache.del(key);
    }

    public static deleteValueReg(test: RegExp) {
        __cache.keys().forEach(key => {
            if (test.test(key)) {
                __cache.del(key);
            }
        });
    }

    public static clear() {
        __cache.reset();
    }
}
