import * as React from 'react';
import { Moment } from 'moment';
import { MOMENTJS_DATE_DISPLAY_FORMAT } from '../../constants';

interface IUScorePopup {
    date: Moment;
    impact: number;
}

export default class UScorePopup extends React.Component<IUScorePopup> {
    public render() {
        return (
            <div className="uscore-tooltip-data">
                <div className="uscore-date">
                    <span>
                        {this.props.date.format(MOMENTJS_DATE_DISPLAY_FORMAT)}
                    </span>
                </div>
                <div className="uscore-impact">
                    <span>{this.props.impact.toFixed(2)}</span>
                </div>
            </div>
        );
    }
}
