import React from 'react';
import cx from 'classnames';

import { IActiveCountry } from '../../../interfaces';
import { IChartIndicatorProps } from '../chartInterfaces';
import countryStore from '../../../stores/CountryStore';
import GQPopupStorage from '../../GQPopup/GQPopupStorage';

const hoverKey = 'chart-side-indicator-popup';

interface IChartNameIndicatorProps extends IChartIndicatorProps {
    country: IActiveCountry;
    isSquare?: boolean;
}

class ChartNameIndicator extends React.PureComponent<IChartNameIndicatorProps> {
    public render() {
        const { isRight, x, y, country, isSquare } = this.props;
        const xStyle: React.CSSProperties = {};
        if (isRight) {
            xStyle.right = x + 45;
        } else {
            xStyle.left = x;
        }
        return (
            <div
                className={cx([
                    'chart-indicator name-indicator active',
                    { square: isSquare },
                ])}
                style={{
                    borderColor: countryStore.colorStack.color(country.id),
                    top: y - 10,
                    ...xStyle,
                }}
                onMouseEnter={this.onHover}
                onMouseLeave={this.onHoverOut}>
                {(
                    country.abbreviation ||
                    country.abbreviation_short ||
                    ''
                ).toUpperCase()}
            </div>
        );
    }
    private onHover = (e: React.MouseEvent<HTMLDivElement>) => {
        GQPopupStorage.addData(hoverKey, {
            element: e.currentTarget,
            orientation: 'bottom left',
            tooltipData: (
                <span style={{ padding: '8px 4px' }}>
                    {this.props.country.name}
                </span>
            ),
        });
    };
    private onHoverOut = () => {
        GQPopupStorage.addData(hoverKey, null);
    };
}

export default ChartNameIndicator;
