import * as _ from 'lodash';
import { computed, action, observable } from 'mobx';
import ColorStack from '../utils/ColorStack';
import {
    IRootStoreExporter,
    IColorsStackSerializedValuesData,
} from '../interfaces';
import KeyValueStore from './KeyValueStore';
import { IActiveCountryAPI, IWithCountryProps } from 'services/APIService';
import { drop, first, xor } from 'lodash/fp';
export type ICountryStoreSerializedColors = IColorsStackSerializedValuesData<
    number
>;


export class CountryStore implements IRootStoreExporter {
    @observable public colorStack: ColorStack<number>;
    @observable public currentHoveredCountry: number = null;
    @observable public currentStore: KeyValueStore<number> = new KeyValueStore(
        true,
        Number
    );
    @observable public countries: IWithCountryProps = null;

    private defaultColorStack: ColorStack<number>;
    constructor() {
        this.defaultColorStack = new ColorStack<number>();
        this.colorStack = this.defaultColorStack;
    }

    public serializeColors(): ICountryStoreSerializedColors[] {
        return this.colorStack.serialize();
    }

    @action public setStacks(colorStack: ColorStack<number>) {
        this.colorStack = colorStack;
    }

    @action public setDefaultStacks() {
        this.colorStack = this.defaultColorStack;
    }

    @action public toInitialState() {
        this.clear(true);
    }

    @action updateCountries({ countries, licensed, all }: IActiveCountryAPI) {
        this.countries = {
            countries,
            countriesArr: _.values(countries),
            licensedCountries: licensed,
            licensedCountriesArr: _.values(licensed),
            allCountries: all,
            allCountriesArr: _.values(all),
        };
    }

    @action public toggleIsMultiValue(override?: boolean) {
        if (this.currentStore) {
            this.currentStore.toggleIsMultiValue(override);
        }
    }

    @computed public get currentCountryList(): number[] {
        if (this.currentStore === null) {
            return [];
        }
        return this.currentStore.getValues();
    }

    @action public addCountry(countryID: number, ...ids: number[]) {
        if (this.currentStore === null) {
            return;
        }
        this.currentStore.setValue(countryID, ...ids);
    }

    @action public removeCountry(countryID: number, ...ids: number[]) {
        if (this.currentStore === null) {
            return;
        }
        this.currentStore.removeValue(countryID, ...ids);
    }

    @action public toggleCountry(countryID: number, ...ids: number[]) {
        if (this.currentStore === null) {
            return;
        }

        this.currentStore.toggleValue(countryID, ...ids);
    }

    @action public toggleSecondaryCountry(countryID: number) {
        const newCountryList = [
            first(this.currentCountryList),
            ...xor([countryID], drop(1, this.currentCountryList)),
        ];
        this.currentStore.overrideValues(...newCountryList);
    }

    @action public changeFirstCountry(countryID: number, ...ids: number[]) {
        if (this.currentStore === null) {
            return;
        }
        // we can simply assign the new value as the first country, since the second one will be reseted (removed);
        this.currentStore.clean();

        this.currentStore.toggleValue(countryID);
    }

    @action public changeSecondCountry(countryID: number, ...ids: number[]) {
        if (this.currentStore === null) {
            return;
        }

        // if this is already selected as the second country we need to deselect it
        if (this.currentCountryList[1] === countryID) {
            this.currentStore.removeValue(countryID);
        } else {
            // first we need to get the second country id to remove it from values. But if there are only 1 country we do nothing
            const secondCountryId =
                this.currentCountryList.length > 1 &&
                this.currentCountryList[1];
            if (secondCountryId) {
                this.currentStore.removeValue(secondCountryId);
            }

            this.currentStore.toggleValue(countryID);
        }
    }

    @action public clear(all: boolean = false) {
        if (this.currentStore) {
            this.currentStore.clean();
        }
    }

    @action public setHoveredCountry(countryID: number) {
        this.currentHoveredCountry = countryID;
    }

    @computed public get lastFromStack() {
        const list = this.currentCountryList;
        return _.last(list);
    }

    @computed get isMultiValue(): boolean {
        return this.currentStore ? this.currentStore.isMultiValue : false;
    }

    public customExport() {
        return {
            selectedCountries: this.currentCountryList.map(id => {
                return {
                    id,
                    color: this.colorStack.color(id),
                };
            }),
        };
    }

    @action
    public customRestore(data: {
        selectedCountries: Array<{ id: number; color: string }>;
    }) {
        this.clear(false);
        _.forEach(data.selectedCountries, ({ id }) => {
            this.addCountry(id);
        });
    }
}

export default new CountryStore();
