import * as _ from 'lodash';
import React from 'react';
import * as ReactDOM from 'react-dom';
import CancelableEvents from '../../utils/CancelableEvents';
import GQToast, { E_TOAST_TYPE } from './GQToast';
import { generateID } from '../../utils/generalUtils';
import { TransitionGroup, Transition } from 'react-transition-group';
import { TweenMax } from 'gsap';

interface IToastOptions {
    type?: E_TOAST_TYPE;
    className?: string;
    buttons?: JSX.Element[];
    showCloseButton?: boolean;
    duration?: number;
    timeout?: number;
    autoClose?: boolean;
    onDurationEnd?: () => void;
}

interface IToastDurationMap {
    [id: string]: {
        remaining: number;
        start: number;
        timeout: { cancel: () => void };
    };
}

interface IGQToastCollectionItem {
    id: string;
    text: string;
    options: IToastOptions;
}

interface IGQToastContainerProps {
    className?: string;
}

interface IGQToastState {
    toastsCollection: IGQToastCollectionItem[];
}

export class GQToastContainer extends React.Component<
    IGQToastContainerProps,
    IGQToastState
> {
    private cancelable = new CancelableEvents();
    private toastDurationMap: IToastDurationMap = {};
    constructor(props: IGQToastContainerProps) {
        super(props);
        this.state = {
            toastsCollection: [],
        };
    }
    public componentWillUnmount() {
        this.cancelable.cancelAll();
    }
    public createToast(text: string, options?: IToastOptions) {
        const id = generateID();
        const newToast: IGQToastCollectionItem = {
            id,
            text,
            options: {
                duration: 10000,
                timeout: 0.5,
                autoClose: true,
                showCloseButton: true,
                type: E_TOAST_TYPE.INFO,
                ...options,
            },
        };
        this.setState({
            toastsCollection: [...this.state.toastsCollection, newToast],
        });
    }
    public render() {
        return ReactDOM.createPortal(
            <div className="gq-toast-container flex-container align-center">
                <div className="gq-toast-list flex-container align-center no-pointer-events flex-dir-column">
                    <TransitionGroup component={null}>
                        {this.state.toastsCollection.map(toast => {
                            const onEnter = (node: Element) => {
                                this.onToastEnter(node, toast);
                            };
                            const onExit = (node: Element) => {
                                this.onToastExit(node, toast);
                            };
                            return (
                                <Transition
                                    key={toast.id}
                                    timeout={500}
                                    onEnter={onEnter}
                                    onExit={onExit}>
                                    <GQToast
                                        key={toast.id}
                                        onMouseEnter={this.onToastHover}
                                        onMouseLeave={this.onToastHoverOut}
                                        text={toast.text}
                                        id={toast.id}
                                        onClose={this.onGQToastClose}
                                        onClickCapture={this.onCapture}
                                        {...toast.options}
                                    />
                                </Transition>
                            );
                        })}
                    </TransitionGroup>
                </div>
            </div>,

            document.body
        );
    }

    private onGQToastClose = (id: string) => {
        this.removeToast(id, true);
    };
    private onToastEnter = (node: Element, toast: IGQToastCollectionItem) => {
        const { options } = toast;
        TweenMax.set(node, { clearProps: 'all' });
        TweenMax.from(node, options.timeout, { y: '-=50', opacity: 0 });
        if (options.autoClose) {
            this.toastDurationMap[toast.id] = {
                start: Date.now(),
                remaining: options.duration,
                timeout: this.cancelable.setTimeout(() => {
                    this.removeToast(toast.id, true);
                }, options.duration),
            };
        }
    };
    private onToastExit = (node: Element, toast: IGQToastCollectionItem) => {
        TweenMax.set(node, { clearProps: 'all' });
        TweenMax.to(node, toast.options.timeout, {
            y: '-=50',
            opacity: 0,
        } as any);
    };
    private onToastHover = (id: string) => {
        const toaster = this.toastDurationMap[id];
        if (!toaster) {
            return;
        }
        toaster.timeout.cancel();
    };
    private onToastHoverOut = (id: string) => {
        const toaster = this.toastDurationMap[id];
        const toast = _.find(
            this.state.toastsCollection,
            toast => toast.id === id
        );
        if (!toaster) {
            return;
        }
        toaster.timeout = this.cancelable.setTimeout(() => {
            this.removeToast(id, true);
        }, toast.options.duration);
    };
    private removeToast = (id: string, callOnDurationEnd: boolean) => {
        const toastToRemove = _.find(
            this.state.toastsCollection,
            toast => toast.id === id
        );
        if (toastToRemove) {
            const newCollection = _.without(
                this.state.toastsCollection.slice(),
                toastToRemove
            );
            delete this.toastDurationMap[id];
            this.setState(
                {
                    toastsCollection: newCollection,
                },
                () => {
                    if (
                        callOnDurationEnd &&
                        toastToRemove.options.onDurationEnd
                    ) {
                        toastToRemove.options.onDurationEnd();
                    }
                }
            );
        }
    };
    private onCapture = (id: string) => {
        const toast = _.find(
            this.state.toastsCollection,
            toast => toast.id === id
        );
        if (toast) {
            this.removeToast(id, false);
        }
    };
}
