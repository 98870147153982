import React from 'react';
import bodymovin from 'lottie-web';
import AppStore from 'AppStore';
import AnimationJson from 'assets/bg/loader.json';

export default class LoginAnimationContainer extends React.Component<any> {
    public componentDidMount() {
        if ((window as any).FS) {
            (window as any).FS.shutdown();
        }

        const animation = bodymovin.loadAnimation({
            container: document.getElementById('login-page-animation'),
            renderer: 'svg',
            loop: false,
            autoplay: true,
            animationData: AnimationJson,
            rendererSettings: {
                preserveAspectRatio: 'none',
                clearCanvas: false,
            },
        });
        animation.play();
        animation.addEventListener('complete', this.onComplete);
    }
    public onComplete() {
        AppStore.setIsLoggedIn(true);
        AppStore.setfirstLoad(false);
    }

    public render() {
        const { children } = this.props;

        return (
            <div className="login-animation-container">
                <div className="login-animation" id="login-page-animation" />

                {children}
            </div>
        );
    }
}
