import React from 'react';
import classNames from 'classnames';
import { SETTINGS_TABS } from '../../constants';

interface IGQSettingsMenuItemProps {
    id: SETTINGS_TABS;
    label: string;
    active?: boolean;
    onClick?: (id: SETTINGS_TABS) => void;
}

export default class GQSettingsMenuItem extends React.Component<
    IGQSettingsMenuItemProps
> {
    public render() {
        return (
            <div
                className={classNames(['gq-settings-menu-item'], {
                    active: this.props.active,
                })}
                onClick={this.onClick}>
                <p>{this.props.label}</p>
                <div className="menu-item-label-border" />
            </div>
        );
    }

    private onClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        this.props.onClick(this.props.id);
    };
}
