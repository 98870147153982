import React from 'react';
import cx from 'classnames';

import { GQButton } from '../GQButton';

export enum E_TOAST_TYPE {
    SUCCESS = 'success',
    ERROR = 'error',
    INFO = 'info',
}

export interface IGQToastProps {
    id: string;
    text: string;
    type?: E_TOAST_TYPE;
    duration?: number;
    timeout?: number;
    buttons?: JSX.Element[];
    showCloseButton?: boolean;
    autoClose?: boolean;
    className?: string;
    onClose: (id: string) => void;
    onClickCapture: (id: string) => void;
    onMouseEnter: (id: string) => void;
    onMouseLeave: (id: string) => void;
}

export default class GQToast extends React.PureComponent<IGQToastProps> {
    public render() {
        return (
            <div
                className={cx([
                    'gq-toast flex-container align-center-middle all-pointer-events',
                    {
                        [this.props.type]: !!this.props.type,
                        [this.props.className]: !!this.props.className,
                    },
                ])}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}>
                <div className="gq-toast-content flex-container align-middle">
                    <span>{this.props.text}</span>
                </div>
                <div
                    onClickCapture={this.onCaptureClick}
                    className="gq-toast-buttons flex-container flex-child-grow align-right">
                    {this.props.buttons}
                </div>
                <div className="gq-toast-close-button flex-container align-right align-middle">
                    {this.props.showCloseButton && (
                        <GQButton
                            icon="gqi-close"
                            onClick={this.onClose}
                            noBorder
                            noPadding
                        />
                    )}
                </div>
            </div>
        );
    }
    private onMouseEnter = () => {
        this.props.onMouseEnter(this.props.id);
    };
    private onMouseLeave = () => {
        this.props.onMouseLeave(this.props.id);
    };
    private onClose = () => {
        this.props.onClose(this.props.id);
    };
    private onCaptureClick = () => {
        this.props.onClickCapture(this.props.id);
    };
}
