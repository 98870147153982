const appConfigPromise = require('../app-config.json');

let baseUrlWebworker: string = null;

const defaultNamespace: string = process.env.REACT_APP_SERVER_ENV || 'dev';

export const SUPPORT_EMAIL = 'support@geoquant.com';

const PRODUCTION_NAMESPACE_CONFIG = {
    api: 'https://app.geoquant.com/api/webapp_api',
    ws: 'https://app.geoquant.com',
    path: { path: '/api/socket.io' },
};

export function isHeadlessMode(): boolean {
    return (window as any).__headlessGQFlag === true;
}

function getConnectionString(): {
    api: string;
    ws: string;
    path?: { path: string };
} {
    if (isQAEnvironment()) {
        return PRODUCTION_NAMESPACE_CONFIG;
    }

    const shouldUseRelativePath = !isLocalhost();
    if (shouldUseRelativePath) {
        return {
            api: '/api/webapp_api',
            ws: `https://${document.location.host}`,
            path: { path: '/api/socket.io' },
        };
    }

    if (!defaultNamespace) {
        return {
            api: 'http://localhost:8000/webapp_api',
            ws: 'http://localhost:8000',
        };
    }

    if (defaultNamespace === 'production') {
        return PRODUCTION_NAMESPACE_CONFIG;
    }

    return {
        api: `https://webapp-${defaultNamespace}.s-pub.stg1.geoquant.io/api/webapp_api`,
        ws: `https://webapp-${defaultNamespace}.s-pub.stg1.geoquant.io`,
        path: { path: '/api/socket.io' },
    };
}

export function setBaseURL(url: string) {
    baseUrlWebworker = url;
}
export function getApiURL(): string {
    if (baseUrlWebworker !== null) {
        return baseUrlWebworker;
    }
    return getConnectionString().api;
}

export function getAPIWSUrl(): string {
    if (baseUrlWebworker !== null) {
        return baseUrlWebworker;
    }
    return getConnectionString().ws;
}

export function getAPIWSConfig() {
    const { path } = getConnectionString();
    return path ? path : {};
}

export async function getMenuConfig() {
    const { menu } = appConfigPromise;
    return menu;
}

export async function getMapViewConfig() {
    const { mapView } = appConfigPromise;
    return mapView;
}

export async function isDebugMode() {
    return false;
}

export function isLocalhost(): boolean {
    return window.location.host.indexOf('localhost') > -1;
}

export function isEmbed(): boolean {
    return window.location !== window.parent.location;
}

export function isQAEnvironment(): boolean {
    return /^qa\.geoquant/i.test(window.location.hostname);
}

export function isProduction(): boolean {
    return /^app\.geoquant/i.test(window.location.hostname);
}