import React from 'react';
import { observer } from 'mobx-react';
import { createPortal } from 'react-dom';

import NoteAnchor, { IActivityNoteAnchorPosition } from './NoteAnchor';
import { IAnnoStore } from '../../../stores/AnnotationsStore';
import { ActivityNoteStore } from '../../../stores/insights/InsightsNoteStore';
import { TransitionGroup, Transition } from 'react-transition-group';
import { TweenLite } from 'gsap';
import { E_COMPONENT_TYPE } from '../../../services/APIService';

const E_ANCHOR_TOP_MARGIN = 25;

interface IGQNoteAnchorProps {
    annotations: ActivityNoteStore[];
    container: HTMLElement | string;
    scaleStore: IAnnoStore['annoStore'];
}

@observer
export default class GQNoteAnchorList extends React.Component<
    IGQNoteAnchorProps
> {
    public render() {
        const container =
            typeof this.props.container === 'string'
                ? (document.getElementById(
                      this.props.container
                  ) as HTMLDivElement)
                : this.props.container;
        return container
            ? createPortal(
                  <TransitionGroup component={null}>
                      {this.props.annotations.map((anchor, i) => {
                          const index = i + 1;
                          const position = this.getAnchorStyle(anchor);
                          return (
                              <Transition
                                  key={anchor.id}
                                  onEnter={this.onNoteEnter}
                                  onExit={this.onNoteExit}
                                  unmountOnExit={true}
                                  mountOnEnter={true}
                                  timeout={500}>
                                  <NoteAnchor
                                      key={anchor.id}
                                      index={index}
                                      note={anchor}
                                      position={position}
                                  />
                              </Transition>
                          );
                      })}
                  </TransitionGroup>,
                  container
              )
            : null;
    }
    private onNoteEnter = (node: HTMLElement) => {
        TweenLite.set(node, { clearProps: 'opacity, scale' });
        TweenLite.from(node, 0.5, { opacity: 0, scale: 0 });
    };
    private onNoteExit = (node: HTMLElement) => {
        TweenLite.set(node, { clearProps: 'opacity, scale' });
        TweenLite.to(node, 0.5, { opacity: 0, scale: 0 });
    };

    private getAnchorStyle = (anchor: ActivityNoteStore) => {
        const container =
            typeof this.props.container === 'string'
                ? (document.getElementById(
                      this.props.container
                  ) as HTMLDivElement)
                : this.props.container;
        const ret: IActivityNoteAnchorPosition = {
            left: 0,
            top: 0,
        };
        if (anchor.noteType === E_COMPONENT_TYPE.CHART) {
            const xScale = this.props.scaleStore.xScale;
            const yScale = this.props.scaleStore.yScales[anchor.lineIdentifier];
            if (!xScale || !yScale) {
                return ret;
            }
            ret.left = xScale(
                typeof anchor.date === 'string'
                    ? Date.parse(anchor.date)
                    : anchor.date
            );
            ret.top = yScale(anchor.yValue);
        } else {
            const circle = document.getElementById(String(anchor.countryID));
            const parentBox = container.getBoundingClientRect();
            if (circle) {
                const { width, left, top } = circle.getBoundingClientRect();
                ret.left = left + width / 2 - parentBox.left;
                ret.top = top - parentBox.top + E_ANCHOR_TOP_MARGIN;
            }
        }
        return ret;
    };
}
