import React, { useMemo } from 'react';
import HeatmapCell from './HeatmapCell';
import { HeatmapPercentile, HeatmapScoreType } from './HeatmapTypes';
import { map } from 'lodash/fp';
import classNames from 'classnames';
import { isHeatmapPercentileVisible } from 'utils/generalUtils';

type Props = {
    percentiles: HeatmapPercentile[];
    onSelect: (percentile: HeatmapPercentile) => void;
    selectedPercentile: HeatmapPercentile;
    numDays: number;
    scoreType: HeatmapScoreType;
};

const HeatmapLegend = React.memo(
    ({
        percentiles,
        onSelect,
        selectedPercentile,
        numDays,
        scoreType,
    }: Props) => {
        const label = useMemo(
            () =>
                scoreType === HeatmapScoreType.RISK
                    ? `Percentile of daily change in risk relative to ${numDays} days`
                    : `Percentile of daily pulse score relative to ${numDays} days`,
            [scoreType, numDays]
        );

        return (
            <div className="heatmap-legend">
                <div className="heatmap-legend-list-container">
                    <div className="heatmap-legend-list">
                        {map(percentile => {
                            const key = `${percentile.label} ${percentile.type}`;
                            const isVisible = isHeatmapPercentileVisible(
                                percentile,
                                selectedPercentile
                            );
                            return (
                                <div
                                    key={key}
                                    style={
                                        isVisible
                                            ? {
                                                  backgroundColor:
                                                      percentile.color,
                                              }
                                            : {}
                                    }
                                    className={classNames([
                                        'heatmap-legend-list-item',
                                        {
                                            'heatmap-legend-list-item-selected': isVisible,
                                        },
                                    ])}
                                    onClick={() => onSelect(percentile)}>
                                    <HeatmapCell
                                        onClick={() => onSelect(percentile)}
                                        width={19}
                                        height={19}
                                        isVisible={true}
                                        color={
                                            isVisible
                                                ? 'transparent'
                                                : percentile.color
                                        }
                                        clickable={true}
                                    />
                                    <div
                                        className={classNames([
                                            'heatmap-legend-cell-text',
                                            {
                                                'heatmap-legend-cell-text-selected': isVisible,
                                            },
                                        ])}>
                                        {percentile.label}
                                    </div>
                                </div>
                            );
                        }, percentiles)}
                    </div>
                </div>
                <div className="heatmap-legend-label">{label}</div>
            </div>
        );
    }
);

export default HeatmapLegend;
