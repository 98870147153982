import { E_RISK_TYPE } from 'interfaces';
import { first, includes } from 'lodash/fp';
import { useEffect, useState } from 'react';
import KeyValueStore from 'stores/KeyValueStore';
import rootStore from '../RootStore';
const INDICATORS_LIST = [
    E_RISK_TYPE.CLIENT_FACING_INDICATORS,
    E_RISK_TYPE.DIMENSIONS,
];

type Props = {
    riskType: E_RISK_TYPE;
    multiCountry: boolean;
    multiRisk: boolean;
};
const useInitCountryDim = ({ riskType, multiCountry, multiRisk }: Props) => {
    const [ready, setReady] = useState<boolean>(false);

    useEffect(() => {
        const prevType = rootStore.risksStore.currentRisksType;
        const shouldUpdateRiskType =
            !includes(prevType, INDICATORS_LIST) ||
            !includes(riskType, INDICATORS_LIST);

        if (shouldUpdateRiskType) {
            rootStore.risksStore.setCurrentRiskType(riskType);
        }

        if (!multiRisk) {
            rootStore.risksStore.storesByRiskType.forEach(
                (riskStore: KeyValueStore<number>) => {
                    riskStore.overrideValues(first(riskStore.getValues()));
                }
            );
        }

        if (!multiCountry) {
            rootStore.countryStore.currentStore.overrideValues(
                first(rootStore.countryStore.currentCountryList)
            );
        }

        setReady(true);
    }, [riskType, multiCountry, multiRisk]);

    return ready;
};

export default useInitCountryDim;
