import { EventSubscription } from 'fbemitter';

interface ICancelablePromise<T> extends Promise<T> {
    cancel: () => void;
}

export const CANCELED_PROMISE_ERROR = 'Promise is canceled';

export default class CancelableEvents {
    public static isCanceledPromiseError(err: Error) {
        return err.message === CANCELED_PROMISE_ERROR;
    }
    private isDead: boolean = false;
    private timeouts: Set<number> = new Set();
    private cancelableEvents: Set<() => void> = new Set();
    public setTimeout(
        handler: (...args: any[]) => void,
        timeout: number = 0,
    ) {
        const timeoutId = window.setTimeout(this.onTimeoutCB, timeout, handler);
        this.timeouts.add(timeoutId);
        const cancel = this.createCancelTimeoutCB(timeoutId);
        return { cancel };
    }

    public addEventSubscription(sub: EventSubscription) {
        if (this.isDead) {
            throw new Error(
                'Trying to add document listener to canceled instance'
            );
        }
        const cancel = () => {
            sub.remove();
            this.cancelableEvents.delete(cancel);
        };

        this.cancelableEvents.add(cancel);

        return { cancel };
    }
    public cancelAll() {
        Array.from(this.timeouts).forEach(c => {
            this.createCancelTimeoutCB(c)();
        });

        Array.from(this.cancelableEvents).forEach(c => {
            c();
        });
        this.isDead = true;
    }
    public addWindowListener<K extends keyof WindowEventMap>(
        type: K,
        listener: (ev: WindowEventMap[K]) => any
    ) {
        if (this.isDead) {
            throw new Error(
                'Trying to add window listener to canceled instance'
            );
        }
        const cb = (ev: WindowEventMap[K]) => {
            return listener(ev);
        };

        const cancel = () => {
            window.removeEventListener(type, cb);
            this.cancelableEvents.delete(cancel);
        };
        this.cancelableEvents.add(cancel);
        window.addEventListener(type, cb);

        return { cancel };
    }

    public addDocumentListener<K extends keyof DocumentEventMap>(
        type: K,
        listener: (ev: DocumentEventMap[K]) => any
    ) {
        if (this.isDead) {
            throw new Error(
                'Trying to add document listener to canceled instance'
            );
        }
        const cb = (ev: DocumentEventMap[K]) => {
            return listener(ev);
        };
        const cancel = () => {
            document.removeEventListener(type, cb);
            this.cancelableEvents.delete(cancel);
        };
        this.cancelableEvents.add(cancel);
        document.addEventListener(type, cb);

        return { cancel };
    }

    public promise<T>(
        handler: (...args: any[]) => Promise<T>,
        ...args: any[]
    ): ICancelablePromise<T> {
        if (this.isDead) {
            throw new Error(
                'Trying to add document listener to canceled instance'
            );
        }
        let isCanceled = false;
        const cancel = () => {
            isCanceled = true;
            this.cancelableEvents.delete(cancel);
        };
        this.cancelableEvents.add(cancel);
        const promise = new Promise<T>(async (resolve, reject) => {
            try {
                const res: T = await handler(...args);
                if (!isCanceled) {
                    resolve(res);
                    return;
                }
                throw new Error(CANCELED_PROMISE_ERROR);
            } catch (err) {
                if (!isCanceled) {
                    reject(err);
                    return;
                }
                if (err.message !== CANCELED_PROMISE_ERROR) {
                    console.error('canceled promise got error', err.message);
                }
                reject(new Error(CANCELED_PROMISE_ERROR));
            }
        });
        (promise as ICancelablePromise<T>).cancel = cancel;
        return promise as ICancelablePromise<T>;
    }
    private onTimeoutCB = (
        handler: (...args: any[]) => void,
        ...args: any[]
    ) => {
        handler(...args);
    };

    private createCancelTimeoutCB = (timeoutId: number) => () => {
        clearTimeout(timeoutId);
        this.timeouts.delete(timeoutId);
    };
}
