import React from 'react';
import ScoreEventSlider from '../scoringPage/ScoreEventSlider';
import { scaleLinear } from 'd3';

export interface IGQScoreSliderDataProps {
    sliderName?: string;
    id: string;
    values: number[];
    onDrag: (value: number) => void;
    exactValue?: number;
    color: boolean;
    findTextByValue?: (
        value: number
    ) => {
        text: string;
        value: number;
        isBelong: (value: number) => boolean;
    };
}
export interface IGQScoreSliderDataState {
    isDragging?: boolean;
    exactValue?: number;
}

export default class ScoreSliderData extends React.Component<
    IGQScoreSliderDataProps,
    IGQScoreSliderDataState
> {
    constructor(props: IGQScoreSliderDataProps) {
        super(props);
        this.state = {
            isDragging: false,
            exactValue: this.props.exactValue,
        };
    }
    public UNSAFE_componentWillReceiveProps(
        nextProps: IGQScoreSliderDataProps
    ) {
        if (nextProps.exactValue !== this.state.exactValue) {
            this.setState({
                exactValue: nextProps.exactValue,
            });
        }
    }
    public render() {
        const moveSlider = (e: React.MouseEvent<HTMLDivElement>) => {
            const { values } = this.props;
            const scale = scaleLinear()
                .domain([0, values.length - 1])
                .range([0, 100]);
            const { left, width } = e.currentTarget.getBoundingClientRect();
            const currentPosition = ((e.clientX - left) / width) * 100;
            this.setState({
                exactValue: values[Math.round(scale.invert(currentPosition))],
                isDragging: false,
            });
        };
        return (
            <div
                className="score-slider-data-container"
                style={{
                    cursor: this.state.isDragging ? '-webkit-grabbing' : '',
                }}>
                <div className="score-slider-data-header">
                    <div className="score-slider-data-title">
                        {this.props.sliderName}
                    </div>
                    <div className="score-slider-value-text">
                        {this.props.findTextByValue(this.props.exactValue).text}
                    </div>
                </div>
                <div className="score-slider-item">
                    <div
                        className={
                            this.props.color
                                ? 'color-slider-bar'
                                : 'white-slider-bar'
                        }
                        onClick={moveSlider}>
                        <ScoreEventSlider
                            id={this.props.id}
                            values={this.props.values}
                            onDrag={this.handleDrag}
                            exactValue={this.state.exactValue}
                            onDragStops={this.handleDragStops}
                        />
                    </div>
                </div>
            </div>
        );
    }
    private handleDrag = (value: number) => {
        this.setState({
            isDragging: true,
        });
        this.props.onDrag(value);
    };
    private handleDragStops = () => {
        this.setState({
            isDragging: false,
        });
    };
}
