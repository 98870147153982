import { HeatmapPercentile } from './HeatmapTypes';

export const HEATMAP_PERCENTILES: HeatmapPercentile[] = [
    {
        percentile: 99,
        label: '99%',
        type: 'red',
        color: 'rgba(253,61,60,1)',
    },
    {
        percentile: 95,
        label: '95%',
        type: 'red',
        color: 'rgba(253,61,60,0.6)',
    },
    {
        percentile: 90,
        label: '90%',
        type: 'red',
        color: 'rgba(253,61,60,0.45)',
    },
    {
        percentile: 75,
        label: '75%',
        type: 'red',
        color: 'rgba(253,61,60,0.2)',
    },
    {
        percentile: 0,
        label: '<75%',
        type: 'red',
        color: 'rgba(253,61,60,0.1)',
    },
    {
        percentile: 0,
        label: '<75%',
        type: 'green',
        color: 'rgba(23,255,150,0.1)',
    },
    {
        percentile: 75,
        label: '75%',
        type: 'green',
        color: 'rgba(23,255,150,0.2)',
    },
    {
        percentile: 90,
        label: '90%',
        type: 'green',
        color: 'rgba(23,255,150,0.45)',
    },
    {
        percentile: 95,
        label: '95%',
        type: 'green',
        color: 'rgba(23,255,150,0.6)',
    },
    {
        percentile: 99,
        label: '99%',
        type: 'green',
        color: 'rgba(23,255,150,1)',
    },
];
