import React from 'react';
import SidebarRiskPickerWrapper from './SidebarRiskPickerWrapper';
import SidebarRiskPickerListContent, {
    ISidebarRiskPickerMenuItem,
} from './SidebarRiskPickerListContent';
import * as _ from 'lodash';
import { E_RISK_TYPE } from '../../interfaces';
import riskStore from '../../stores/RisksStore';
import SidebarRiskPickerTreeContent, {
    ISidebarRiskPickerTreeMenuItem,
} from './SidebarRiskPickerTreeContent';
import {
    IActiveDimensionTree,
    IAPIActiveClientFacingIndicator,
} from '../../services/APIService';
import { IMobxRootState } from 'RootStore';
import { inject } from 'mobx-react';

const LONG_NAMED_DIMS_HACK = ['Social Polarization', 'Human Development'];

interface Props {
    selectedRisks?: number[];
    multi?: boolean;
    dimensionsTree?: IActiveDimensionTree;
    activeClientFacingIndicators?: IAPIActiveClientFacingIndicator;
}

@inject(
    ({ risksStore }: IMobxRootState): Partial<Props> => {
        return {
            selectedRisks: risksStore.currentList,
            dimensionsTree: riskStore.dimensions.tree,
            activeClientFacingIndicators: risksStore.clientFacingIndicators,
        };
    }
)
export default class IndicatorsSidebarRiskPicker extends React.Component<
    Props
> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    public render() {
        return (
            <SidebarRiskPickerWrapper
                title="Risk Type"
                initialSelectedTabIndex={this.initiallySelectedTab()}
                onTabChange={this.onTabChange}>
                <SidebarRiskPickerTreeContent
                    headerTab={{
                        name: 'Fundamentals',
                        iconName: 'gqi-dimensions',
                    }}
                    treeMenuItem={this.fundamentalTabTreeMenuItem()}
                    selectedItemsIds={this.props.selectedRisks}
                    onItemClick={this.onRiskItemClick}
                />
                <SidebarRiskPickerListContent
                    headerTab={{
                        name: 'Focus',
                        iconName: 'gqi-indicators',
                    }}
                    menuItems={this.focusTabMenuItems()}
                    selectedItemsIds={this.props.selectedRisks}
                    onItemClick={this.onRiskItemClick}
                    multi={this.props.multi}
                />
            </SidebarRiskPickerWrapper>
        );
    }

    private focusTabMenuItems = (): ISidebarRiskPickerMenuItem[] => {
        const sortedIndicators = _.sortBy(
            this.props.activeClientFacingIndicators,
            indicator => indicator.order
        ).filter(indicator => !indicator.disabled);
        return _.map(sortedIndicators, indicator => {
            return {
                id: indicator.id,
                name: indicator.display_name,
                iconName: `gqi-dimension${indicator.id}`,
                disabled: riskStore.checkIfRiskDisabled(
                    E_RISK_TYPE.CLIENT_FACING_INDICATORS,
                    [indicator.id]
                ),
                topDivider: indicator.top_divider,
                definition: indicator.definition,
            };
        });
    };

    private fundamentalTabTreeMenuItem = (): ISidebarRiskPickerTreeMenuItem => {
        return this.recursivelyBuildFundamentalTreeMenuItem(
            this.props.dimensionsTree
        );
    };

    private recursivelyBuildFundamentalTreeMenuItem = (
        dimTree: IActiveDimensionTree
    ): ISidebarRiskPickerTreeMenuItem => {
        const name =
            LONG_NAMED_DIMS_HACK.includes(dimTree.lname) &&
            document.body.getBoundingClientRect().height <= 1010
                ? dimTree.sname
                : dimTree.lname;
        return {
            name,
            id: dimTree.id,
            children: dimTree.children
                ? dimTree.children.map(childDimTree =>
                      this.recursivelyBuildFundamentalTreeMenuItem(childDimTree)
                  )
                : null,
            disabled: riskStore.checkIfRiskDisabled(E_RISK_TYPE.DIMENSIONS, [
                dimTree.id,
            ]),
            hidden: dimTree.hidden,
            definition: dimTree.definition,
        };
    };

    private onTabChange = (tabIndex: number) => {
        // *** IMPORTANT NOTICE *** this rely on the fact that the order of tabs (SidebarRiskPickerContent children) in render match the order in this logic!
        switch (tabIndex) {
            case 0: {
                riskStore.setCurrentRiskType(E_RISK_TYPE.DIMENSIONS);
                break;
            }
            case 1: {
                riskStore.setCurrentRiskType(
                    E_RISK_TYPE.CLIENT_FACING_INDICATORS
                );
                break;
            }
        }
    };

    private initiallySelectedTab = () => {
        // *** IMPORTANT NOTICE *** this rely on the fact that the order of tabs (SidebarRiskPickerContent children) in render method match the E_RISK_TYPE values. Meaning DIMENSIONS === 0 and CLIENT_FACING_INDICATORS === 1
        return riskStore.currentRisksType != null
            ? riskStore.currentRisksType
            : 1;
    };

    private onRiskItemClick = (riskId: number) => {
        if (this.props.multi) {
            riskStore.toggleRisk(riskId);
        } else {
            riskStore.currentStore.overrideValues(riskId);
        }
    };
}
