import React from 'react';
import CheckBox from './CheckBox';

type Props = {
    onClick: () => void;
    isSelected: boolean;
};

const CountryPickerSelectAllListItem = (props: Props) => {
    const { onClick, isSelected } = props;

    return (
        <div
            className="new-country-picker-list-item-container"
            onClick={onClick}>
            <span className="new-country-picker-select-all-icon-and-text-container">
                <i
                    aria-hidden="true"
                    className="gqi-dimension20 new-country-picker-select-all-icon"
                />
                <span
                    className="new-country-picker-list-item-text new-country-picker-select-all-text"
                    onClick={onClick}>
                    all
                </span>
            </span>
            <CheckBox isRadioButton={false} isSelected={isSelected} />
        </div>
    );
};

export default CountryPickerSelectAllListItem;
