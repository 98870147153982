import * as d3 from 'd3';
import {
    IChartLine,
    ILineValue,
    ILineSideIndicator,
    E_CHART_GROUP_IDENTIFIERS,
} from './chartInterfaces';
import VirtualChartData from './VirtualChartData';
import moment from 'moment';
import { SVGProps } from 'react';
import {
    C_SCORES_DATE_FORMAT,
    C_TIMELINE_FORMATTED_DATES,
    C_TIMELINE_MIN_DATE,
} from '../../constants';
import { forEach, isUndefined } from 'lodash/fp';

export default class ChartLine extends VirtualChartData implements IChartLine {
    public sideIndicator: ILineSideIndicator = null;
    public hideYValue = false;
    public $$debug = false;
    private cachedVirtualValues: { [date: string]: ILineValue } = null;
    private globalMinValue: number = Number.MAX_VALUE;
    private globalMaxValue: number = Number.MIN_VALUE;
    public chartType: E_CHART_GROUP_IDENTIFIERS = null;
    constructor(
        public identifier: string,
        private allValues: ILineValue[],
        public country: number,
        public className: string,
        public props: SVGProps<any>,
        virtualMinDate: moment.Moment,
        virtualMaxDate: moment.Moment,
        cachedMinValue: number = null,
        cachedMaxValue: number = null
    ) {
        super(virtualMinDate, virtualMaxDate);
        this.setVirtualRanges(virtualMinDate, virtualMaxDate);
        this.cachedVirtualValues = {};
        forEach(v => {
            this.cachedVirtualValues[v.date.format(C_SCORES_DATE_FORMAT)] = v;
            this.globalMaxValue = Math.max(this.globalMaxValue, v.yValue);
            this.globalMinValue = Math.min(this.globalMinValue, v.yValue);
        }, this.allValues);
    }

    public getValue(dateKey: string): ILineValue {
        let value = this.cachedVirtualValues[dateKey];
        if (!isUndefined(value)) {
            return value;
        }

        const date = moment(dateKey, C_SCORES_DATE_FORMAT);
        let index = date.diff(C_TIMELINE_MIN_DATE, 'days');
        while (index && !value) {
            value = this.cachedVirtualValues[
                C_TIMELINE_FORMATTED_DATES[index--]
            ];
        }

        value = value || null;
        this.cachedVirtualValues[dateKey] = value;
        return value;
    }

    public get maxValue(): number {
        if (this.cachedMaxValue === null) {
            this.loadMinMaxCacheValues();
        }
        return this.cachedMaxValue;
    }

    public get minValue(): number {
        if (this.cachedMinValue === null) {
            this.loadMinMaxCacheValues();
        }
        return this.cachedMinValue;
    }

    public setVirtualRanges(from: moment.Moment, until: moment.Moment) {
        this.virtualMinDate = from;
        this.virtualMaxDate = until;
        this.cachedMinValue = null;
        this.cachedMaxValue = null;
    }

    private get virtualDatesKeys(): string[] {
        if (!this.virtualMaxDate || !this.virtualMinDate) {
            return [];
        }

        const startIndex = this.virtualMinDate.diff(
            C_TIMELINE_MIN_DATE,
            'days'
        );
        const endIndex =
            startIndex +
            this.virtualMaxDate.diff(this.virtualMinDate, 'days') +
            1;
        return C_TIMELINE_FORMATTED_DATES.slice(startIndex, endIndex);
    }

    private loadMinMaxCacheValues() {
        if (!this.virtualMaxDate || !this.virtualMinDate) {
            return;
        }

        let minValue = Infinity;
        let maxValue = -Infinity;

        forEach(dateKey => {
            const value = this.getValue(dateKey);

            if (!value) {
                return;
            }

            if (minValue > value.yValue) {
                minValue = value.yValue;
            }

            if (maxValue < value.yValue) {
                maxValue = value.yValue;
            }
        }, this.virtualDatesKeys);

        this.cachedMinValue = !isFinite(minValue)
            ? this.globalMinValue
            : minValue;

        this.cachedMaxValue = !isFinite(maxValue)
            ? this.globalMaxValue
            : maxValue;
    }

    public draw(
        yScale: d3.ScaleLinear<any, any>,
        xScale: d3.ScaleTime<any, any>
    ) {
        const lineGen = d3
            .line<ILineValue>()
            .x(lineDatum => xScale(lineDatum.date))
            .y(lineDatum => {
                return yScale(lineDatum.yValue);
            })
            .curve(d3.curveMonotoneX);

        const data = this.virtualDatesKeys
            .map(dateKey => this.getValue(dateKey))
            .filter(Boolean);

        return lineGen(data);
    }
}
