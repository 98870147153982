import { Moment } from 'moment';

export default class VirtualChartData {
    constructor(
        protected virtualMinDate: Moment,
        protected virtualMaxDate: Moment,
        protected cachedMinValue: number = null,
        protected cachedMaxValue: number = null
    ) {}
}
