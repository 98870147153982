import React from 'react';
import { IEventUScore, IActiveDimension } from '../../interfaces';
import { effectOptions } from '../scoringPage/consts';
import GQFlag from '../GQFlag';
import * as _ from 'lodash';
import GQPopupStorage from '../GQPopup/GQPopupStorage';
import { first, get } from 'lodash/fp';

interface IScoreImpactProps {
    scores: IEventUScore[];
    dimensions: { [dimensionId: number]: IActiveDimension };
}

interface IImpactState {
    showTooltip: boolean;
    eventEl: HTMLDivElement;
}

export default class ScoresImpact extends React.PureComponent<
    IScoreImpactProps,
    IImpactState
> {
    constructor(props: IScoreImpactProps) {
        super(props);
        this.state = {
            eventEl: null,
            showTooltip: false,
        };
    }
    public render() {
        const colorBGWidth = 28;
        const resolveChild = () => {
            if (this.props.scores.length === 1) {
                const score = _.first(this.props.scores);
                return (
                    <div className="score-summary">
                        <div className="score-country">
                            <GQFlag countryId={score.country_id} />
                        </div>
                        <div className="score-dimension">
                            <span className="dimension">
                                {get(
                                    [score.dimension_id, 'sname'],
                                    this.props.dimensions
                                )}
                            </span>
                        </div>
                        <div className="score-color-container">
                            <div
                                className="score-color-background"
                                style={{
                                    backgroundColor: this.findImpactByValue(
                                        score.impact
                                    ).color,
                                }}
                            />
                        </div>
                        <div
                            className="impact-score"
                            style={{
                                backgroundColor: this.findImpactByValue(
                                    score.impact
                                ).color,
                            }}
                        />
                    </div>
                );
            }
            const countryKeys = _.keys(
                _.groupBy(this.props.scores, item => item.country_id)
            );
            const dimensionKeys = _.keys(
                _.groupBy(this.props.scores, item => item.dimension_id)
            );
            const sortedScores: { [impact: string]: number } = {};
            let totalLength = 0;
            _.forEach(_.sortBy(this.props.scores, 'impact'), score => {
                if (!sortedScores[score.impact]) {
                    sortedScores[score.impact] = 0;
                }
                sortedScores[score.impact] += 1;
                totalLength += 1;
            });
            let drawCountry = true;
            let drawDimension = true;
            if (dimensionKeys.length === 1 && countryKeys.length > 1) {
                drawCountry = false;
            } else if (dimensionKeys.length > 1 && countryKeys.length === 1) {
                drawDimension = false;
            } else if (dimensionKeys.length > 1 && countryKeys.length > 1) {
                drawDimension = false;
                drawCountry = false;
            }
            const sortedKeys = _.keys(sortedScores)
                .sort((a, b) => Number(a) - Number(b))
                .reverse();
            return (
                <div className="score-summary">
                    {drawCountry && (
                        <div className="score-country">
                            <GQFlag countryId={Number(_.first(countryKeys))} />
                        </div>
                    )}
                    {drawDimension && (
                        <div className="score-dimension">
                            <span className="dimension">
                                {get(
                                    [first(dimensionKeys), 'sname'],
                                    this.props.dimensions
                                )}
                            </span>
                        </div>
                    )}
                    <div
                        className="score-color-container"
                        style={{ width: `${colorBGWidth}px` }}>
                        {_.map(sortedKeys, keyName => {
                            return (
                                <span
                                    key={keyName}
                                    className="score-color-background"
                                    style={{
                                        backgroundColor: this.findImpactByValue(
                                            Number(keyName)
                                        ).color,
                                        width:
                                            sortedKeys.length > 1
                                                ? `${(Math.abs(
                                                      sortedScores[keyName]
                                                  ) /
                                                      totalLength) *
                                                      100}%`
                                                : `${colorBGWidth}px`,
                                        height: '100%',
                                    }}
                                />
                            );
                        })}
                    </div>
                    <div className="score-count">
                        <span>{totalLength}</span>
                    </div>
                </div>
            );
        };
        return (
            <div
                className="scores-container"
                onMouseEnter={
                    this.props.scores.length === 1 ? null : this.onMouseEnter
                }
                onMouseLeave={
                    this.props.scores.length === 1 ? null : this.onMouseLeave
                }>
                {resolveChild()}
            </div>
        );
    }
    private onMouseEnter = (e: React.MouseEvent<HTMLDivElement>) => {
        const theDiv = e.target as HTMLDivElement;
        GQPopupStorage.addData('Impact_Tooltip', {
            element: theDiv,
            tooltipData: this.getTooltipContent(),
            orientation: 'bottom right',
        });
        window.addEventListener('mousewheel', this.onMouseLeave);
    };
    private onMouseLeave = () => {
        window.removeEventListener('mousewheel', this.onMouseLeave);
        GQPopupStorage.addData('Impact_Tooltip', null);
    };
    private getTooltipContent = () => {
        return (
            <div className="impact-tooltip-content">
                {_.map(this.props.scores, item => {
                    return (
                        <div className="impact-tooltip-row" key={item.score_id}>
                            <div className="impact-flag impact-tooltip-col">
                                <GQFlag countryId={item.country_id} />
                            </div>
                            <div className="impact-dimension impact-tooltip-col">
                                <span>
                                    {get(
                                        [item.dimension_id, 'sname'],
                                        this.props.dimensions
                                    )}
                                </span>
                            </div>
                            <div className="impact-tooltip-col">
                                <div
                                    className="impact-score"
                                    style={{
                                        backgroundColor: this.findImpactByValue(
                                            item.impact
                                        ).color,
                                    }}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };
    private findImpactByValue = (val: number) => {
        for (const option of effectOptions) {
            if (option.isBelong(val)) {
                return option;
            }
        }
    };
}
