import * as React from 'react';
import classNames from 'classnames';

interface IGQCGMapItemProps {
    id: number;
    pathStr: string;
    isSelected: boolean;
    selectable: boolean;
    onClick?: (id: number) => void;
    onHover?: (id: number, element: SVGPathElement) => void;
}

export default class IGQCGMapItem extends React.Component<IGQCGMapItemProps> {
    public render() {
        return (
            <path
                className={classNames([
                    'cg-map-item',
                    {
                        active: this.props.isSelected,
                        'no-edit': !this.props.selectable,
                    },
                ])}
                d={this.props.pathStr}
                onClick={this.onClick}
                onMouseEnter={this.onHover}
                onMouseLeave={this.onHoverOut}
            />
        );
    }
    private onClick = (e: React.MouseEvent<SVGPathElement>) => {
        if (this.props.selectable) {
            this.props.onClick(this.props.id);
        }
    };
    private onHover = (e: React.MouseEvent<SVGPathElement>) => {
        const el = e.currentTarget;
        this.props.onHover(this.props.id, el);
    };
    private onHoverOut = (e: React.MouseEvent<SVGPathElement>) => {
        this.props.onHover(null, null);
    };
}
