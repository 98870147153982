import React from 'react';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import { IMobxRootState } from '../../RootStore';
import { GQButtonTooltip } from '../GQButton';
import { E_RISK_TYPE } from '../../services/APIService';

interface IDimensionItemProps {
    id: number;
    name: string;
    lname?: string;
    isActive: boolean;
    tier: number;
    maxDepth: number;
    iconClass: string;
    onClick: (id: number) => void;
    backgroundColor?: string;
    customBackgroundWidth?: number;
    itemHeight?: number;
    isDebug?: boolean;
    riskType?: E_RISK_TYPE;
    isDisabled?: boolean;
    style?: React.CSSProperties;
    vertical?: boolean;
    topDivider?: boolean;
    definition?: string;
}

interface IDimensionItemState {
    showTooltip?: boolean;
}

@inject(({ appStore }: IMobxRootState) => {
    return {
        isDebug: appStore.debugMode,
    };
})
@observer
export default class DimensionItem extends React.Component<
    IDimensionItemProps,
    IDimensionItemState
> {
    //todo: try getting rid of this code
    public width: any = {
        15: 145,
        16: 230,
        17: 230,
        18: 230,
        19: 230,
        20: 230,
        22: 230,
        23: 230,
        24: 230,
        28: 230,
        29: 145,
        30: 210,
        25: 160,
        26: 160,
        107: 205,
        31: 210,
        32: 100,
        33: 210,
        34: 210,
        37: 210,
    };
    public container: HTMLDivElement = null;
    public shortIds: any;
    public itemClass: any;
    public style: React.CSSProperties = {};

    constructor(props: IDimensionItemProps) {
        super(props);

        this.state = {
            showTooltip: false,
        };
    }

    public render() {
        this.shortIds = [9, 10];
        this.itemClass = classNames([
            `tier-${this.props.tier}`,
            'dimensions-item',
            {
                vertical: this.props.vertical,
                active: this.props.isActive,
                'top-divider': this.props.topDivider && !this.props.isActive,
            },
        ]);

        this.style.backgroundColor = this.props.backgroundColor;
        if (this.props.isDisabled) {
            this.style.opacity = 0.3;
            this.style.cursor = 'auto';
        }

        return (
            <div
                ref={el => {
                    this.container = el;
                }}
                id={this.props.id.toString()}
                className={this.itemClass}
                onClick={
                    (!this.props.isDisabled &&
                        this.props.onClick.bind(this, this.props.id)) ||
                    null
                }
                onMouseEnter={this.onEnter}
                onMouseLeave={this.onLeave}
                style={
                    this.props.tier === this.props.maxDepth
                        ? {
                              height: this.props.itemHeight,
                              ...this.style,
                              ...this.props.style,
                          }
                        : {
                              ...this.style,
                              ...this.props.style,
                          }
                }>
                {this.state.showTooltip && !!this.props.definition && (
                    <GQButtonTooltip
                        content={this.props.definition}
                        position="bottom left"
                        parentProps={this.container}
                        width={this.width[this.props.id]}
                        speed={1500}
                        delay={450}
                    />
                )}

                <i aria-hidden={true} />
                {/* the risk icon should be hiddenn on ESG:
                https://geoquant.monday.com/boards/391051526/pulses/1008990971
                <i aria-hidden={true} className={this.props.iconClass} />
                */}

                <div
                    id={this.props.id.toString()}
                    className={
                        this.props.vertical
                            ? 'rotate-text'
                            : this.props.tier === this.props.maxDepth
                            ? `tier-${this.props.maxDepth}-align-left`
                            : ''
                    }>
                    {this.shortIds.includes(this.props.id) ? (
                        <span className="dimensions-short-name">
                            {this.props.name}
                        </span>
                    ) : (
                        <span className="dimensions-long-name">
                            {this.props.lname || this.props.name}
                        </span>
                    )}
                    {this.props.isDebug && (
                        <span style={{ color: 'pink' }}>({this.props.id})</span>
                    )}
                </div>
            </div>
        );
    }

    private onEnter = () => {
        this.setState({
            showTooltip: true,
        });
    };

    private onLeave = () => {
        this.setState({
            showTooltip: false,
        });
    };
}
