import React, {
    useState,
    InputHTMLAttributes,
    useCallback,
    useEffect,
} from 'react';
import { debounce } from 'lodash';

type Props = InputHTMLAttributes<HTMLInputElement> & {
    onChangeDebounced: (value: string) => void;
    timeout?: number;
    initialValue?: string;
};

const DEFAULT_TIMEOUT = 500;

const DebouncedInput = ({
    onChangeDebounced,
    timeout = DEFAULT_TIMEOUT,
    initialValue = '',
    ...otherProps
}: Props) => {
    const [inputValue, setInputValue] = useState<string>('');

    useEffect(() => {
        setInputValue(initialValue);
    }, [initialValue]);

    const debouncedOnChange = useCallback(
        debounce(onChangeDebounced, timeout),
        [timeout, onChangeDebounced]
    );

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        setInputValue(value);
        debouncedOnChange(value);
    };

    return <input value={inputValue} onChange={onChange} {...otherProps} />;
};
export default DebouncedInput;
