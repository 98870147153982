import React from 'react';
import { inject, observer } from 'mobx-react';
import * as _ from 'lodash';
import classNames from 'classnames';
import Animate from 'react-smooth';
import { runInAction } from 'mobx';
import { TweenMax } from 'gsap';
import GQCWMenu from './GQCWMenu';
import CWRenderUtil from './GQCWRenderUtil';
import IGQCWSlider from './GQCustomWeightsSlider';
import GQCWSubPreset from './GQCWSubPreset';
import GQButton from '../../../GQButton/GQButton';
import Toast from '../../../scoringPage/lib/Toast';
import PresetInput from '../../../GQInput/GQSettingsInput';
import {
    IActiveDimension,
    ICustomWeightTree,
    IPreset,
} from '../../../../interfaces';
import { generateNumber, objLength } from '../../../../utils/generalUtils';
import CWStore from '../../../../stores/CustomWeightStore';
import rootStore, { IMobxRootState } from '../../../../RootStore';

const util = new CWRenderUtil();

export interface Props {
    saveStatus: 'success' | 'error';
    viewingPreset?: IPreset;
    presets?: IPreset[];
    activeLoading?: boolean;
    saveCurrentEditingCB: () => void;
    setActivePreset: (id: string) => void;
    getLastPreset: () => Promise<IPreset>;
    lastPreset?: IPreset;
    dimensions?: { [dimensionId: number]: IActiveDimension };
}

interface State {
    activePath: number[];
    hoverPath: {
        id: string;
        path: number[];
    };
    activeCW: ICustomWeightTree[];
    editID: string;
    isPristine: boolean;
    editName: string;
    toast: {
        showToast: boolean;
        msg: string;
        className: string;
    };
    showGuide: boolean;
    countryFilter: {
        [id: string]: string;
    };
    selectedCountries: number[];
    showCountryList: boolean;
}

@inject(
    ({ customWeightStore, risksStore }: IMobxRootState): Partial<Props> => {
        return {
            viewingPreset: customWeightStore.currentEditingPreset,
            presets: _.values(customWeightStore.presets),
            dimensions: risksStore.dimensions.hashed,
        };
    }
)
@observer
export default class GQCustomWeights extends React.Component<Props, State> {
    public lastPreset: any;
    private guideRef: HTMLDivElement = null;
    private nameTimeout: any = null;
    private presetsKey: number = generateNumber();
    constructor(props: Props) {
        super(props);
        this.lastPreset = { id: 0 };
        this.state = {
            activePath: [-1],
            activeCW: [],
            hoverPath: null,
            isPristine: true,
            toast: {
                showToast: false,
                msg: '',
                className: '',
            },
            editName: null,
            showGuide: false,
            editID: '',
            countryFilter: null,
            selectedCountries: [],
            showCountryList: false,
        };
    }

    public componentDidMount() {
        const preset = localStorage.getItem('lastPreset');
        this.lastPreset = JSON.parse(preset);
        if (this.lastPreset === null) {
            this.lastPreset = { id: 0 };
        }
    }

    public UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (this.props.saveStatus !== nextProps.saveStatus) {
            if (!nextProps.saveStatus) {
                return;
            }

            this.setState(
                {
                    toast: {
                        showToast: true,
                        msg:
                            nextProps.saveStatus === 'success'
                                ? 'Preset saved'
                                : 'Error saving preset',
                        className: nextProps.saveStatus,
                    },
                },
                this.resetToast
            );
        }
        if (!this.lastPreset.tree && nextProps.lastPreset) {
            this.lastPreset = nextProps.lastPreset;
        }

        if (
            this.props.viewingPreset &&
            nextProps.viewingPreset &&
            this.props.viewingPreset.id !== nextProps.viewingPreset.id
        ) {
            // Generate key to force re-render for animations
            this.presetsKey = generateNumber();

            this.setState({
                activeCW: [],
                activePath: [-1],
                editName: '',
            });
        }
    }

    public render() {
        return (
            <div className="gq-custom-weights">
                <GQCWMenu
                    setActivePreset={this.props.setActivePreset}
                    lastPresetId={this.lastPreset.id}
                    getLastPreset={this.props.getLastPreset}
                />
                <div className="gq-cw-container">
                    {!CWStore.noteDismissed &&
                        this.props.viewingPreset &&
                        this.props.presets.length > 1 && (
                            <div
                                className="gq-cw-info-bar"
                                ref={el => {
                                    if (el) {
                                        this.guideRef = el;
                                    }
                                }}>
                                <i
                                    className="gqi-guide"
                                    style={{
                                        paddingTop: '3px',
                                        paddingRight: '5px',
                                    }}
                                />

                                <div className="gq-cw-info-caption">
                                    <span>
                                        Create and adjust your Indicators impact
                                        on political risk. Click on the bars to
                                        adjust weights
                                    </span>
                                </div>

                                <GQButton
                                    noPadding={true}
                                    rounded={true}
                                    icon="gqi-close"
                                    onClick={this.hideGuide}
                                />
                            </div>
                        )}

                    {this.props.viewingPreset &&
                    this.props.presets.length > 1 ? (
                        this.props.viewingPreset.id !== null ? (
                            this.vewingPreset()
                        ) : (
                            this.fallbackViewingPreset()
                        )
                    ) : (
                        <h1 className="cw-no-cw-message">
                            You have no Custom Weights
                        </h1>
                    )}
                </div>

                <Toast
                    show={this.state.toast.showToast}
                    text={this.state.toast.msg}
                    toastClass={this.state.toast.className}
                />
            </div>
        );
    }

    private fallbackViewingPreset = () => {
        const presetID = this.lastPreset ? this.lastPreset.id : null;

        return (
            <div
                id="contentDiv"
                className="gq-cw-content"
                key={this.presetsKey}>
                <div className="gq-cw-header">
                    <PresetInput
                        editable={false}
                        focusOnMount={this.lastPreset.id === 'new'}
                        onChange={this.onNameChange}
                        placeholder={this.lastPreset.name}
                        inputValue={this.state.editName}
                    />

                    <GQButton
                        caption="Add Subpreset"
                        onClick={this.createSub}
                        style={{ height: 33, marginLeft: 16 }}
                        disabled={true}
                    />

                    <GQButton
                        toggleButton={true}
                        style={{
                            height: 33,
                            marginLeft: 16,
                            paddingLeft: 16,
                            paddingRight: 16,
                        }}
                        onClick={this.setActivePreset}
                        active={this.lastPreset.isActive}
                        disabled={true}
                        caption={
                            this.lastPreset.isActive ? 'Active' : 'Activate'
                        }
                        showLoading={this.props.activeLoading}
                    />
                </div>

                <div className="gq-cw-overview">
                    {this.lastPreset.tree &&
                        this.generateTree(
                            this.lastPreset.tree,
                            100,
                            1,
                            null,
                            this.lastPreset.id
                        )}
                </div>

                <IGQCWSlider
                    activeEntries={this.state.activeCW}
                    parentPath={this.state.activePath}
                    onChange={this.setDirty}
                    presetId={presetID}
                    editable={false}
                    show={this.state.editID === this.lastPreset.id}
                />
                {_.map(this.lastPreset.subs, sub => {
                    return (
                        <GQCWSubPreset
                            key={sub.id}
                            editingID={this.state.editID}
                            viewingPreset={this.lastPreset}
                            onChange={this.setDirty}
                            activePath={this.state.activePath}
                            activeTree={this.state.activeCW}
                            treeGen={this.generateTree}
                            sub={sub}
                        />
                    );
                })}
            </div>
        );
    };

    private vewingPreset = () => {
        const presetID = this.props.viewingPreset
            ? this.props.viewingPreset.id
            : null;

        const editable = this.props.viewingPreset
            ? this.props.viewingPreset.editable
            : false;

        return (
            <div
                id="contentDiv"
                className="gq-cw-content"
                key={this.presetsKey}>
                <div className="gq-cw-header">
                    <PresetInput
                        editable={this.props.viewingPreset.editable}
                        focusOnMount={this.props.viewingPreset.id === 'new'}
                        onChange={this.onNameChange}
                        placeholder={this.props.viewingPreset.name}
                        inputValue={this.state.editName}
                    />

                    <GQButton
                        caption="Add Subpreset"
                        onClick={this.createSub}
                        style={{ height: 33, marginLeft: 16 }}
                        disabled={
                            this.props.viewingPreset.id === 'new' ||
                            !this.props.viewingPreset.editable
                        }
                    />

                    <GQButton
                        toggleButton={true}
                        style={{
                            height: 33,
                            marginLeft: 16,
                            paddingLeft: 16,
                            paddingRight: 16,
                        }}
                        onClick={this.setActivePreset}
                        active={this.props.viewingPreset.isActive}
                        disabled={
                            this.props.viewingPreset.id === 'new' ||
                            this.props.viewingPreset.isActive
                        }
                        caption={
                            this.props.viewingPreset.isActive
                                ? 'Active'
                                : 'Activate'
                        }
                        showLoading={this.props.activeLoading}
                    />
                </div>

                <div className="gq-cw-overview">
                    {this.generateTree(
                        this.props.viewingPreset.tree,
                        100,
                        1,
                        null,
                        this.props.viewingPreset.id
                    )}
                </div>

                <IGQCWSlider
                    activeEntries={this.state.activeCW}
                    parentPath={this.state.activePath}
                    onChange={this.setDirty}
                    presetId={presetID}
                    editable={editable}
                    show={this.state.editID === this.props.viewingPreset.id}
                />
                {_.map(this.props.viewingPreset.subs, sub => {
                    return (
                        <GQCWSubPreset
                            key={sub.id}
                            editingID={this.state.editID}
                            viewingPreset={this.props.viewingPreset}
                            onChange={this.setDirty}
                            activePath={this.state.activePath}
                            activeTree={this.state.activeCW}
                            treeGen={this.generateTree}
                            sub={sub}
                        />
                    );
                })}
            </div>
        );
    };

    private setActivePreset = () => {
        if (
            !this.props.viewingPreset ||
            (this.props.viewingPreset && this.props.viewingPreset.isActive)
        ) {
            return;
        }

        this.props.setActivePreset(this.props.viewingPreset.id);
    };

    private createSub = () => {
        CWStore.createSub((id: string) => {
            try {
                TweenMax.to('#cwMainContainer', 0.5, {
                    scrollTo: `#gqCWTree\\:${id.replace(':', '\\:')}`,
                } as any);
            } catch (ex) {
                throw ex;
            }
        });
    };

    private hideGuide = () => {
        TweenMax.to(this.guideRef, 1, {
            y: '-25px',
            opacity: 0,
        } as any);

        CWStore.setNoteDismissed();
    };

    private onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        clearTimeout(this.nameTimeout);
        const newName = e.target.value;

        if (newName) {
            this.nameTimeout = setTimeout(() => {
                this.onSave();
            }, 1500);
            CWStore.updateName(newName);
        }

        this.setState(
            {
                editName: newName,
                isPristine: false,
            },
            () => {
                runInAction(() => {
                    rootStore.customWeightStore.currentEditingPreset.name = this.state.editName;
                });
            }
        );
    };

    private resetToast = () => {
        setTimeout(() => {
            this.setState({
                toast: {
                    showToast: false,
                    msg: '',
                    className: '',
                },
            });
        }, 3000);
    };

    private setDirty = () => {
        this.onSave();
    };

    private onSave = () => {
        this.setState({ isPristine: true }, () => {
            if (this.state.editName) {
                CWStore.updateName(this.state.editName);
            }

            this.props.saveCurrentEditingCB();
        });
    };

    private generateTree = (
        tree: ICustomWeightTree,
        width: number,
        depth: number,
        parent: ICustomWeightTree,
        id: string
    ): JSX.Element => {
        if (!this.props.dimensions || objLength(this.props.dimensions) === 0) {
            return null;
        }

        const name = this.getDimensionName(tree.dimensionId).lname;
        const childLen = tree.children ? objLength(tree.children) : 1;
        const parentPath = parent ? parent.path.concat([]) : [0];

        const onHover = () => {
            this.setState({
                hoverPath: {
                    id,
                    path: parentPath,
                },
            });
        };

        const onHoverOut = () => {
            this.setState({
                hoverPath: null,
            });
        };

        const onClick = () => {
            this.setState({
                activePath: parentPath,
                activeCW: parent
                    ? _.map(
                          parent.children,
                          (child: ICustomWeightTree) => child
                      )
                    : [],
                editID: id,
            });
        };

        const parentKeys = parent ? _.keys(parent.children) : [];

        return (
            <Animate
                key={tree.dimensionId}
                attributeName="transform"
                from={'scale(0)'}
                to={'scale(1)'}
                begin={300 * depth}>
                <div
                    className={classNames([
                        'cw-dim-root',
                        {
                            'tier-0': tree.tier === 0,
                            'tier-1': tree.tier === 1,
                            'tier-2': tree.tier === 2,
                            'tier-3': tree.tier === 3,
                        },
                    ])}
                    style={{ width: `${width}%` }}>
                    <div
                        className={classNames([
                            'cw-dim-tile',
                            {
                                active:
                                    _.isEqual(
                                        this.state.activePath,
                                        parentPath
                                    ) && this.state.editID === id,
                                hovered:
                                    _.isEqual(
                                        this.state.hoverPath &&
                                            this.state.hoverPath.path,
                                        parentPath
                                    ) &&
                                    this.state.hoverPath &&
                                    this.state.hoverPath.id === id,
                                left:
                                    parentKeys.indexOf(
                                        String(tree.dimensionId)
                                    ) === 0,
                                center:
                                    parentKeys.indexOf(
                                        String(tree.dimensionId)
                                    ) !== 0 &&
                                    parentKeys.indexOf(
                                        String(tree.dimensionId)
                                    ) !==
                                        parentKeys.length - 1,
                                right:
                                    parentKeys.indexOf(
                                        String(tree.dimensionId)
                                    ) ===
                                    parentKeys.length - 1,
                            },
                        ])}
                        style={
                            tree.tier !== 0
                                ? {
                                      backgroundColor: util.getColor(
                                          tree.dimensionId,
                                          tree.tier,
                                          tree.path
                                      ),
                                  }
                                : {}
                        }
                        onMouseEnter={onHover}
                        onMouseLeave={onHoverOut}
                        onClick={onClick}>
                        <div className="tile-ghost" />

                        {tree.tier !== 0 && (
                            <div className="cw-dim-val">
                                {this.props.viewingPreset.editable && (
                                    <span>
                                        {tree.tier < 3 ? width.toFixed(0) : ''}
                                    </span>
                                )}
                            </div>
                        )}

                        <div
                            className={classNames([
                                'cw-dim-name',
                                { 'last-tier': tree.tier === 3 },
                            ])}
                            title={tree.tier === 3 ? name : ''}>
                            <span>{name}</span>
                        </div>
                    </div>

                    {tree.children && (
                        <div className="cw-dim-children">
                            {_.map(
                                tree.children,
                                (branch: ICustomWeightTree) => {
                                    return this.generateTree(
                                        branch,
                                        isNaN(branch.weight)
                                            ? 100 / childLen
                                            : branch.weight * 100,
                                        depth + 1,
                                        tree,
                                        id
                                    );
                                }
                            )}
                        </div>
                    )}
                </div>
            </Animate>
        );
    };

    private getDimensionName(id: number) {
        return this.props.dimensions[id] || null;
    }
}
