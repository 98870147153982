import { IEventsData } from '../components/eventsFeed/EventsFeedInterfaces';
import * as _ from 'lodash';
import { IMapById } from '../interfaces';
import { HeatmapPercentile } from 'components/Heatmap/HeatmapTypes';
export function isScored(event: IEventsData) {
    return _.isArray(event.uscores) && event.uscores.length > 0;
}

export function objLength(obj: object) {
    return _.keys(obj).length;
}

// https://stackoverflow.com/questions/62036514/make-sure-that-using-this-pseudorandom-number-generator-is-safe-here
export function generateID() {
    return generateNumber().toString(36);
}

// https://stackoverflow.com/questions/62036514/make-sure-that-using-this-pseudorandom-number-generator-is-safe-here
export function generateNumber() {
    let array = new Uint32Array(1);
    window.crypto.getRandomValues(array); // Compliant for security-sensitive use cases
    const number = array[0];
    return number;
}

export function arrayToMapObjectById<T>(arr: T[], key: keyof T): IMapById<T> {
    const res: IMapById<T> = {};
    _.forEach(arr, t => (res[t[key] as any] = t));
    return res;
}

export function sortedArrayInsertIndex<T>(array: T[], newValue: T): number {
    let low = 0;
    let high = array.length;
    while (low < high) {
        const mid = Math.floor((low + high) / 2);
        if (array[mid] < newValue) {
            low = mid + 1;
        } else {
            high = mid;
        }
    }
    return low;
}

export function median(numbers: number[]): number {
    const sorted = [...numbers].sort((a, b) => a - b);
    const middle = Math.floor(sorted.length / 2);

    if (sorted.length % 2 === 0) {
        return (sorted[middle - 1] + sorted[middle]) / 2;
    }
    return sorted[middle];
}

export function dateOrMinAllowed(
    notNullDate: Date,
    minAllowedDate: Date
): Date {
    if (minAllowedDate && notNullDate < minAllowedDate) {
        return minAllowedDate;
    } else {
        return notNullDate;
    }
}

export function dateOrMaxAllowed(
    notNullDate: Date,
    maxAllowedDate: Date
): Date {
    if (maxAllowedDate && notNullDate > maxAllowedDate) {
        return maxAllowedDate;
    } else {
        return notNullDate;
    }
}

export function isHeatmapPercentileVisible(
    percentile: HeatmapPercentile,
    selectedPercentile: HeatmapPercentile
) {
    if (!selectedPercentile) {
        return false;
    }
    return (
        percentile.type === selectedPercentile.type &&
        percentile.percentile >= selectedPercentile.percentile
    );
}

// https://github.com/PimpTrizkit/PJs/wiki/12.-Shade,-Blend-and-Convert-a-Web-Color-(pSBC.js)#stackoverflow-archive-begin
// https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
export function shadeHexColor(color, percent) {
    var f = parseInt(color.slice(1), 16),
        t = percent < 0 ? 0 : 255,
        p = percent < 0 ? percent * -1 : percent,
        R = f >> 16,
        G = (f >> 8) & 0x00ff,
        B = f & 0x0000ff;
    return (
        '#' +
        (
            0x1000000 +
            (Math.round((t - R) * p) + R) * 0x10000 +
            (Math.round((t - G) * p) + G) * 0x100 +
            (Math.round((t - B) * p) + B)
        )
            .toString(16)
            .slice(1)
    );
}
export function blendHexColors(c0, c1, p) {
    var f = parseInt(c0.slice(1), 16),
        t = parseInt(c1.slice(1), 16),
        R1 = f >> 16,
        G1 = (f >> 8) & 0x00ff,
        B1 = f & 0x0000ff,
        R2 = t >> 16,
        G2 = (t >> 8) & 0x00ff,
        B2 = t & 0x0000ff;
    return (
        '#' +
        (
            0x1000000 +
            (Math.round((R2 - R1) * p) + R1) * 0x10000 +
            (Math.round((G2 - G1) * p) + G1) * 0x100 +
            (Math.round((B2 - B1) * p) + B1)
        )
            .toString(16)
            .slice(1)
    );
}