import { IUserOrganization } from 'interfaces';
import { observable, action } from 'mobx';
export const activeGroupIdKey = '__$ag';
export const activeGroupScroll = '__$agc';
class UserStore {
    @observable public uscoresPermissions: { [key: string]: boolean } = {
        can_edit: false,
        can_delete: false,
        can_score: false,
    };

    @observable public geoquantFlagsPermission: { [key: string]: boolean } = {
        can_see_archived_uscores: false,
    };

    @observable public username: string;
    @observable public organization: IUserOrganization;
    @observable public allPermissions: object;

    @action public setUscorePermission(permissions: {
        [key: string]: boolean;
    }) {
        this.uscoresPermissions = permissions;
    }

    @action public setGeoquantFlagsPermission(permissions: {
        [key: string]: boolean;
    }) {
        this.geoquantFlagsPermission = permissions;
    }

    @action public setUsername(username: string) {
        this.username = username;
    }

    @action public setOrganization(organization: IUserOrganization) {
        this.organization = organization;
    }

    @action public setAllPermissions(allPermissions: object) {
        this.allPermissions = allPermissions;
    }
}
export default new UserStore();
