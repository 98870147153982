import React from 'react';
import Map from '../GQSettings/SettingsPages/CountryGroups/Map/GQCGMap';

type Props = {
    selectedIds?: number[];
    onCountrySelected: (id: number) => void;
};

const GQCountrySelect = React.memo(
    ({ onCountrySelected, selectedIds }: Props) => {
        return (
            <div className="gq-cg-content-wrapper">
                <div className="gq-country-mode-select">
                    <Map
                        selectedIds={selectedIds}
                        onClick={onCountrySelected}
                    />
                </div>
            </div>
        );
    }
);

export default GQCountrySelect;
