import React, { useCallback } from 'react';
import rootStore from '../../RootStore';
import GQCountrySelect from '../GQCountryMode/GQCountrySelect';
import { useHistory } from 'react-router-dom';
import { VIEW } from 'interfaces';

const CountryGraphSelectCountry = () => {
    const { push } = useHistory();

    const onSelectCountry = useCallback((id: number) => {
        rootStore.countryStore.currentStore.overrideValues(id);
        push(`/${VIEW.COUNTRY_GRAPH}`);
    }, [push]);

    return <GQCountrySelect onCountrySelected={onSelectCountry} />;
};

export default CountryGraphSelectCountry;
