import React from 'react';
import * as _ from 'lodash';
import { GQutils } from '../../services/GQutils';
import ScoreRiskItem, { IRiskItem } from './GQScoreRiskItem';
import SeeAllRisks from './GQSeeAllRisks';
import { GQButton } from '../GQButton';

export interface IGQScoreRisksProps {
    allRisks: IRiskItem[];
    onSelected?: (riskId: number) => void;
    selectedRisk?: number;
    editMode: boolean;
}
export interface IGQScoreRisksState {
    seeAllRisks?: boolean;
    arrowClicked: boolean;
    customRisks: number[];
}

const TOP_SUGGESTIONS_NUM = 5;

export default class ScoreRisks extends React.Component<
    IGQScoreRisksProps,
    IGQScoreRisksState
> {
    constructor(props: IGQScoreRisksProps) {
        super(props);
        this.state = {
            seeAllRisks: false,
            arrowClicked: false,
            customRisks: [],
        };
    }

    public componentDidUpdate(prevProps: IGQScoreRisksProps) {
        if (
            JSON.stringify(prevProps.allRisks) !==
            JSON.stringify(this.props.allRisks)
        ) {
            this.setState({
                customRisks: [],
            });
        } else if (this.props.selectedRisk !== prevProps.selectedRisk) {
            this.selectRisk(this.props.selectedRisk, false);
        }
    }

    public render() {
        const seeAll = (): any => {
            if (!this.state.arrowClicked && !this.state.seeAllRisks) {
                this.setState({
                    seeAllRisks: true,
                    arrowClicked: true,
                });
            } else {
                this.setState({
                    arrowClicked: false,
                });
            }
        };

        return (
            <div className="score-risks-container">
                <div className="score-risks-header">
                    <div className="score-risks-title">Select risk</div>
                    <div className="score-risks-see-all" id="seeAllRisks">
                        {' '}
                        {this.state.seeAllRisks ? 'Collapse all' : 'See all'}
                    </div>
                    <GQButton
                        icon={'gqi-dropdown'}
                        noBorder={true}
                        noPadding={true}
                        style={{
                            fontSize: '20px',
                            color: 'rgba(255,255,255,0.6)',
                            verticalAlign: 'middle',
                        }}
                        onClick={seeAll}
                    />
                    {this.state.seeAllRisks ? (
                        <SeeAllRisks
                            allRisks={this.props.allRisks}
                            onSelected={this.selectRisk}
                            position={this.getSeeAllRiskPosition()}
                            closeSeeAllRisks={this.hideSeeAllRisks}
                        />
                    ) : (
                        ''
                    )}
                </div>

                <div className={'select-risk-mode active'}>
                    {_.map(this.suggestedRisks, risk => {
                        return (
                            <ScoreRiskItem
                                key={risk.dimension_id}
                                onclick={this.selectRisk}
                                risk={risk}
                                selected={
                                    this.props.selectedRisk ===
                                    risk.dimension_id
                                }
                            />
                        );
                    })}
                </div>
            </div>
        );
    }

    private get suggestedRisks(): IRiskItem[] {
        const topSuggestions = [...this.props.allRisks]
            .sort((a, b) => b.relevance_value - a.relevance_value)
            .slice(0, TOP_SUGGESTIONS_NUM);
        this.state.customRisks.forEach(riskId => {
            const risk = this.props.allRisks.find(
                r => r.dimension_id === riskId
            );
            topSuggestions.push(risk);
        });
        return topSuggestions;
    }

    private selectRisk = (riskId: number, notifyParent: boolean): void => {
        const customRisks = [...this.state.customRisks];
        if (
            this.props.allRisks.map(r => r.dimension_id).includes(riskId) &&
            !this.suggestedRisks.find(r => r.dimension_id === riskId)
        ) {
            customRisks.push(riskId);
        }
        this.setState({
            customRisks,
            seeAllRisks: false,
        });
        if (notifyParent) {
            this.props.onSelected(riskId);
        }
    };

    private hideSeeAllRisks = () => {
        // hide see all component if windows resize
        this.setState({
            seeAllRisks: false,
        });
    };

    private getSeeAllRiskPosition = () => {
        return GQutils.findElementPosition('seeAllRisks');
    };
}
