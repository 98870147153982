import React from 'react';
import { InsightRisk } from '../useInsightTemplate';
import moment from 'moment';
import { MOMENTJS_DATE_EXPORT_INSIGHT_FORMAT } from '../../../../constants';
import { TR, TD } from '../InsightTemplateElements';
import { isEmpty } from 'lodash';
import { ReactComponent as Logo } from 'assets/general/Geoquant_Logo_Digital_Black.svg';

const FONT_SIZE = 16;
const HEADER_BASE_FONT_SIZE = FONT_SIZE * 0.8;

type Props = {
    title: string;
    tags: string[];
    date: Date;
    risks: InsightRisk[];
};

const InsightTemplatePdfHeader = ({ title, tags, date, risks }: Props) => {
    return (
        <TR>
            <TD style={{ padding: 0 }}>
                <div style={{ fontFamily: 'OpenSans' }}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginBottom: '25px',
                        }}>
                        <Logo style={{ height: 50 }} />
                        <div
                            style={{
                                fontSize: HEADER_BASE_FONT_SIZE,
                                lineHeight: '25px',
                                height: '25px',
                            }}>
                            {moment(date).format(
                                MOMENTJS_DATE_EXPORT_INSIGHT_FORMAT
                            )}
                        </div>
                    </div>
                    <div
                        style={{
                            fontSize: FONT_SIZE,
                            fontWeight: 600,
                            lineHeight: 1.67,
                            color: '#000',
                            marginBottom: '12px',
                        }}>
                        {title}
                    </div>
                    <div
                        style={{
                            borderTop: '1px solid #000',
                            borderBottom: '1px solid #000',
                            marginBottom: '25px',
                            padding: '10px 0',
                            fontSize: HEADER_BASE_FONT_SIZE,
                            color: 'rgba(0, 0, 0, 0.65)',
                        }}>
                        {!isEmpty(tags) && (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '10px',
                                }}>
                                <i
                                    className="gqi-tags"
                                    style={{
                                        marginRight: '10px',
                                        fontSize: HEADER_BASE_FONT_SIZE,
                                    }}
                                />

                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}>
                                    {tags.join(', ')}
                                </div>
                            </div>
                        )}
                        {!isEmpty(risks) && (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                <i
                                    className="gqi-tags"
                                    style={{
                                        marginRight: '10px',
                                        fontSize: HEADER_BASE_FONT_SIZE,
                                    }}
                                />
                                {risks.map((risk: InsightRisk) => {
                                    return (
                                        <div
                                            key={risk.name}
                                            style={{
                                                fontSize: HEADER_BASE_FONT_SIZE,
                                                color: 'rgba(0, 0, 0, 0.65)',
                                                marginRight: '6px',
                                            }}>
                                            {risk.name}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </div>
            </TD>
        </TR>
    );
};

export default InsightTemplatePdfHeader;
