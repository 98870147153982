import {
    IEventsData,
    IUscoreActive,
    SCORED_EVENTS_FILTER,
} from './EventsFeedInterfaces';
import { E_RISK_TYPE, IActiveDimensionTree, IGetEventsQuery } from '../../interfaces';

export enum E_EVENTS_WORKER_IPC_CODES {
    INIT_DATA = 1,
    FETCH_EVENTS = 2,
    FETCH_TEMP_EVENTS = 3,
    SET_ACTIVE_GROUP = 4,
}

export interface IEventsWorkerIPCHandleActiveUscore {
    code: typeof E_EVENTS_WORKER_IPC_CODES.FETCH_TEMP_EVENTS;
    requestId: string;
    uscore: IUscoreActive;
}

export interface IEventsWorkerIPCInitData {
    code: typeof E_EVENTS_WORKER_IPC_CODES.INIT_DATA;
    token: string;
    baseURL: string;
    dimensionsTree: IActiveDimensionTree;
}

export interface IEventsWorkerIPCSetActiveGroup {
    code: typeof E_EVENTS_WORKER_IPC_CODES.SET_ACTIVE_GROUP;
    activeGroupId: string;
}

export interface IEventWorkerIPCExecuteActionProps extends IGetEventsQuery {
    selectedCountries: number[];
    activeGroupCountries: number[];
    selectedRisks: number[];
    limit: number;
    isDebug: boolean;
    searchString: string;
    scoredEventsFilter: SCORED_EVENTS_FILTER;
    includeMentionedCountries: boolean;
    riskType: E_RISK_TYPE;
}

export interface IEventWorkerIPCExecuteAction {
    code: typeof E_EVENTS_WORKER_IPC_CODES.FETCH_EVENTS;
    requestId: string;
    props: IEventWorkerIPCExecuteActionProps;
}

export type IEventsWorkerIPCRequest =
    | IEventsWorkerIPCInitData
    | IEventWorkerIPCExecuteAction
    | IEventsWorkerIPCHandleActiveUscore
    | IEventsWorkerIPCSetActiveGroup;

export enum E_EVENTS_WORKER_IPC_RESPONSE_CODES {
    EVENTS = 0,
    USCORE_EVENTS = 1,
}

export interface IEventsWorkerIPCResponseEvents {
    code: typeof E_EVENTS_WORKER_IPC_RESPONSE_CODES.EVENTS;
    requestId: string;
    data: IEventsData[];
}

export interface IEventsWorkerIPCResponseUScoreEvents {
    code: typeof E_EVENTS_WORKER_IPC_RESPONSE_CODES.USCORE_EVENTS;
    requestId: string;
    data: IEventsData[];
    uscore: IUscoreActive;
}

export type IEventsWorkerIPCResponse =
    | IEventsWorkerIPCResponseEvents
    | IEventsWorkerIPCResponseUScoreEvents;
