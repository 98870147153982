import { useEffect, useState } from 'react';
import APIService, {
    IActiveContingentRisk,
    IActiveCountryAPI,
    IActiveDimensionAPI,
    IActiveGroupsAPIResponse,
    IAPIActiveClientFacingIndicator,
} from 'services/APIService';

type PreloadData = {
    dimensions: IActiveDimensionAPI;
    clientFacingIndicators: IAPIActiveClientFacingIndicator;
    contingentRisks: IActiveContingentRisk[];
    countries: IActiveCountryAPI;
    activeGroups: IActiveGroupsAPIResponse;
};

const fetchAll = async () => {
    const [
        dimensions,
        clientFacingIndicators,
        contingentRisks,
        countries,
        activeGroups
    ] = await Promise.all([
        APIService.getActiveDimensions(),
        APIService.getActiveClientFacingIndicators(),
        APIService.getActiveContingentRisks(),
        APIService.getActiveCountries(),
        APIService.getActiveGroups(),
    ]);
    return {
        dimensions,
        clientFacingIndicators,
        contingentRisks,
        countries,
        activeGroups
    };
};

const delay = retryCount => {
    return new Promise(resolve => setTimeout(resolve, 1000 * 2 ** retryCount));
};

const fetchRetry = async (retryCount = 1, lastError = null) => {
    if (retryCount > 5) throw new Error(lastError);
    try {
        return await fetchAll();
    } catch (e) {
        await delay(retryCount);
        return fetchRetry(retryCount + 1, e);
    }
};

const usePreload = () => {
    const [didPreload, setDidPreload] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [preloadData, setPreloadData] = useState<PreloadData>();

    useEffect(() => {
        (async () => {
            if (didPreload) {
                return;
            }

            try {
                const data = await fetchRetry();
                setDidPreload(true);
                setPreloadData(data);
            } catch (e) {
                setError(true);
            }
        })();
    }, [didPreload]);
    return { didPreload, error, preloadData };
};

export default usePreload;
