import { C_SCORES_DATE_FORMAT } from '../constants';
import {
    AutoInsightMetadata,
    E_RISK_TYPE,
    IAPIActiveClientFacingIndicator,
    IActiveDimension,
    VIEW,
} from 'interfaces';
import { first, flow, get, isEmpty, join, map } from 'lodash/fp';
import moment from 'moment';
import APIService, { IActiveCountryMap, RiskAutoInsight } from 'services/APIService';
import { Descendant } from 'slate';

type RiskNameProps = {
    riskType: E_RISK_TYPE;
    dimensions: { [dimensionId: number]: IActiveDimension };
    activeClientFacingIndicators: IAPIActiveClientFacingIndicator;
    dateFrom: moment.Moment;
    dateUntil: moment.Moment;
    selectedCountries: number[];
    countries?: IActiveCountryMap;
    riskId: number;
};

type AutoGenerateInsightProps = {
    selectedCountries: number[];
    dateFrom: moment.Moment;
    dateUntil: moment.Moment;
    riskType: E_RISK_TYPE;
    selectedRisks: number[];
    question: string;
};

type AutoGenerateInsightResult = {
    insightNodes: Descendant[];
    metadata: AutoInsightMetadata;
};

export const getAutoGenerateName = (props: RiskNameProps) => {
    const {
        riskType,
        dimensions,
        activeClientFacingIndicators,
        dateFrom,
        dateUntil,
        selectedCountries,
        riskId,
        countries,
    } = props;

    const countries_str = join(
        ',',
        map((country: number) => countries[country].name, selectedCountries)
    );
    const riskName =
        riskType === E_RISK_TYPE.DIMENSIONS
            ? dimensions[riskId].lname
            : activeClientFacingIndicators[riskId].display_name;

    return `${countries_str} ${riskName} Auto Insight ${dateFrom.format(
        C_SCORES_DATE_FORMAT
    )} ${dateUntil.format(C_SCORES_DATE_FORMAT)}`;
};

const convertAutoInsightRisksToText = (
    prefix: string,
    riskAutoInsights: RiskAutoInsight[]
) => {
    const spaceNode = {
        children: [
            {
                text: '',
            },
        ],
    };

    const prefixNode = {
        children: [
            {
                text: prefix || '',
                bold: true,
                underline: true,
            },
        ],
    };

    const insightPerRiskNodes = map((riskAutoInsight: RiskAutoInsight) => {
        return {
            children: [
                {
                    children: [
                        {
                            text: riskAutoInsight.risk,
                            bold: true,
                        },
                    ],
                },
                {
                    children: [
                        {
                            text: riskAutoInsight.text,
                        },
                    ],
                },
            ],
        };
    }, riskAutoInsights);

    return [spaceNode, prefixNode, spaceNode, ...insightPerRiskNodes];
};

export const autoGenerateInsight = async ({
    selectedCountries,
    dateFrom,
    dateUntil,
    riskType,
    selectedRisks,
    question,
}: AutoGenerateInsightProps): Promise<AutoGenerateInsightResult> => {
    const insight = await APIService.autoGenerateInsight(
        selectedCountries,
        dateFrom,
        dateUntil,
        riskType,
        first(selectedRisks),
        question
    );

    if (isEmpty(insight)) {
        return;
    }

    const autoInsgihtRisks = get('auto_insights', insight);
    const prefix = get('prefix', insight);
    let textNodes = convertAutoInsightRisksToText(prefix, autoInsgihtRisks);
    const links = flow(
        get('articles'),
        map(article => ({
            type: 'link',
            url: `/#/${VIEW.SCORE_EVENT}/${get('id', article)}`,
            children: [
                {
                    text: `${get('headline', article)}`,
                },
            ],
        })),
        map(link => ({
            children: [link, { text: '' }],
        }))
    )(insight);
    const warnings = flow(
        get('warnings'),
        map(warning => ({
            children: [
                {
                    text: warning,
                    bold: true,
                },
            ],
        }))
    )(insight);

    const linksAndWarnings = [];
    if (!isEmpty(links)){
        linksAndWarnings.push({children: links})
    }
    if (!isEmpty(warnings)){
        linksAndWarnings.push({children: warnings})
    }
    const insightNodes: Descendant[] = question ? [] : linksAndWarnings;
    insightNodes.push(...textNodes, {
        children: [{ text: '' }],
    });

    const metadata: AutoInsightMetadata = {
        countries_id: selectedCountries,
        articles_ids: flow(get('articles'), map(get('id')))(insight),
        start_date: dateFrom.valueOf(),
        end_date: dateUntil.valueOf(),
        risks: selectedRisks,
        risk_type: riskType,
        date_created: moment().valueOf(),
    };
    return { metadata, insightNodes };
};
