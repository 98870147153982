import { Moment } from 'moment';
import { IActiveCountry } from '../../interfaces';
import { SVGProps } from 'react';
import { E_CHART_GROUP_IDENTIFIERS } from '../../services/lib/APIServiceInterfaces';

export { E_CHART_GROUP_IDENTIFIERS };
export interface IChartData {
    identifier: E_CHART_GROUP_IDENTIFIERS;
    lines: IChartLine[];
    maxValue: number;
    minValue: number;
    domainY: {
        expanded: E_DOMAIN_Y_SCALE;
        minimized: E_DOMAIN_Y_SCALE;
    };
}

export interface IChartIndicatorProps {
    [key: string]: any;
    isRight: boolean;
    x: number;
    y: number;
}

export enum E_DOMAIN_Y_SCALE {
    standalone = 0, // by min and max value of $.chartLine
    shared = 1, // IChartData.minValue and maxValue
}

export interface ILineValue {
    date: Moment;
    yValue: number;
    circle?: IChartCircle;
}

export interface ILineSideIndicator {
    component: React.ComponentClass<IChartIndicatorProps>;
    componentProps: any;
}
export interface IChartLine {
    identifier: string;
    maxValue: number;
    minValue: number;
    className: string;
    props: SVGProps<any>;
    country: number;
    sideIndicator?: ILineSideIndicator;
    setVirtualRanges?: (from: Moment, until: Moment) => void;
    hideYValue?: boolean;
    getValue: (dateKey: string) => ILineValue;
    draw: (
        yScale: d3.ScaleLinear<any, any>,
        xScale: d3.ScaleTime<any, any>
    ) => string;
    chartType?: E_CHART_GROUP_IDENTIFIERS;
}

export interface IClosestChartLine {
    yVal: number;
    distance: number;
    identifier: string;
    activeDate: Moment;
    type: E_CHART_GROUP_IDENTIFIERS;
    country: number;
}

export interface IChartCircle {
    identifier: string;
    radius?: number;
    impact?: number;
    className: string;
    props: SVGProps<any>;
}

export type IExternalData = Map<string, IChartLine>;
export interface IChartStoreOptional {
    baseDate?: Moment;
    rangeLeft?: number;
    rangeRight?: number;
    chartData?: IChartData[];
    showLines?: boolean;
    showCircles?: boolean;
    opacity?: boolean;
    countryList?: IActiveCountry[];
    width?: number;
    height?: number;
    clientWidth?: number;
    clientHeight?: number;
    expanded?: boolean;
    virtualToday?: Moment;
    isDragging?: boolean;
    externalData?: IExternalData;
    snapshotMode?: boolean;
    showVirtualToday?: boolean;
}
