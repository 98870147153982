import * as _ from 'lodash';
import React from 'react';
import moment from 'moment';
import { TransitionGroup, Transition } from 'react-transition-group';
import { TweenLite } from 'gsap';
import InsightNote from './InsightNote';
import GQButton from '../../GQButton/GQButton';
import rootState from '../../../RootStore';
import { ActivityNoteStore } from '../../../stores/insights/InsightsNoteStore';
import { MOMENTJS_DATE_DISPLAY_FORMAT } from '../../../constants';
import {
    E_COMPONENT_TYPE,
    IActiveCountryMap,
} from '../../../services/APIService';

interface Props {
    notes: ActivityNoteStore[];
    countries?: IActiveCountryMap;
    onTextChange: (id: string, newVal: string) => void;
    onDeleteNote: (index: number) => void;
    onLockClick: () => void;
}

const editButtonStyle: React.CSSProperties = {
    position: 'absolute',
    zIndex: 2,
    right: '-.75em',
    top: '1rem',
};

export default class InsightNotes extends React.Component<Props> {
    public render() {
        return (
            <div className="gq-insights-list flex-container flex-dir-column">
                <div className="gq-insights-list-header flex-container align-middle">
                    <span>Notes</span>
                </div>
                <div className="gq-insights-list-content flex-container flex-dir-column">
                    <TransitionGroup component={null}>
                        {_.map(this.props.notes, this.renderNote)}
                    </TransitionGroup>
                </div>
                <GQButton
                    icon="gqi-edit"
                    toggleButton
                    rounded
                    containerStyle={editButtonStyle}
                    onClick={this.onLockToggle}
                    active={rootState.insightsStore.annotationMode}
                    className="gq-insight-list-edit-mode-button"
                />
            </div>
        );
    }
    private renderNote = (note: ActivityNoteStore, i: number) => {
        const index = i + 1;
        const date =
            note.noteType === E_COMPONENT_TYPE.CHART
                ? moment(note.date).format(MOMENTJS_DATE_DISPLAY_FORMAT)
                : null;
        return (
            <Transition
                key={note.id}
                onEnter={this.onNoteEnter}
                onExit={this.onNoteLeave}
                timeout={300}>
                <InsightNote
                    id={note.id}
                    index={index}
                    text={note.text}
                    date={date}
                    onChange={this.props.onTextChange}
                    onDelete={this.props.onDeleteNote}
                    country={
                        this.props.countries &&
                        this.props.countries[note.countryID]
                    }
                />
            </Transition>
        );
    };
    private onNoteEnter(node: HTMLElement) {
        TweenLite.set(node, { clearProps: 'x' });
        TweenLite.from(node, 0.3, {
            x: `-=${node.getBoundingClientRect().width}`,
        });
    }
    private onNoteLeave(node: HTMLElement) {
        TweenLite.set(node, { clearProps: 'x' });
        TweenLite.to(node, 0.3, {
            x: `-=${node.getBoundingClientRect().width}`,
        });
    }
    private onLockToggle = () => {
        if (this.props.onLockClick) {
            this.props.onLockClick();
        }
    };
}
