import APIService, {
    IActivityComponent,
    E_RISK_TYPE,
} from 'services/APIService';
import { get, flow, map, keyBy } from 'lodash/fp';
import { useState, useEffect } from 'react';
import { ComponentsById } from '../InsightTemplateTypes';
import { Descendant } from 'slate';

export type InsightRisk = { name: string; color: string };
export type InsightData = {
    nodes: Descendant[]
    componentsById: ComponentsById;
    date: Date;
    title: string;
    tags: string[];
    risks: InsightRisk[];
};

const useInsightTemplate = (insightId: string) => {
    const [insightData, setInsightData] = useState<InsightData>(null);

    //todo: this is a hack, think of a better way of toggling body overflow visible/hidden value
    useEffect(() => {
        document.body.style.overflow = 'auto';
        return () => (document.body.style.overflow = 'hidden');
    }, []);

    useEffect(() => {
        (async () => {
            if (!insightId){
                return;
            }
            const [activity, indicators, dimensionsList] = await Promise.all([
                APIService.getFullActivity(insightId),
                APIService.getActiveClientFacingIndicators(),
                APIService.getActiveDimensionsList(),
            ]);

            const componentsById = flow(
                get('components'),
                map((component: IActivityComponent[]) => ({
                    url: get('imageURL', component),
                    id: get('id', component),
                    type: get('type', component),
                })),
                keyBy('id')
            )(activity);

            const risks = flow(
                get('risks'),
                map((risk: { type: E_RISK_TYPE; id: number }) => {
                    switch (risk.type) {
                        case E_RISK_TYPE.DIMENSIONS:
                            return {
                                name: dimensionsList.find(
                                    dimension => dimension.id === risk.id
                                ).lname,
                                color: 'red', //todo: use colorStack
                            };

                        case E_RISK_TYPE.CLIENT_FACING_INDICATORS:
                            return {
                                name: indicators[risk.id].display_name,
                                color: 'red',
                            };
                    }
                })
            )(activity);

            setInsightData({
                componentsById,
                nodes: activity.insightsData.insight.nodes,
                date: get('shareDate', activity) || get('date', activity),
                title: activity.insightsData.name,
                tags: activity.insightsData.tags,
                risks,
            });
        })();
    }, [insightId]);

    return insightData;
};

export default useInsightTemplate;
