import { IChartStoreOptional } from './chartInterfaces';
import moment from 'moment';
import { observable, action, computed } from 'mobx';

import {
    C_TIMELINE_MIN_DATE,
    C_TIMELINE_MAX_DATE,
    CHART_TOOLBAR_HEIGHT,
} from '../../constants';

import { IRootStoreExporter } from '../../interfaces';
import { debounce } from 'lodash';

export const minRanges = {
    rangeLeft: 7,
    rangeRight: 7,
};

export const maxRanges = {
    minBaseDate: C_TIMELINE_MIN_DATE.clone(),
    maxBaseDate: C_TIMELINE_MAX_DATE.clone(),
};

export class ChartStore implements IRootStoreExporter {
    @observable public rangeLeft: number;
    @observable public rangeRight: number;
    @observable public baseDate: moment.Moment = moment().startOf('day');
    @observable public showLines: boolean = true;
    @observable public showCircles: boolean = false;
    @observable public showVirtualToday: boolean = true;
    @observable public opacity: boolean = false;
    @observable public virtualToday: moment.Moment = moment().startOf('day');
    @observable public width: number = 800;
    @observable public height: number = 90;
    @observable public clientWidth: number = 800;
    @observable public clientHeight: number = 0;
    @observable public sequence: number = 0;
    @observable public isDragging: boolean = false;

    @action public updateDimensions = () => {
        const elem = document.querySelector('.chart-container');
        if (!elem) {
            return;
        }
        const { width, height } = elem.getBoundingClientRect();
        if (
            this.width !== width ||
            this.height !== height - CHART_TOOLBAR_HEIGHT
        ) {
            this.setState({
                width,
                height: height - CHART_TOOLBAR_HEIGHT,
            });
        }
    };
    @action public setState(state: IChartStoreOptional) {
        Object.assign(this, state);
    }

    @action public updateStateByScoresDelta(
        scoresDeltaOffsetStart,
        scoresDeltaWindowSize
    ) {
        let baseDate = moment().startOf('day');
        const startDate = baseDate.clone().add(scoresDeltaOffsetStart, 'days');
        const endDate = startDate.clone().add(scoresDeltaWindowSize, 'days');
        baseDate = this.ensureDateWithinRange(
            this.baseDate,
            startDate,
            endDate
        );

        this.setState({
            rangeLeft: baseDate.diff(startDate, 'days'),
            rangeRight: endDate.diff(baseDate, 'days'),
            baseDate,
            showLines: true,
            showCircles: false,
            opacity: false,
            virtualToday: baseDate,
            isDragging: false,
            showVirtualToday: true,
            clientWidth: document.documentElement.clientWidth,
            clientHeight: document.documentElement.clientHeight,
        });
    }

    // ** NOTICE ** consider using the newer setNewRangeByDates method, since this one is probably gonna be deprecated
    @action public setNewRanges(from: number, until: number) {
        let newLeft = Math.max(minRanges.rangeLeft, from);
        let newRight = Math.max(minRanges.rangeRight, until);
        let xLeft = this.baseDate.clone().subtract(newLeft, 'days');
        let xRight = this.baseDate.clone().add(newRight, 'days');
        if (xLeft.isBefore(maxRanges.minBaseDate)) {
            xLeft = maxRanges.minBaseDate;
            newLeft = this.baseDate.diff(xLeft, 'days');
        }

        if (xRight.isAfter(maxRanges.maxBaseDate)) {
            xRight = maxRanges.maxBaseDate;
            newRight = xRight.diff(this.baseDate, 'days');
        }
        this.setState({
            rangeLeft: newLeft,
            rangeRight: newRight,
        });
    }

    @action public setNewRangeByDates = (leftDate: Date, rightDate: Date) => {
        const startDate = moment.max(moment(leftDate), maxRanges.minBaseDate);
        const endDate = moment.min(moment(rightDate), maxRanges.maxBaseDate);
        const daysDiff = endDate.diff(startDate, 'days');
        if (daysDiff < 1) {
            throw new Error(
                'Invalid date range given. rightDate must be at least one day bigger than leftDate'
            );
        }

        const baseDate = this.ensureDateWithinRange(
            this.baseDate,
            startDate,
            endDate
        );
        const virtualToday = this.ensureDateWithinRange(
            this.virtualToday,
            startDate,
            endDate
        );
        this.setState({
            baseDate,
            virtualToday,
            rangeLeft: baseDate.diff(startDate, 'days'),
            rangeRight: endDate.diff(baseDate, 'days'),
        });
    };

    @action public increaseSequence() {
        this.sequence++;
    }

    @action public setVirtualToday(moment: moment.Moment) {
        this.virtualToday = moment;
    }

    @action public setNewBaseDate(
        date: moment.Moment,
        virtualToday: moment.Moment
    ): moment.Moment | undefined {
        if (date.diff(this.baseDate, 'days') === 0) {
            return;
        }
        const possibleMinimum = date.clone().subtract(this.rangeLeft, 'days');
        const possibleMaximum = date.clone().add(this.rangeRight, 'days');
        if (
            possibleMinimum.isAfter(maxRanges.minBaseDate, 'days') &&
            possibleMaximum.isBefore(maxRanges.maxBaseDate, 'days')
        ) {
            this.setState({
                baseDate: date,
            });

            if (virtualToday.isAfter(possibleMaximum, 'days')) {
                return possibleMaximum;
            }

            if (virtualToday.isBefore(possibleMinimum, 'days')) {
                return possibleMinimum;
            }
        }
    }

    public exportKeys() {
        return [
            'rangeLeft',
            'rangeRight',
            'baseDate',
            'showLines',
            'showCircles',
            'opacity',
            'virtualToday',
        ];
    }

    @computed get rangeLeftDate() {
        return this.baseDate.clone().subtract(this.rangeLeft, 'days');
    }

    @computed get rangeRightDate() {
        return this.baseDate.clone().add(this.rangeRight, 'days');
    }

    @computed get minPossibleRangeLeftDate() {
        return this.baseDate.clone().subtract(minRanges.rangeLeft, 'days');
    }

    @computed get minPossibleRangeRightDate() {
        return this.baseDate.clone().add(minRanges.rangeRight, 'days');
    }

    private ensureDateWithinRange(
        date: moment.Moment,
        rangeStart: moment.Moment,
        rangeEnd: moment.Moment
    ) {
        if (date.isBefore(rangeStart) || this.baseDate.isAfter(rangeEnd)) {
            const daysDiff = rangeEnd.diff(rangeStart, 'days');
            return rangeStart.clone().add(Math.round(daysDiff / 2), 'days');
        } else {
            return date;
        }
    }
}

const chartStore = new ChartStore();

const onResize = () => {
    const elem = document.querySelector('.chart-container');
    if (!elem) {
        return;
    }

    const width = elem.clientWidth;
    const height = elem.clientHeight - CHART_TOOLBAR_HEIGHT;
    const { clientWidth, clientHeight } = document.documentElement;

    if (
        chartStore.clientWidth === clientWidth &&
        chartStore.clientHeight === clientHeight &&
        width === chartStore.width &&
        height === chartStore.height
    ) {
        return;
    }
    chartStore.setState({
        // todo: refactor elem.getWidth/Height
        width: elem.clientWidth,
        height: elem.clientHeight - CHART_TOOLBAR_HEIGHT,
        clientWidth,
        clientHeight,
    });
};
const onResizeDebounced = debounce(onResize, 300);

window.addEventListener('resize', onResizeDebounced);

export default chartStore;
