import { ReactElement } from 'react';

export type HeatmapCellType = {
    value: number;
    isVisible: boolean;
    color: string;
    clickable: boolean;
    percentile: HeatmapPercentile;
    tooltip: () => ReactElement;
};

export type HeatmapCellPosition = {
    row: number;
    column: number;
};

export type HeatmapPercentile = {
    label: string;
    type: 'green' | 'red' | 'white';
    color: string;
    percentile: number;
};

export enum HeatmapScoreType {
    RISK = 'RISK',
    PULSE = 'PULSE',
}

export type HeatmapRisk = {
    id: number;
    title: string;
};
