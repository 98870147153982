import { TweenLite } from 'gsap';

export interface IAnimateOptions {
    duration?: number;
    onComplete?: () => void;
}
class AnimationUtils {
    private duration = 0.5;
    public fadeInFromTop = (
        element: Element | string,
        cb: () => void,
        duration?: number
    ) => {
        TweenLite.set(element, { clearProps: 'y, opacity' });
        TweenLite.from(element, duration || this.duration, {
            y: '-=10',
            opacity: 0,
            onComplete: cb,
        });
    };
    public fadeOutToTop = (
        element: Element | string,
        cb: () => void,
        duration?: number
    ) => {
        TweenLite.set(element, { clearProps: 'y, opacity' });
        TweenLite.to(element, duration || this.duration, {
            y: '-=10',
            opacity: 0,
            onComplete: cb,
        });
    };
    public fadeOutToBottom = (
        element: Element | string,
        cb: () => void,
        duration?: number
    ) => {
        TweenLite.set(element, { clearProps: 'y, opacity' });
        TweenLite.to(element, duration || this.duration, {
            y: '+=10',
            opacity: 0,
            onComplete: cb,
        });
    };
    public fadeInFromBottom = (
        element: Element | string,
        cb: () => void,
        duration?: number
    ) => {
        TweenLite.set(element, { clearProps: 'y, opacity' });
        TweenLite.from(element, duration || this.duration, {
            y: '+=10',
            opacity: 0,
            onComplete: cb,
        });
    };
    public animateHeight = (
        element: Element | string,
        from: number,
        to: number,
        options?: IAnimateOptions
    ) => {
        const { duration, onComplete } = options || ({} as IAnimateOptions);
        TweenLite.set(element, { clearProps: 'height' });
        TweenLite.fromTo(
            element,
            duration || this.duration,
            { height: from },
            { height: to, onComplete }
        );
    };
}

const animUtils = new AnimationUtils();
export default animUtils;
