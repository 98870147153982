import React from 'react';
import CheckBox from 'components/CountryPicker/CheckBox';
import { includes } from 'lodash/fp';

interface IExternalDataItemState {
    checked: boolean;
}
interface IExternalDataItemProps {
    name: string;
    id: number;
    checked: boolean;
    selectedList: number[];
    onclick: (id: number) => any;
}

class ExternalDataItem extends React.Component<
    IExternalDataItemProps,
    IExternalDataItemState
> {
    constructor(props: IExternalDataItemProps) {
        super(props);
        this.state = {
            checked: false,
        };
    }

    public render() {
        return (
            <div
                className="external-data-item"
                onClick={() => this.props.onclick(this.props.id)}>
                <CheckBox
                    isRadioButton={true}
                    isSelected={includes(
                        this.props.id,
                        this.props.selectedList
                    )}
                />{' '}
                <span>{this.props.name}</span>{' '}
            </div>
        );
    }
}

export default ExternalDataItem;
