import React from 'react';
import { TD } from '../InsightTemplateElements';

const Link = (
    props: React.DetailedHTMLProps<
        React.AnchorHTMLAttributes<HTMLAnchorElement>,
        HTMLAnchorElement
    >
) => {
    return (
        <a {...props} target="_blank" style={{ marginRight: '25px' }}>
            {props.children}
        </a>
    );
};

const Image = (
    props: React.DetailedHTMLProps<
        React.ImgHTMLAttributes<HTMLImageElement>,
        HTMLImageElement
    >
) => {
    return (
        <img {...props} style={{ width: '24px', height: '24px' }} alt="">
            {props.children}
        </img>
    );
};
const InsightTemplateFooterLinks = () => {
    return (
        <React.Fragment>
            <TD>
                <Link
                    href="https://www.linkedin.com/company/geoquant/"
                    target="_blank">
                    <Image
                        src="https://cdn-images.mailchimp.com/icons/social-block-v2/outline-dark-linkedin-48.png"
                        alt="LinkedIn"
                    />
                </Link>
            </TD>

            <TD>
                <Link href="https://twitter.com/geoquant" target="_blank">
                    <Image
                        src="https://cdn-images.mailchimp.com/icons/social-block-v2/outline-dark-twitter-48.png"
                        alt="Twitter"
                    />
                </Link>
            </TD>
            <TD>
                <Link href="https://www.geoquant.com/" target="_blank">
                    <Image
                        src="https://cdn-images.mailchimp.com/icons/social-block-v2/outline-dark-link-48.png"
                        alt="Website"
                    />
                </Link>
            </TD>
            <TD>
                <Link href="mailto:marketing@geoquant.com" target="_blank">
                    <Image
                        src="https://cdn-images.mailchimp.com/icons/social-block-v2/outline-dark-forwardtofriend-48.png"
                        alt="Email"
                    />
                </Link>
            </TD>
        </React.Fragment>
    );
};

export default InsightTemplateFooterLinks;
