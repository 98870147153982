import React from 'react';
import { TweenLite } from 'gsap';
import cx from 'classnames';
import { TransitionGroup, Transition } from 'react-transition-group';

import GQButton from '../GQButton/GQButton';
import CancelableEvents from '../../utils/CancelableEvents';

interface IGQAccordionProps {
    icon: string;
    expanded: boolean;
    expandedWidth?: number;
    active?: boolean;
    tooltip?: string;
    onClick?: () => void;
    onAnimComplete?: () => void;
    hideMainButton?: boolean;
    containerStyle?: React.CSSProperties;
}

const width = {
    collapsed: 32,
    expanded: 128,
};

export default class GQAccordion extends React.Component<IGQAccordionProps> {
    private containerRef: HTMLDivElement = null;
    private cancelable: CancelableEvents;
    private expansionTween: TweenLite;
    public UNSAFE_componentWillUpdate(nextProps: IGQAccordionProps) {
        if (nextProps.expanded && !this.props.expanded) {
            this.expansionTween = TweenLite.fromTo(
                this.containerRef,
                0.5,
                { width: width.collapsed },
                { width: this.expandedWidth, onComplete: this.onComplete }
            );
        } else if (this.props.expanded && !nextProps.expanded) {
            this.expansionTween = TweenLite.fromTo(
                this.containerRef,
                0.5,
                { width: this.expandedWidth },
                { width: width.collapsed, onComplete: this.onComplete }
            );
        }
        if (this.expansionTween) {
        }
    }
    public componentDidMount() {
        this.cancelable = new CancelableEvents();
    }
    public componentWillUnmount() {
        this.cancelable.cancelAll();
    }
    public render() {
        return (
            <div
                className={cx([
                    'gq-accordion',
                    { expanded: this.props.expanded },
                ])}
                style={this.props.containerStyle}
                ref={el => (this.containerRef = el)}>
                {!this.props.hideMainButton && (
                    <GQButton
                        icon={this.props.icon}
                        tooltip={this.props.tooltip}
                        onClick={this.props.onClick}
                        noBorder={true}
                        toggleButton={true}
                        tooltipPosition="bottom left"
                        style={{ fontSize: '18px' }}
                        active={this.props.active}
                    />
                )}
                <TransitionGroup component={null}>
                    {this.props.expanded &&
                        React.Children.map(this.props.children, child => (
                            <Transition
                                timeout={500}
                                onEnter={this.onSubMenuEnter}
                                onExit={this.onSubMenuExit}>
                                {child}
                            </Transition>
                        ))}
                </TransitionGroup>
            </div>
        );
    }
    private onSubMenuEnter = (node: HTMLElement) => {
        TweenLite.set(node, { clearProps: 'all' });
        TweenLite.from(node, 0.5, { y: '+=50', opacity: 0 });
    };
    private onSubMenuExit = (node: HTMLElement) => {
        this.cancelable.setTimeout(() => {
            TweenLite.set(node, { clearProps: 'all' });
            TweenLite.to(node, 0.5, { y: '-=50', opacity: 0 });
        });
    };
    private onComplete = () => {
        this.props.onAnimComplete && this.props.onAnimComplete();
    };

    private get expandedWidth(): number {
        return this.props.expandedWidth || width.expanded;
    }
}
