import React from 'react';
import { IChartData, E_CHART_GROUP_IDENTIFIERS } from './chartInterfaces';
import ChartScoresService from '../../services/ChartScoresService';
import { inject, observer } from 'mobx-react';
import rootStore, { IMobxRootState } from '../../RootStore';
import Chart, { IChartPadding } from './Chart';
import { Moment } from 'moment';
import chartStore from './ChartStore';
import EventsService from '../../services/EventsService';
import CancelableEvents from '../../utils/CancelableEvents';
import { VIEW, GLOBAL_INDEXES, navbarContingentGroup } from '../../interfaces';
import { toJS } from 'mobx';
import APIService, {
    E_RISK_TYPE,
    IAPIMaxImpactMap,
} from '../../services/APIService';
import loading from '../../services/LoadingService';
import { first, isEmpty, map, filter, flow, forEach, isEqual, includes } from 'lodash/fp';
import ChartData from './ChartData';
interface IChartDataProviderState {
    data: IChartData[];
}

interface IChartDataProviderProps {
    onPan: (pan: number) => void;
    onVirualTodayChange: (date: Moment) => void;
    onWheel: (isUp: boolean) => void;
    hasToolbar: boolean;
    showVirtualToday: boolean;
    activeView: VIEW;
    minX?: Moment;
    maxX?: Moment;
    width?: number;
    height?: number;
    virtualToday?: Moment;
    expanded?: boolean;
    showLines?: boolean;
    showCircles?: boolean;
    isOpaque?: boolean;
    isDragging?: boolean;
    padding?: IChartPadding;
    currencyCountry?: number[];
    countries?: number[];
    identifiers?: number[];
    identifierType?: E_RISK_TYPE;
    isDimensionsMultiSelect?: boolean;
    showExchange?: boolean;
    showProjectionData?: boolean;
    showPulseData?: boolean;
    showGeoquantLine?: boolean;
    sequence?: number;
    annoMode?: boolean;
    hasActiveCustomWeight?: boolean;
    updateChartDimensions?: () => void;
    activeGlobalIndicators?: GLOBAL_INDEXES[];
    changeDataWhenDragStop?: boolean;
}
@inject(
    (
        {
            chartStore,
            risksStore,
            countryStore,
            customWeightStore,
            insightsStore,
        }: IMobxRootState,
        nextProps: IChartDataProviderProps
    ): Partial<IChartDataProviderProps> => {
        // todo:eventually ChartDataProvider should receive the countries list as a prop depending on each page's country list data structure
        // (eg - Contingent page will pass the first country + secondary countries list as a single array, while the map page will pass the selected country list array etc.)
        // ChartDataProvider should not decide or know how to obtain its country list since this is a concern of each page in the app.
        // the current plan is to:
        // 1. make the ChartDataProvider decide which country store to use
        // 2. convert HomeController mega-component into multiple components - each component per app page (eg: ContingentPage, WorldGrpahPage, CountryPage etc.)
        // 3. Each page will have (or not have) its own map component which will receive the countries list as a prop from the page component
        return {
            minX: chartStore.baseDate
                .clone()
                .startOf('day')
                .subtract(chartStore.rangeLeft, 'days'),
            maxX: chartStore.baseDate
                .clone()
                .startOf('day')
                .add(chartStore.rangeRight, 'days'),
            width: nextProps.width || chartStore.width,
            height: nextProps.height || chartStore.height,
            virtualToday: chartStore.virtualToday,
            showLines: chartStore.showLines,
            showCircles: chartStore.showCircles,
            isOpaque: chartStore.opacity,
            isDragging: chartStore.isDragging,
            countries: countryStore.currentCountryList,
            identifiers: risksStore.currentList,
            identifierType: risksStore.currentRisksType,
            isDimensionsMultiSelect:
                nextProps.activeView === VIEW.COUNTRY_GRAPH,
            showExchange: risksStore.getIsCurrencyOn(),
            currencyCountry: risksStore.getCurrencyCountry(),
            sequence: chartStore.sequence,
            showGeoquantLine: risksStore.showGeoquantLine,
            showProjectionData:
                !customWeightStore.hasActiveCustomWeight &&
                risksStore.projectionDataOn,
            showPulseData:
                !customWeightStore.hasActiveCustomWeight &&
                risksStore.showPulseData,
            annoMode: insightsStore.annotationMode,
            hasActiveCustomWeight: customWeightStore.hasActiveCustomWeight,
            updateChartDimensions: chartStore.updateDimensions,
            showVirtualToday: chartStore.showVirtualToday,
            activeGlobalIndicators: risksStore.activeGlobalIndicators,
        };
    }
)
@observer
export default class ChartDataProvider extends React.Component<
    IChartDataProviderProps,
    IChartDataProviderState
> {
    private cancelable: CancelableEvents;

    constructor(props: IChartDataProviderProps) {
        super(props);
        this.state = {
            data: [],
        };
    }

    public componentDidMount() {
        this.cancelable = new CancelableEvents();
        this.loadRisksData();
        this.loadCurrencyExchangeData();
        this.loadGlobalIndexChart();
        this.cancelable.addEventSubscription(
            EventsService.registerOnReloadCountry(this.onReloadCountryCB)
        );
        this.cancelable.setTimeout(() => {
            this.props.updateChartDimensions();
        });
    }

    public componentWillUnmount() {
        this.cancelable.cancelAll();
    }

    public shouldComponentUpdate() {
        return !rootStore.appStore.freezeRender;
    }

    public componentDidUpdate(prevProps: IChartDataProviderProps) {
        this.cancelable.setTimeout(() => {
            this.props.updateChartDimensions();
        });

        this.reloadRisks(prevProps);

        if (
            !isEqual(this.props.sequence, prevProps.sequence) ||
            this.props.currencyCountry.toString() !==
                prevProps.currencyCountry.toString() ||
            !isEqual(this.props.showExchange, prevProps.showExchange) ||
            !this.props.minX.isSame(prevProps.minX, 'days') ||
            !this.props.maxX.isSame(prevProps.maxX, 'days')
        ) {
            this.loadCurrencyExchangeData();
        }

        if (
            !this.props.minX.isSame(prevProps.minX, 'days') ||
            !this.props.maxX.isSame(prevProps.maxX, 'days') ||
            !isEqual(
                toJS(this.props.activeGlobalIndicators.length),
                toJS(prevProps.activeGlobalIndicators.length)
            )
        ) {
            this.loadGlobalIndexChart();
        }
        if (this.props.activeGlobalIndicators.length === 0) {
            this.removeChartData(E_CHART_GROUP_IDENTIFIERS.GLOBAL_INDEX);
        }
    }

    public render() {
        return (
            <Chart
                activeView={this.props.activeView}
                data={this.state.data}
                minX={this.props.minX}
                maxX={this.props.maxX}
                width={this.props.width}
                height={this.props.height}
                virtualToday={this.props.virtualToday}
                emitter={null}
                onVirualTodayChange={this.props.onVirualTodayChange}
                onWheel={this.props.onWheel}
                expanded={this.props.expanded}
                showLines={this.props.showLines}
                showCircles={this.props.showCircles}
                isOpaque={this.props.isOpaque}
                onPan={this.props.onPan}
                isDragging={this.props.isDragging}
                onDragChange={this.onDragStateChange}
                annoMode={this.props.annoMode}
                padding={this.props.padding}
                hasToolbar={this.props.hasToolbar}
                showVirtualToday={this.props.showVirtualToday}
                changeDataWhenDragStop={this.props.changeDataWhenDragStop}
            />
        );
    }

    private reloadRisks(prevProps: IChartDataProviderProps) {
        if (
            !isEqual(this.props.activeView, prevProps.activeView) ||
            !isEqual(this.props.sequence, prevProps.sequence) ||
            !isEqual(this.props.countries, prevProps.countries) ||
            !isEqual(this.props.identifiers, prevProps.identifiers) ||
            !isEqual(this.props.identifierType, prevProps.identifierType) ||
            !isEqual(
                this.props.isDimensionsMultiSelect,
                prevProps.isDimensionsMultiSelect
            ) ||
            !isEqual(this.props.expanded, prevProps.expanded) ||
            !isEqual(
                this.props.showProjectionData,
                prevProps.showProjectionData
            ) ||
            !isEqual(this.props.showPulseData, prevProps.showPulseData) ||
            !isEqual(this.props.showGeoquantLine, prevProps.showGeoquantLine) ||
            !isEqual(
                this.props.hasActiveCustomWeight,
                prevProps.hasActiveCustomWeight
            ) ||
            !this.props.minX.isSame(prevProps.minX, 'days') ||
            !this.props.maxX.isSame(prevProps.maxX, 'days') ||
            !this.props.virtualToday.isSame(prevProps.virtualToday, 'days')
        ) {
            this.loadRisksData(prevProps);
        }
    }

    private onDragStateChange = (isDragging: boolean) => {
        chartStore.setState({
            isDragging,
        });
    };

    private getPoliticalRiskQuery() {
        return {
            from: this.props.minX,
            until: this.props.maxX,
            countries: this.props.isDimensionsMultiSelect
                ? [first(this.props.countries)].filter(Boolean)
                : this.props.countries,
            identifiers: this.props.identifiers,
            identifierType: this.props.identifierType,
            isDimensionBased: this.props.isDimensionsMultiSelect,
            projectionDate:
                this.props.expanded && this.props.showProjectionData
                    ? this.props.virtualToday
                    : null,
            geoquantLine: this.props.showGeoquantLine || !this.props.expanded,
            showPulseData: this.props.showPulseData && this.props.expanded,
        };
    }

    private async loadContingentRisks(prevProps?: IChartDataProviderProps) {
        this.removeChartData(E_CHART_GROUP_IDENTIFIERS.POLITICAL_RISK);
        this.removeChartData(E_CHART_GROUP_IDENTIFIERS.PULSE);
        loading.start();
        const risks = await APIService.getContingentRisk(
            first(this.props.identifiers),
            first(this.props.countries)
        );

        const baseCountryId = this.props.countries[0];
        const baseCountryChanged: boolean =
            prevProps &&
            prevProps.countries[0] &&
            prevProps.countries[0] !== baseCountryId;
        const noBaseCountry: boolean =
            !prevProps &&
            (!this.props.countries || this.props.countries.length === 0);
        if (noBaseCountry || baseCountryChanged) {
            this.removeChartData(E_CHART_GROUP_IDENTIFIERS.CONTINGENT_RISK);
        } else {
            const contingentCountriesIds = this.props.countries.slice(1);
            if (!isEmpty(contingentCountriesIds)) {
                const countryMaxImpactMaps: {
                    [countryId: number]: IAPIMaxImpactMap;
                } = {};
                const maxImpactMapPromises = contingentCountriesIds.map(
                    countryId => {
                        return APIService.getMaxImpactMaps(
                            E_RISK_TYPE.CONTINGENT,
                            first(this.props.identifiers),
                            [baseCountryId, countryId]
                        )
                            .then(
                                countryMaxImpactMap =>
                                    (countryMaxImpactMaps[
                                        countryId
                                    ] = countryMaxImpactMap)
                            )
                            .catch(
                                () =>
                                    (countryMaxImpactMaps[
                                        countryId
                                    ] = {} as IAPIMaxImpactMap)
                            );
                    }
                );
                await Promise.all(maxImpactMapPromises);

                const contingentChartLines = flow(
                    filter(
                        (currentCountryId: number) => !!risks[currentCountryId]
                    ),
                    map((currentCountryId: number) => {
                        const targetRiskData = risks[currentCountryId];
                        const targetMaxImpactMap =
                            countryMaxImpactMaps[currentCountryId];
                        const currentCountryLineData = ChartScoresService.generateContingentRiskLine(
                            currentCountryId,
                            targetRiskData,
                            targetMaxImpactMap
                        );

                        return currentCountryLineData;
                    })
                )(contingentCountriesIds);

                const contingentRiskChart = ChartScoresService.getContingentRiskChart(
                    contingentChartLines,
                    this.getPoliticalRiskQuery()
                );

                this.setChartData(
                    contingentRiskChart,
                    E_CHART_GROUP_IDENTIFIERS.CONTINGENT_RISK
                );
            } else {
                this.removeChartData(E_CHART_GROUP_IDENTIFIERS.CONTINGENT_RISK);
            }
        }
        loading.stop();
    }

    private async loadRisksData(prevProps?: IChartDataProviderProps) {
        if (isEmpty(this.props.identifiers) || isEmpty(this.props.countries)) {
            this.setState({ data: [] });
            return;
        }

        if (navbarContingentGroup.has(this.props.activeView)) {
            this.loadContingentRisks(prevProps);
            return;
        }
        try {
            const query = this.getPoliticalRiskQuery();
            if (isEmpty(query.countries) || isEmpty(query.identifiers)) {
                return;
            }

            loading.start();
            const charts = await this.cancelable.promise(() => {
                return ChartScoresService.getPoliticalRiskChart(query);
            });

            const linesToRemove = [
                E_CHART_GROUP_IDENTIFIERS.POLITICAL_RISK,
                E_CHART_GROUP_IDENTIFIERS.CONTINGENT_RISK,
                E_CHART_GROUP_IDENTIFIERS.PULSE,
            ];
            this.setState(state => ({
                data: state.data.filter(
                    line => !includes(line.identifier, linesToRemove)
                ),
            }));
            forEach((chart: ChartData) => {
                this.setChartData(chart, chart.identifier);
            }, charts);
        } catch (err) {
            if (CancelableEvents.isCanceledPromiseError(err)) {
                return;
            }
            console.error(err);
        } finally {
            loading.stop();
        }
    }

    private async loadCurrencyExchangeData() {
        try {
            if (this.props.showExchange) {
                const linesData = await this.cancelable.promise(() => {
                    return ChartScoresService.getCurrencyExchange({
                        from: this.props.minX,
                        until: this.props.maxX,
                        countries: this.props.currencyCountry,
                    });
                });
                this.setChartData(
                    linesData,
                    E_CHART_GROUP_IDENTIFIERS.CURRENCY_EXCHANGE
                );
            } else {
                this.removeChartData(
                    E_CHART_GROUP_IDENTIFIERS.CURRENCY_EXCHANGE
                );
            }
        } catch (err) {
            if (CancelableEvents.isCanceledPromiseError(err)) {
                return;
            }
            console.error(err);
        }
    }

    private async loadGlobalIndexChart() {
        try {
            const { activeGlobalIndicators } = this.props;
            if (activeGlobalIndicators.length === 0) {
                return this.removeChartData(
                    E_CHART_GROUP_IDENTIFIERS.GLOBAL_INDEX
                );
            }

            const chart = await this.cancelable.promise(() => {
                return ChartScoresService.getGlobalIndexChart(
                    activeGlobalIndicators,
                    this.props.minX,
                    this.props.maxX
                );
            });
            this.setChartData(chart, E_CHART_GROUP_IDENTIFIERS.GLOBAL_INDEX);
        } catch (err) {
            if (CancelableEvents.isCanceledPromiseError(err)) {
                return;
            }
            console.error(err);
        }
    }

    private setChartData(chart: IChartData, type: E_CHART_GROUP_IDENTIFIERS) {
        this.setState(state => {
            return {
                data: state.data
                    .filter(c => c.identifier !== type)
                    .concat(chart),
            };
        });
    }

    private removeChartData(type: E_CHART_GROUP_IDENTIFIERS) {
        const filteredData = this.state.data.filter(c => c.identifier !== type);
        if (filteredData.length !== this.state.data.length) {
            this.setState({
                data: filteredData,
            });
        }
    }

    private onReloadCountryCB = (cids: number[]) => {
        this.cancelable.setTimeout(() => {
            let shouldSequence = false;
            forEach(cid => {
                if (this.props.countries.includes(cid)) {
                    shouldSequence = true;
                }
            }, cids);
            if (shouldSequence) {
                chartStore.increaseSequence();
            }
        }, 0);
    };
}
