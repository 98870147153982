export const colorByTier = {
    0: 'rgba(255, 255, 255, .3)',
    1: 'rgba(255, 255, 255, .2)',
    2: 'rgba(255, 255, 255, .15)',
    3: 'rgba(255, 255, 255, .1)',
};

export const hoverColorByTier = {
    0: 'rgba(255, 255, 255, .35)',
    1: 'rgba(255, 255, 255, .25)',
    2: 'rgba(255, 255, 255, .2)',
    3: 'rgba(255, 255, 255, .15)',
};
