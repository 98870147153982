import React from 'react';
import { IExternalDataItem } from '../../interfaces';
import ExternalDataItem from './ExternalDataItem';
import GQFlag from '../GQFlag';
import classNames from 'classnames';
import GQDropDown from '../GQDropDown/CustomeDropDown';
import { globalRiskColor, translateGlobalIndex } from '../../utils/renderUtils';

interface IExternalDataPopupProps {
    itemsList: IExternalDataItem[];
    selectedList: number[];
    dropDownList: any[];
    dropDownSelectedItems: any[];
    onDropDownItemClicked: (id: any) => void;
}
interface IExternalDataPopupState {
    showDropDown: boolean;
}

class ExternalDataPopup extends React.Component<
    IExternalDataPopupProps,
    IExternalDataPopupState
> {
    constructor(props: IExternalDataPopupProps) {
        super(props);
        this.state = {
            showDropDown: false,
        };
    }

    public render() {
        const { itemsList, selectedList } = this.props;
        return (
            <div className="external-data-popup-content">
                <div className="external-data-top-content">
                    {itemsList &&
                        itemsList.map(item => {
                            return (
                                <ExternalDataItem
                                    name={item.name}
                                    id={item.id}
                                    selectedList={selectedList}
                                    checked={selectedList.includes(item.id)}
                                    key={item.id}
                                    onclick={item.onClick}
                                />
                            );
                        })}
                </div>
                <ExternalDataDropDown
                    list={this.props.dropDownList}
                    selectedList={this.props.dropDownSelectedItems}
                    onClick={this.onDropDownItemClicked}
                    selectedExternalData={this.props.selectedList[0]}
                    changeDropDownState={this.changeDropDownState}
                    showDropDown={this.state.showDropDown}
                />
            </div>
        );
    }

    private onDropDownItemClicked = (id: any) => {
        this.changeDropDownState();
        this.props.onDropDownItemClicked(id);
    };
    private changeDropDownState = () => {
        this.setState({
            showDropDown: !this.state.showDropDown,
        });
    };
}

interface IExternalDataDropDown {
    list: any[];
    onClick?: (id: any) => any;
    selectedList: any[];
    selectedExternalData: number;
    showDropDown: boolean;
    changeDropDownState: () => any;
}

const ExternalDataDropDown = (props: IExternalDataDropDown) => {
    let dropDown;
    let selectedItem;
    if (props.selectedExternalData === 2) {
        dropDown = props.list.map(item => {
            return (
                <div
                    key={item.id}
                    className={classNames(['drop-down-item'], {
                        selected: props.selectedList.includes(item.id),
                    })}
                    onClick={() => props.onClick(item.id)}
                    data-searchable-text={`${item.name} (${item.currency_code})`}>
                    <GQFlag countryId={item.id} className="flag-icon-squared" />
                    <span>
                        {item.name} ({item.currency_code})
                    </span>{' '}
                </div>
            );
        });
        const selected = props.list.filter(
            item => item.id === props.selectedList[0]
        );
        selectedItem =
            props.selectedList.length > 0 ? (
                <div>
                    <GQFlag
                        countryId={selected[0].id}
                        className="flag-icon-squared"
                    />
                    <span>{selected[0].abbreviation_short}</span>{' '}
                </div>
            ) : (
                <div className="select-from-list">select currency</div>
            );
    } else if (props.selectedExternalData === 4) {
        dropDown = props.list.map(item => {
            return (
                <div
                    key={item}
                    className={classNames(['drop-down-item'], {
                        selected: props.selectedList.includes(item),
                    })}
                    onClick={() => props.onClick(item)}
                    data-searchable-text={translateGlobalIndex[item]}>
                    <span
                        style={{
                            marginLeft: '4px',
                            marginBottom: '2px',
                            marginRight: '5px',
                            height: '6px',
                            width: '6px',
                            backgroundColor: globalRiskColor(item),
                            borderRadius: '50%',
                            display: 'inline-block',
                        }}
                    />
                    <span>{translateGlobalIndex[item]}</span>
                </div>
            );
        });
        const selected = props.list.filter(
            item => item === props.selectedList[0]
        );
        selectedItem =
            props.selectedList.length > 0 ? (
                <div>
                    <span
                        style={{
                            marginLeft: '4px',
                            marginBottom: '-1px',
                            marginRight: '5px',
                            height: '6px',
                            width: '6px',
                            backgroundColor: globalRiskColor(selected[0]),
                            borderRadius: '50%',
                            display: 'inline-block',
                        }}
                    />
                    <span>{translateGlobalIndex[selected[0]]}</span>
                </div>
            ) : (
                <div className="select-from-list">select oil indicator</div>
            );
    }
    return (
        <GQDropDown dropDownContent={dropDown} selectedItem={selectedItem} />
    );
};

export default ExternalDataPopup;
