import { observable, action } from 'mobx';

export default class KeyValueStore<T> {
    @observable public isMultiValue: boolean;
    @observable private values: Map<T, boolean> = null;
    @observable private val: T = null;

    private defaultValue: T = null;

    constructor(
        isMultiValue: boolean,
        private mapper?: (t: T) => any,
        public allowEmpty = true,
        defaultValue: T = null
    ) {
        this.isMultiValue = isMultiValue;
        if (this.isMultiValue) {
            this.values = new Map<T, boolean>();
        }
        this.defaultValue = defaultValue;
        if (defaultValue != null) {
            this.setValue(defaultValue);
        }
    }

    public clone(): KeyValueStore<T> {
        const st = new KeyValueStore<T>(
            this.isMultiValue,
            this.mapper,
            this.allowEmpty,
            this.defaultValue
        );
        st.onCloneSetVariable(new Map(this.values), this.val);
        return st;
    }

    @action public onCloneSetVariable(values: Map<T, boolean>, val: T) {
        this.values = values;
        this.val = val;
    }
    @action public toggleIsMultiValue(override?: boolean) {
        const newValue = override !== undefined ? override : !this.isMultiValue;
        this.isMultiValue = newValue;
        if (newValue && this.values === null) {
            this.values = new Map<T, boolean>();
        }
    }

    @action public overrideSingleValue(singleValue: T) {
        this.val = singleValue;
    }

    @action public setValue(value: T, ...values: T[]) {
        if (this.isMultiValue) {
            [value, ...values].forEach(v => {
                this.values.set(v, true);
            });
            return this;
        }
        this.val = value;
        return this;
    }

    @action public removeValue(value: T, ...values: T[]) {
        if (this.isMultiValue) {
            [value, ...values].forEach(v => {
                this.values.delete(v);
            });
            return;
        }
        if (value === this.val && this.allowEmpty) {
            this.val = null;
        }
    }

    @action public toggleValue(value: T, ...values: T[]) {
        if (this.isMultiValue) {
            [value, ...values].forEach(v => {
                if (this.values.has(v)) {
                    this.values.delete(v);
                    return;
                }
                this.values.set(v, true);
            });
            return;
        }
        if (!this.allowEmpty) {
            this.val = value;
            return;
        }
        this.val = this.val === value ? null : value;
    }

    @action public clean() {
        if (this.isMultiValue) {
            this.values.clear();
            return;
        }
        if (this.allowEmpty) {
            this.val = null;
        }
    }

    @action public overrideValues(...values: T[]) {
        if (this.isMultiValue) {
            this.values = new Map<T, boolean>(
                [...values]
                    .filter(v => v !== undefined)
                    .map((v): [T, boolean] => {
                        return [v, true];
                    })
            );
            return this;
        }
        this.val = values[0];
        return this;
    }

    @action public setValuesToDefault() {
        return this.setValue(this.defaultValue);
    }

    public getValues(): T[] {
        if (this.isMultiValue) {
            let res = Array.from(this.values.keys());
            if (res.length === 0 && this.defaultValue != null) {
                res = [this.defaultValue];
            }
            if (this.mapper) {
                return res.map(this.mapper);
            }
        }
        return this.val === null
            ? this.defaultValue
                ? [this.defaultValue]
                : []
            : [this.val];
    }
}
