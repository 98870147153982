import { GQButton } from '../GQButton';
import React from 'react';
import classNames from 'classnames';
import riskStore from '../../stores/RisksStore';

export interface ISidebarRiskPickerAbstractContentProps {
    onItemClick: (itemId: number) => void;
    selectedItemsIds: number[];
    headerTab?: {
        name?: string;
        iconName?: string;
    };
}

interface ISidebarRiskPickerWrapperProps {
    title?: string;
    initialSelectedTabIndex?: number;
    onTabChange?: (tabIndex: number) => void;
    children:
        | Array<React.ReactElement<ISidebarRiskPickerAbstractContentProps>>
        | React.ReactElement<ISidebarRiskPickerAbstractContentProps>;
}

interface ISidebarRiskPickerWrapperState {
    isMinimized: boolean;
    selectedTabIndex: number;
}

export default class SidebarRiskPickerWrapper extends React.Component<
    ISidebarRiskPickerWrapperProps,
    ISidebarRiskPickerWrapperState
> {
    constructor(props: ISidebarRiskPickerWrapperProps) {
        super(props);

        this.state = {
            isMinimized: false,
            selectedTabIndex: null,
        };
    }

    public render() {
        const tabs = React.Children.toArray(this.props.children);

        const selectedTabContent = tabs[this.selectedTabIndex];

        const wrapperClasses = classNames({
            minimized: this.state.isMinimized,
        });

        return (
            <div id="sidebar-risk-picker-wrapper" className={wrapperClasses}>
                <div id="sidebar-risk-picker-wrapper-header">
                    <GQButton
                        icon={
                            this.state.isMinimized
                                ? 'gqi-arrow-left'
                                : 'gqi-arrow-right'
                        }
                        noBorder={true}
                        onClick={this.onMinimizeClick}
                        style={{ fontSize: '16px' }}
                        containerStyle={{ left: '-10px' }}
                    />

                    <span className="sidebar-risk-picker-wrapper-header-content">
                        <span>{this.props.title}</span>

                        <div className="header-tabs">
                            {tabs.length > 1 &&
                                tabs.map(
                                    //@ts-ignore
                                    (
                                        tab: React.ReactElement<
                                            ISidebarRiskPickerAbstractContentProps
                                        >,
                                        i
                                    ) => {
                                        const headerTabProps = {
                                            iconName: 'gqi-indicators',
                                            name: undefined,
                                            ...tab.props.headerTab,
                                        };

                                        return (
                                            <GQButton
                                                key={`sidebar-risk-picker-header-tab-${i}`}
                                                toggleButton={true}
                                                noBorder={true}
                                                onClick={this.onTabChange.bind(
                                                    this,
                                                    i
                                                )}
                                                active={
                                                    this.selectedTabIndex === i
                                                }
                                                icon={headerTabProps.iconName}
                                                tooltip={headerTabProps.name}
                                                tooltipPosition="bottom left"
                                            />
                                        );
                                    }
                                )}
                        </div>
                    </span>
                </div>

                <div id="sidebar-risk-picker-content">{selectedTabContent}</div>
            </div>
        );
    }

    private get selectedTabIndex(): number {
        const numTabs = React.Children.count(this.props.children);
        if (this.state.selectedTabIndex != null) {
            return this.state.selectedTabIndex;
        } else if (
            this.props.initialSelectedTabIndex != null &&
            this.props.initialSelectedTabIndex < numTabs
        ) {
            return this.props.initialSelectedTabIndex;
        } else {
            return 0;
        }
    }

    private onTabChange = (tabIndex: number) => {
        if (this.state.selectedTabIndex !== tabIndex) {
            this.setState({
                selectedTabIndex: tabIndex,
            });

            if (this.props.onTabChange) {
                this.props.onTabChange(tabIndex);
            }
        }
    };

    private onMinimizeClick = () => {
        this.setState(
            {
                isMinimized: !this.state.isMinimized,
            },
            () => {
                setTimeout(
                    () => riskStore.setMinimized(this.state.isMinimized),
                    500
                ); //Timeout becuase of the width transition that takes 0.5 sec
            }
        );
    };
}
