import React, { useState } from 'react';
import { colorByTier, hoverColorByTier } from './HeatmapHeaderColors';
import { IActiveClientFacingIndicator } from 'services/APIService';
import cx from 'classnames';

type Props = {
    indicator: IActiveClientFacingIndicator;
    onIndicatorClick: (indicatorId: number) => void;
    hoveredIndicatorId: number;
    selectedIndicatorId: number;
};

const COLOR = colorByTier[0];
const HOVER_COLOR = hoverColorByTier[0];

const HeatmapHeaderFocusRisk = ({
    indicator,
    onIndicatorClick,
    hoveredIndicatorId,
    selectedIndicatorId,
}: Props) => {
    const [isHovered, setIsHovered] = useState<boolean>(false);

    const isSelected = selectedIndicatorId === indicator.id;
    const backgroundColor = isSelected
        ? 'white'
        : isHovered || hoveredIndicatorId === indicator.id
        ? HOVER_COLOR
        : COLOR;

    return (
        <div
            key={indicator.id}
            className={cx([
                'risk-header-item',
                'focus-header-item',
                {
                    'risk-header-item-selected': isSelected,
                },
            ])}
            style={{
                backgroundColor,
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => onIndicatorClick(indicator.id)}>
            <div className="risk-header-item-inner">
                {indicator.display_name}
            </div>
        </div>
    );
};

export default HeatmapHeaderFocusRisk;
