import { get } from 'lodash/fp';

const EDGE_REGEX = /edg\//i;

export const isCurrentBrowserSupported = (): boolean => {
    const isChromeOrForkOfChrome =
        typeof window !== 'undefined' && !!(window as any).chrome;

    if (!isChromeOrForkOfChrome) {
        return false;
    }

    const isEdge = EDGE_REGEX.test(get('navigator.userAgent', window));
    return !isEdge;
};
