import { HeatmapCellType } from './HeatmapTypes';
import React, { useMemo } from 'react';
import HeatmapCell from './HeatmapCell';
import { E_RISK_TYPE } from 'interfaces';

type HeatmapCellsProps = {
    cells: HeatmapCellType[][];
    onCellPress: (rowIndex: number, colIndex: number) => void;
    onCellMouseEnter: (rowIndex: number, colIndex: number) => void;
    riskType: E_RISK_TYPE;
};

type CellContainerProps = {
    rowIndex: number;
    colIndex: number;
    width: number;
    height: number;
    cellData: HeatmapCellType;
    onCellPress: (rowIndex: number, colIndex: number) => void;
    onCellMouseEnter: (rowIndex: number, colIndex: number) => void;
};

const CellContainer = React.memo(
    ({
        cellData,
        onCellMouseEnter,
        onCellPress,
        rowIndex,
        colIndex,
        width,
        height,
    }: CellContainerProps) => {
        return (
            <div
                style={{
                    gridColumn: colIndex + 2,
                    gridRow: rowIndex + 2,
                    cursor: cellData.clickable ? 'pointer' : 'default',
                }}
                onClick={() => onCellPress(rowIndex, colIndex)}
                onMouseEnter={() => onCellMouseEnter(rowIndex, colIndex)}>
                <HeatmapCell
                    clickable={cellData.clickable}
                    color={cellData.color}
                    isVisible={cellData.isVisible}
                    width={width}
                    height={height}
                    tooltip={cellData.tooltip}
                    padding={1.5}
                />
            </div>
        );
    }
);
const HeatmapCells = React.memo(
    ({ cells, onCellMouseEnter, onCellPress, riskType }: HeatmapCellsProps) => {
        const [width, height] = useMemo(
            () =>
                riskType === E_RISK_TYPE.DIMENSIONS
                    ? [18, 15]
                    : [30.5, 15],
            [riskType]
        );
        return (
            <React.Fragment>
                {cells.map((rowData: HeatmapCellType[], rowIndex: number) =>
                    rowData.map(
                        (cellData: HeatmapCellType, colIndex: number) => (
                            <CellContainer
                                key={`${rowIndex}-${colIndex}`}
                                cellData={cellData}
                                rowIndex={rowIndex}
                                colIndex={colIndex}
                                onCellMouseEnter={onCellMouseEnter}
                                onCellPress={onCellPress}
                                width={width}
                                height={height}
                            />
                        )
                    )
                )}
            </React.Fragment>
        );
    }
);

export default HeatmapCells;
