import * as _ from 'lodash';
import React from 'react';
import cx from 'classnames';

import { TweenLite } from 'gsap';
import GQFlag from '../../GQFlag';
import GQTabs from '../../GQTabs/GQTabs';
import GQLoader from '../../GQLoader/GQLoader';
import { ActivityPopupKey } from './ActivityView';
import ActivityRecordRow from './ActivityRecordRow';
import PopupStorage from '../../GQPopup/GQPopupStorage';
import { Transition } from 'react-transition-group';
import EventsService, {
    EventSubscription,
} from '../../../services/EventsService';
import {
    IPrediction,
    IBasicActivityRecord,
    IActiveDimension,
    IAPIActiveClientFacingIndicator,
} from '../../../services/lib/APIServiceInterfaces';
import { E_TIME_FILTER, E_TAB_FILTER } from './ActivityPageInterfaces';
import { GQButton } from '../../GQButton';
import moment from 'moment';
import VirtualList from 'react-virtual-list';
import { inject } from 'mobx-react';
import { IMobxRootState } from 'RootStore';
import { get } from 'lodash/fp';

export const ActivityTimePopupKey = 'insight_time_filter';

type Tab = { label: string, id: E_TAB_FILTER};

interface Props {
    predictions: IPrediction[];
    activities?: IBasicActivityRecord[];
    activePredictionId: string;
    activeInsightId: string;
    activeTab: E_TAB_FILTER;
    timeFilter: E_TIME_FILTER;
    canShareInsights: boolean;
    canDownloadInsightData: boolean;
    showSearch: boolean;
    searchVal: string;
    onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    toggleSearch: (e: React.MouseEvent<HTMLDivElement>) => void;
    clearSearch: (e: React.MouseEvent<HTMLDivElement>) => void;
    onTabChange: (tab: E_TAB_FILTER) => void;
    onPredictionClick: (id: number) => void;
    onActivitySelect: (activity: IBasicActivityRecord) => void;
    onActivityDelete: (activity: IBasicActivityRecord) => void;
    onActivityEdit: (activity: IBasicActivityRecord) => void;
    onActivityDownload: (activity: IBasicActivityRecord) => void;
    onActivityBroadcast: (
        activity: IBasicActivityRecord,
        sendEmail: boolean
    ) => void;
    onTimeFilterChange: (filter: E_TIME_FILTER) => void;
    dimensions?: { [dimensionId: number]: IActiveDimension };
    activeClientFacingIndicators?: IAPIActiveClientFacingIndicator;
    tabs?: Tab[];
}

interface State {
    activityListContainer: HTMLElement;
}

@inject(
    ({ risksStore, UserStore }: IMobxRootState): Partial<Props> => {
        const bmiFeedVisible = get(
            ['insightsResource', 'bmi_feed_visible'],
            UserStore.allPermissions
        );

        const tabs = [
            {
                label: 'Geoquant Insights',
                id: E_TAB_FILTER.GQ,
            },
            {
                label: 'MY Insights',
                id: E_TAB_FILTER.MY,
            },
            {
                label: 'Predictions',
                id: E_TAB_FILTER.PREDICTIONS,
            },
        ];

        if (bmiFeedVisible) {
            tabs.splice(1, 0, {
                label: 'BMI Insights',
                id: E_TAB_FILTER.BMI,
            });
        }
        return {
            dimensions: risksStore.dimensions.hashed,
            activeClientFacingIndicators: risksStore.clientFacingIndicators,
            tabs,
        };
    }
)
export default class ActivityPage extends React.Component<Props, State> {
    private timeFilters = {
        [E_TIME_FILTER.WEEK]: 'Past Week',
        [E_TIME_FILTER.MONTH]: 'Past Month',
        [E_TIME_FILTER.ALL]: 'All Time',
    };
    private onClickSub: EventSubscription = null;
    private lastSearchRef: HTMLInputElement = null;

    constructor(props: Props) {
        super(props);
        this.state = {
            activityListContainer: null,
        };
    }

    public componentDidMount() {
        this.onClickSub = EventsService.registerOnMouseLeftClick(() => {
            PopupStorage.addData(ActivityTimePopupKey, null);
        });
    }

    public componentWillUnmount() {
        this.onClickSub.remove();
    }

    public render() {
        return (
            <div className="gq-activity-history-content">
                {this.isLoadingState() ? (
                    <div style={{ height: '100%' }}>
                        <GQLoader
                            style={{
                                position: 'absolute',
                                top: '45%',
                                left: 'calc(50% - 35px)',
                            }}
                        />
                    </div>
                ) : (
                    <div className="grid-y gq-activity-history-table-container">
                        <div
                            className="gq-activity-history-content-header cell small-1 grid-x"
                            style={{ height: 40 }}>
                            <GQTabs
                                tabs={this.props.tabs}
                                onChange={this.onTabChange}
                                selectedTab={this.props.activeTab}
                            />
                            <div className="activity-time-filter filler">
                                <GQButton
                                    icon="gqi-search"
                                    onClick={this.props.toggleSearch}
                                    noBorder
                                    tooltip="Search"
                                    toggleButton
                                    active={this.props.showSearch}
                                />
                                <span>
                                    <span>Show:</span>
                                    <span
                                        className="activity-time-filter-dropdown"
                                        onClick={this.onTimeFilterClick}>
                                        <span>
                                            {
                                                this.timeFilters[
                                                    this.props.timeFilter
                                                ]
                                            }
                                        </span>
                                        <span>
                                            <i className="gqi-dropdown" />
                                        </span>
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className="gq-activity-search-bar-container">
                            <Transition
                                timeout={500}
                                in={this.props.showSearch}
                                onEnter={this.onSearchEnter}
                                onExit={this.onSearchExit}
                                mountOnEnter
                                unmountOnExit>
                                <div className="gq-activity-search-container flex-container align-middle">
                                    <input
                                        type="search"
                                        onChange={this.props.onSearchChange}
                                        placeholder={this.searchPlaceHolder}
                                        value={this.props.searchVal}
                                        ref={this.onSearchRef}
                                    />
                                    <GQButton
                                        icon="gqi-close"
                                        onClick={this.props.clearSearch}
                                        noBorder
                                    />
                                </div>
                            </Transition>
                        </div>
                        <div
                            ref={this.onTableRef}
                            className="cell small-10 flex-child-auto"
                            style={{ overflowX: 'hidden', overflowY: 'auto' }}>
                            {this.getTable()}
                        </div>
                    </div>
                )}
            </div>
        );
    }

    private onTableRef = (ref: HTMLElement) => {
        if (this.state.activityListContainer === null) {
            this.setState({
                activityListContainer: ref,
            });
        }
    };

    private onSearchRef = (ref: HTMLInputElement) => {
        if (ref !== null && this.lastSearchRef === null) {
            ref.focus();
        }
        this.lastSearchRef = ref;
    };

    private get searchPlaceHolder() {
        switch (this.props.activeTab) {
            case E_TAB_FILTER.GQ:
                return 'Search Geoquant Insights';
            case E_TAB_FILTER.MY:
                return 'Search My Insights';
            case E_TAB_FILTER.BMI:
                return 'Search BMI Insights';
            case E_TAB_FILTER.PREDICTIONS:
                return 'Search Predictions';
            default:
                throw new Error('unsupported active tab');
        }
    }

    private onSearchEnter = (node: Element) => {
        TweenLite.set(node, { clearProps: 'all' });
        TweenLite.from(node, 0.5, { opacity: 0, y: '-=50' });
    };

    private onSearchExit = (node: Element) => {
        TweenLite.set(node, { clearProps: 'all' });
        TweenLite.to(node, 0.5, { opacity: 0, y: '-=50' });
    };

    private getTable() {
        if (this.props.activeTab === E_TAB_FILTER.PREDICTIONS) {
            const { predictions } = this.props;
            const percent =
                (_.filter(predictions, this.filterScoredPredictions).length /
                    _.filter(predictions, this.filterTotalPredictions).length) *
                100;
            return [
                <div
                    className="activity-history-prediction-metrics"
                    key="prediction-metrics">
                    <div className="activity-history-prediction-percent">
                        <span>
                            {isNaN(percent) ? '0' : percent.toFixed(1)}%
                        </span>
                    </div>
                    <div className="activity-history-prediction-label">
                        <span>Correct</span>
                    </div>
                </div>,
                <table
                    className="activity-history-table predictions-table"
                    key="prediction-table">
                    <thead>
                        <tr>
                            <th>Country</th>
                            <th>Description</th>
                            <th>Call Made</th>
                            <th>Realization</th>
                            <th>Window</th>
                            <th>Confidence</th>
                            <th>Consensus</th>
                            <th>Outcome</th>
                        </tr>
                    </thead>
                    <tbody>
                        {_.map(predictions, d => {
                            return (
                                <tr
                                    key={d.id}
                                    onClick={this.onPredictionClick(d.id)}
                                    className={cx({
                                        active:
                                            this.props.activePredictionId ===
                                            String(d.id),
                                    })}>
                                    <td className="activity-history-record-country">
                                        <GQFlag countryId={d.country_id} />
                                    </td>
                                    <td className="activity-history-record-description">
                                        <span>{d.abbreviation}</span>
                                    </td>
                                    <td className="activity-history-record-realization">
                                        {moment(Date.parse(d.call_date)).format(
                                            'MMM DD, YYYY'
                                        )}
                                    </td>
                                    <td className="activity-history-record-realization">
                                        {d.realization_date}
                                    </td>
                                    <td className="activity-history-record-window">
                                        {d.window}
                                    </td>
                                    <td className="activity-history-record-confidence">
                                        {d.confidence}
                                    </td>
                                    <td className="activity-history-record-consensus">
                                        {d.consensus}
                                    </td>
                                    <td className="activity-history-record-outcome">
                                        {d.outcome}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>,
            ];
        } else {
            return this.renderActivityTable();
        }
    }

    private renderActivityTable = () => {
        if (this.state.activityListContainer) {
            const VirtualActivityList = VirtualList({
                container: this.state.activityListContainer,
            })(this.renderActivityTableContent);
            return (
                <VirtualActivityList
                    items={this.props.activities}
                    itemHeight={28}
                    itemBuffer={5}
                />
            );
        }
    };

    private renderActivityTableContent = (args: any) => {
        const { virtual, itemHeight } = args;
        return (
            <table className="activity-history-table" style={virtual.style}>
                <thead>
                    <tr>
                        <th className="activity-history-table-col">Type</th>
                        <th className="activity-history-table-col">Name</th>
                        <th className="activity-history-table-col">
                            Published
                        </th>
                        <th className="activity-history-table-col">
                            Countries / Dimensions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {_.map(virtual.items, activity => (
                        <ActivityRecordRow
                            key={activity._id}
                            rowHeight={itemHeight}
                            activeTab={this.props.activeTab}
                            activity={activity}
                            onSelect={this.props.onActivitySelect}
                            isActive={this.isActive(activity)}
                            onActivityDelete={this.props.onActivityDelete}
                            onActivityBroadcast={this.props.onActivityBroadcast}
                            onActivityDownload={this.props.onActivityDownload}
                            onActivityEdit={this.props.onActivityEdit}
                            dimensions={this.props.dimensions}
                            activeClientFacingIndicators={
                                this.props.activeClientFacingIndicators
                            }
                            canShare={this.props.canShareInsights}
                            canDownloadData={this.props.canDownloadInsightData}
                        />
                    ))}
                </tbody>
            </table>
        );
    };

    private filterScoredPredictions = (p: IPrediction) => {
        return p.outcome === 'correct' || p.outcome === 'partial';
    };

    private filterTotalPredictions = (p: IPrediction) => {
        return p.outcome !== 'pending';
    };

    private onPredictionClick = (id: number) => () => {
        this.props.onPredictionClick(id);
    };

    private isLoadingState() {
        return !(
            this.props.predictions !== null && this.props.activities !== null
        );
    }

    private isActive(activity: IBasicActivityRecord): boolean {
        return this.props.activeInsightId === activity._id;
    }

    private onTabChange = (id: E_TAB_FILTER) => {
        this.props.onTabChange(id);
    };

    private changeTimeFilter = (filter: E_TIME_FILTER) => {
        this.props.onTimeFilterChange(filter);
    };

    private onTimeFilterClick = (e: React.MouseEvent<HTMLSpanElement>) => {
        e.stopPropagation();
        PopupStorage.addData(ActivityPopupKey, null);
        const el = e.currentTarget;
        PopupStorage.addData(ActivityTimePopupKey, {
            element: el,
            tooltipData: this.getTooltipDropDown(),
            orientation: 'bottom left',
        });
    };

    private getTooltipDropDown() {
        return (
            <div className="activity-time-filter-tooltip">
                {_.map(_.keys(this.timeFilters), (filter: E_TIME_FILTER) => (
                    <TimeFilterEl
                        key={filter}
                        label={this.timeFilters[filter]}
                        onClick={this.changeTimeFilter}
                        id={filter}
                    />
                ))}
            </div>
        );
    }
}

const TimeFilterEl = (props: {
    onClick: (filter: E_TIME_FILTER) => void;
    label: string;
    id: E_TIME_FILTER;
}) => {
    const onClick = () => {
        props.onClick(props.id);
    };
    return (
        <div className="activity-time-filter-tooltip-item" onClick={onClick}>
            <span>{props.label}</span>
        </div>
    );
};
