import { isDebugMode } from './configService';

export class LoggerService {
    public static log(message: any, title: string) {
    }

    public static async debugLog(message: any, title: string) {
        if (await isDebugMode) {
        }
    }
}
