import React, { useState } from 'react';
import cx from 'classnames';
import { IActiveDimension } from 'services/APIService';
import { colorByTier, hoverColorByTier } from './HeatmapHeaderColors';

type Props = {
    dimension: IActiveDimension;
    onIndicatorClick: (dimensionId: number) => void;
    hoveredIndicatorId: number;
    selectedIndicatorId: number;
};

const HeatmapHeaderFundamentalRisk = ({
    dimension,
    onIndicatorClick,
    hoveredIndicatorId,
    selectedIndicatorId,
}: Props) => {
    const [isHovered, setIsHovered] = useState<boolean>(false);
    const isSelected = selectedIndicatorId === dimension.id;
    const color = colorByTier[dimension.tier];
    const hoverColor = hoverColorByTier[dimension.tier];
    const backgroundColor = isSelected
        ? 'white'
        : isHovered || hoveredIndicatorId === dimension.id
        ? hoverColor
        : color;

    return (
        <div
            key={dimension.id}
            className={cx([
                'risk-header-item',
                {
                    'risk-header-item-selected': isSelected,
                },
            ])}
            style={{
                backgroundColor,
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => onIndicatorClick(dimension.id)}>
            <div className="risk-header-item-inner">{dimension.lname}</div>
        </div>
    );
};

export default HeatmapHeaderFundamentalRisk;
