import EventsFeedDataProvider from 'components/eventsFeed/EventFeedDataProvider';
import { GQButton } from 'components/GQButton';
import IndicatorsSidebarRiskPicker from 'components/sidebarRiskPicker/IndicatorsSidebarRiskPicker';
import useInitCountryDim from 'hooks/useInitCountryDim';
import useSettings from 'hooks/useSettings';
import { E_RISK_TYPE } from 'interfaces';
import {
    compact,
    flow,
    get,
    isEmpty,
    isNumber,
    join,
    map,
    size,
    split,
    take,
    toNumber,
    trim,
    xor,
} from 'lodash/fp';
import React, {
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import APIService, { IEvent } from 'services/APIService';
import rootStore from '../../RootStore';

const PREFIX =
    `Please summarize the following articles in 2 paragraphs.
At the beginning of the summary, explain why '<RISK_NAME>' risk is <rising | falling> in <COUNTRY_NAME>
 based on the following articles.
Make a single summary for all articles combined.`;

const POSTFIX = '';
// for an explanation - see the comment on chatgpt.ts on insight-generator project
const DEFAULT_WORD_LIMIT = Math.ceil(8192 * 0.6);

export const Prompt = React.memo(() => {
    const ready = useInitCountryDim({
        riskType: E_RISK_TYPE.DIMENSIONS,
        multiCountry: true,
        multiRisk: false,
    });

    const [eventsIds, setEventsIds] = useState<number[]>([]);
    const [events, setEvents] = useState<IEvent[]>([]);
    const [eventsText, setEventsText] = useState<string[]>([]);
    const [prompt, setPrompt] = useState<string>();
    const [prefix, setPrefix] = useState<string>(PREFIX);
    const [postfix, setPostfix] = useState<string>(POSTFIX);
    const [wordLimit, setWordLimit] = useState<number>(DEFAULT_WORD_LIMIT);
    const [eventUrlInput, setEventUrlInput] = useState<string>('');

    useEffect(() => {
        rootStore.eventsFeedStore.setcollapse(true);
        return () => {
            rootStore.eventsFeedStore.setcollapse(false);
        };
    }, []);

    useEffect(() => {
        (async () => {
            const events = await Promise.all(
                eventsIds.map(id => APIService.getScoreEvent(id))
            );
            setEvents(events);
            const eventsText = await Promise.all(
                events.map(event =>
                    APIService.getEventContent(event.article_text)
                )
            );
            setEventsText(eventsText);
        })();
    }, [eventsIds]);

    const { settings, loading: loadingSettings } = useSettings();

    useEffect(() => {
        if (loadingSettings) {
            return;
        }
        if (settings.organization.name !== 'GeoQuantInternal') {
            window.location.href = '/';
        }
    }, [settings, loadingSettings]);

    const onEventClick = (id: number) => {
        setEventsIds(curr => xor([id], curr));
    };

    const promptFromArticlesAndSuffix = useMemo(() => {
        return (
            prefix +
            '\n' +
            eventsText.map((eventText, index) => {
                const text = trimArticle(eventText, wordLimit);
                const headline = get([index, 'headline'], events);
                return `Article ${index + 1} headline:
${headline}.
Article ${index + 1} text:
${text}.`;
            }) +
            '\n\n' +
            postfix
        );
    }, [prefix, postfix, eventsText, wordLimit, events]);

    useEffect(() => {
        setPrompt(promptFromArticlesAndSuffix);
    }, [promptFromArticlesAndSuffix]);

    const wordCount = useMemo(() => {
        return flow(split(/\s/), map(trim), compact, size)(prompt);
    }, [prompt]);

    const didReachLimit = useMemo(() => {
        return wordCount > wordLimit;
    }, [wordCount, wordLimit]);

    const onAddEventByUrlClick = useCallback(() => {
        let urlAsNumber = toNumber(eventUrlInput);
        if (isNumber(urlAsNumber) && !isNaN(urlAsNumber)) {
            setEventsIds(curr => xor([urlAsNumber], curr));
            setEventUrlInput('');
            return;
        }
        const match = eventUrlInput.match(/\/(\d.+)$/);
        if (isEmpty(match)) {
            return;
        }
        const id = toNumber(match[1]);
        setEventsIds(curr => xor([id], curr));
        setEventUrlInput('');
    }, [eventUrlInput]);

    return (
        ready && (
            <div style={{ display: 'flex', flex: 1 }}>
                <EventsFeedDataProvider onEventsClick={onEventClick} />
                <div style={{ flex: 1, padding: '1rem' }}>
                    <div style={{ display: 'flex' }}>
                        <Block style={{ flex: 1 }}>
                            <Title>Prefix:</Title>
                            <textarea
                                className="prompt-input"
                                rows={4}
                                cols={20}
                                placeholder="Prefix"
                                onChange={e => setPrefix(e.target.value)}
                                value={prefix}
                            />
                        </Block>
                        <Block style={{ flex: 1, marginInlineStart: 8 }}>
                            <Title>Postfix:</Title>
                            <textarea
                                className="prompt-input"
                                rows={4}
                                cols={20}
                                placeholder="Postfix"
                                onChange={e => setPostfix(e.target.value)}
                                value={postfix}
                            />
                        </Block>
                    </div>
                    <Block>
                        <Title>Overview Auto-Insgiht prompt:</Title>
                        <i>Please summarize in a single insightful sentence</i>
                    </Block>
                    <Block>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div>
                                <Title>Word Limit:</Title>
                                <input
                                    type="number"
                                    style={{ width: 100 }}
                                    className="prompt-input"
                                    value={wordLimit}
                                    onChange={e =>
                                        setWordLimit(toNumber(e.target.value))
                                    }
                                />
                            </div>
                            {didReachLimit && (
                                <div className="reached-limit">
                                    Limit Reached
                                </div>
                            )}
                        </div>
                    </Block>
                    {!isEmpty(events) && (
                        <Block>
                            <Title>Events:</Title>
                            <ul
                                style={{
                                    margin: 4,
                                    maxHeight: 200,
                                    overflowY: 'auto',
                                }}>
                                {events.map(event => (
                                    <li
                                        key={event.id}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>
                                        {event.headline}
                                        <span
                                            onClick={() => {
                                                setEventsIds(curr =>
                                                    xor([event.id], curr)
                                                );
                                            }}>
                                            <i className="gqi-x prompt-headline-delete" />
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </Block>
                    )}
                    <Block style={{ display: 'flex', alignItems: 'center' }}>
                        <Title>Add events by url:</Title>
                        <input
                            type="text"
                            style={{ width: 300, margin: '0 16px' }}
                            className="event-url-input"
                            value={eventUrlInput}
                            onChange={e => setEventUrlInput(e.target.value)}
                        />
                        <GQButton
                            disabled={isEmpty(eventUrlInput)}
                            style={{ marginInlineStart: '1rem' }}
                            onClick={onAddEventByUrlClick}>
                            Add event
                        </GQButton>
                    </Block>
                    <Block style={{ display: 'flex', alignItems: 'center' }}>
                        <Title>Prompt:</Title>
                        <GQButton
                            disabled={didReachLimit}
                            style={{ marginInlineStart: '1rem' }}
                            onClick={() => {
                                const text = document.querySelector(
                                    '.prompt-text'
                                ).textContent;
                                navigator.clipboard.writeText(text);
                            }}>
                            Copy To Clipboard
                        </GQButton>
                        <div
                            style={{
                                marginInlineStart: 32,
                                fontSize: 12,
                            }}>
                            {wordCount} Words
                        </div>
                    </Block>
                    <Block>
                        <textarea
                            rows={10}
                            cols={80}
                            className="prompt-text prompt-input"
                            style={{ borderRadius: 8 }}
                            onChange={e => setPrompt(e.target.value)}
                            value={prompt}
                        />
                    </Block>
                </div>
                <IndicatorsSidebarRiskPicker />
            </div>
        )
    );
});

const Block = React.memo(
    ({
        children,
        style = {},
    }: {
        children: ReactNode;
        style?: React.CSSProperties;
    }) => <div style={{ paddingBlock: 4, ...style }}>{children}</div>
);

const Title = React.memo(({ children }) => (
    <div style={{ fontWeight: 900 }}>{children}</div>
));
const trimArticle = (text: string, wordLimit: number) => {
    const trimmed = flow(
        split(/\s/),
        map(trim),
        compact,
        take(wordLimit),
        join(' ')
    )(text);
    return trimmed;
};
