import React from 'react';
import MobxReactForm from 'mobx-react-form';
import * as validatorjs from 'validatorjs';
import FormField from '../Form/FormField';
import GQLoader from '../GQLoader/GQLoader';
import AuthPageContainer from './AuthPageContainer';
import APIService from '../../services/APIService';

interface IForgotPasswordPageState {
    error: string;
    inProgress: boolean;
    mailSent: boolean;
}

interface Iform {
    values: () => {
        email: string;
    };
}

export default class ForgotPasswordPage extends React.Component<
    any,
    IForgotPasswordPageState
> {
    constructor(props: any) {
        super(props);
        this.state = {
            inProgress: false,
            error: null,
            mailSent: false,
        };
    }

    public onSubmit = (form: Iform) => {
        this.setState(
            {
                inProgress: true,
                error: null,
            },
            async () => {
                try {
                    const email = form.values();
                    APIService.resetPassword(email.email).then(res => {
                        this.setState({
                            inProgress: false,
                            mailSent: true,
                        });
                    });
                } catch (err) {
                    this.setState({
                        inProgress: false,
                        error: err.message || err,
                    });
                }
            }
        );
    };

    public initForm = () => {
        const plugins = { dvr: validatorjs };

        const fields = [
            {
                name: 'email',
                rules: 'required|email|string',
            },
        ];

        const hooks = {
            onSuccess: this.onSubmit,
        };

        return new MobxReactForm({ fields }, { plugins, hooks });
    };

    form = this.initForm();

    public render() {
        return (
            <AuthPageContainer>
                {!this.state.mailSent ? (
                    <div className="auth-form-common forgot-password-form">
                        <div className="auth-form-common__title">
                            Enter your email to reset password
                        </div>

                        <form
                            className="auth-form-common__form"
                            onSubmit={this.form.onSubmit}>
                            <FormField
                                field={this.form.$('email')}
                                className="auth-form-common__field"
                                placeholder="Email address"
                            />
                            <div className="auth-form-common__submit">
                                {!this.state.inProgress && (
                                    <div className="login-forgot-password">
                                        <span>
                                            remembered password? click{' '}
                                            <a href="#/login">here</a> to login
                                        </span>
                                    </div>
                                )}

                                {this.state.inProgress && (
                                    <div className="auth-form-common__submit-loader">
                                        <GQLoader />
                                    </div>
                                )}

                                <button type="submit">
                                    {this.state.inProgress ? '' : 'Submit'}
                                </button>
                            </div>
                            <div className="auth-form-common__error">
                                <span>
                                    {this.state.error || this.form.error}
                                </span>
                            </div>
                        </form>
                    </div>
                ) : (
                    <div className="auth-form-common mail-sent-container">
                        <div className="check-your-email">Check your mail</div>
                        <div className="follow-inst">
                            please follow the instructions in the email to reset
                            your password
                        </div>
                        <div className="back-to-login">
                            {' '}
                            <span>
                                <a href="#/login">Back to login page</a>
                            </span>
                        </div>
                    </div>
                )}
            </AuthPageContainer>
        );
    }
}
