import * as React from 'react';

const Toast: React.SFC<{
    text: string;
    toastClass: string;
    show: boolean;
}> = (props: { text: string; toastClass: string; show: boolean }) => {
    const classNames: string[] = [];
    classNames.push('toast', props.toastClass);
    return (
        <div className="toast-container">
            <div
                className={classNames.join(' ')}
                style={{ opacity: props.show ? 1 : 0 }}>
                {props.text}
            </div>
        </div>
    );
};

export default Toast;
