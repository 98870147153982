import React, {
    useState,
    ReactNode,
    useEffect,
    useRef,
    forwardRef,
    useImperativeHandle,
} from 'react';

export type ToggleButtonProps = {
    isVisible: boolean;
};

type Props = {
    children: ReactNode;
    renderToggleButton: (props: ToggleButtonProps) => ReactNode;
};

const ToggleComponentButton = (
    { children, renderToggleButton }: Props,
    forwardedRef
) => {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const containerRef = useRef<HTMLDivElement>(null);

    const toggleComponent = () => setIsVisible(current => !current);

    const handleClickOutside = (event: any) => {
        if (!isVisible) {
            return;
        }

        const didClickOutside =
            containerRef.current &&
            !containerRef.current.contains(event.target);
        if (didClickOutside) {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside, true);

        return () =>
            document.removeEventListener('mousedown', handleClickOutside, true);
    });

    useImperativeHandle(forwardedRef, () => ({
        hideComponent: () => setIsVisible(false),
        showComponent: () => setIsVisible(true),
    }));

    return (
        <div className="toggle-component-button" ref={containerRef}>
            <div onClick={toggleComponent}>
                {renderToggleButton({ isVisible })}
            </div>
            {isVisible && <React.Fragment>{children}</React.Fragment>}
        </div>
    );
};

export default forwardRef(ToggleComponentButton);
