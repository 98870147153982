import React from 'react';
import * as _ from 'lodash';
import cx from 'classnames';

import GQButton from '../GQButton/GQButton';
import { E_TAB_FILTER } from '../GQActivityPage/activityPage/ActivityPageInterfaces';

export interface IGQTab {
    id: string;
    label: string;
}

interface IGQTabsProps {
    tabs: IGQTab[];
    selectedTab: string;
    onChange?: (id: E_TAB_FILTER) => void;
}

export default class GQTabs extends React.Component<IGQTabsProps> {
    public render() {
        const { tabs } = this.props;

        const activeTabIndex = _.findIndex(
            tabs,
            tab => tab.id === this.props.selectedTab
        );

        const tabWidth = `${100 / _.size(tabs)}%`;

        return (
            <div>
                <div className="gq-tabs flex-container align-middle">
                    {_.map(tabs, ({ label, id }) => (
                        <Tab
                            key={id}
                            label={label}
                            onClick={() => this.onClick(id as E_TAB_FILTER)}
                            isActive={this.props.selectedTab === id}
                            style={{ width: tabWidth }}
                        />
                    ))}
                </div>
                <div
                    className="tab-border"
                    style={{
                        width: tabWidth,
                        transform: `translateX(${activeTabIndex * 100}%)`,
                    }}
                />
            </div>
        );
    }
    private onClick = (id: E_TAB_FILTER) =>
        this.props.onChange && this.props.onChange(id);
}

function Tab(props: {
    label: string;
    onClick: () => void;
    isActive: boolean;
    style: React.CSSProperties;
}) {
    return (
        <div className="gq-tab" style={{ ...props.style }}>
            <GQButton
                className={cx(['gq-tab-button', { active: props.isActive }])}
                caption={props.label}
                onClick={props.onClick}
                noBorder={true}
                noPadding={true}
            />
        </div>
    );
}
