import * as _ from 'lodash';
export const toastClasses = {
    success: 'success',
    error: 'error',
};

export const durationOptions = [
    {
        text: '1d-1w',
        value: 1,
        isBelong: (value: number) => value < 7,
    },
    {
        text: '1w-1m',
        value: 7,
        isBelong: (value: number) => value >= 7 && value < 30,
    },
    {
        text: '1-3m',
        value: 30,
        isBelong: (value: number) => value >= 30 && value < 90,
    },
    {
        text: '3-6m',
        value: 90,
        isBelong: (value: number) => value >= 90 && value < 180,
    },
    {
        text: '6m-1y',
        value: 180,
        isBelong: (value: number) => value >= 180 && value < 365,
    },
    {
        text: '1-2y',
        value: 365,
        isBelong: (value: number) => value >= 365 && value < 730,
    },
    {
        text: '2-5y',
        value: 730,
        isBelong: (value: number) => value >= 730,
    },
];

export const effectOptions = [
    {
        text: 'Very positive',
        value: -3,
        isBelong: (value: number) => value < -2,
        color: '#17ff96',
    },
    {
        text: 'Positive',
        value: -2,
        isBelong: (value: number) =>
            value === -2 || _.inRange(Number(value.toFixed(2)), -1.001, -2),
        color: '#b2febc',
    },
    {
        text: 'Slightly positive',
        value: -1,
        isBelong: (value: number) =>
            value === -1 || _.inRange(Number(value.toFixed(2)), -0.001, -1),
        color: '#d2fde1',
    },
    {
        text: 'Neutral',
        value: 0,
        isBelong: (value: number) => Number(value.toFixed(2)) === 0,
        color: '#fff',
    },
    {
        text: 'Slightly negative',
        value: 1,
        isBelong: (value: number) =>
            value === 1 || _.inRange(Number(value.toFixed(2)), 0, 0.999),
        color: '#e69c8d',
    },
    {
        text: 'Negative',
        value: 2,
        isBelong: (value: number) =>
            value === 2 || _.inRange(Number(value.toFixed(2)), 1, 1.999),
        color: '#f36f67',
    },
    {
        text: 'Very negative',
        value: 3,
        isBelong: (value: number) => value > 2,
        color: '#fd3d3c',
    },
];
