import * as React from 'react';
import * as _ from 'lodash';
import cx from 'classnames';

import { IMapNode, VIEW } from '../../interfaces';
import GQPopupStorage from '../GQPopup/GQPopupStorage';
import rootState from '../../RootStore';
import { ICustomMapComponent } from './GQMapProvider';
import { getCircleBoundPosition } from '../../utils/renderUtils';
import { Transition } from 'react-transition-group';
import { TweenLite } from 'gsap';
import { includes, size } from 'lodash/fp';

const flagSizeRaw: number = 28; // not the actual size of the flag, because it is getting clipped by the circle
const DELTA_VIEW_ITEM_HEIGHT = 90;
const DELTA_VIEW_ITEM_WIDTH = 65;

interface IGQMapProps {
    nodes: IMapNode[];
    geoPath: string;
    view: VIEW;
    showScores: boolean;
    showHalos: boolean;
    selectedCountries: number[];
    customComponents?: ICustomMapComponent[];
    className?: string;
    colorToSelectedIdentifier: (id: number) => string;
    onCircleClick: (
        id: number,
        e: React.MouseEvent<SVGElement, MouseEvent>
    ) => void;
    onCircleHover: (
        id: number,
        e: React.MouseEvent<SVGGElement, MouseEvent>
    ) => void;
    isSmallScreen: boolean;
    contentWidth: number;
    contentHeight: number;
}
export default class GQMap extends React.Component<IGQMapProps> {
    public static defaultProps: Partial<IGQMapProps> = {
        customComponents: [],
    };

    public componentWillUnmount() {
        GQPopupStorage.addData('map_tooltip', null);
    }

    private defineRadius(item: IMapNode) {
        if (this.isContingent()) {
            return item.radius > 7 ? item.radius - 6 : item.radius;
        }

        return item.radius;
    }

    private getShortName(item: IMapNode) {
        return item.abbreviation || item.abbreviation_short;
    }

    private defineOuterCircleStyle(item: IMapNode) {
        return {
            fill: 'none',
            stroke: item.riskValue < 0 ? '#00ff90' : '#f20000',
            strokeOpacity: 0.98,
            strokeWidth: 2,
        };
    }

    private defineInnerCircleStyle(
        item: IMapNode,
        isSelected: boolean,
        isSelectedAsSecondaryCountry: boolean
    ) {
        const { colorToSelectedIdentifier } = this.props;

        if (this.isContingent()) {
            return {
                fill: isSelectedAsSecondaryCountry ? '#fffcfc' : 'none',
                stroke: item.riskValue < 0 ? '#00ff90' : '#f20000',
                strokeOpacity: item.riskValue < 0 ? 0.1 : 0.25, // red color is barely visible so I made it more opaque
                strokeWidth: item.radius > 16 ? 8 : 2,
            };
        }

        if (this.props.view === VIEW.RISK_VIEW) {
            const hue = item.delta_type === 'up' ? 0 : 160;
            let saturation = '100%';
            let lightness = `${10 + Math.round(item.opacity * 60)}%`;
            const hsl = `hsl(${hue}, ${saturation}, ${lightness})`;
            return {
                fill: hsl,
            };
        }

        return isSelected ? { fill: colorToSelectedIdentifier(item.id) } : {};
    }

    renderFlag(item: IMapNode) {
        const clipId = `clipping-${item.id}`;

        return (
            <g className="node" key={item.id}>
                <image
                    x={item.x - flagSizeRaw / 2}
                    y={item.y - flagSizeRaw / 2}
                    width={flagSizeRaw}
                    height={flagSizeRaw}
                    xlinkHref={`${
                        process.env.PUBLIC_URL
                    }/assets/flags/1x1/${item.abbreviation_short &&
                        item.abbreviation_short.toLowerCase()}.svg`}
                    clipPath={`url(#${clipId})`}
                />

                <text
                    className="map-flag-name"
                    x={item.x}
                    y={item.y + flagSizeRaw}
                    textAnchor="middle">
                    {item.original_name}
                </text>

                <defs>
                    <clipPath id={clipId}>
                        <circle
                            id={String(item.id)}
                            r={flagSizeRaw / 2}
                            cx={item.x}
                            cy={item.y}
                        />
                    </clipPath>
                </defs>
            </g>
        );
    }

    public getSelectedCountryModel() {
        return this.props.nodes.find(
            node => node.id === this.props.selectedCountries[0]
        );
    }

    public renderContingentLine(
        item: IMapNode,
        selectedCountryModel: IMapNode
    ) {
        const secondaryId = this.props.selectedCountries[1];
        const isSecondaryCountry = secondaryId && secondaryId === item.id;

        const gradientID = `gradient-for-line-id-${item.id}`;

        return item && selectedCountryModel ? (
            <g key={item.id}>
                <defs>
                    <linearGradient
                        id={gradientID}
                        gradientUnits="userSpaceOnUse"
                        x1={selectedCountryModel.x}
                        y1={selectedCountryModel.y}
                        x2={item.x}
                        y2={item.y}>
                        <stop
                            stopColor={
                                isSecondaryCountry
                                    ? '#606e80'
                                    : 'rgba(113,145,184, .1)'
                            }
                            offset="0"
                        />{' '}
                        <stop stopColor="rgba(4,22,44, 0.01)" offset="5" />
                    </linearGradient>
                </defs>

                <line
                    x1={selectedCountryModel.x}
                    y1={selectedCountryModel.y}
                    x2={item.x}
                    y2={item.y}
                    stroke={`url(#${gradientID})`}
                    strokeWidth={isSecondaryCountry ? '4' : '2'}
                />
            </g>
        ) : null;
    }

    public renderLinesList() {
        return _.map(this.props.nodes, item => {
            return this.renderContingentLine(
                item,
                this.getSelectedCountryModel()
            );
        });
    }

    private isContingent() {
        return this.props.view === VIEW.CONTINGENT;
    }

    public render() {
        const { view, selectedCountries } = this.props;
        const isContingent = this.isContingent();
        const deltaView = view === VIEW.SORT_BT_DELTA;
        const sortByRisk = view === VIEW.SORT_BY_RISK;
        const deltaViewNumColumns = Math.floor(
            this.props.contentWidth / DELTA_VIEW_ITEM_WIDTH
        );
        const deltaViewNumRows =
            Math.ceil(size(this.props.nodes) / deltaViewNumColumns) + 1;
        const deltaViewHeight = deltaViewNumRows * DELTA_VIEW_ITEM_HEIGHT;

        const viewBox = sortByRisk
            ? '0 0 1100 800'
            : deltaView
            ? `0 0 ${this.props.contentWidth} ${deltaViewHeight}`
            : '0 0 960 600';

        return (
            <div
                className={cx([
                    'map-container',
                    {
                        [this.props.className]: !!this.props.className,
                    },
                ])}
                style={{ minHeight: deltaView ? deltaViewHeight : 'auto' }}>
                <svg
                    id="mapContainerSVG"
                    width="100%"
                    height="100%"
                    viewBox={viewBox}>
                    <path
                        d={this.props.geoPath}
                        className={cx(['land'], {
                            hidden: deltaView || sortByRisk,
                        })}
                    />

                    {/* render lines for contingent risk page. THis is a separate map function since we need to place lines beneath circles so they will not overlap them. If that renderLine function will be inside the map map function, it will result in some cilrcles to be overlapped by some lines */}
                    {isContingent && this.renderLinesList()}

                    {_.map(this.props.nodes, item => {
                        const isSelected = includes(item.id, selectedCountries);

                        const isSelectedAsSecondaryCountry =
                            isContingent &&
                            isSelected &&
                            includes(item.id, selectedCountries.slice(1));

                        const onClick = (e: React.MouseEvent<SVGElement>) => {
                            e.persist();
                            this.props.onCircleClick(item.id, e);
                        };
                        const onHover = (
                            e: React.MouseEvent<SVGCircleElement>
                        ) => {
                            e.persist();
                            this.props.onCircleHover(item.id, e);
                        };
                        const onHoverOut = (
                            e: React.MouseEvent<SVGCircleElement>
                        ) => {
                            e.persist();
                            this.props.onCircleHover(null, e);
                        };
                        const CustomComponent = this.props.customComponents.find(
                            note => note.countryID === item.id
                        );

                        if (
                            isContingent &&
                            isSelected &&
                            selectedCountries[0] === item.id
                        ) {
                            return this.renderFlag(item);
                        }

                        return (
                            <g className="node" key={item.id} onClickCapture={onClick}>
                                {deltaView && (
                                    <rect
                                        x={item.x}
                                        y={item.y}
                                        width={DELTA_VIEW_ITEM_WIDTH}
                                        height={DELTA_VIEW_ITEM_HEIGHT}
                                        fill="none"
                                        className="host-rect"
                                    />
                                )}

                                {/* render double circles for contingent risk page (inner + outer circles for creating the complex gradient effect) */}
                                {isContingent && (
                                    <circle
                                        onMouseEnter={onHover}
                                        onMouseLeave={onHoverOut}
                                        id={`outer_${String(item.id)}`}
                                        className={cx(['host-circle'], {
                                            hidden: deltaView,
                                        })}
                                        r={item.radius}
                                        cx={item.x}
                                        cy={item.y}
                                        style={this.defineOuterCircleStyle(
                                            item
                                        )}
                                    />
                                )}
                                <circle
                                    onMouseEnter={onHover}
                                    onMouseLeave={onHoverOut}
                                    id={String(item.id)}
                                    className={cx(['host-circle'], {
                                        hidden: deltaView,
                                    })}
                                    r={this.defineRadius(item)}
                                    cx={item.x}
                                    cy={item.y}
                                    style={this.defineInnerCircleStyle(
                                        item,
                                        isSelected,
                                        isSelectedAsSecondaryCountry
                                    )}
                                />
                                {isSelected &&
                                    this.props.view === VIEW.RISK_VIEW && (
                                        <circle
                                            className="selected-circle"
                                            r={this.defineRadius(item) + 2}
                                            cx={item.x}
                                            cy={item.y}
                                            fill="transparent"
                                            stroke={this.props.colorToSelectedIdentifier(
                                                item.id
                                            )}
                                            strokeWidth="2px"
                                        />
                                    )}
                                {item.customCircleSvgContent &&
                                    item.customCircleSvgContent}
                                {!isContingent && this.drawScoreCircles(item)}
                                {deltaView && this.getCaret(item)}
                                {this.getCountryText(
                                    item,
                                    isSelectedAsSecondaryCountry
                                )}
                                {isContingent &&
                                    this.renderCountryRiskValue(
                                        item,
                                        isSelectedAsSecondaryCountry
                                    )}
                                {this.shouldDrawScore(item) &&
                                    this.getScoreText(item)}
                                {CustomComponent && (
                                    <Transition
                                        in={!!CustomComponent}
                                        timeout={300}
                                        onEnter={this.onCustomComponentEnter}
                                        onExit={this.onCustomComponentExit}>
                                        <CustomComponent.Component
                                            x={
                                                this.props.view ===
                                                VIEW.SORT_BT_DELTA
                                                    ? item.x + (110 / 2 + 20)
                                                    : getCircleBoundPosition({
                                                          center: item.x,
                                                          radius: item.radius,
                                                          targetDegree: 315,
                                                          trigoFN: Math.cos,
                                                      })
                                            }
                                            y={
                                                this.props.view ===
                                                VIEW.SORT_BT_DELTA
                                                    ? item.y + (110 / 2 - 20)
                                                    : getCircleBoundPosition({
                                                          center: item.y,
                                                          radius: item.radius,
                                                          targetDegree: 315,
                                                          trigoFN: Math.sin,
                                                      })
                                            }
                                            data={CustomComponent.data}
                                        />
                                    </Transition>
                                )}
                            </g>
                        );
                    })}
                </svg>
            </div>
        );
    }

    private onCustomComponentEnter = (node: any) => {
        TweenLite.set(node, { clearProps: 'all' });
        TweenLite.from(node, 0.3, { opacity: 0, scale: 0 });
    };

    private onCustomComponentExit = (node: any) => {
        TweenLite.set(node, { clearProps: 'all' });
        TweenLite.to(node, 0.3, { opacity: 0, scale: 0 });
    };

    private getCaret = (item: IMapNode) => {
        const isPositive = item.class === 'positive-circle';
        const isSmallScreen = this.props.isSmallScreen;
        // arrow down           :   arrow up
        const points = isPositive
            ? '0,2.5 5.5,7.5 10,2.5'
            : '0,8.5 5.5,2.5 10,8.5';
        const svgProps = {
            width: isSmallScreen ? 10 : 5,
            height: isSmallScreen ? 10 : 5,
            x: item.x + (isSmallScreen ? 20 : 32),
            y:
                item.y +
                (isSmallScreen
                    ? isPositive
                        ? 102
                        : 98
                    : isPositive
                    ? 100
                    : 98),
        };
        return (
            <svg viewBox="0 0 10 10" {...svgProps}>
                <polygon
                    className={cx(['delta-caret'], { [item.class]: true })}
                    points={points}
                />
            </svg>
        );
    };

    private getCountryText = (
        item: IMapNode,
        isSelectedAsSecondaryCountry: boolean
    ) => {
        const selected = this.props.selectedCountries.includes(item.id);
        const isSmallScreen = this.props.isSmallScreen;
        const selectionRectProps = {
            x: 0,
            y: 0,
            width: 0,
            height: 0,
            rx: 8,
            ty: 8,
        };
        const textProps = {
            x: 0,
            y: 0,
            dy: '0px',
        };
        switch (this.props.view) {
            case VIEW.SORT_BT_DELTA:
                textProps.x = item.x + 55;
                textProps.y = item.y + 55;
                textProps.dy = '35px';
                if (selected) {
                    const dummy = document.createElement('span');
                    dummy.textContent = item.name;
                    dummy.style.fontSize = isSmallScreen ? '15px' : '10px';
                    document.body.appendChild(dummy);
                    const box = dummy.getBoundingClientRect();
                    dummy.remove();
                    const width = Math.max(35, Math.min(box.width + 15, 50));
                    selectionRectProps.width = width;
                    selectionRectProps.height = box.height + 2;
                    selectionRectProps.x = textProps.x - width / 2;
                    selectionRectProps.y =
                        textProps.y + 31.5 - (box.height + 2) / 2;
                }
                break;
            case VIEW.RISK_VIEW:
                textProps.dy = isSmallScreen ? '0px' : '-2px';
                textProps.x = item.x;
                textProps.y = item.y;
                break;
            default:
                textProps.x = item.x;
                textProps.y = item.y;
                textProps.dy = '-2px';
        }
        return [
            <rect
                key="text-selection-rect"
                {...selectionRectProps}
                fill={
                    selected
                        ? rootState.countryStore.colorStack.color(item.id)
                        : 'transparent'
                }
            />,
            <text
                key="text-country-name"
                className={cx(['circle-text'], {
                    [item.class]: this.props.view === VIEW.SORT_BT_DELTA,
                    'circle-text-black': isSelectedAsSecondaryCountry,
                    'circle-text-mapview': this.props.view === VIEW.RISK_VIEW,
                    'circle-text-deltaview':
                        this.props.view === VIEW.SORT_BT_DELTA,
                    'circle-text-contingent': this.isContingent(),
                })}
                {...textProps}>
                {this.getShortName(item)}
            </text>,
        ];
    };

    private getScoreText = (item: IMapNode) => {
        const isSmallScreen = this.props.isSmallScreen;
        const textProps = {
            x: 0,
            y: 0,
            dy: '0px',
        };

        const text =
            this.props.view === VIEW.SORT_BT_DELTA
                ? item.delta.toFixed(3)
                : this.props.view === VIEW.RISK_VIEW
                ? item.score.toFixed(1)
                : item.score.toFixed(2);

        switch (this.props.view) {
            case VIEW.SORT_BT_DELTA:
                textProps.x = item.x + 55;
                textProps.y = item.y + 55;
                textProps.dy = isSmallScreen ? '55px' : '50px';
                break;
            case VIEW.RISK_VIEW:
                textProps.x = item.x;
                textProps.y = item.y;
                textProps.dy = isSmallScreen ? '13px' : '8px';
                break;
            default:
                textProps.x = item.x;
                textProps.y = item.y;
                textProps.dy = isSmallScreen ? '14px' : '10px';
        }
        return (
            <text
                className={cx(['circle-text-score'], {
                    [item.class]: this.props.view === VIEW.SORT_BT_DELTA,
                    'circle-text-score-mapview':
                        this.props.view === VIEW.RISK_VIEW,
                    'circle-text-score-deltaview':
                        this.props.view === VIEW.SORT_BT_DELTA,
                })}
                {...textProps}>
                {text}
            </text>
        );
    };

    private renderCountryRiskValue(
        item: IMapNode,
        isSelectedAsSecondaryCountry: boolean
    ) {
        const textProps = {
            x: item.x,
            y: item.y,
            dy: this.props.isSmallScreen ? '14px' : '10px',
        };

        return (
            <text
                className={cx(['circle-text-score'], {
                    'circle-text-black': isSelectedAsSecondaryCountry,
                    [item.class]: this.props.view === VIEW.SORT_BT_DELTA,
                })}
                {...textProps}>
                {`${item.riskValue > 0 ? '+' : ''} ${Number(
                    item.riskValue
                ).toFixed(1)}`}
            </text>
        );
    }

    private shouldDrawScore(item: IMapNode) {
        return !_.isNil(item.score) && !_.isNaN(item.score);
    }

    private drawScoreCircles(item: IMapNode) {
        const scoreRadi = Math.max(item.scoreRadius * 10, 2);
        let cx = 0;
        let cy = 0;
        switch (this.props.view) {
            case VIEW.SORT_BY_RISK:
            case VIEW.WORLD_GRAPH:
                return null;
            case VIEW.SORT_BT_DELTA:
                cx = item.x + 55;
                cy = item.y + 55;
                break;
        }
        const circleProps: Array<React.SVGProps<SVGCircleElement>> = [
            {
                key: 'sc',
                className: `score-circle ${item.class}`,
                r: this.props.showScores && scoreRadi ? scoreRadi : 0,
                cx,
                cy,
            },
            {
                key: 'sch',
                className: `score-circle-halo ${item.class}`,
                r: this.props.showHalos && scoreRadi ? scoreRadi * 2 : 0,
                cx,
                cy,
                opacity: this.props.showHalos ? 1 : 0,
            },
        ];
        return _.map(circleProps, c => <circle {...c} />);
    }
}
