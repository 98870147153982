import React from 'react';
import * as _ from 'lodash';
import classNames from 'classnames';
import TopEventsItem from './GQTopEventsItem';
import { VIEW } from '../../interfaces';
import APIService, {
    IGetTopPredictionsResponse,
} from '../../services/APIService';
interface IGQTopPredictionsProps {
    onViewChange?: (view: VIEW, ...args: any[]) => void;
}

interface IGQTopPredictionsState {
    collapsed: boolean;
    data: IGetTopPredictionsResponse;
}

export default class GQTopPredictions extends React.Component<
    IGQTopPredictionsProps,
    IGQTopPredictionsState
> {
    private mounted: boolean = false;
    constructor(props: IGQTopPredictionsProps) {
        super(props);
        this.state = {
            collapsed: false,
            data: { correct: 0, predictions: [] },
        };
    }
    public selectTopPrediction(id: number) {
        this.props.onViewChange(VIEW.INSIGHTS);
    }
    public componentDidMount() {
        this.mounted = true;
        this.getPredictionsFeed();
    }

    public componentWillUnmount() {
        this.mounted = false;
    }

    public render() {
        const { data } = this.state;
        const baseClass = 'gq-top-events';
        return (
            <div
                className={classNames([`${baseClass}-container`, 'col'], {
                    fixed: this.state.collapsed,
                })}>
                <div className={`${baseClass}-header row`}>
                    <div className={`${baseClass}-title`}>
                        <span>Top Predictions</span>
                    </div>
                    <div className="filler" />
                    <span className={'correct'}>
                        <span className="bold">{data.correct}%</span> Correct
                    </span>
                </div>
                <div
                    className={classNames([`${baseClass}-content`], {
                        collapsed: this.state.collapsed,
                    })}>
                    {data.predictions &&
                        _.map(data.predictions, prediction => {
                            if (prediction.id) {
                                return (
                                    <TopEventsItem
                                        key={prediction.id}
                                        countryID={prediction.country_id}
                                        event={prediction.abbreviation}
                                        onViewChange={this.props.onViewChange}
                                        eventId={prediction.id}
                                    />
                                );
                            } else {
                                return null;
                            }
                        })}
                </div>
            </div>
        );
    }

    private async getPredictionsFeed() {
        try {
            const topPredictions = await APIService.getPredictionsFeed();
            if (this.mounted) {
                this.setState({
                    data: topPredictions,
                });
            }
        } catch (err) {
        }
    }
}
