import {
    E_RISK_TYPE,
    IRootStoreExporter,
    GLOBAL_INDEXES,
    IColorsStackSerializedValuesData,
} from '../interfaces';
import {
    observable,
    action,
    computed,
    extendObservable,
} from 'mobx';
import ColorStack from '../utils/ColorStack';
import * as _ from 'lodash';
import KeyValueStore from './KeyValueStore';
import { flow, isNil, orderBy, first, get, includes } from 'lodash/fp';
import {
    IActiveContingentRisk,
    IActiveDimension,
    IActiveDimensionAPI,
    IAPIActiveClientFacingIndicator,
    RISK_CHART_MODES,
} from 'services/APIService';

interface IRisksStoreOptionalState {
    currencyOn?: boolean;
    currentRisksType?: E_RISK_TYPE;
    isMinimized?: boolean;
    projectionDataOn?: boolean;
    riskChartMode?: RISK_CHART_MODES;
    showGeoquantLine?: boolean;
    currencyCountry?: number[];
}

export interface IRiskStoreSerializedColors {
    dimensionsStack: Array<IColorsStackSerializedValuesData<number>>;
    clientFacingIndicatorsStack: Array<
        IColorsStackSerializedValuesData<number>
    >;
}
export class RisksStore implements IRootStoreExporter {
    @observable public currencyOn: boolean;
    @observable public currencyCountry: number[];
    @observable public projectionDataOn: boolean;
    @observable public riskChartMode: RISK_CHART_MODES;
    @observable public currentRisksType: E_RISK_TYPE;
    @observable public isMinimized: boolean;
    @observable public storesByRiskType: Map<
        E_RISK_TYPE,
        KeyValueStore<number>
    > = new Map();
    @observable public dimensionsStack: ColorStack<number>;
    @observable public clientFacingIndicatorsStack: ColorStack<number>;
    @observable public activeGlobalIndicators: GLOBAL_INDEXES[] = [];
    @observable public dimensions: IActiveDimensionAPI = null;
    @observable public allDimensions: IActiveDimension[] = [];
    @observable public contingentRisks: IActiveContingentRisk[] = [];
    @observable
    public clientFacingIndicators: IAPIActiveClientFacingIndicator;

    private dimensionsPermissions: { [key: number]: boolean };
    private CFIPermissions: { [key: number]: boolean };
    private defaultDimensionsStack: ColorStack<number>;
    private defaultClientFacingIndicatorsStack: ColorStack<number>;
    constructor() {
        this.toInitialState();
        this.defaultDimensionsStack = new ColorStack<number>();
        this.defaultClientFacingIndicatorsStack = new ColorStack<number>();
        this.dimensionsStack = this.defaultDimensionsStack;
        this.clientFacingIndicatorsStack = this.defaultClientFacingIndicatorsStack;
        this.currencyCountry = [];
    }

    public serializeColors(): IRiskStoreSerializedColors {
        return {
            dimensionsStack: this.dimensionsStack.serialize(),
            clientFacingIndicatorsStack: this.clientFacingIndicatorsStack.serialize(),
        };
    }

    @action public setStacks(
        dimensionsStack: ColorStack<number>,
        clientFacingIndicatorsStack: ColorStack<number>
    ) {
        this.dimensionsStack = dimensionsStack;
        this.clientFacingIndicatorsStack = clientFacingIndicatorsStack;
    }

    @action public setDefaultStacks() {
        this.dimensionsStack = this.defaultDimensionsStack;
        this.clientFacingIndicatorsStack = this.defaultClientFacingIndicatorsStack;
    }

    @action public setMinimized(value: boolean) {
        this.setState({
            isMinimized: value,
        });
    }

    @action public setCurrencyCountry(id: number) {
        const currencyCountry = this.currencyCountry[0] === id ? [] : [id];
        this.setState({
            currencyOn: currencyCountry.length > 0,
            currencyCountry,
        });
    }

    @action public setCurrencyCountries(ids: number[]) {
        this.setState({
            currencyOn: ids.length > 0,
            currencyCountry: [...ids],
        });
    }

    @action public cleanCurrencyCountry() {
        this.setState({
            currencyOn: false,
            currencyCountry: [],
        });
    }

    @action public setIsCurrencyOn(value: boolean) {
        this.setState({
            currencyOn: value,
        });
    }
    public getCurrencyCountry(): number[] {
        return this.currencyCountry;
    }

    public getIsCurrencyOn(): boolean {
        return this.currencyOn;
    }

    @action public updateDimensions(data: IActiveDimensionAPI) {
        this.dimensions = data;
    }

    @action public updateAllDimensions(data: IActiveDimension[]) {
        this.allDimensions = data;
    }
    @action public updateClientFacingIndicators(
        data: IAPIActiveClientFacingIndicator
    ) {
        this.clientFacingIndicators = data;
    }
    @action public updateContingentRisks(data: IActiveContingentRisk[]) {
        this.contingentRisks = data;
    }

    @action public setState(state: IRisksStoreOptionalState) {
        extendObservable(this, state);
    }

    @action public toInitialState() {
        this.clear();
        const currentRisksType = isNil(this.currentRisksType)
            ? E_RISK_TYPE.DIMENSIONS
            : this.currentRisksType;

        this.setState({
            currentRisksType,
            currencyOn: false,
            isMinimized: false,
            projectionDataOn: false,
            riskChartMode: RISK_CHART_MODES.RISK_AND_PULSE,
            currencyCountry: [],
        });
    }
    @action public setDisabledRisks(
        dimensions: { [key: number]: boolean },
        CFI: { [key: number]: boolean }
    ) {
        this.dimensionsPermissions = dimensions;
        this.CFIPermissions = CFI;
    }

    @computed public get showGeoquantLine(): boolean {
        return includes(this.riskChartMode, [RISK_CHART_MODES.RISK, RISK_CHART_MODES.RISK_AND_PULSE]);
    }

    @computed public get showPulseData(): boolean {
        return includes(this.riskChartMode, [
            RISK_CHART_MODES.PULSE,
            RISK_CHART_MODES.RISK_AND_PULSE,
        ]);
    }

    @computed public get currentList(): number[] {
        return this.currentStore.getValues();
    }

    @computed public get currentStore(): KeyValueStore<number> {
        return this.storesByRiskType.get(this.currentRisksType);
    }

    @computed public get isClientFacingIndicators(): boolean {
        return this.currentRisksType === E_RISK_TYPE.CLIENT_FACING_INDICATORS;
    }

    @action public setCurrentRiskType(risk: E_RISK_TYPE) {
        this.currentRisksType = risk;
    }

    @action public toggleRisk(riskID: number, ...ids: number[]) {
        this.currentStore.toggleValue(riskID, ...ids);
    }

    @action public clear() {
        this.clearGlobalIndicator();
        this.cleanCurrencyCountry();
    }

    public get colorStack(): ColorStack<number> {
        return this.currentRisksType === E_RISK_TYPE.DIMENSIONS
            ? this.dimensionsStack
            : this.clientFacingIndicatorsStack;
    }

    public customExport() {
        const stack = this.colorStack;
        return {
            currencyOn: this.currencyOn,
            currentRisksType: this.currentRisksType,
            projectionDataOn: this.projectionDataOn,
            riskChartMode: this.riskChartMode,
            selectedRisks: this.currentList.map(id => {
                return {
                    id,
                    color: stack.color(id),
                };
            }),
        };
    }
    @action public checkIfRiskDisabled(
        riskType: E_RISK_TYPE,
        riskID: number[]
    ) {
        switch (riskType) {
            case E_RISK_TYPE.CLIENT_FACING_INDICATORS:
                for (const risk of riskID) {
                    if (this.CFIPermissions && !this.CFIPermissions[risk]) {
                        return true;
                    }
                }
                return false;
            case E_RISK_TYPE.DIMENSIONS:
                for (const risk of riskID) {
                    if (
                        this.dimensionsPermissions &&
                        !this.dimensionsPermissions[risk]
                    ) {
                        return true;
                    }
                }
                return false;
        }
    }

    @action public toggleIsMultiValue(override?: boolean) {
        this.currentStore.toggleIsMultiValue(override);
    }

    @action public toggleGlobalIndicator(indicator: GLOBAL_INDEXES) {
        if (this.activeGlobalIndicators.includes(indicator)) {
            this.activeGlobalIndicators = this.activeGlobalIndicators.filter(
                i => i !== indicator
            );
            return;
        }

        this.activeGlobalIndicators = [indicator];
    }
    @action public clearGlobalIndicator() {
        this.activeGlobalIndicators.length = 0;
    }

    @action public overrideGlobalIndicators(indicators: GLOBAL_INDEXES[]) {
        this.activeGlobalIndicators = [...indicators];
    }

    @action
    public customRestore(data: {
        selectedRisks: Array<{ id: number; color: string }>;
    }) {
        this.clear();
        const state = { ...data };
        _.forEach(state.selectedRisks, ({ id }) => {
            this.toggleRisk(id);
        });
        delete state.selectedRisks;
        extendObservable(this, state);
    }

    @action public initRisksByType() {
        const firstDimensionId = get([0, 'id'], this.dimensions.flatten);
        const firstClientFacingIndicatorId = flow(
            orderBy(['order'], ['asc']),
            first,
            get('id')
        )(this.clientFacingIndicators);
        const firstContingentRiskId = get('id', first(this.contingentRisks));

        this.storesByRiskType = new Map([
            [
                E_RISK_TYPE.DIMENSIONS,
                new KeyValueStore(true, Number, true, firstDimensionId),
            ],
            [
                E_RISK_TYPE.CONTINGENT,
                new KeyValueStore(true, Number, true, firstContingentRiskId),
            ],
            [
                E_RISK_TYPE.CLIENT_FACING_INDICATORS,
                new KeyValueStore(
                    true,
                    Number,
                    true,
                    firstClientFacingIndicatorId
                ),
            ],
        ]);
    }
}

export default new RisksStore();
