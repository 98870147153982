import React from 'react';
import { RouteComponentProps } from 'react-router';
import GQLoader from 'components/GQLoader/GQLoader';
import InsightTemplateBody from '../InsightTemplateBody';
import { isEmpty } from 'lodash/fp';
import InsightTemplateHtmlFooter from './InsightTemplateHtmlFooter';
import { TBody, TR, TD } from '../InsightTemplateElements';
import useInsightTemplate from '../useInsightTemplate';
import InsightTemplateHtmlDailyHeader from './InsightTemplateHtmlDailyHeader';
import useInsightHtmlTemplate from './useInsightHtmlTemplate';

type Props = RouteComponentProps<{ id: string }>;

const InsightTemplateHtmlDaily = (props: Props) => {
    const insightData = useInsightTemplate(props.match.params.id);

    useInsightHtmlTemplate(insightData);

    if (isEmpty(insightData)) {
        return <GQLoader />;
    }

    return (
        <table
            style={{
                width: '100%',
            }}>
            <TBody>
                <TR>
                    <TD align="center">
                        <table
                            style={{
                                width: '100%',
                                maxWidth: '600px',
                                fontFamily:
                                    '"Open Sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif',
                            }}>
                            <TBody>
                                <TR>
                                    <TD align="center" width={600}>
                                        <table
                                            className="insightTemplate"
                                            style={{
                                                maxWidth: '600px',
                                                backgroundColor:
                                                    'white !important',
                                                color: 'black',
                                            }}>
                                            <TBody>
                                                <InsightTemplateHtmlDailyHeader />
                                                <InsightTemplateBody
                                                    nodes={insightData.nodes}
                                                    componentsById={
                                                        insightData.componentsById
                                                    }
                                                />
                                                <InsightTemplateHtmlFooter />
                                            </TBody>
                                        </table>
                                    </TD>
                                </TR>
                            </TBody>
                        </table>
                    </TD>
                </TR>
            </TBody>
        </table>
    );
};

export default InsightTemplateHtmlDaily;
