import { get, isNil, curry, map } from 'lodash/fp';

export const mapWithKeys = map.convert({ cap: false });

const getOrWrapper = (defaultValue, path, container) => {
    const value = get(path, container);

    return isNil(value) ? defaultValue : value;
};

export const getOr = curry(getOrWrapper);
