import React, { ReactNode } from 'react';
import { TR, TD, Separator, InsightsLogo } from '../InsightTemplateElements';

const Link = ({
    children,
    href,
    target = '',
}: {
    children: ReactNode;
    href: string;
    target?: string;
}) => {
    return (
        <a
            style={{
                color: '#007c89',
                textDecoration: 'underline',
            }}
            href={href}
            target={target}>
            {children}
        </a>
    );
};

const InsightTemplateHtmlDailyHeader = () => {
    return (
        <React.Fragment>
            <TR>
                <InsightsLogo />
            </TR>
            <TR>
                <Separator />
            </TR>
            <TR>
                <TD
                    width={600}
                    style={{
                        maxWidth: '600px',
                        width: '100%',
                        textAlign: 'center',
                    }}>
                    <span
                        style={{
                            fontSize: 32,
                            letterSpacing: 20,
                            marginLeft: 20,
                            lineHeight: 1.5,
                            color: '#8e8e8e',
                            fontWeight: 700,
                        }}>
                        INSIGHT
                    </span>
                </TD>
            </TR>
            <TR>
                <TD
                    width={600}
                    style={{
                        maxWidth: '600px',
                        width: '100%',
                        paddingBottom: 16,
                    }}>
                    <span style={{ fontSize: '16px', lineHeight: 1.2 }}>
                        Data driven snapshots of country and global political
                        risk.
                    </span>
                </TD>
            </TR>
            <TR>
                <TD
                    width={600}
                    style={{
                        maxWidth: '600px',
                        width: '100%',
                        paddingBottom: 16,
                    }}>
                    <span style={{ fontSize: '16px', lineHeight: 1.2 }}>
                        <Link href="https://www.geoquant.com" target="_blank">
                            Visit us
                        </Link>
                        &nbsp;to learn more.
                    </span>
                </TD>
            </TR>
            <TR>
                <TD
                    align="center"
                    width={600}
                    style={{ maxWidth: '600px', width: '100%' }}>
                    <span
                        className="insightDate"
                        style={{
                            fontSize: '16px',
                        }}>
                        *|DATE:j F Y|*
                    </span>
                    <hr />
                </TD>
            </TR>
        </React.Fragment>
    );
};

export default InsightTemplateHtmlDailyHeader;
