import React from 'react';
import * as _ from 'lodash';

import Tooltip from '../../components/GQButton/GQButtonTooltip';
import { IGQPopupData } from './GQPopupInterfaces';
import GQPopupStorage from './GQPopupStorage';
import EventsService, { EventSubscription } from '../../services/EventsService';
interface IGQPopupManagerState {
    refList: {
        [key: string]: IGQPopupData;
    };
}

export default class GQPopupManager extends React.Component<
    {},
    IGQPopupManagerState
> {
    private popupChangeSubscription: EventSubscription = null;
    constructor(props: any) {
        super(props);
        this.state = {
            refList: GQPopupStorage.data,
        };
    }

    public componentDidMount() {
        this.popupChangeSubscription = EventsService.registerOnPopupAddRef(
            this.onPopupChange
        );
    }
    public componentWillUnmount() {
        if (this.popupChangeSubscription) {
            this.popupChangeSubscription.remove();
        }
    }
    public render() {
        return _.map(this.state.refList, (refData, refKey) =>
            refData ? (
                <Tooltip
                    children={refData.tooltipData}
                    key={refKey}
                    parentProps={refData.element}
                    position={refData.orientation}
                    offset={refData.offset}
                    fixedPos={refData.fixedPos}
                    options={{ ...refData.options }}
                    delay={refData.delay}
                />
            ) : null
        );
    }

    private onPopupChange = () => {
        this.setState({
            refList: GQPopupStorage.data,
        });
    };
}
