import React from 'react';
import cx from 'classnames';

interface IGQMenuItemProps {
    label: string;
    icon?: string;
    disabled?: boolean;
    onClick?: () => void;
    href?: string;
    colorIcon?: string;
    selected?: boolean;
}

export default class IGQMenuItem extends React.Component<IGQMenuItemProps> {
    public render() {
        return (
            <a
                className={cx([
                    'gq-menu-item flex-container align-middle',
                    { disabled: this.props.disabled },
                    { selected: this.props.selected },
                ])}
                href={this.props.href}
                target="_blank"
                rel="noopener noreferrer"
                onClick={this.onClick}>
                {this.props.icon && (
                    <div className="gq-menu-item-icon flex-container align-middle">
                        <i
                            style={{
                                ...(this.props.colorIcon
                                    ? { color: this.props.colorIcon }
                                    : {}),
                            }}
                            className={this.props.icon}
                        />
                    </div>
                )}
                <div className="gq-menu-item-label flex-container align-middle flex-child-grow">
                    {this.props.label}
                </div>
            </a>
        );
    }
    private onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.stopPropagation();
        if (this.props.disabled) {
            return;
        }
        if (this.props.onClick) {
            this.props.onClick();
        }
    };
}
