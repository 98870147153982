import React, { useCallback } from 'react';
import {
    IActiveDimension,
    IActiveClientFacingIndicator,
    E_RISK_TYPE,
} from 'services/APIService';
import { map } from 'lodash/fp';
import HeatmapHeaderFundamentalRisk from './HeatmapHeaderFundamentalRisk';
import HeatmapHeaderFocusRisk from './HeatmapHeaderFocusRisk';

type Props = {
    dimensionsList: IActiveDimension[];
    hoveredIndicatorId: number;
    selectedIndicatorId: number;
    riskType: E_RISK_TYPE;
    onIndicatorClick: (indicatorId: number) => void;
    clientFacingIndicators: IActiveClientFacingIndicator[];
};

const HeatmapHeader = React.memo(
    ({
        dimensionsList,
        hoveredIndicatorId,
        selectedIndicatorId,
        riskType,
        onIndicatorClick,
        clientFacingIndicators,
    }: Props) => {
        const renderFundamentals = useCallback(() => {
            return map((dimension: IActiveDimension) => {
                return (
                    <HeatmapHeaderFundamentalRisk
                        key={dimension.id}
                        dimension={dimension}
                        onIndicatorClick={onIndicatorClick}
                        hoveredIndicatorId={hoveredIndicatorId}
                        selectedIndicatorId={selectedIndicatorId}
                    />
                );
            }, dimensionsList);
        }, [
            dimensionsList,
            hoveredIndicatorId,
            selectedIndicatorId,
            onIndicatorClick,
        ]);

        const renderFocus = useCallback(() => {
            return map((indicator: IActiveClientFacingIndicator) => {
                return (
                    <HeatmapHeaderFocusRisk
                        key={indicator.id}
                        indicator={indicator}
                        onIndicatorClick={onIndicatorClick}
                        selectedIndicatorId={selectedIndicatorId}
                        hoveredIndicatorId={hoveredIndicatorId}
                    />
                );
            }, clientFacingIndicators);
        }, [
            clientFacingIndicators,
            hoveredIndicatorId,
            selectedIndicatorId,
            onIndicatorClick,
        ]);

        return (
            <div className="heatmap-header">
                <div className="heatmap-header-items">
                    <div className="heatmap-header-items-inner">
                        {riskType === E_RISK_TYPE.DIMENSIONS
                            ? renderFundamentals()
                            : renderFocus()}
                    </div>
                </div>
            </div>
        );
    }
);

export default HeatmapHeader;
