import React from 'react';
import DimensionItem from '../GQDimensionItem/GQDimensionItem';
import riskStore from '../../stores/RisksStore';
import { ISidebarRiskPickerAbstractContentProps } from './SidebarRiskPickerWrapper';

interface ISidebarRiskPickerListContentProps
    extends ISidebarRiskPickerAbstractContentProps {
    menuItems: ISidebarRiskPickerMenuItem[];
    multi?: boolean;
}

export interface ISidebarRiskPickerMenuItem {
    name: string;
    id: number;
    iconName?: string;
    disabled?: boolean;
    hidden?: boolean;
    topDivider?: boolean;
    definition?: string;
}

export default class SidebarRiskPickerListContent extends React.Component<
    ISidebarRiskPickerListContentProps,
    {}
> {
    public render() {
        return (
            <div className="sidebar-risk-picker-content-list">
                {this.props.menuItems.map(item => {
                    const currentItemSelected = this.props.selectedItemsIds.includes(
                        item.id
                    );
                    return (
                        <DimensionItem
                            maxDepth={1}
                            key={`sidebar-risk-picker-content-item-${item.id}`}
                            id={item.id}
                            name={item.name}
                            isActive={currentItemSelected}
                            tier={3}
                            riskType={riskStore.currentRisksType}
                            onClick={this.onMenuItemClick.bind(this, item.id)}
                            backgroundColor={
                                this.props.multi && currentItemSelected
                                    ? riskStore.colorStack.color(item.id)
                                    : null
                            }
                            iconClass={item.iconName}
                            isDisabled={item.disabled}
                            topDivider={item.topDivider}
                            definition={item.definition}
                        />
                    );
                })}
            </div>
        );
    }

    private onMenuItemClick = (itemId: number) => {
        this.props.onItemClick(itemId);
    };
}
