import React from 'react';
import classNames from 'classnames';

import GQFlag from '../GQFlag';

interface IGQTopIndicatorItemProps {
    countryID: number;
    dimensionName: string;
    scoreDelta: number;
    onTopIndicatorClicked?: (dimensionName: string, countryId: number) => void;
}

export default class IGQTopIndicatorItem extends React.Component<
    IGQTopIndicatorItemProps
> {
    public render() {
        const { countryID, dimensionName, scoreDelta } = this.props;
        return (
            <div
                className="gq-top-indicators-item row"
                onClick={this.onItemClick}>
                <GQFlag countryId={countryID} />
                <div className="gq-top-indicators-dimension">
                    <span>{dimensionName}</span>
                </div>
                <div
                    className={classNames(['gq-top-indicators-score'], {
                        positive: scoreDelta < 0,
                        negative: scoreDelta > 0,
                    })}>
                    <div
                        className={classNames(['gq-caret-container'], {
                            up: scoreDelta > 0,
                        })}>
                        <i className="gqi-dropdown" />
                    </div>
                    <span>{Math.abs(scoreDelta).toFixed(2)}</span>
                </div>
            </div>
        );
    }
    private onItemClick = () => {
        this.props.onTopIndicatorClicked(
            this.props.dimensionName,
            this.props.countryID
        );
    };
}
