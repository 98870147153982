import React from 'react';
import { feature } from 'topojson';
import * as d3Geo from 'd3-geo';
import * as _ from 'lodash';

import MapItem from './GQCGMapItem';
import GQPopupStorage from '../../../../GQPopup/GQPopupStorage';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import { IMobxRootState } from 'RootStore';
import { IActiveCountry, IActiveCountryMap } from 'services/APIService';

const wMap = require('../../../../../data/worldMap.json');
const isoToName: {
    [key: number]: string;
} = require('../../../../../data/isoToName.json');
const features = feature(wMap, wMap.objects.countries);

const baseScaleFactor = 1712;

interface ICGDot {
    x: number;
    y: number;
    id: number;
    disabled: boolean;
}

interface State {
    projection: d3Geo.GeoProjection;
    geoPath: d3Geo.GeoPath<any, d3Geo.GeoPermissibleObjects>;
}

interface Props {
    selectedIds: number[];
    onClick?: (id?: number) => void;
    disabled?: boolean;
    countries?: IActiveCountryMap;
    countriesArr?: IActiveCountry[];
}

@inject(({ countryStore }: IMobxRootState) => {
    return {
        countriesArr: countryStore.countries.countriesArr,
        countries: countryStore.countries.countries,
    };
})
@observer
export default class IGQCGMap extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            geoPath: null,
            projection: null,
        };
    }
    public componentDidMount() {
        const container = document.querySelector('.gq-cg-content-wrapper');
        const projection = d3Geo
            .geoMercator()
            .clipExtent([
                [0, 0],
                [container.clientWidth, container.clientHeight],
            ])
            .scale(
                Math.min(
                    (container.clientWidth / baseScaleFactor) * 220 + 20,
                    220
                )
            )
            .translate([container.clientWidth / 2, container.clientHeight / 2])
            .center([0, 25]);
        const geoPath = d3Geo.geoPath();
        geoPath.projection(projection);
        this.setState({ geoPath, projection });
    }

    public render() {
        const keyMap: { [key: string]: number } = {};
        const dots: ICGDot[] = [];
        if (!this.state.projection) {
            return null;
        }

        this.getDots(dots);
        const { selectedIds } = this.props;
        return (
            <div className="gq-cg-map">
                <svg width="100%" height="100%">
                    {_.map(features.features, (country: any) => {
                        const id = this.getID(country.id);
                        keyMap[id] =
                            keyMap[id] !== undefined ? (keyMap[id] += 1) : 0;
                        return id ? (
                            <MapItem
                                id={id}
                                isSelected={
                                    selectedIds && selectedIds.includes(id)
                                }
                                pathStr={this.state.geoPath(country)}
                                key={`${country.id}-${keyMap[id]}`}
                                onClick={this.onClick}
                                selectable={id !== -1 && !this.props.disabled}
                                onHover={this.showTooltip}
                            />
                        ) : null;
                    })}
                    {_.map(dots, dot => {
                        const onClick = () => {
                            this.onClick(dot.id);
                        };
                        const onHover = () => {
                            this.showTooltip(dot.id);
                        };
                        return (
                            <g
                                key={dot.id}
                                className={classNames([
                                    'gq-cg-dot-group',
                                    {
                                        hide:
                                            selectedIds &&
                                            selectedIds.includes(dot.id),
                                        'no-edit': dot.disabled,
                                    },
                                ])}
                                onClick={onClick}
                                transform={`translate(${dot.x},${dot.y})`}
                                onMouseEnter={onHover}>
                                <circle
                                    className="gq-cg-dot"
                                    id={'cgDot' + dot.id.toString()}
                                    r="2.5"
                                />
                                <circle className="gq-cg-dot-pulse" r="6" />
                            </g>
                        );
                    })}
                </svg>
            </div>
        );
    }
    private getDots(arr: ICGDot[]) {
        for (const c of this.props.countriesArr) {
            arr.push({
                id: c.id,
                disabled: this.props.disabled,
                x: +this.state.projection([c.longitude, c.latitude])[0],
                y: +this.state.projection([c.longitude, c.latitude])[1],
            });
        }
    }
    private getID(isoID: string) {
        const isoName = isoToName[Number(isoID)];
        for (const c of this.props.countriesArr) {
            if (c.abbreviation_short === isoName) {
                return c.id;
            }
        }
        return -1;
    }
    private onClick = (id: number) => {
        if (this.props.onClick) {
            this.props.onClick(id);
        }
    };
    private showTooltip = (id: number) => {
        if (!id) {
            GQPopupStorage.addData('gq-cg-popup', null);
            return;
        }
        const element = document.querySelector('#cgDot' + id) as HTMLElement;
        const country = this.props.countries[id];
        if (!country || !element) {
            return;
        }
        GQPopupStorage.addData('gq-cg-popup', {
            element,
            orientation: 'top center',
            tooltipData: (
                <span style={{ padding: '8px 16px' }}>{country.name}</span>
            ),
        });
    };
}
