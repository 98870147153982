import React from 'react';
import { TweenLite } from 'gsap';

import GQFlag from '../../../../GQFlag';
import GQPopupStorage from '../../../../GQPopup/GQPopupStorage';
import { ITransitionGroupComponent } from '../../../../../interfaces';

interface IGQCGListItemProps {
    disabled: boolean;
    id: number;
    name: string;
    top: React.CSSProperties['top'];
    left: React.CSSProperties['left'];
    onClick?: (id?: number) => void;
}

export default class GQCGListItem extends React.Component<IGQCGListItemProps>
    implements ITransitionGroupComponent {
    private itemID: string = null;
    private wrapID: string = null;
    private elRef: HTMLDivElement = null;
    public componentWillEnter(cb: () => void) {
        const { top } = this.props;
        const el = this.elRef;
        TweenLite.set(el, { y: top ? top : 0 });
        TweenLite.from(el, 0.5, { y: '+=50', onComplete: cb });
    }

    public componentDidMount() {
        this.wrapID = 'cgWrap' + this.props.id;
        this.itemID = 'cgItem' + this.props.id;
    }
    public componentDidUpdate(prevProps: IGQCGListItemProps) {
        const el = this.elRef;
        if (this.props.left !== prevProps.left) {
            TweenLite.fromTo(
                el,
                0.5,
                { x: prevProps.left },
                { x: this.props.left }
            );
        }
    }
    public componentWillLeave(cb: () => void) {
        const el = this.elRef;
        TweenLite.to(el, 0.5, { y: '-=50', onComplete: cb });
    }
    public render() {
        return (
            <div
                id={this.wrapID}
                className="gq-cg-selected-item-wrapper"
                onMouseEnter={this.onHover}
                onMouseLeave={this.onHoverOut}
                onClick={this.onClick}
                style={{
                    transform: `translate(${this.props.left}px, ${this.props.top}px)`,
                }}
                ref={el => (this.elRef = el)}>
                <div id={this.itemID} className="gq-cg-selected-item">
                    <GQFlag
                        countryId={this.props.id}
                        className="flag-icon-squared"
                        hideTooltip={true}
                    />
                    <i className="gqi-close gq-cg-selected-item-hover" />
                </div>
            </div>
        );
    }
    private onHover = (e: React.MouseEvent<HTMLDivElement>) => {
        GQPopupStorage.addData('gq-flag', {
            element: e.currentTarget,
            orientation: 'bottom center',
            tooltipData: (
                <span className="gq-flag-popup">{this.props.name}</span>
            ),
        });
        if (this.props.disabled) {
            return;
        }
        TweenLite.fromTo(
            '#' + this.itemID,
            0.5,
            { rotationY: 0 },
            { rotationY: -180, ease: 'Back.easeOut' }
        );
    };
    private onHoverOut = (e: React.MouseEvent<HTMLDivElement>) => {
        GQPopupStorage.addData('gq-flag', null);
        if (this.props.disabled) {
            return;
        }
        TweenLite.fromTo(
            '#' + this.itemID,
            0.5,
            { rotationY: -180 },
            { rotationY: 0, ease: 'Back.easeOut' }
        );
    };
    private onClick = () => {
        if (this.props.disabled) {
            return;
        }
        if (this.props.onClick) {
            this.props.onClick(this.props.id);
        }
    };
}
