import React from 'react';
import { IEventsData } from './EventsFeedInterfaces';
import GQFlag from '../GQFlag';
import ScoresImpact from './Impact';
import classNames from 'classnames';
import { MOMENTJS_DATE_DISPLAY_FORMAT } from '../../constants';
import { GQButton } from '../GQButton';
import { GQMenu, GQMenuItem } from '../GQMenu';
import { IActiveDimension } from '../../services/APIService';

export interface Props extends IEventsData {
    isActive: boolean;
    onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
    isTemp: boolean;
    onDelete: (id: number) => void;
    isDebug?: boolean;
    dimensions: { [dimensionId: number]: IActiveDimension };
}

interface State {
    menuOpened: boolean;
}

export default class EventItem extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            menuOpened: false,
        };
    }

    public render() {
        const classes = classNames([
            'event-feed-item',
            {
                active: this.props.isActive,
                hovered: this.props.isTemp,
            },
        ]);

        const hasScores = (this.props.uscores || []).length > 0;

        const hasHumanScores =
            hasScores && !!this.props.uscores.find(score => score.human);

        return (
            <div
                id={this.props.id.toString()}
                className={classes}
                onClick={this.props.onClick}
                onMouseLeave={this.hideMenu}>
                <div className={'event-feed-item-content'}>
                    {this.props.headline}
                    {this.renderMenuButton()}
                </div>
                <div className="event-feed-item-footer">
                    <div className="event-footer-details">
                        <div className="event-item-date-container">
                            <span className="event-item-date">
                                {this.props.time.format(
                                    this.props.isDebug
                                        ? 'MMM D, YYYY kk:mm:ss'
                                        : MOMENTJS_DATE_DISPLAY_FORMAT
                                )}
                                {this.props.isDebug
                                    ? `(BS-${this.props.bullshit_metric})`
                                    : ''}
                            </span>
                        </div>
                        <div className="event-item-source-container">
                            <span className="event-item-source-name">
                                {this.props.source}
                            </span>
                        </div>
                    </div>
                    {this.props.debugData && this.props.debugData}
                    <div className="event-item-dynamic-details">
                        {(this.props.countries &&
                            hasScores &&
                            this.props.countries.length > 1) ||
                        !hasScores ? (
                            <div
                                className={'event-item-flags flexed'}
                                style={hasScores ? { marginLeft: '4px' } : {}}>
                                {this.props.countries.map(c => {
                                    return <GQFlag countryId={c} key={c} />;
                                })}
                            </div>
                        ) : (
                            <div className="filler" />
                        )}
                        {hasScores ? (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                <ScoresImpact
                                    dimensions={this.props.dimensions}
                                    scores={this.props.uscores}
                                />
                                {hasHumanScores && (
                                    <i className="icon gqi-certified has-human-scores" />
                                )}
                            </div>
                        ) : (
                            <div className="even-item-pending-status">
                                <span>Pending</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    private renderMenuButton = () => {
        if (!this.props.is_analyst) {
            return null;
        }

        return (
            <div className="event-item-menu-icon">
                <GQButton
                    icon={
                        this.state.menuOpened ? 'gqi-close' : 'gqi-ellipsis-v'
                    }
                    noBorder={true}
                    onClick={this.toggleEventItemMenu}
                    noPadding={true}
                    catchAllPointerEvents={true}
                />
                {this.state.menuOpened && (
                    <div className="gq-button-tooltip-container">
                        <div
                            className="gq-button-tooltip bottom left end"
                            style={{ position: 'absolute', right: '0px' }}>
                            <GQMenu>
                                <GQMenuItem
                                    label="Delete"
                                    icon="gqi-delete"
                                    onClick={this.onDeleteClick}
                                />
                            </GQMenu>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    private toggleEventItemMenu = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        if (this.state.menuOpened) {
            this.hideMenu();
        } else {
            this.setState({
                menuOpened: true,
            });
        }
    };

    private hideMenu = () => {
        if (this.state.menuOpened) {
            this.setState({
                menuOpened: false,
            });
        }
    };

    private onDeleteClick = () => {
        this.hideMenu();
        this.props.onDelete(this.props.id);
    };
}
