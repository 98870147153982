import React from 'react';

import rootStore from '../../RootStore';
import GQFlag from '../GQFlag';
import classNames from 'classnames';
import { TweenLite } from 'gsap';
import { ITransitionGroupComponent } from '../../interfaces';

interface IGQCountryButtonProps {
    id: number;
    isSelected: boolean;
    onClick?: (id: number) => void;
    style?: React.CSSProperties;
    containerStyle?: React.CSSProperties;
    animStyle?: {
        top: React.CSSProperties['top'];
        left: React.CSSProperties['left'];
    };
}

export default class GQCountryButton
    extends React.Component<IGQCountryButtonProps>
    implements ITransitionGroupComponent {
    private elRef: HTMLDivElement = null;
    public componentWillEnter(cb: () => void) {
        const { animStyle } = this.props;
        const el = this.elRef;
        TweenLite.set(el, { y: animStyle ? animStyle.top : 0 });
        TweenLite.from(el, 0.3, { y: '+=50', onComplete: cb });
    }
    public componentWillLeave(cb: () => void) {
        const el = this.elRef;
        TweenLite.to(el, 0.3, { y: '-=50', onComplete: cb });
    }
    public componentDidUpdate(prevProps: IGQCountryButtonProps) {
        if (!prevProps.animStyle || !this.props.animStyle) {
            return;
        }
        const el = this.elRef;
        if (this.props.animStyle.left !== prevProps.animStyle.left) {
            TweenLite.fromTo(
                el,
                0.5,
                { x: prevProps.animStyle.left },
                { x: this.props.animStyle.left }
            );
        }
    }
    public render() {
        const { id, isSelected, style, containerStyle, animStyle } = this.props;
        const containerClass = classNames(['flag-container'], {
            active: isSelected,
        });
        return (
            <div
                className="col gq-country-button"
                style={{
                    marginRight: '4px',
                    marginLeft: '6px',
                    ...containerStyle,
                    transform: animStyle
                        ? `translate(${animStyle.left}px, ${animStyle.top}px)`
                        : '',
                }}
                ref={el => (this.elRef = el)}>
                <div
                    key={id}
                    onClick={this.onClick}
                    className={containerClass}
                    style={
                        isSelected
                            ? {
                                  ...style,
                                  borderColor:
                                      (style && style.borderColor) ||
                                      rootStore.countryStore.colorStack.color(
                                          id
                                      ),
                                  transition: 'border .4s ease',
                              }
                            : {}
                    }>
                    <GQFlag countryId={id} className="flag-icon-squared" />
                </div>
            </div>
        );
    }
    private onClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        if (this.props.onClick) {
            this.props.onClick(this.props.id);
        }
    };
}
