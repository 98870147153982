import React from 'react';
import GQFlag from '../GQFlag';
import { VIEW } from '../../interfaces';

interface IGQTopEventsItemProps {
    countryID: number;
    event: string;
    onClick?: (id: number) => void;
    selectTopEvent?: (id: number) => void;
    onViewChange?: (view: VIEW, ...args: any[]) => void;
    eventId?: any;
}

export default class IGQTopEventsItem extends React.Component<
    IGQTopEventsItemProps
> {
    public render() {
        const { countryID, event } = this.props;
        return (
            <div className="gq-top-events-item row" onClick={this.onItemClick}>
                {countryID === 0 ? (
                    <span className="gq-flag flag-icon flag-icon-zz" />
                ) : (
                    <GQFlag countryId={countryID} />
                )}
                <div className="gq-top-indicators-dimension">
                    <span>{event}</span>
                </div>
            </div>
        );
    }
    private onItemClick = () => {
        this.props.onViewChange(VIEW.INSIGHTS, this.props.eventId);
    };
}
