import React from 'react';
import classNames from 'classnames';

interface IGQInputProps {
    editable: boolean;
    inputValue?: string;
    placeholder: string;
    highlightOnFocus?: boolean;
    onBlur?: () => void;
    onFocus?: () => void;
    focusOnMount?: boolean;
    style?: React.CSSProperties;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface IGQInputState {
    active: boolean;
    isFocused: boolean;
}

export default class GQInput extends React.Component<
    IGQInputProps,
    IGQInputState
> {
    constructor(props: IGQInputProps) {
        super(props);
        this.state = {
            active: false,
            isFocused: false,
        };
    }
    public shouldComponentUpdate(
        nextProps: IGQInputProps,
        nextState: IGQInputState
    ) {
        if (this.props.placeholder !== nextProps.placeholder) {
            return true;
        }
        if (this.props.inputValue !== nextProps.inputValue) {
            return true;
        }
        if (this.state.active !== nextState.active) {
            return true;
        }
        return false;
    }

    public render() {
        return (
            <div
                className={classNames([
                    'gq-settings-input',
                    {
                        active: this.state.active,
                        'no-edit': !this.props.editable,
                    },
                ])}
                style={{ ...this.props.style }}>
                <input
                    disabled={!this.props.editable}
                    placeholder={this.props.placeholder || ''}
                    maxLength={16}
                    onChange={this.props.onChange}
                    ref={el => {
                        if (el && this.props.focusOnMount) {
                            el.focus();
                            if (this.props.highlightOnFocus) {
                                el.setSelectionRange(0, el.value.length);
                            }
                        }
                    }}
                    onFocus={this.onInputFocus}
                    onBlur={this.onInputBlur}
                    onMouseEnter={this.setInputActive}
                    onMouseLeave={this.setInputInactive}
                />
                <i className="gqi-edit" style={{ fontSize: '17px' }} />
            </div>
        );
    }

    private onInputFocus = () => {
        this.setState({
            isFocused: true,
            active: true,
        });
    };

    private onInputBlur = () => {
        this.setState({
            isFocused: false,
            active: false,
        });
    };

    private setInputInactive = () => {
        if (!this.state.isFocused) {
            this.setState({
                active: false,
            });
        }
    };

    private setInputActive = () => {
        if (this.props.editable && !this.state.active) {
            this.setState({
                active: true,
            });
        }
    };
}
