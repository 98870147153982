import { IActivityBaseComponent } from 'interfaces';
import { get } from 'lodash/fp';
import React from 'react';
import { useMemo } from 'react';

type Props = {
    component: IActivityBaseComponent;
};
const EditorIframe = React.memo((props: Props) => {
    const iframeUrl = useMemo(() => get('url', props.component), [
        props.component,
    ]);
    return (
        <div className="insight-iframe-container">
            <iframe className="insight-iframe" src={iframeUrl} title="insight-iframe"/>
        </div>
    );
});

export default EditorIframe;
