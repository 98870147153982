import React from 'react';
import * as _ from 'lodash';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import { Moment } from 'moment';
import { IMobxRootState } from '../../RootStore';
import { IGlobalTopRisksDelta } from '../../interfaces';
import TopIndicatorItem from './GQTopIndicatorItem';
import APIService, { E_RISK_TYPE } from '../../services/APIService';
import CancelableEvents from 'utils/CancelableEvents';

interface IGQTopIndicatorsProps {
    minimized?: boolean;
    dateStart?: Moment;
    dateEnd?: Moment;
    activeGroupId?: string;
    onTopIndicatorClicked?: (dimensionName: string, countryId: number) => void;
}

interface IGQTopIndicatorsState {
    collapsed: boolean;
    data: IGlobalTopRisksDelta[];
    dimensionIdToNameMap: { [dimId: number]: string };
}

@inject(({ eventsFeedStore, chartStore, settingsStore }: IMobxRootState) => {
    return {
        minimized: eventsFeedStore.isMinimized,
        dateStart: chartStore.rangeLeftDate,
        dateEnd: chartStore.rangeRightDate,
        activeGroupId: settingsStore.activeGroupId,
    };
})
@observer
export default class GQTopIndicators extends React.Component<
    IGQTopIndicatorsProps,
    IGQTopIndicatorsState
> {
    private loadTopRisks: () => Promise<void>;
    private cancelableEvents = new CancelableEvents();

    constructor(props: IGQTopIndicatorsProps) {
        super(props);
        this.state = {
            collapsed: false,
            data: [],
            dimensionIdToNameMap: null,
        };
        this.loadTopRisks = _.debounce(this.fetchTopRisksData, 500);
    }

    public componentDidMount() {
        this.loadTopRisks();
        this.mapDimensionIdToName();
    }

    public componentWillUnmount() {
        this.cancelableEvents.cancelAll();
    }
    public componentDidUpdate(prevProps: IGQTopIndicatorsProps) {
        if (
            !prevProps.dateStart.isSame(this.props.dateStart, 'day') ||
            !prevProps.dateEnd.isSame(this.props.dateEnd, 'day')
        ) {
            this.loadTopRisks();
        }
        if (this.props.activeGroupId !== prevProps.activeGroupId) {
            this.loadTopRisks();
        }
    }
    public render() {
        const { data, dimensionIdToNameMap } = this.state;
        const baseClass = 'gq-top-indicators';
        return (
            <div className={classNames([`${baseClass}-container`, 'col'])}>
                <div className={`${baseClass}-header row`}>
                    <div className={`${baseClass}-title`}>
                        <span>Top Indicators</span>
                    </div>
                    <div className="filler" />
                </div>
                <div
                    className={classNames([`${baseClass}-content`], {
                        collapsed: this.state.collapsed,
                    })}>
                    {dimensionIdToNameMap &&
                        data &&
                        _.map(data, risk => {
                            if (risk.country) {
                                return (
                                    <TopIndicatorItem
                                        key={`${risk.country.id}-${
                                            dimensionIdToNameMap[risk.riskId]
                                        }`}
                                        countryID={risk.country.id}
                                        dimensionName={
                                            dimensionIdToNameMap[risk.riskId]
                                        }
                                        scoreDelta={risk.delta}
                                        onTopIndicatorClicked={
                                            this.props.onTopIndicatorClicked
                                        }
                                    />
                                );
                            } else {
                                return null;
                            }
                        })}
                </div>
            </div>
        );
    }

    private async fetchTopRisksData() {
        try {
            const topRisks = await this.cancelableEvents.promise(async () => {
                let topRisks = await APIService.getGlobalTopRisks(
                    this.props.dateStart,
                    this.props.dateEnd,
                    3,
                    E_RISK_TYPE.DIMENSIONS,
                    false
                );
                if (topRisks.length === 0) {
                    topRisks = await APIService.getGlobalTopRisks(
                        this.props.dateStart,
                        this.props.dateEnd,
                        3,
                        E_RISK_TYPE.DIMENSIONS,
                        true
                    );
                }
                return topRisks;
            });
            this.setState({
                data: topRisks,
            });
        } catch (error) {
            console.warn(error);
        }
    }

    private mapDimensionIdToName() {
        this.cancelableEvents
            .promise(() => APIService.getActiveDimensionsList())
            .then(dimensions => {
                const dimensionIdToNameMap: { [dimId: number]: string } = {};
                dimensions.forEach(
                    dim => (dimensionIdToNameMap[dim.id] = dim.lname)
                );
                this.setState({ dimensionIdToNameMap });
            })
            .catch(console.warn);
    }
}
