import React from 'react';
import MobxReactForm from 'mobx-react-form';
import * as validatorjs from 'validatorjs';
import APIService from '../../services/APIService';
import FormField from '../Form/FormField';
import GQLoader from '../GQLoader/GQLoader';
import AuthPageContainer from './AuthPageContainer';
import appStore from '../../AppStore';

interface INewPasswordState {
    error: string;
    inProgress: boolean;
    passReset: boolean;
}

interface IForm {
    values: () => {
        password: string;
        passwordConfirm: string;
    };
}

export default class CreateNewPassportPage extends React.Component<
    any,
    INewPasswordState
> {
    constructor(props: any) {
        super(props);
        this.state = {
            inProgress: false,
            error: null,
            passReset: false,
        };
    }

    public onSubmit = (form: IForm) => {
        this.setState(
            {
                inProgress: true,
                error: null,
            },
            async () => {
                try {
                    const pageURL = window.location.href;
                    const id = pageURL.substr(pageURL.lastIndexOf('/') + 1);
                    const formFields = form.values();
                    APIService.changePassword(id, formFields.password).then(
                        async res => {
                            await APIService.login({
                                username: res.id,
                                password: formFields.password,
                            });
                            if (appStore.isAgreeTerms) {
                                appStore.setfirstLoad(true);
                            }
                        }
                    );
                } catch (err) {
                    this.setState({
                        inProgress: false,
                        error: err.message || err,
                    });
                }
            }
        );
    };

    public initForm = () => {
        const plugins = {
            dvr: {
                package: validatorjs,
                extend: ($validator: any) => {
                    const messages = $validator.getMessages('en');
                    messages.same = 'Passwords do not match';
                    $validator.setMessages('en', messages);
                    messages.required = 'Password cannot be empty';

                    $validator.register(
                        'complex_password',
                        function(value: string) {
                            return (
                                value.match(/[A-Z]/g) && value.match(/[A-Z]/g)
                            );
                        },
                        'Password must contains at least 1 number and 1 uppercase letter'
                    );
                },
            },
        };

        const fields = [
            {
                name: 'password',
                rules: 'required|string|complex_password|between:8,30',
            },
            {
                name: 'passwordConfirm',
                rules:
                    'same:password|required|string|complex_password|between:8,30',
            },
        ];

        const hooks = {
            onSuccess: this.onSubmit,
        };

        return new MobxReactForm({ fields }, { plugins, hooks });
    };

    form = this.initForm();

    public renderTooltip = () => (
        <div>
            Password must contains at least:
            <br />
            8 characters
            <br />
            1 number
            <br />
            1 uppercase letter
            <br />
        </div>
    );

    public render() {
        return (
            <AuthPageContainer>
                {!this.state.passReset ? (
                    <div className="auth-form-common">
                        <div className="create-password-form__title">
                            Create new password
                        </div>
                        <form
                            name="gqLogin"
                            className="auth-form"
                            onSubmit={this.form.onSubmit}>
                            <FormField
                                field={this.form.$('password')}
                                className="auth-form-common__field"
                                type="password"
                                placeholder="Type new password"
                                tooltip={this.renderTooltip()}
                            />

                            <FormField
                                field={this.form.$('passwordConfirm')}
                                type="password"
                                className="login-password"
                                placeholder="Retype password"
                            />

                            <div className="auth-form-common__submit">
                                {this.state.inProgress && (
                                    <div className="auth-form-common__submit-loader">
                                        <GQLoader />
                                    </div>
                                )}

                                <button type="submit">
                                    {this.state.inProgress ? '' : 'Confirm'}
                                </button>
                            </div>

                            <div className="auth-form-common__error">
                                <span>
                                    {this.state.error || this.form.error}
                                </span>
                            </div>
                        </form>
                    </div>
                ) : (
                    <div id="loader-animation" />
                )}
            </AuthPageContainer>
        );
    }
}
