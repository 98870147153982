import React from 'react';
import { observer, inject } from 'mobx-react';
import GQCGMap from './Map/GQCGMap';
import CGMenu from './Menu/GQCGMenu';
import countryGroupStore, { ICGStore } from './CountryGroupsStore';
import CGPicker from './Picker/GQCGPicker';
import { IMobxRootState } from '../../../../RootStore';
import { IActiveGroup } from '../../../../interfaces';

interface IGQCountryGroupsProps {
    selectedIds?: number[];
    activeGroup?: IActiveGroup;
}

@inject(({ cgStore }: IMobxRootState & ICGStore) => {
    return {
        selectedIds: cgStore.selectedIds,
        activeGroup: cgStore.activeGroup,
    };
})
@observer
export default class GQCountryGroups extends React.Component<
    IGQCountryGroupsProps,
    any
> {
    public render() {
        const disabled =
            !this.props.activeGroup ||
            (this.props.activeGroup && this.props.activeGroup.isGQGroup);

        return (
            <div className="gq-cg-container">
                <div className="gq-cg-menu-wrapper">
                    <CGMenu />
                </div>

                <div className="gq-cg-content-wrapper">
                    <div className="gq-cg-bg-dimmer" />
                    <GQCGMap
                        selectedIds={this.props.selectedIds}
                        onClick={this.toggleCountry}
                        disabled={disabled}
                    />
                    <CGPicker />
                </div>
            </div>
        );
    }

    private toggleCountry = (id: number) => {
        countryGroupStore.toggleId(id);
    };
}
