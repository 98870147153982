import { get } from 'lodash/fp';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { IMobxRootState } from '../../RootStore';

export interface IDriftChatProps {
    appId: string;
    username?: string;
    orgName?: string;
}

@inject(({ UserStore }: IMobxRootState) => {
    return {
        username: get('username', UserStore),
        orgName: get('organization.name', UserStore),
    };
})
@observer
export default class DriftChat extends React.Component<IDriftChatProps, {}> {
    constructor(props: any) {
        super(props);

        this.addMainScript = this.addMainScript.bind(this);
        this.addIdentityVariables = this.addIdentityVariables.bind(this);
        this.insertScript = this.insertScript.bind(this);
    }

    public insertScript = (scriptText: any) => {
        const script = document.createElement('script');
        script.textContent = scriptText;
        script.async = true;
        document.body.appendChild(script);
    };

    public addMainScript = () => {
        const scriptText = `!function() {
      var t = window.driftt = window.drift = window.driftt || [];
      if (!t.init) {
        if (t.invoked) return void (window.console && console.error && console.error("Drift snippet included twice."));
        t.invoked = !0, t.methods = [ "identify", "config", "track", "reset", "debug", "show", "ping", "page", "hide", "off", "on" ],
        t.factory = function(e) {
          return function() {
            var n = Array.prototype.slice.call(arguments);
            return n.unshift(e), t.push(n), t;
          };
        }, t.methods.forEach(function(e) {
          t[e] = t.factory(e);
        }), t.load = function(t) {
          var e = 3e5, n = Math.ceil(new Date() / e) * e, o = document.createElement("script");
          o.type = "text/javascript", o.async = !0, o.crossorigin = "anonymous", o.src = "https://js.driftt.com/include/" + n + "/" + t + ".js";
          var i = document.getElementsByTagName("script")[0];
          i.parentNode.insertBefore(o, i);
        };
      }
    }();
    drift.SNIPPET_VERSION = '0.3.1';
    drift.load('${this.props.appId}');`;

        this.insertScript(scriptText);
    };

    public addIdentityVariables = (userId: string, organization: string) => {
        const scriptText = `
      var t = window.driftt = window.drift = window.driftt || [];
      drift.identify('${userId}', {
        email: '${userId}',
        company: '${organization}'
      });
    `;
        this.insertScript(scriptText);
    };

    public componentDidMount(): any {
        //todo: check why this 'if' statement is here
        if (typeof window !== 'undefined') {
            this.addMainScript();
            this.addIdentityVariables(this.props.username, this.props.orgName);
        }
    }

    public render(): any {
        return '';
    }
}
