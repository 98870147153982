import React from 'react';

import GQButton from '../GQButton/GQButton';
import Logo from 'assets/general/Geoquant_Logo_Digital_White.svg';
import MediumImage from 'assets/img/medium2.png';
import TwitterImage from 'assets/img/twitter2.png';
import { SUPPORT_EMAIL } from 'services/configService';

export default class WelcomePage extends React.Component<any> {
    public render() {
        return (
            <div className="welcome-page">
                <div className="welcome-page__logo">
                    <img src={Logo} alt="" />
                </div>

                <div className="welcome-page__moto">
                    <b>Objective. High-Frequency. Actionable</b>
                    <br />
                    Political Risk Metrics for Analysts and Global Investors
                </div>

                <GQButton className="welcome-page__button">
                    Access Data
                </GQButton>

                <div className="welcome-page__prescription">
                    for any help contact:{' '}
                    <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
                </div>

                <div className="welcome-page__social-links">
                    <a
                        href="https://blog.geoquant.com/"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img src={MediumImage} width="40" alt="" />
                    </a>

                    <a
                        href="https://twitter.com/geo_quant"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img src={TwitterImage} width="40" alt="" />
                    </a>
                </div>
            </div>
        );
    }
}
