import React from 'react';
import { observer } from 'mobx-react';

import { ActivityNoteStore } from '../../../stores/insights/InsightsNoteStore';

export interface IActivityNoteAnchorPosition {
    left: number;
    top: number;
}

interface IGQNoteAnchorProps {
    note: ActivityNoteStore;
    index: number;
    position: {
        left: number;
        top: number;
    };
    style?: React.CSSProperties;
    size?: number;
}

@observer
export default class GQNoteAnchor extends React.Component<IGQNoteAnchorProps> {
    public static defaultProps: Partial<IGQNoteAnchorProps> = {
        size: 21,
    };
    public render() {
        const style: React.CSSProperties = {
            left: this.props.position.left - this.props.size / 2,
            top: this.props.position.top - this.props.size / 2,
            width: this.props.size,
            height: this.props.size,
            position: 'absolute',
            zIndex: 999,
        };
        return (
            <div
                style={{ ...style }}
                className="gq-insight-annotation-anchor flex-container align-center-middle">
                <span>{this.props.index}</span>
            </div>
        );
    }
}
