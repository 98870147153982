import * as React from 'react';
import { History } from 'history';
import StorageService from '../services/StorageService';
import { API_CACHE_KEYS } from '../services/lib/APIServiceInterfaces';
import appStore from '../AppStore';
import { DeprecatedCSSProperties } from '../types/DeprecatedCSSProperties';
const style: DeprecatedCSSProperties = {
    container: {
        color: 'white',
        height: '100%',
        textAlign: 'center',
        width: '100%',
    },
};

export interface IActivateProps {
    match: {
        path: string;
        url: string;
        params: any;
        isExact: boolean;
    };
    history: History;
    token: string;
}
export default class Activate extends React.Component<IActivateProps> {
    public componentDidMount() {
        const token = this.props.match.params.token;
        StorageService.set(API_CACHE_KEYS.TOKEN, token);
        appStore.setIsLoggedIn(true);

        this.props.history.goBack();
    }
    public render() {
        return <div style={style.container}>Loading</div>;
    }
}
