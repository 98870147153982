import React from 'react';

export default class GQTimelineHandle extends React.Component<
    React.HTMLAttributes<HTMLDivElement>
> {
    public render() {
        return (
            <div className="range-handle" {...this.props}>
                <i
                    className="gqi-slide"
                    style={{
                        color: '#384f71',
                        fontSize: 'large',
                        marginTop: '-3px',
                        marginLeft: '-2px',
                    }}
                />
            </div>
        );
    }
}
