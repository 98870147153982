import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import createHashHistory from 'history/createHashHistory';
import { VIEW } from '../interfaces';

class RoutingStore extends RouterStore {
    private previousDifferentViewPath: string = `/${VIEW.RISK_VIEW}`;

    constructor() {
        super();
        const hashHistory = createHashHistory();
        this.history = syncHistoryWithStore(hashHistory, this);
    }

    // location parameter is of type Location, like window.location, but there is an annoying TypeScript error so I just use "any"
    public trackViewChange = (prevLocation: any, curLoaction: any) => {
        const curView = this.getViewTypeFromLocationPath(curLoaction.pathname);
        const prevView = this.getViewTypeFromLocationPath(
            prevLocation.pathname
        );
        if (curView !== prevView) {
            this.previousDifferentViewPath = prevLocation.pathname;
        }
    };

    public goBackToPreviousDifferentView() {
        this.replace(this.previousDifferentViewPath);
    }

    private getViewTypeFromLocationPath(locationPath: string): VIEW {
        if (locationPath.match(VIEW.CREATE_INSIGHT)) {
            // *** NOTICE - because many other view types can be "nested" after 'create_insight' in the URL (e.g. /create_insight/map), this special check and handling needs to be done explicitly (also, don't want / cant rely on the order of the keys in the E_HOME_VIEW enum)
            return VIEW.CREATE_INSIGHT;
        } else {
            for (const viewName in VIEW) {
                const view = VIEW[viewName] as VIEW;
                const regexp = new RegExp(`/${view}(/|$)`);
                if (regexp.test(locationPath)) {
                    return view;
                }
            }
            // This part is a hack for robustness. In case not all views are defined in E_HOME_VIEW, this code is used as a fallback
            const locationParts = locationPath
                .split('/')
                .filter(s => s.length > 0);
            return (locationParts.length > 1
                ? locationParts[1]
                : locationParts[0]) as VIEW;
        }
    }
}

const routingStore = new RoutingStore();
export default routingStore;
