import { observable } from 'mobx';
import { IClosestChartLine } from '../components/GQChart/chartInterfaces';

class AnnotationStore {
    @observable public yScales: {
        [identifier: string]: d3.ScaleLinear<number, number>;
    } = {};
    @observable public xScale: d3.ScaleTime<number, number> = null;
    @observable public closestLine: IClosestChartLine = null;
    @observable public selectedMapCountry: number = null;
}

const store = new AnnotationStore();

export interface IAnnoStore {
    annoStore: typeof store;
}
export default store;
