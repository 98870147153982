import React from 'react';
import * as _ from 'lodash';
import { TweenMax } from 'gsap';
import cx from 'classnames';
import TransitionGroup from 'react-addons-transition-group';
import GQCWMenuItem from './GQCWMenuItem';
import { IPreset, IPresetSub } from '../../../../interfaces';
import { GQButton } from '../../../GQButton';
import GQPopupStorage from '../../../GQPopup/GQPopupStorage';
import { GQMenu, GQMenuItem } from '../../../GQMenu';
import loading from '../../../../services/LoadingService';
import APIService from '../../../../services/APIService';
import ChartScoresService from '../../../../services/ChartScoresService';
import RootStore from '../../../../RootStore';

interface IGQCWAccordionProps {
    preset: IPreset;
    activeID: string;
    lastPresetId?: string;
    onSelect?: (preset?: IPreset) => void;
    onMenuItemClick?: () => void;
    onDeleteClick?: (id: string) => Promise<any>;
    onSubDeleteClick?: (sub: IPresetSub) => Promise<any>;
    onSetActive?: () => void;
    getLastPreset?: () => Promise<IPreset>;
}

interface IGQCWAccordionState {
    expanded: boolean;
    showEllipsis: boolean;
    activeLoading: boolean;
    isActive: boolean;
}

export default class GQCWAccordion extends React.Component<
    IGQCWAccordionProps,
    IGQCWAccordionState
> {
    constructor(props: IGQCWAccordionProps) {
        super(props);

        this.state = {
            expanded: this.props.activeID === this.props.preset.id,
            showEllipsis: false,
            activeLoading: false,
            isActive: this.props.preset.isActive,
        };
    }

    public UNSAFE_componentWillReceiveProps(nextProps: IGQCWAccordionProps) {
        if (this.props.preset) {
            if (
                nextProps.activeID &&
                this.props.preset.id === nextProps.activeID
            ) {
                this.setState({
                    expanded: true,
                });
            } else {
                if (this.state.expanded) {
                    this.setState({
                        expanded: false,
                    });
                }
            }
        }
    }

    public activeIndicator = () => {
        return this.props.preset.isActive ? (
            <i
                className="gqi-selected active-icon"
                style={{
                    paddingTop: 2,
                }}
            />
        ) : (
            <i
                className="gqi-unselected inactive-icon"
                style={{
                    paddingTop: 2,
                    cursor: 'pointer',
                }}
                onClick={this.onSetActive}
            />
        );
    };

    public render() {
        const { preset } = this.props;
        const activeIndicator = this.activeIndicator();

        return (
            <div
                className={cx([
                    'gq-cw-accordion',
                    {
                        expanded: this.state.expanded,
                    },
                ])}>
                <div
                    key={this.props.preset.id}
                    className={cx([
                        'gq-cw-accordion-header',
                        {
                            active:
                                ((!this.props.activeID ||
                                    this.props.activeID === 'null') &&
                                    this.props.preset.id ===
                                        this.props.lastPresetId) ||
                                (this.props.activeID &&
                                    this.props.preset.id ===
                                        this.props.activeID),
                        },
                    ])}
                    onMouseEnter={this.onHover}
                    onMouseLeave={this.onHoverOut}>
                    {activeIndicator}

                    <GQCWMenuItem
                        active={preset.isActive}
                        editing={this.props.activeID === preset.id}
                        displayName={preset.name}
                        id={preset.id}
                        onClick={this.onMenuItemClick}
                        onDelete={this.props.onDeleteClick}
                        onSetActive={this.props.onSetActive}
                        isGQ={!preset.editable}
                        getLastPreset={this.props.getLastPreset}
                    />

                    {this.props.preset &&
                        this.props.preset.subs &&
                        this.props.preset.subs.length > 0 && (
                            <div
                                className="gq-cw-accordion-expand-state"
                                onClick={this.toggleExpand}>
                                <i
                                    className="gqi-dropdown"
                                    style={{
                                        color: '#667588',
                                        fontSize: 24,
                                        padding: 0,
                                    }}
                                />
                            </div>
                        )}

                    {!preset.editable && (
                        <i
                            className="gqi-lock"
                            style={{
                                color: '#667588',
                                paddingLeft: 4,
                                paddingRight: 3,
                            }}
                        />
                    )}

                    <div style={{ width: 15 }}>
                        {this.state.showEllipsis &&
                            !this.props.preset.isActive && (
                                <GQButton
                                    noPadding
                                    noBorder
                                    icon="gqi-ellipsis-v"
                                    onClick={this.toggleMenu}
                                    style={{
                                        marginRight: 0,
                                    }}
                                />
                            )}
                    </div>
                </div>

                <div className="gq-cw-accordion-subs">
                    <TransitionGroup>
                        {this.state.expanded &&
                            _.map(this.props.preset.subs, sub => {
                                const onSubClick = () => {
                                    if (
                                        this.props.activeID ===
                                        this.props.preset.id
                                    ) {
                                        this.scrollContainer(
                                            `#gqCWTree\\:${sub.id.replace(
                                                ':',
                                                '\\:'
                                            )}`
                                        );
                                    } else {
                                        this.props.onMenuItemClick();

                                        setTimeout(() => {
                                            const cont = document.getElementById(
                                                'cwMainContainer'
                                            );
                                            if (cont) {
                                                cont.scrollTop = 0;
                                            }

                                            this.scrollContainer(
                                                `#gqCWTree\\:${sub.id.replace(
                                                    ':',
                                                    '\\:'
                                                )}`
                                            );
                                        }, 0);
                                    }
                                };

                                const onSubDelete = async () => {
                                    return this.props.onSubDeleteClick(sub);
                                };

                                return (
                                    <GQCWMenuItem
                                        key={sub.id}
                                        active={false}
                                        editing={false}
                                        displayName={sub.name}
                                        id={sub.id}
                                        onClick={onSubClick}
                                        onDelete={onSubDelete}
                                        onSetActive={null}
                                        isGQ={!this.props.preset.editable}
                                        isSub={sub.id}
                                        getLastPreset={this.props.getLastPreset}
                                    />
                                );
                            })}
                    </TransitionGroup>
                </div>
            </div>
        );
    }

    private onHover = () => {
        this.setState({
            showEllipsis: true,
        });
    };

    private onHoverOut = () => {
        if (
            this.state.showEllipsis &&
            !GQPopupStorage.isExisting('custom-weights-popup')
        ) {
            this.setState({
                showEllipsis: false,
            });
        }
    };

    private toggleMenu = (e: React.MouseEvent<Element>) => {
        e.stopPropagation();

        if (GQPopupStorage.isExisting('custom-weights-popup')) {
            GQPopupStorage.addData('custom-weights-popup', null);
        } else {
            GQPopupStorage.addData('custom-weights-popup', {
                element: e.currentTarget,
                orientation: 'bottom center',
                tooltipData: (
                    <GQMenu>
                        <GQMenuItem
                            label="Activate"
                            icon="gqi-activate"
                            onClick={this.onSetActive}
                        />

                        {this.props.preset.editable && (
                            <GQMenuItem
                                label="Delete"
                                icon="gqi-delete"
                                onClick={this.onDelete}
                            />
                        )}
                    </GQMenu>
                ),
            });
        }
    };

    private onSetActive = async () => {
        loading.start();
        APIService.clearCountriesData();
        ChartScoresService.clearCache();

        this.setState({
            activeLoading: true,
        });

        try {
            await APIService.setActivePreset(this.props.preset.id);

            this.setState({
                activeLoading: false,
            });

            if (GQPopupStorage.isExisting('custom-weights-popup')) {
                GQPopupStorage.addData('custom-weights-popup', null);
            }

            loading.stop();
        } catch (error) {
            this.setState({
                activeLoading: false,
            });

            loading.stop();

            throw error;
        }

        RootStore.chartStore.increaseSequence();
        APIService.reconnectWebSocketTransport();
        await this.getPresets();
        RootStore.customWeightStore.setCurrentEditingId(this.props.preset.id);
    };

    private async getPresets(isFirst?: boolean) {
        loading.start();

        const presets = await APIService.getMyPresets();
        RootStore.customWeightStore.setPresets(presets);

        if (isFirst) {
            RootStore.customWeightStore.switchToActiveId();
        }

        loading.stop();
    }

    private onDelete = (): any => {
        if (this.props.onDeleteClick) {
            loading.start();

            if (GQPopupStorage.isExisting('custom-weights-popup')) {
                GQPopupStorage.addData('custom-weights-popup', null);
            }

            this.props.onDeleteClick(this.props.preset.id);

            loading.stop();
        }
    };

    private scrollContainer(target: string | number) {
        try {
            TweenMax.to('#cwMainContainer', 0.5, { scrollTo: target } as any);
        } catch (ex) {
            throw ex;
        }
    }

    private onMenuItemClick = () => {
        this.scrollContainer(0);

        if (this.props.onMenuItemClick) {
            this.props.onMenuItemClick();
        }
    };

    private toggleExpand = () => {
        this.setState({
            expanded: !this.state.expanded,
        });
    };
}
