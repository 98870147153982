import * as React from 'react';
import * as _ from 'lodash';

import GQFlag from '../../../../GQFlag';
import GQLoader from '../../../../GQLoader/GQLoader';
import {
    IActiveCountry,
    IActiveGroup,
} from '../../../../../interfaces';
import GQEventEmitter, {
    EventSubscription,
    IArrowKey,
    E_ARROW_KEYS,
} from '../../../../../services/EventsService';
import { inject, observer } from 'mobx-react';
import { IMobxRootState } from 'RootStore';

interface Props {
    show: boolean;
    list: IActiveCountry[];
    activeGroup?: IActiveGroup;
    style?: React.CSSProperties;
    onChange: (id: number) => void;
    countriesArr?: IActiveCountry[];
}

interface IGQCGCountryListState {
    focusedIndex: number;
}

@inject(({ countryStore }: IMobxRootState) => {
    return {
        countriesArr: countryStore.countries.countriesArr,
    };
})
@observer
export default class GQCGCountryList extends React.Component<
    Props,
    IGQCGCountryListState
> {
    private mounted: boolean = false;
    private ulElRef: HTMLUListElement = null;
    private arrowKeyListener: EventSubscription = null;
    private enterKeyListener: EventSubscription = null;
    constructor(props: Props) {
        super(props);
        this.state = {
            focusedIndex: -1,
        };
    }
    public componentDidMount() {
        this.mounted = true;
        this.arrowKeyListener = GQEventEmitter.registerOnArrowKey(
            this.onArrowKey
        );
        this.enterKeyListener = GQEventEmitter.registerOnEnterKey(
            this.onEnterKey
        );
    }
    public componentWillUnmount() {
        this.mounted = false;
        this.arrowKeyListener.remove();
        this.enterKeyListener.remove();
    }
    public render() {
        const noCountryLabel = !!this.props.countriesArr
            ? 'No matches'
            : 'Loading countries';
        return (
            <div
                className="gq-cg-country-list-container"
                style={{ ...this.props.style }}>
                <ul
                    className="gq-cg-country-list"
                    ref={el => (this.ulElRef = el)}>
                    {this.props.list.length > 0 ? (
                        _.map(this.props.list, (c, index) => (
                            <CountryItem
                                key={c.id}
                                id={c.id}
                                name={c.name}
                                isFocused={this.state.focusedIndex === index}
                                onChange={this.props.onChange}
                            />
                        ))
                    ) : (
                        <EmptyItem
                            label={noCountryLabel}
                            showLoader={!this.props.countriesArr}
                        />
                    )}
                </ul>
            </div>
        );
    }
    private onArrowKey = (key: IArrowKey) => {
        if (this.mounted) {
            const range = {
                min: 0,
                max: 0,
            };
            const ulEl = this.ulElRef;
            if (ulEl) {
                range.min = ulEl.scrollTop;
                range.max = ulEl.scrollTop + ulEl.clientHeight;
            }
            switch (key.arrow) {
                case E_ARROW_KEYS.ARROW_DOWN:
                    if (this.state.focusedIndex + 1 < this.props.list.length) {
                        this.setState(
                            {
                                focusedIndex: this.state.focusedIndex + 1,
                            },
                            () => {
                                const item = this.props.list[
                                    this.state.focusedIndex
                                ];
                                if (!item) {
                                    return;
                                }
                                const el = document.getElementById(
                                    'cgListItem' + item.id
                                );
                                if (
                                    el &&
                                    el.offsetTop + el.clientHeight > range.max
                                ) {
                                    ulEl.scrollTop = ulEl.scrollTop + 30;
                                }
                            }
                        );
                    }
                    break;
                case E_ARROW_KEYS.ARROW_UP:
                    if (this.state.focusedIndex - 1 >= 0) {
                        this.setState(
                            {
                                focusedIndex: this.state.focusedIndex - 1,
                            },
                            () => {
                                const item = this.props.list[
                                    this.state.focusedIndex
                                ];
                                if (!item) {
                                    return;
                                }
                                const el = document.getElementById(
                                    'cgListItem' + item.id
                                );
                                if (el && el.offsetTop < range.min) {
                                    ulEl.scrollTop = ulEl.scrollTop - 30;
                                }
                            }
                        );
                    }
                    break;
            }
        }
    };
    private onEnterKey = () => {
        if (this.mounted && this.props.onChange) {
            if (this.props.list.length === 1) {
                this.props.onChange(this.props.list[0].id);
            }
            if (
                this.state.focusedIndex >= 0 &&
                this.state.focusedIndex < this.props.list.length &&
                this.props.list[this.state.focusedIndex]
            ) {
                this.props.onChange(
                    this.props.list[this.state.focusedIndex].id
                );
            }
        }
    };
}

interface ICountryListItem {
    id: number;
    name: string;
    isFocused: boolean;
    onChange: (id: number) => void;
}
const CountryItem = (props: ICountryListItem) => {
    const onClick = (e: React.MouseEvent<HTMLLIElement>) => {
        e.stopPropagation();
        if (props.onChange) {
            props.onChange(props.id);
        }
    };
    return (
        <li
            id={'cgListItem' + props.id}
            onClick={onClick}
            className={props.isFocused ? 'focused' : ''}>
            <GQFlag countryId={props.id} className="flag-icon-squared" />
            <span>{props.name}</span>
            <span style={{ display: 'inline-block', height: '100%' }} />
        </li>
    );
};

const EmptyItem = (props: { showLoader: boolean; label: string }) => {
    return (
        <li style={{ pointerEvents: 'none' }}>
            <span style={{ marginRight: '16px' }}>{props.label}</span>
            {props.showLoader && <GQLoader />}
        </li>
    );
};
