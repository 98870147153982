import * as d3 from 'd3';
import { C_GRAPH } from '../constants';
import { GLOBAL_INDEXES } from '../services/APIService';

export function getMapContainerDimensions() {
    const element = d3.select('#midTopContent').node() as HTMLDivElement;
    return {
        width: element.clientWidth,
        height: element.clientHeight,
    };
}

export function getMapProjection(): d3.GeoProjection {
    return d3
        .geoMercator()
        .center([15, 35])
        .scale(185);
}

export function getGraphHeight(): number {
    const element = d3.select('.chart-container').node() as HTMLDivElement;
    const d = C_GRAPH.Dimensions;
    const sub =
        d.AxisX.Height +
        d.Bottom.Height +
        d.Timeline.Height +
        d.Timeline.Padding.Top;
    return element.clientHeight - sub;
}

interface ICircleBoundProps {
    center: number;
    targetDegree: number;
    radius: number;
    trigoFN: (c: number) => number;
}

export function getCircleBoundPosition(props: ICircleBoundProps) {
    const { center, targetDegree, radius, trigoFN } = props;
    return center + radius * trigoFN(targetDegree * (Math.PI / 180));
}

export const globalRiskColor = (riskId: GLOBAL_INDEXES) => {
    switch (riskId) {
        case 'oil_price_normalized':
            return '#4e52b2';
        case 'oil_price_risk':
            return '#78B53B';
        case 'oil_production_risk':
            return '#C00A3B';
    }
};

export const translateGlobalIndex: { [key: string]: string } = {
    oil_price_normalized: 'Oil Price (Normalized)',
    oil_price_risk: 'Oil Price Risk',
    oil_production_risk: 'Oil Price Risk (Production only)',
};
