import React from 'react';
import { ICustomMapComponentProps } from '../../GQMap/GQMapProvider';

export interface IGQMapNoteAnchorData {
    index: number;
    circleProps?: React.SVGProps<SVGCircleElement>;
}

const NOTE_RADIUS = 10.5;

type GQMapNoteAnchorProps = ICustomMapComponentProps<IGQMapNoteAnchorData>;

export default class GQMapNoteAnchor extends React.Component<
    GQMapNoteAnchorProps
> {
    public render() {
        const { index, circleProps } = this.props.data;
        return (
            <g
                className="gq-map-note-anchor"
                transform={`translate(${this.props.x},${this.props.y})`}>
                <circle r={NOTE_RADIUS} {...circleProps} />
                <text dy={NOTE_RADIUS - 6}>{index}</text>
            </g>
        );
    }
}
