import React, {
    useRef,
    useState,
    useEffect,
    useCallback,
} from 'react';
import { E_RISK_TYPE, IActiveCountry, RISK_CHART_MODES } from 'services/APIService';
import GQFlag from 'components/GQFlag';
import { GQButton, GQButtonTooltip } from 'components/GQButton';
import rootStore from '../../RootStore';
import { HeatmapRisk, HeatmapScoreType } from './HeatmapTypes';
import { useHistory } from 'react-router-dom';
import { VIEW } from 'interfaces';
import moment from 'moment';

type Props = {
    country: IActiveCountry;
    risk: HeatmapRisk;
    riskType: E_RISK_TYPE;
    scoreType: HeatmapScoreType;
    minDate: number;
    numDays: number;
    isAnalyst: boolean;
};

const HeatmapTooltip = React.memo((props: Props) => {
    const { country, risk, riskType, scoreType, minDate, numDays, isAnalyst } = props;
    const containerRef = useRef(null);
    const [isInitialized, setIsInitialized] = useState(false);
    const { push } = useHistory();
    useEffect(() => {
        setIsInitialized(true);
    }, []);

    const updateStoresBySelection = useCallback(() => {
        rootStore.countryStore.changeFirstCountry(country.id);
        rootStore.risksStore.setCurrentRiskType(riskType);
        rootStore.risksStore.currentStore.overrideValues(risk.id);

        const end = minDate;
        const start = moment(end)
            .subtract(numDays, 'days')
            .toDate();
        rootStore.chartStore.setNewRangeByDates(start, new Date(end));
        rootStore.chartStore.setVirtualToday(moment(end));

        if (scoreType === HeatmapScoreType.PULSE) {
            rootStore.risksStore.riskChartMode = RISK_CHART_MODES.PULSE;
        }
    }, [country, risk, riskType, scoreType, minDate, numDays]);
    const onSwitchToGraphViewClick = useCallback(
        (event: React.MouseEvent<HTMLDivElement>) => {
            event.stopPropagation();
            updateStoresBySelection();
            push(`/${VIEW.WORLD_GRAPH}`);
        },
        [push, updateStoresBySelection]
    );

    const onSwitchToInsightsGeneratorClick = useCallback(
        async (event: React.MouseEvent<HTMLDivElement>) => {
            event.stopPropagation();
            updateStoresBySelection();
            push(`/${VIEW.CREATE_INSIGHT}/${VIEW.WORLD_GRAPH}/auto_generate`);
        },
        [push, updateStoresBySelection]
    );

    return (
        <div ref={containerRef}>
            {isInitialized && (
                <GQButtonTooltip
                    offset={{
                        vertical: -5,
                    }}
                    clickable
                    position="top left"
                    speed={300}
                    delay={700}
                    parentProps={containerRef.current}>
                    <div className="heatmap-tooltip-container">
                        <div className="heatmap-tooltip-header">
                            <GQFlag countryId={country.id} />
                            <div className="heatmap-tooltip-country">
                                {country.name}
                            </div>
                        </div>
                        <div className="heatmap-tooltip-dimension">
                            {risk.title}
                        </div>
                        <GQButton
                            caption="Switch to Graph View"
                            onClick={onSwitchToGraphViewClick}
                            style={{ marginBottom: 8 }}
                        />
                        {isAnalyst && (
                            <GQButton
                                caption="Generate Smart Insight"
                                onClick={onSwitchToInsightsGeneratorClick}
                            />
                        )}
                    </div>
                </GQButtonTooltip>
            )}
        </div>
    );
});

export default HeatmapTooltip;
