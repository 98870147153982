import { useEffect } from 'react';
import rootStore from '../RootStore';

const useInitChart = () => {
    useEffect(() => {
        rootStore.chartStore.setState({
            expanded: true,
        });

        return () => {
            rootStore.chartStore.setState({
                expanded: false,
            });
        };
    }, []);
};
export default useInitChart;
