import { IColorsStackSerializedValuesData } from '../services/lib/APIServiceInterfaces';
import { generateNumber } from './generalUtils';
import { flow, get, keyBy, map, mapValues, values } from "lodash/fp";
import { blendHexColors, shadeHexColor } from "utils/generalUtils";

interface IColorsStackValue {
    color: string;
    isRandom: boolean;
}
export default class ColorStack<T> {
    public static generateColor() {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[generateNumber() % 16];
        }
        return color;
    }

    public static fromSerialize<K>(
        colors: Array<IColorsStackSerializedValuesData<K>>
    ): ColorStack<K> {
        const st = new ColorStack<K>();
        colors.forEach(({ color, isRandom, key }) => {
            if (!isRandom) {
                st.colorPalette.delete(color);
            }
            st.usedColors.set(key, {
                color,
                isRandom,
            });
        });
        return st;
    }

    private colorPalette: Set<string> = new Set<string>();
    private usedColors: Map<T, IColorsStackValue> = new Map();
    constructor(colorStack: string[] = defaultColorsStack) {
        this.colorPalette = new Set([...colorStack]);
        this.usedColors = new Map();
    }

    public serialize(): Array<IColorsStackSerializedValuesData<T>> {
        const res: Array<IColorsStackSerializedValuesData<T>> = [];
        this.usedColors.forEach(({ color, isRandom }, key) => {
            res.push({
                key,
                color,
                isRandom,
            });
        });
        return res;
    }

    public color(identifier: T): string {
        if (this.usedColors.has(identifier)) {
            const colorStack = this.usedColors.get(identifier);
            return colorStack ? colorStack.color : ''; //todo: fix fallback color=''
        } else {
            return this.populateColor(identifier);
        }
    }

    public clear() {
        this.usedColors.forEach((_, key) => {
            this.unset(key);
        });
    }

    public unset(identifier: T) {
        if (this.usedColors.has(identifier)) {
            const colorStack = this.usedColors.get(identifier);
            if (!colorStack) {
                return;
            }
            const { color, isRandom } = colorStack;
            if (!isRandom) {
                this.colorPalette.add(color);
            }
            this.usedColors.delete(identifier);
        }
    }

    private populateColor(identifier: T): string {
        let color = Array.from(this.colorPalette).shift();
        let isRandom: boolean;
        if (!color) {
            color = ColorStack.generateColor();
            isRandom = true;
        } else {
            this.colorPalette.delete(color);
            isRandom = false;
        }
        this.usedColors.set(identifier, {
            color,
            isRandom,
        });
        return color;
    }
}

export const customWeightsColorStack: {
    [tier: number]: string[];
} = {
    1: ['#9474ce', '#63b4f7', '#aed581'],
    2: [
        '#9c27b0',
        '#673ab7',
        '#4b5dca',
        '#1d88e5',
        '#069be5',
        '#4baf50',
        '#8ac349',
    ],
    3: [
        '#7b20a2',
        '#6a1c9a',
        '#4a158b',
        '#512da8',
        '#4527a0',
        '#321c92',
        '#3f50b5',
        '#303f9f',
        '#2b3593',
        '#1a247e',
        '#1975d3',
        '#1665c0',
        '#0c47a1',
        '#0587d1',
        '#0277bd',
        '#02579b',
        '#42a046',
        '#388e3c',
        '#2d7d33',
        '#33691f',
        '#7bb341',
        '#679f38',
        '#558a30',
    ],
};

const baseColors = [
    '#e60049',
    '#0bb4ff',
    '#50e991',
    '#e6d800',
    '#9b19f5',
    '#ffa300',
    '#dc0ab4',
    '#b3d4ff',
    '#00bfa0',
];
const colorGroups = flow(
    map(baseColor => ({
        baseColor,
        light: shadeHexColor(baseColor, 0.25),
        lighter: shadeHexColor(baseColor, 0.5),
        orange: blendHexColors(baseColor, '#ffA500', 0.5),
        brown: blendHexColors(baseColor, '#964B00', 0.5),
        dark: shadeHexColor(baseColor, -0.25),
        darker: shadeHexColor(baseColor, -0.5),
    })),
    keyBy('baseColor')
)(baseColors);

//@ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const pickColor = (baseColor, variant) =>{
    return get([baseColor, variant], colorGroups);
}

//@ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const pickGroup = (baseColor) =>{
    return values(get(baseColor, colorGroups));
}

const pickVariant = (variant) =>{
    return values(mapValues(group => get(variant, group), colorGroups));
}

const defaultColorsStack = [
    ...pickVariant('baseColor'),
    ...pickVariant('lighter'),
    ...pickVariant('orange')
];