import React, { CSSProperties, useCallback } from 'react';
import isHotkey from 'is-hotkey';
import { Editable, useSlate, Slate, ReactEditor } from 'slate-react';
import {
    Editor,
    Transforms,
    Descendant,
    Element as SlateElement,
    Range,
} from 'slate';
import { E_COMPONENT_TYPE, IActivityBaseComponent } from 'interfaces';
import EditorImage from './EditorImage';
import { get } from 'lodash/fp';
import EditorIframe from './EditorIframe';
import { GQButton } from 'components/GQButton';
import isUrl from 'is-url';

const HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
};
const LIST_TYPES = ['bulleted-list'];
const BTN_SIZE = 24;

type LinkElement = { type: 'link'; url: string; children: Descendant[] };

type Props = {
    value: Descendant[];
    placeholder?: string;
    onChange?: (value: Descendant[]) => void;
    readOnly?: boolean;
    showToolbar?: boolean;
    getComponentById: (id: string) => IActivityBaseComponent;
    editor: ReactEditor;
    onAutoGenerateClick?: () => void;
    isAnalyst?: boolean;
};
const NewInsightTextEditor = React.memo(
    ({
        value,
        placeholder,
        onChange,
        readOnly,
        showToolbar,
        getComponentById,
        editor,
        onAutoGenerateClick,
        isAnalyst,
    }: Props) => {
        const renderElement = useCallback(
            props => <Element {...props} getComponentById={getComponentById} />,
            [getComponentById]
        );
        const renderLeaf = useCallback(props => <Leaf {...props} />, []);

        const onKeyDown = useCallback(event => {
            event.stopPropagation();
            for (const hotkey in HOTKEYS) {
                if (isHotkey(hotkey, event as any)) {
                    event.preventDefault();
                    const mark = HOTKEYS[hotkey];
                    toggleMark(editor, mark);
                }
            }
        }, [editor]);

        return (
            <Slate editor={editor} value={value} onChange={onChange}>
                {showToolbar && (
                    <InsightToolbar
                        editor={editor}
                        onAutoGenerateClick={onAutoGenerateClick}
                        isAnalyst={isAnalyst}
                    />
                )}
                <Editable
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    placeholder={placeholder}
                    autoFocus
                    readOnly={readOnly}
                    onKeyDown={onKeyDown}
                />
            </Slate>
        );
    }
);

const InsightToolbar = React.memo(
    ({
        editor,
        onAutoGenerateClick,
        isAnalyst,
    }: {
        editor: Editor;
        onAutoGenerateClick: () => void;
        isAnalyst: boolean;
    }) => {
        const onLinkClick = useCallback(() => {
            if (isLinkActive(editor)) {
                unwrapLink(editor);
            } else {
                const url = prompt('Enter a URL');
                insertLink(editor, url);
            }
        }, [editor]);

        return (
            <div className="gq-editor-toolbar flex-container align-right">
                <MarkButton format="bold" icon="gqi-bold" />
                <MarkButton format="italic" icon="gqi-italic" />
                <BlockButton
                    format="heading-one"
                    icon="gqi-size"
                    fontSize={24}
                />
                <BlockButton
                    format="heading-two"
                    icon="gqi-size"
                    fontSize={16}
                />
                <BlockButton format="bulleted-list" icon="gqi-bullet-point" />
                <GQButton
                    style={{ fontSize: BTN_SIZE }}
                    icon="gqi-hyperlink"
                    noBorder
                    toggleButton
                    active={false}
                    onClick={onLinkClick}
                />
                {isAnalyst && (
                    <GQButton
                        style={{ fontSize: BTN_SIZE }}
                        icon="gqi-new-insight"
                        tooltip="Auto generate insight"
                        noBorder
                        toggleButton
                        active={false}
                        onClick={onAutoGenerateClick}
                    />
                )}
            </div>
        );
    }
);

const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(editor, format);
    const isList = LIST_TYPES.includes(format);

    Transforms.unwrapNodes(editor, {
        match: n =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            //@ts-ignore
            LIST_TYPES.includes(n.type),
        split: true,
    });
    const newProperties: Partial<SlateElement> = {
        //@ts-ignore
        type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    };
    Transforms.setNodes<SlateElement>(editor, newProperties);

    if (!isActive && isList) {
        const block = { type: format, children: [] };
        Transforms.wrapNodes(editor, block);
    }
};

const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
        Editor.removeMark(editor, format);
    } else {
        Editor.addMark(editor, format, true);
    }
};

const isBlockActive = (editor, format, blockType = 'type') => {
    const { selection } = editor;
    if (!selection) return false;

    const [match] = Array.from(
        Editor.nodes(editor, {
            at: Editor.unhangRange(editor, selection),
            match: n =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                n[blockType] === format,
        })
    );

    return !!match;
};

const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
};

const Link = props => {
    return (
        <a
            href={props.element.url}
            {...props.attributes}
            className="gq-editor-link"
            target="_blank"
            rel="noopener noreferrer">
            {props.children}
        </a>
    );
};
const Element = props => {
    const { attributes, children, element, getComponentById } = props;
    const style = { textAlign: element.align };
    switch (element.type) {
        case 'gq-insight': {
            const compId = get(['data', 'id'], element);
            const component = getComponentById(compId);
            if (!component) {
                return null;
            }

            if (E_COMPONENT_TYPE.IFRAME === get('type', component)) {
                return <EditorIframe component={component} />;
            }
            return <EditorImage component={component} {...props} />;
        }
        case 'link': {
            return <Link {...props} />;
        }
        case 'heading-one':
            return (
                <h1 style={style} {...attributes}>
                    {children}
                </h1>
            );
        case 'heading-two':
            return (
                <h2 style={style} {...attributes}>
                    {children}
                </h2>
            );
        case 'bulleted-list':
            return (
                <ul
                    className="gq-editor-bullet-list"
                    style={style}
                    {...attributes}>
                    {children}
                </ul>
            );
        case 'list-item':
            return (
                <li style={style} {...attributes}>
                    {children}
                </li>
            );
        case 'numbered-list':
            return (
                <ol style={style} {...attributes}>
                    {children}
                </ol>
            );
        default: {
            return (
                <p style={style} {...attributes}>
                    {children}
                </p>
            );
        }
    }
};

const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>;
    }

    if (leaf.italic) {
        children = <em>{children}</em>;
    }

    if (leaf.underline) {
        children = <u>{children}</u>;
    }

    return <span {...attributes}>{children}</span>;
};

const BlockButton = ({
    format,
    icon,
    fontSize = BTN_SIZE,
}: {
    icon: string;
    format: string;
    fontSize?: CSSProperties['fontSize'];
}) => {
    const editor = useSlate();
    const onMouseDown = useCallback(event => {
        event.preventDefault();
        toggleBlock(editor, format);
    }, [editor, format]);

    return (
        <GQButton
            icon={icon}
            onMouseDown={onMouseDown}
            toggleButton
            noBorder
            active={isBlockActive(editor, format)}
            style={{ fontSize }}
        />
    );
};

const MarkButton = ({
    format,
    icon,
    fontSize = BTN_SIZE,
}: {
    format: string;
    icon: string;
    fontSize?: CSSProperties['fontSize'];
}) => {
    const editor = useSlate();
    const onMouseDown = useCallback(event => {
        event.preventDefault();
        toggleMark(editor, format);
    }, [editor, format]);

    return (
        <GQButton
            icon={icon}
            onMouseDown={onMouseDown}
            toggleButton
            noBorder
            active={isMarkActive(editor, format)}
            style={{ fontSize }}
        />
    );
};

const unwrapLink = editor => {
    Transforms.unwrapNodes(editor, {
        match: n =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            //@ts-ignore
            n.type === 'link',
    });
};

const wrapLink = (editor, url: string) => {
    if (isLinkActive(editor)) {
        unwrapLink(editor);
    }

    const { selection } = editor;
    const isCollapsed = selection && Range.isCollapsed(selection);
    const link: LinkElement = {
        type: 'link',
        url,
        children: isCollapsed ? [{ text: url }] : [],
    };

    if (isCollapsed) {
        Transforms.insertNodes(editor, link);

        // source: https://github.com/geopulseinc/geopulse-front-end/pull/270/files
        if (Range.isCollapsed(selection)) {
            const inline = Editor.above(editor, {
                match: n => Editor.isInline(editor, n),
                mode: 'highest',
            });

            if (inline) {
                const [, inlinePath] = inline;

                const point = Editor.after(editor, inlinePath)!;
                Transforms.setSelection(editor, {
                    anchor: point,
                    focus: point,
                });
                ReactEditor.focus(editor);
            }
        }
    } else {
        Transforms.wrapNodes(editor, link, { split: true });
        Transforms.collapse(editor, { edge: 'end' });
    }
};

const insertLink = (editor, url) => {
    if (editor.selection) {
        wrapLink(editor, url);
    }
};

const isLinkActive = editor => {
    //@ts-ignore
    const [link] = Editor.nodes(editor, {
        match: n =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            //@ts-ignore
            n.type === 'link',
    });
    return !!link;
};

//todo: move to SlateHTML.js and rename file
export const withInlines = editor => {
    const { insertData, insertText, isInline } = editor;

    editor.isInline = element => element.type === 'link' || isInline(element);

    editor.insertText = text => {
        if (text && isUrl(text)) {
            wrapLink(editor, text);
        } else {
            insertText(text);
        }
    };

    editor.insertData = data => {
        const text = data.getData('text/plain');

        if (text && isUrl(text)) {
            wrapLink(editor, text);
        } else {
            insertData(data);
        }
    };

    return editor;
};

export default NewInsightTextEditor;
