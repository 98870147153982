import React, { ReactElement, useState } from 'react';

type Props = {
    text?: string;
    isVisible: boolean;
    width: number;
    height: number;
    onClick?: () => void;
    color: string;
    clickable: boolean;
    tooltip?: () => ReactElement;
    padding?: number;
};

const HeatmapCell = ({
    isVisible,
    width,
    height,
    onClick,
    text,
    color,
    clickable,
    tooltip,
    padding,
}: Props) => {
    const [isHovered, setIsHovered] = useState<boolean>(false);
    return (
        <div
            className="heatmap-cell"
            style={{
                width: text ? 'auto' : `${width + padding * 2}px`,
                height: `${height + padding * 2}px`,
                padding,
            }}
            onClick={clickable && onClick ? onClick : null}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>
            <div
                className="heatmap-cell-circle"
                style={{
                    backgroundColor: isVisible ? color : 'transparent',
                }}>
                {text && <div className="heatmap-cell-text">{text}</div>}
                {isHovered && tooltip && tooltip()}
            </div>
        </div>
    );
};

HeatmapCell.defaultProps = {
    padding: 0,
};

export default HeatmapCell;
