import React from 'react';
import { Descendant } from 'slate';
import { get } from 'lodash/fp';
import { TR, TBody, TD } from './InsightTemplateElements';
import { ComponentsById } from '../InsightTemplateTypes';
import { DEFAULT_FONT_SIZE } from './InsightTemplateConsts';

export const HEADER_TO_FONT_SIZE = {
    'heading-one': DEFAULT_FONT_SIZE * 1.5,
    'heading-two': DEFAULT_FONT_SIZE * 1.25,
};

type Props = {
    nodes: Descendant[];
    componentsById: ComponentsById;
};

const renderLeaf = (leaf: any) => {
    const { text } = leaf;
    if (!text) {
        return null;
    }

    const textStyles: React.CSSProperties = {
        fontFamily:
            '"Open Sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif',
    };

    if (leaf.bold) {
        textStyles.fontWeight = 'bold';
    }
    if (leaf.italic) {
        textStyles.fontStyle = 'italic';
    }

    return text && <span style={textStyles}>{text}</span>;
};

const renderImage = (imageUrl: string) => {
    return (
        <img
            width={600}
            style={{ width: '100%', maxWidth: '600px' }}
            src={imageUrl}
            alt=""
        />
    );
};

const InsightTemplateBody = (props: Props) => {
    const { nodes, componentsById } = props;

    const renderInsight = (node: any) => {
        const itemId = get(['data', 'id'], node);
        const imageUrl = get([itemId, 'url'], componentsById);
        return imageUrl ? renderImage(imageUrl) : null;
    };

    const renderBulletList = (node: any) => {
        return (
            <table style={{ margin: '4px 10px', padding: 0 }}>
                <TBody>
                    {(node.children || []).map(
                        (childNode: any, nodeIndex: number) => {
                            return (
                                <TR key={nodeIndex}>
                                    <td
                                        style={{
                                            width: 10,
                                            margin: 0,
                                            padding: '1px 0 0 0',
                                            verticalAlign: 'top',
                                            fontWeight: 900,
                                        }}>
                                        <span>&bull;</span>
                                    </td>
                                    <td
                                        style={{
                                            margin: 0,
                                            padding: 0,
                                            verticalAlign: 'top',
                                        }}>
                                        {renderElement(childNode)}
                                    </td>
                                </TR>
                            );
                        }
                    )}
                </TBody>
            </table>
        );
    };

    const renderLinkElement = (linkElement: any) => {
        return (
            <a href={linkElement.url}>
                {get(['children', 0, 'text'], linkElement)}
            </a>
        );
    };

    const renderElement = (node: any) => {
        if (!node.children) {
            return renderLeaf(node);
        }

        if (node.type === 'gq-insight') {
            return renderInsight(node);
        }

        if (node.type === 'bulleted-list') {
            return renderBulletList(node);
        }

        const tbodyStyle: React.CSSProperties = {};
        switch (node.type) {
            case 'heading-one':
                tbodyStyle.fontSize = HEADER_TO_FONT_SIZE['heading-one'];
                break;
            case 'heading-two':
                tbodyStyle.fontSize = HEADER_TO_FONT_SIZE['heading-two'];
                break;
        }

        return (
            <table
                style={{
                    margin: 0,
                    borderCollapse: 'separate',
                    borderSpacing: 0,
                }}>
                <TBody style={tbodyStyle}>
                    <TR>
                        <TD
                            style={{
                                padding: 0,
                            }}>
                            {node.children.map(
                                (childNode: any, childIndex: number) => {
                                    return (
                                        <React.Fragment key={childIndex}>
                                            {childNode.type === 'link'
                                                ? renderLinkElement(childNode)
                                                : renderElement(childNode)}
                                        </React.Fragment>
                                    );
                                }
                            )}
                        </TD>
                    </TR>
                </TBody>
            </table>
        );
    };

    return (
        <TR>
            <TD
                style={{
                    padding: 0,
                }}>
                <table
                    style={{
                        fontSize: `${DEFAULT_FONT_SIZE}px`,
                        lineHeight: 1.5,
                    }}>
                    <TBody>
                        {nodes.map((node: Descendant, nodeIndex: number) => {
                            return (
                                // note: using the map index as a React key is bad practice but in this case
                                // it's expensive to provive a unique key for a tree element
                                // (you will need to traverse its entire children path in order to generate a uniuqe identifer)
                                // also - this page is not consumed by the users, rather by puppeteer, so performance is less of an issue here
                                <TR key={nodeIndex}>
                                    <TD width={600} style={{ padding: 0 }}>
                                        {renderElement(node)}
                                    </TD>
                                </TR>
                            );
                        })}
                    </TBody>
                </table>
            </TD>
        </TR>
    );
};

export default InsightTemplateBody;
