import React from 'react';
import bodymovin from 'lottie-web';
import AnimationJson from 'assets/bg/BG.json';
import Logo from 'assets/general/Geoquant_Logo_Digital_White.svg';

export default class AuthPageContainer extends React.Component<any> {
    public componentDidMount() {
        if ((window as any).FS) {
            (window as any).FS.shutdown();
        }

        bodymovin.loadAnimation({
            container: document.getElementById('auth-page-animation'),
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: AnimationJson,
            rendererSettings: {
                preserveAspectRatio: 'none',
                clearCanvas: false,
            },
        });
    }

    public render() {
        const { children } = this.props;

        return (
            <div className="auth-page-container">
                <div
                    className="auth-page-container__animation"
                    id="auth-page-animation"></div>

                <img className="auth-page-container__logo" src={Logo} alt="logo" />

                {children}
            </div>
        );
    }
}
