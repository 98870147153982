import React from 'react';
import { IActiveGroup } from '../../interfaces';

export interface IGroupSectionProps {
    groups?: IActiveGroup[];
    selectedGroup?: string;
    activeGroupScroll?: number;
    onGroupClick: (id: string, scroll: number) => void;
}
export interface IGroupSectionState {
    isOverFlow: boolean;
    scrollLeft: number;
    activePos: number;
    container: HTMLDivElement;
}
export interface IGroupSectionStore {
    mouseDownStart: Date;
    mouseUpEnd: Date;
}

class GroupSection extends React.Component<
    IGroupSectionProps,
    IGroupSectionState
> {
    public selectedRef: HTMLDivElement = null;
    constructor(props: IGroupSectionProps) {
        super(props);
        this.state = {
            isOverFlow: false,
            scrollLeft: this.props.activeGroupScroll,
            activePos: 0,
            container: null,
        };
    }

    public render(): JSX.Element | true {
        const clickHandler = (group: IActiveGroup): any => {
            this.props.onGroupClick(group.id, this.state.scrollLeft);
            this.setState({
                activePos: this.state.scrollLeft,
            });
        };
        const handleMoveToRight = () => {
            this.state.container.scrollLeft +=
                this.state.container.clientWidth / 2;
            this.setState({
                scrollLeft: this.state.container.scrollLeft,
                activePos: this.state.container.scrollLeft,
            });
        };
        const handleMoveToleft = () => {
            this.state.container.scrollLeft -=
                this.state.container.clientWidth / 2;
            this.setState({
                scrollLeft: this.state.container.scrollLeft,
                activePos: this.state.container.scrollLeft,
            });
        };
        const isOverFlow = () => {
            return (
                this.state.container.clientWidth <
                this.state.container.scrollWidth
            );
        };
        const { groups, selectedGroup } = this.props;
        return (
            <div
                className="groups-section-dropdown"
                ref={el => {
                    if (!el || this.state.container) {
                        return;
                    }
                    el.scrollLeft += this.props.activeGroupScroll;
                    this.setState({ container: el });
                }}>
                {groups
                    ? groups.map(group => (
                          <div
                              key={group.id}
                              className={
                                  group.id === selectedGroup ? 'selected' : ''
                              }
                              ref={
                                  group.id === selectedGroup
                                      ? el => (this.selectedRef = el)
                                      : ''
                              }
                              onClick={clickHandler.bind(this, group)}>
                              {' '}
                              {group.name}{' '}
                          </div>
                      ))
                    : ''}
                {this.state.container &&
                    isOverFlow() &&
                    this.state.scrollLeft <
                        this.state.container.scrollWidth -
                            this.state.container.clientWidth && (
                        <span
                            onClick={handleMoveToRight}
                            className="arrow-right">
                            <span className="arrow" />
                        </span>
                    )}
                {this.state.container && this.state.scrollLeft > 0 && (
                    <span onClick={handleMoveToleft} className="arrow-left">
                        <span className="arrow" />
                    </span>
                )}
            </div>
        );
    }
}

export default GroupSection as React.ComponentClass<IGroupSectionProps>;
