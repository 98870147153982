import React, { ReactNode, CSSProperties } from 'react';

export const TR = ({ children }: { children: ReactNode }) => {
    return <tr style={{ backgroundColor: 'white' }}>{children}</tr>;
};

type Align = 'left' | 'center' | 'right' | 'justify' | 'char';

type VAlign = 'top' | 'middle' | 'bottom' | 'baseline';

export const TD = ({
    children,
    align,
    valign,
    style = {},
    width,
}: {
    children: ReactNode;
    align?: Align;
    valign?: VAlign;
    style?: CSSProperties;
    width?: number;
}) => {
    return (
        <td
            align={align}
            valign={valign}
            style={{ height: 24, padding: 0, ...style }}
            //@ts-ignore
            width={width}>
            {children}
        </td>
    );
};

export const TBody = ({
    children,
    style = {},
}: {
    children: ReactNode;
    style?: CSSProperties;
}) => {
    return (
        <tbody style={{ border: 'none', backgroundColor: 'white', ...style }}>
            {children}
        </tbody>
    );
};

export const Separator = () => {
    return (
        <td style={{ padding: 0, maxWidth: 600 }}>
            <img
                alt="separator"
                style={{ maxWidth: '100%' }}
                src="https://gq-static-content-production.s3.amazonaws.com/red-gradient-seperator.png"
            />
        </td>
    );
};

export const InsightsLogo = () => (
    <TD align="center" style={{ maxWidth: 600 }}>
        <img
            alt="logo"
            src="https://gq-static-content-production.s3.amazonaws.com/logo-red.png"
            style={{
                maxWidth: '100%',
                maxHeight: 70,
            }}
        />
    </TD>
);
