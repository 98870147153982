import React from 'react';
import cx from 'classnames';

import { ICWChangeNotification, E_CW_STATUS } from '../interfaces';
import GQButton from './GQButton/GQButton';
import { observer } from 'mobx-react';

interface IActiveWeightProps {
    preset: ICWChangeNotification;
    active: boolean;
    onClick: () => void;
}

@observer
export default class ActiveWeight extends React.Component<IActiveWeightProps> {
    public render() {
        const { progress, status } = this.props.preset;
        return (
            <div className="gq-active-weight flex-container">
                <div className="gq-active-weight-temp">
                    <GQButton
                        icon="gqi-lens"
                        onClick={this.props.onClick}
                        active={this.props.active}
                        disabled={status && status !== E_CW_STATUS.READY}
                        tooltip="Compare to Geoquant"
                        noBorder={true}
                        tooltipPosition="top left"
                    />
                </div>
                <div
                    className={cx([
                        'gq-active-weight-name',
                        { active: this.props.active },
                    ])}>
                    {this.resolveName()}
                </div>

                {status && status !== E_CW_STATUS.READY && (
                    <div className="gq-active-weight-details flex-container align-left flex-dir-column">
                        <div
                            className="gq-active-weight-details-name"
                            title={this.props.preset.activePreset}>
                            <span>
                                {this.props.preset.status &&
                                this.props.preset.status === E_CW_STATUS.PENDING
                                    ? `Loading: ${this.props.preset.activePreset}`
                                    : this.props.preset.activePreset}
                            </span>
                        </div>
                        <div className="gq-active-weight-details-progress">
                            <div
                                className="gq-active-weight-details-progress-bar"
                                style={{
                                    width: `${progress ? progress * 100 : 0}%`,
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    }
    private resolveName() {
        const { activePreset, status } = this.props.preset;
        if (status && status === E_CW_STATUS.READY) {
            if (this.props.active) {
                return (
                    <div className="flex-container align-middle-center">
                        <div
                            className={cx([
                                'gq-active-weight-border-left',
                                { active: this.props.active },
                            ])}
                            style={{ marginRight: 8 }}>
                            {activePreset}
                        </div>
                        <div>Geoquant</div>
                    </div>
                );
            } else {
                return activePreset;
            }
        } else {
            return (
                <div
                    className={cx([
                        'gq-active-weight-border-left',
                        { active: this.props.active },
                    ])}>
                    Geoquant
                </div>
            );
        }
    }
}
