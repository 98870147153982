import GQFlag from 'components/GQFlag';
import React from 'react';
import CheckBox from './CheckBox';

type Props = {
    id: number;
    name: string;
    isMultiSelect: boolean;
    isSelected: boolean;
};

const CountryPickerListItem = (props: Props) => {
    const { id, name, isMultiSelect, isSelected } = props;

    return (
        <div className="new-country-picker-list-item-container">
            <div className="new-country-picker-list-item-flag-and-text-container">
                <GQFlag
                    countryId={id}
                    className="flag-icon-squared new-country-picker-list-item-flag"
                />
                <span className="new-country-picker-list-item-text">
                    {name}
                </span>
            </div>
            <CheckBox isRadioButton={!isMultiSelect} isSelected={isSelected} />
        </div>
    );
};

export default CountryPickerListItem;
