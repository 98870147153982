import React from 'react';
import GQFlag from '../GQFlag';
import { inject, observer } from 'mobx-react';
import { IMobxRootState } from 'RootStore';
import { IActiveCountryMap } from 'services/APIService';

type Props = {
    selectedCountries?: number[];
    FirstCountry?: string;
    allCountries?: IActiveCountryMap;
};

@inject(({ countryStore }: IMobxRootState) => {
    return {
        allCountries: countryStore.countries.allCountries,
    };
})
@observer
export default class EventsListHeader extends React.Component<Props> {
    public render(): JSX.Element | true {
        const country = this.country;
        if (!country) {
            return (
                <div className="eventsHeaderTitle noFlag no-border">
                    <div className="flagsWrapper">
                        <i aria-hidden={true} className="gqi-world" />
                    </div>
                    <span className="eventsTitle">
                        <span>Worldwide Events</span>
                    </span>
                </div>
            );
        }
        const { selectedCountries } = this.props;
        return (
            <div className="eventsHeaderTitle no-border">
                <div className="flagsWrapper">
                    <GQFlag
                        countryId={selectedCountries[0]}
                        key={selectedCountries[0]}
                    />
                    <GQFlag
                        countryId={selectedCountries[1]}
                        key={selectedCountries[1]}
                    />
                    <GQFlag
                        countryId={selectedCountries[2]}
                        key={selectedCountries[2]}
                    />
                    <span
                        className={
                            selectedCountries.length < 4
                                ? 'hidden countryNumbers'
                                : 'countryNumbers'
                        }>
                        {' '}
                        +{selectedCountries.length - 3}
                    </span>
                </div>
                <span className="eventsTitle">
                    <span
                        className={
                            selectedCountries.length !== 1 ? 'hidden' : ''
                        }>
                        {' '}
                        &nbsp;{country.name}
                    </span>
                    &nbsp;Events
                </span>
            </div>
        );
    }
    private get country() {
        if (!this.props.allCountries) {
            return null;
        }
        return this.props.allCountries[this.props.selectedCountries[0]];
    }
}
