import { observable, action } from 'mobx';
import { IActiveGroup } from '../../../../interfaces';

export class CountryGroupsStore {
    @observable public activeGroup: IActiveGroup = null;
    @observable public selectedIds: number[];
    private tempMap: Map<number, boolean> = new Map();

    @action public toggleId(id: number) {
        this.tempMap.clear();
        if (!this.activeGroup) {
            return;
        }
        for (const id of this.selectedIds) {
            this.tempMap.set(id, true);
        }
        if (this.tempMap.has(id)) {
            this.tempMap.delete(id);
        } else {
            this.tempMap.set(id, true);
        }
        const tempArr: number[] = [];
        this.tempMap.forEach((val, id) => {
            tempArr.push(id);
        });
        this.selectedIds = tempArr;
    }

    @action public setActiveGroup(group: IActiveGroup) {
        this.activeGroup = { ...group };
        this.selectedIds = this.activeGroup.countries;
    }
}

const countryGroupStore = new CountryGroupsStore();

export interface ICGStore {
    cgStore: typeof countryGroupStore;
}
export default countryGroupStore;
