import React, { useState } from 'react';
import { inject } from 'mobx-react';
import { IMobxRootState } from '../../../RootStore';
import PastScroeItem from './GQPastScroeItem';
import { IEventUScore } from '../../../interfaces';

export type IGQPastScoreListProps = {
    title: string;
    scores: IEventUScore[];
    canUserEdit?: boolean;
    canUserDelete?: boolean;
    onEdit?: (score: IEventUScore) => void;
    onDelete?: (score: IEventUScore) => void;
    editMode?: boolean;
    organizationId?: string;
};

const GQPastScoreList = inject(({ UserStore }: IMobxRootState) => {
    return {
        canUserEdit: UserStore.uscoresPermissions.can_edit,
        canUserDelete: UserStore.uscoresPermissions.can_delete,
        organizationId: UserStore.organization.id,
    };
})(
    ({
        title,
        scores,
        editMode,
        canUserEdit,
        canUserDelete,
        onDelete,
        onEdit,
        organizationId,
    }: IGQPastScoreListProps) => {
        const [isItemMenuOpen, setIsItemMenuOpen] = useState(false);

        if (!scores || !scores.length) return null;

        const canEdit = (uscoreOrganizationId: string) => {
            return canUserEdit && organizationId === uscoreOrganizationId;
        };

        const canDelete = (uscoreOrganizationId: string) => {
            return canUserDelete && organizationId === uscoreOrganizationId;
        };

        const onDeleteScore = (uscore: IEventUScore) => {
            setIsItemMenuOpen(false);
            onDelete(uscore);
        };

        const onEditScore = (uscore: IEventUScore) => {
            setIsItemMenuOpen(false);
            onEdit(uscore);
        };

        const setMenuVisibility = (isVisible: boolean) => {
            setIsItemMenuOpen(isVisible);
        };

        return (
            <div className="past-score-list">
                <div className="past-score-header">
                    <span>{title}</span>
                </div>
                {scores.map((score: IEventUScore) => (
                    <PastScroeItem
                        pastScoreItem={score}
                        key={score.score_id}
                        canEdit={canEdit(score.organization_id)}
                        canDelete={canDelete(score.organization_id)}
                        onDelete={onDeleteScore}
                        onEdit={onEditScore}
                        canShowMenu={!isItemMenuOpen}
                        notifyMenuDispaly={setMenuVisibility}
                        editMode={editMode}
                    />
                ))}
            </div>
        );
    }
);

export default GQPastScoreList;
