import * as _ from 'lodash';
import { customWeightsColorStack } from 'utils/ColorStack';

interface IUsedPalette {
    [path: string]: {
        [id: number]: string;
    };
}

export default class CWRenderUtil {
    private static colorPalette = _.cloneDeep(customWeightsColorStack);
    private static usedPalette: IUsedPalette = {};

    public getColor(id: number, tier: number, path: number[]) {
        const strPath = path.join('');

        if (CWRenderUtil.usedPalette[strPath]) {
            const p = CWRenderUtil.usedPalette[strPath];

            if (!p[id]) {
                this.generateColor(id, tier, strPath);
            }

            return p[id];
        } else {
            this.generateColor(id, tier, strPath);

            return CWRenderUtil.usedPalette[strPath][id];
        }
    }

    private generateColor(id: number, tier: number, path: string) {
        if (CWRenderUtil.usedPalette[path]) {
            CWRenderUtil.usedPalette[path][id] = CWRenderUtil.colorPalette[
                tier
            ].shift();
        } else {
            CWRenderUtil.usedPalette[path] = {
                [id]: CWRenderUtil.colorPalette[tier].shift(),
            };
        }
    }
}
