import { initiate } from './services/APIService';
import { useStrict as mobxUseStrict } from 'mobx';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

mobxUseStrict(true);
initiate(false);

ReactDOM.render(<App />, document.getElementById('root'));
