import React from 'react';
import cx from 'classnames';
import rootStore from '../RootStore';
import GQPopupStorage from './GQPopup/GQPopupStorage';
export interface IGQFlagProps {
    [key: string]: any;

    countryId: number;
    hideTooltip?: boolean;
    onClick?: (...params: any[]) => any;
    style?: React.CSSProperties;
    options?: {
        round?: boolean;
    };
    className?: string;
    id?: string;
    getFlagFromAll?: boolean;
}

type IProps = IGQFlagProps;

interface IGQFlagState {
    isHovering: boolean;
}
class GQFlag extends React.PureComponent<IProps, IGQFlagState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            isHovering: false,
        };
    }

    public componentWillUnmount() {
        if (this.state.isHovering) {
            GQPopupStorage.addData('gq-flag', null);
        }
    }

    public render(): JSX.Element | false | null {
        const country = this.country;
        if (!country) {
            return null;
        }

        const classList: string[] = [];
        classList.push(
            `flag-icon flag-icon-${country.abbreviation_short.toLowerCase()}`
        );
        classList.push(this.props.className ? this.props.className : '');
        return (
            <span
                id={this.props.id}
                style={{ ...this.props.style }}
                className={cx([
                    `gq-flag flag-icon flag-icon-${country.abbreviation_short.toLowerCase()}`,
                    {
                        round: this.props.options && this.props.options.round,
                        [this.props.className]: !!this.props.className,
                    },
                ])}
                onClick={this.props.onClick ? this.props.onClick : null}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
            />
        );
    }

    private onMouseEnter = (e: React.MouseEvent<HTMLSpanElement>) => {
        if (this.props.hideTooltip) {
            return;
        }
        const country = this.country;
        if (!country) {
            return;
        }
        const target = e.currentTarget as HTMLSpanElement;
        this.setState(
            {
                isHovering: true,
            },
            () => {
                GQPopupStorage.addData('gq-flag', {
                    element: target,
                    orientation: 'bottom right',
                    tooltipData: (
                        <span className="gq-flag-popup">{country.name}</span>
                    ),
                });
            }
        );
    };

    private get country() {
        if (!rootStore.countryStore.countries) {
            return null;
        }
        return rootStore.countryStore.countries.allCountries[
            this.props.countryId
        ];
    }

    private onMouseLeave = (e: React.MouseEvent<HTMLSpanElement>) => {
        if (this.props.hideTooltip) {
            return;
        }
        this.setState(
            {
                isHovering: false,
            },
            () => {
                GQPopupStorage.addData('gq-flag', null);
            }
        );
    };
}

export default GQFlag as React.ComponentClass<IGQFlagProps>;
