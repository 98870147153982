import { action, observable } from 'mobx';
import { IEvent, IRootStoreExporter, IActivityRecord } from '../../interfaces';

export class ScoreEventStore implements IRootStoreExporter {
    @observable public activeEvent: IEvent = null;
    public snapshot: IActivityRecord = null;
    @action public setActiveEvent(event: IEvent) {
        this.activeEvent = event;
    }

    public exportKeys(): string[] {
        return [];
    }
}

export default new ScoreEventStore();
