import React from 'react';
import classNames from 'classnames';
import { TweenMax } from 'gsap';
import GQButton from '../../../GQButton/GQButton';
import { GQMenu, GQMenuItem } from '../../../GQMenu';
import GQPopupStorage from '../../../GQPopup/GQPopupStorage';
import Emitter, { GQEmitterEvents } from '../../../../services/EventsService';
import { ITransitionGroupComponent, IPreset } from '../../../../interfaces';
import loading from '../../../../services/LoadingService';

interface IGQCWMenuItemProps {
    id: string;
    active: boolean;
    editing: boolean;
    isGQ: boolean;
    displayName: string;
    hasSubs?: any;
    isSub?: string;
    onSetActive: (id: string) => void;
    onClick: (id: string) => void;
    onDelete: (id: string) => Promise<any>;
    getLastPreset: () => Promise<IPreset>;
}

interface IGQCWMenuItemState {
    isLoading: boolean;
    showDeleteConfirm: boolean;
    showDeleteProgress: boolean;
    showEllipsis: boolean;
}

export default class GQCWMenuItem
    extends React.Component<IGQCWMenuItemProps, IGQCWMenuItemState>
    implements ITransitionGroupComponent {
    private confirmDelRef: HTMLDivElement = null;
    private itemRef: HTMLDivElement = null;
    private mounted = false;

    constructor(props: IGQCWMenuItemProps) {
        super(props);

        this.state = {
            isLoading: false,
            showDeleteConfirm: false,
            showDeleteProgress: false,
            showEllipsis: false,
        };
    }

    public componentWillEnter(cb: any) {
        const el = this.itemRef;
        TweenMax.from(el, 0.5, { y: '-=15', opacity: 0, onComplete: cb });
    }

    public componentWillLeave(cb: any) {
        const el = this.itemRef;
        TweenMax.to(el, 0.5, {
            y: '-=15',
            opacity: 0,
            onComplete: cb,
        } as any);
    }
    public UNSAFE_componentWillReceiveProps(nextProps: IGQCWMenuItemProps) {
        if (this.props.active !== nextProps.active) {
            this.setState({
                isLoading: false,
            });
        }
    }

    public componentDidMount() {
        Emitter.registerEvent(
            GQEmitterEvents.MOUSE_LEFT_CLICK,
            this.hideConfirm
        );
        Emitter.registerOnDeleteClick(this.shouldHideDelete);
    }

    public componentWillUnmount() {
        this.mounted = false;
    }

    public render() {
        return (
            <div
                ref={el => (this.itemRef = el)}
                className={classNames([
                    'gq-cw-menu-item',
                    {
                        editing: this.props.editing,
                    },
                ])}
                onClick={this.onItemClick}
                onMouseEnter={this.onHover}
                onMouseLeave={this.onHoverOut}>
                <span className="gq-cw-menu-item-caption">
                    {this.props.displayName}
                </span>

                <div style={{ width: 15 }}>
                    {this.props.isSub && this.state.showEllipsis && (
                        <GQButton
                            noPadding
                            noBorder
                            icon="gqi-ellipsis-v"
                            onClick={this.toggleMenu}
                            style={{
                                paddingRight: 0,
                                marginRight: -70,
                            }}
                        />
                    )}
                </div>
            </div>
        );
    }

    private onHover = () => {
        this.setState({
            showEllipsis: true,
        });
    };

    private onHoverOut = () => {
        if (
            this.state.showEllipsis &&
            !GQPopupStorage.isExisting('custom-weights-popup')
        ) {
            this.setState({
                showEllipsis: false,
            });
        }
    };

    private toggleMenu = (e: React.MouseEvent<Element>) => {
        e.stopPropagation();

        if (GQPopupStorage.isExisting('custom-weights-popup')) {
            GQPopupStorage.addData('custom-weights-popup', null);
        } else {
            GQPopupStorage.addData('custom-weights-popup', {
                element: e.currentTarget,
                orientation: 'bottom center',
                offset: {
                    horizontal: 35,
                },
                tooltipData: (
                    <GQMenu>
                        {!this.props.isGQ && (
                            <GQMenuItem
                                label="Delete"
                                icon="gqi-delete"
                                onClick={this.onDelete}
                            />
                        )}
                    </GQMenu>
                ),
            });
        }
    };

    private onDelete = (): any => {
        if (this.props.onDelete) {
            loading.start();

            if (GQPopupStorage.isExisting('custom-weights-popup')) {
                GQPopupStorage.addData('custom-weights-popup', null);
            }

            this.props.onDelete(this.props.id);
            this.props.getLastPreset();
            loading.stop();
        }
    };

    private shouldHideDelete = (id: string) => {
        if (this.props.id !== id) {
            this.hideConfirm();
        }
    };

    private onItemClick = (e: React.MouseEvent<HTMLDivElement>) => {
        this.props.onClick(this.props.id);
    };

    private hideConfirm = () => {
        if (this.mounted) {
            this.setState(
                {
                    showDeleteConfirm: false,
                },
                () => this.animateConfirm(true)
            );
        }
    };

    private animateConfirm = (leave?: boolean) => {
        if (leave) {
            TweenMax.to(this.confirmDelRef, 0.5, {
                opacity: 0,
                width: 0,
            } as any);
            return;
        }

        TweenMax.to(this.confirmDelRef, 0.5, {
            opacity: 1,
            width: '78px',
        } as any);
    };
}
