import React from 'react';
import SpecificActivityStore from '../../../stores/SpecificActivityStore';
import IGQInsightComponentListItem from './InsightComponentListItem';
import { observer } from 'mobx-react';
import { TimelineLite, TweenLite } from 'gsap';
import { TransitionGroup, Transition } from 'react-transition-group';
import { ActivityComponentStore } from '../../../stores/insights/InsightsComponentStore';

interface IGQInsightComponentListProps {
    activity: SpecificActivityStore;
    onClick: (store: ActivityComponentStore) => void;
    onRemoveComponent: (id: string) => void;
    activeComponentId: string;
    isUsed: (id: string) => boolean;
}

@observer
export default class GQInsightComponentList extends React.Component<
    IGQInsightComponentListProps
> {
    private listEl: HTMLDivElement;
    public render() {
        const list = this.props.activity.components.map((c, i) => {
            return (
                <Transition
                    key={c.htmlKey}
                    timeout={500}
                    onEnter={this.onComponentEnter}
                    onExit={this.onComponentExit}>
                    <IGQInsightComponentListItem
                        component={c}
                        onClick={this.props.onClick}
                        onRemoveComponent={this.props.onRemoveComponent}
                        usedInText={this.props.isUsed(c.id)}
                        isEditingComponent={
                            c.id === this.props.activeComponentId
                        }
                    />
                </Transition>
            );
        });
        return (
            <div className="gq-insight-component-list-container ">
                <div
                    ref={el => (this.listEl = el)}
                    className="gq-insight-component-list flex-container">
                    <TransitionGroup component={null}>{list}</TransitionGroup>
                </div>
            </div>
        );
    }
    private onComponentEnter = (node: HTMLElement) => {
        if (!node.id.includes('temp')) {
            return;
        }
        new TimelineLite()
            .set(node, { clearProps: 'x, y, scale' })
            .set(node, { position: 'fixed', pointerEvents: 'none' })
            .from(node, 0.6, {
                x: '-500',
                y: '-500',
                scale: 8,
                onComplete: this.resetNodePos,
                onCompleteParams: [node],
            });
    };
    private onComponentExit = (node: HTMLElement) => {
        if (node.id.includes('temp')) {
            TweenLite.set(node, { display: 'none' });
        } else {
            TweenLite.set(node, { clearProps: 'x, y, scale, opacity' });
            TweenLite.to(node, 0.5, { scale: 0, opacity: 0 });
        }
    };
    private resetNodePos = (node: HTMLElement) => {
        if (this.listEl) {
            this.listEl.scrollLeft = this.listEl.scrollWidth;
        }
        TweenLite.set(node, { clearProps: 'all' });
    };
}
