import React from 'react';
import { C_SCORE_SLIDER_RADIUS } from '../../constants';
import * as _ from 'lodash';
import { scaleLinear } from 'd3';
import classNames from 'classnames';
import CancelableEvents from 'utils/CancelableEvents';

interface IScoreEventSliderProps {
    id: string;
    values: number[];
    onDrag: (value: number) => void;
    exactValue?: number;
    onDragStops?: () => void;
}

interface IScoreEventSliderState {
    currentPosition: number;
    dragging: boolean;
    currentRadius: number;
}
export default class ScoreEventSlider extends React.Component<
    IScoreEventSliderProps,
    IScoreEventSliderState
> {
    private cancelableEvents = new CancelableEvents();

    constructor(props: IScoreEventSliderProps) {
        super(props);
        this.state = {
            currentPosition: 50,
            currentRadius: 0,
            dragging: false,
        };
    }

    public componentDidUpdate(
        props: IScoreEventSliderProps,
        state: IScoreEventSliderState
    ) {
        if (this.state.dragging && !state.dragging) {
            document.addEventListener('mousemove', this.onMouseMove as any);
            document.addEventListener('mouseup', this.onMouseUp as any);
        } else if (!this.state.dragging && state.dragging) {
            document.removeEventListener('mousemove', this.onMouseMove as any);
            document.removeEventListener('mouseup', this.onMouseUp as any);
        }

        if (
            props.exactValue !== this.props.exactValue &&
            this.props.exactValue !== null &&
            !this.state.dragging
        ) {
            const currentPosition = this.calcPositionForValue(
                this.props.exactValue
            );
            this.setState(
                {
                    currentPosition,
                },
                () => {
                    this.cancelableEvents.setTimeout(() => {
                        this.props.onDrag(this.calcValue());
                        this.props.onDragStops();
                    }, 100);
                }
            );
        }
    }

    public componentDidMount() {
        if (!_.isNil(this.props.exactValue)) {
            const currentPosition = this.calcPositionForValue(
                this.props.exactValue
            );
            this.setState(
                {
                    currentPosition,
                },
                () => {
                    this.cancelableEvents.setTimeout(() => {
                        this.props.onDrag(this.calcValue());
                        this.props.onDragStops();
                    }, 100);
                }
            );
        }
    }

    public componentWillUnmount() {
        this.cancelableEvents.cancelAll();
    }

    public render() {
        const style: React.CSSProperties = {
            width: C_SCORE_SLIDER_RADIUS,
            height: C_SCORE_SLIDER_RADIUS,
            borderRadius: '50%',
            backgroundColor: 'white',
            position: 'relative',
            left: `${this.state.currentPosition - this.state.currentRadius}%`,
            bottom: '4px',
        };
        return (
            <div id={this.props.id} style={{ height: 20, width: '100%' }}>
                <div
                    style={style}
                    onMouseDown={this.onMouseDown}
                    className={classNames(['score-drag-handle'], {
                        dragging: this.state.dragging,
                    })}
                />
            </div>
        );
    }

    private calcValue() {
        const { values } = this.props;
        const scale = scaleLinear()
            .domain([0, values.length - 1])
            .range([0, 100]);
        return values[Math.round(scale.invert(this.state.currentPosition))];
    }

    private calcPositionForValue(value: number) {
        let index = this.props.values.indexOf(value);
        if (index < 0) {
            // We encountered cases where value is old and not with valid exact values, so we'll look for the closest
            const closestBiggerIndex = this.props.values.findIndex(
                v => v > value
            );
            if (closestBiggerIndex < 0) {
                index = this.props.values.length - 1;
            } else if (closestBiggerIndex === 0) {
                index = 0;
            } else {
                const biggerDiff =
                    this.props.values[closestBiggerIndex] - value;
                const smallerDiff =
                    value - this.props.values[closestBiggerIndex - 1];
                index =
                    biggerDiff < smallerDiff
                        ? closestBiggerIndex
                        : closestBiggerIndex - 1;
            }
        }
        const scale = scaleLinear()
            .domain([0, this.props.values.length - 1])
            .range([0, 100]);
        return scale(index);
    }

    private onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        if (e.button !== 0) {
            return;
        }
        this.setState({
            dragging: true,
        });
        e.stopPropagation();
        e.preventDefault();
    };

    private onMouseUp = (e: MouseEvent | React.MouseEvent<HTMLDivElement>) => {
        this.setState({ dragging: false });
        this.props.onDragStops();
        e.stopPropagation();
        e.preventDefault();
    };

    private onMouseMove = (e: MouseEvent) => {
        if (!this.state.dragging) {
            return;
        }
        const container = document.querySelector(
            `#${this.props.id}`
        ) as HTMLDivElement;
        if (container) {
            const { left, width } = container.getBoundingClientRect();
            let currentPosition = ((e.clientX - left) / width) * 100;
            if (currentPosition < 0) {
                currentPosition = 0;
            }
            if (currentPosition > 100) {
                currentPosition = 100;
            }
            this.setState(
                {
                    currentRadius: (C_SCORE_SLIDER_RADIUS / (2 * width)) * 100,
                    currentPosition,
                },
                () => {
                    this.props.onDrag(this.calcValue());
                }
            );
        }
        e.stopPropagation();
        e.preventDefault();
    };
}
