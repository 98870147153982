import React from 'react';
import GQButton from '../GQButton/GQButton';
import { observer, inject } from 'mobx-react';
import rootStore, { IMobxRootState } from '../../RootStore';
import { isHeadlessMode } from '../../services/configService';
import {
    ICWChangeNotification,
    ExternalDataItemID,
    VIEW,
} from '../../interfaces';
import GQPopupStorage from '../GQPopup/GQPopupStorage';
import { GLOBAL_INDEXES, IActiveCountry, RISK_CHART_MODES } from '../../services/APIService';
import CancelableEvents from '../../utils/CancelableEvents';
import EventsService from '../../services/EventsService';
import ExternalDataPopup from '../GQexternalDataPopup/ExternalDataPopup';
import { globalRiskColor } from '../../utils/renderUtils';
import chartStore from './ChartStore';
import GQFlag from '../GQFlag';
import ActiveWeight from '../ActiveWeight';
import GQAccordion from 'components/GQAccordion/GQAccordion';

type Props = {
    onExpand: () => void;
    currentCountryList?: number[];
    expanded?: boolean;
    showLines?: boolean;
    showCircles?: boolean;
    isOpaque?: boolean;
    currencyOn?: boolean;
    projectionDataOn?: boolean;
    riskChartMode?: RISK_CHART_MODES;
    showGQLine?: boolean;
    annoMode?: boolean;
    activePreset?: ICWChangeNotification;
    virtualTodayOn?: boolean;
    activeGlobalIndicators?: GLOBAL_INDEXES[];
    toggleGlobalIndicator?: (g: GLOBAL_INDEXES) => void;
    hidePastProjectionButton?: boolean;
    hideToggleMap?: boolean;
    hideActivePreset?: boolean;
    countriesArr?: IActiveCountry[];
    hasActiveCustomWeight?: boolean;
};

interface State {
    showOilDropdown: boolean;
    selectedExternalDataList: number[]; // selected data  for external data popup - custom weight, currency, gdp, oil
    showExternalDataPopup: boolean; // if external data popup should be displayed
    externalDropDownList: any; // list for external data popup dropdown - country list/ oil indictaor list...
    externalDropDownSelectedList: any[]; // selected list in external data popup dropdown
    externalDDselectedItem?: any;
}

@inject(
    ({
        chartStore,
        countryStore,
        risksStore,
        insightsStore,
        customWeightStore,
    }: IMobxRootState): Partial<Props> => {
        return {
            currentCountryList: countryStore.currentCountryList,
            showLines: chartStore.showLines,
            showCircles:
                chartStore.showCircles &&
                !customWeightStore.hasActiveCustomWeight,
            isOpaque: chartStore.opacity,
            currencyOn: risksStore.currencyOn,
            projectionDataOn:
                risksStore.projectionDataOn &&
                !customWeightStore.hasActiveCustomWeight,
            riskChartMode: risksStore.riskChartMode,
            showGQLine: risksStore.showGeoquantLine,
            annoMode: insightsStore.annotationMode,
            activePreset: customWeightStore.activePreset,
            virtualTodayOn: chartStore.showVirtualToday,
            activeGlobalIndicators: risksStore.activeGlobalIndicators,
            toggleGlobalIndicator(g: GLOBAL_INDEXES) {
                risksStore.toggleGlobalIndicator(g);
            },
            countriesArr: countryStore.countries.countriesArr,
            hasActiveCustomWeight: customWeightStore.hasActiveCustomWeight,
        };
    }
)
@observer
class ChartToolbar extends React.Component<Props, State> {
    private cancelable = new CancelableEvents();
    private externalNode: Node;
    constructor(props: Props) {
        super(props);
        this.state = {
            showExternalDataPopup: false,
            showOilDropdown: false,
            selectedExternalDataList: [],
            externalDropDownList: [],
            externalDropDownSelectedList: [],
            externalDDselectedItem: [],
        };

        this.toggleProjectionData = this.toggleProjectionData.bind(this);
        this.onPulseModeClick = this.onPulseModeClick.bind(this);
        this.onRiskAndPulseModeClick = this.onRiskAndPulseModeClick.bind(this);
        this.onRiskModeClick = this.onRiskModeClick.bind(this);
        this.toggleVirtualToday = this.toggleVirtualToday.bind(this);

        this.toggleVirtualTodayIf();
    }

    public toggleVirtualTodayIf = () => {
        if (
            this.currentPage(VIEW.RISK_VIEW) ||
            this.currentPage(VIEW.SORT_BY_RISK) ||
            this.currentPage(VIEW.SORT_BT_DELTA) ||
            this.currentPage(VIEW.WORLD_GRAPH) ||
            this.currentPage(VIEW.CONTINGENT_GRAPH)
        ) {
            rootStore.chartStore.setState({
                showVirtualToday: true,
            });
        }
    };
    public handleClickOutside = (e: any) => {
        if (
            this.externalNode &&
            !this.externalNode.contains(e.target as Node)
        ) {
            this.setState({
                showExternalDataPopup: false,
            });
        }
    };

    public currentPage = (path: string) => window.location.href.match(path);

    public componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside, true);
        this.cancelable.addEventSubscription(
            EventsService.registerOnMouseLeftClick(() => {
                if (
                    this.state.showExternalDataPopup &&
                    this.state.selectedExternalDataList.length > 1
                ) {
                    this.setState({
                        showExternalDataPopup: false,
                    });
                }
            })
        );
    }

    public componentWillUnmount() {
        this.cancelable.cancelAll();
        GQPopupStorage.addData('chartOilIndicator', null);
    }
    public componentDidUpdate(prevProps: Props, prevState: State) {
        if (
            prevState.showExternalDataPopup !==
                this.state.showExternalDataPopup ||
            prevState.selectedExternalDataList.length !==
                this.state.selectedExternalDataList.length ||
            prevState.selectedExternalDataList[0] !==
                this.state.selectedExternalDataList[0] ||
            this.state.externalDropDownSelectedList.toString() !==
                prevState.externalDropDownSelectedList.toString()
        ) {
            this.handleExternalDataPopup();
        }

        if (this.props.expanded === false) {
            if (rootStore.risksStore.currencyOn) {
                rootStore.risksStore.cleanCurrencyCountry();
            }
            if (rootStore.risksStore.activeGlobalIndicators.length > 0) {
                rootStore.risksStore.clearGlobalIndicator();
            }
            if (this.state.selectedExternalDataList.length > 0) {
                this.setState({
                    selectedExternalDataList: [],
                    externalDropDownList: [],
                    externalDropDownSelectedList: [],
                    externalDDselectedItem: '',
                });
            }
        } else if (this.state.externalDropDownSelectedList.length === 0) {
            if (rootStore.risksStore.currencyOn) {
                if (this.props.countriesArr) {
                    const tempSelectedId = rootStore.risksStore.getCurrencyCountry();
                    const filteredList = this.props.countriesArr.filter(
                        (item: any) => item.id === tempSelectedId[0]
                    );
                    this.setState({
                        selectedExternalDataList: [ExternalDataItemID.CURRENCY],
                        externalDropDownList: this.props.countriesArr,
                        externalDropDownSelectedList: rootStore.risksStore.getCurrencyCountry(),
                        externalDDselectedItem: filteredList[0],
                    });
                }
            }
            if (rootStore.risksStore.activeGlobalIndicators.length > 0) {
                this.setState({
                    selectedExternalDataList: [ExternalDataItemID.OIL],
                    externalDropDownList: [
                        'oil_price_normalized',
                        'oil_price_risk',
                        'oil_production_risk',
                    ],
                    externalDropDownSelectedList:
                        rootStore.risksStore.activeGlobalIndicators,
                });
            }
        } else if (
            this.state.externalDropDownSelectedList.length > 0 &&
            rootStore.risksStore.activeGlobalIndicators.length === 0 &&
            rootStore.risksStore.getCurrencyCountry().length === 0
        ) {
            this.setState({
                selectedExternalDataList: [],
                externalDropDownSelectedList: [],
                externalDDselectedItem: '',
            });
        }
    }

    public render() {
        if (isHeadlessMode()) {
            return null;
        }
        const eventDotsEnabled =
            !this.props.hasActiveCustomWeight &&
            this.props.riskChartMode !== RISK_CHART_MODES.PULSE;

        const pastProjectionsEnabled =
            !this.props.hasActiveCustomWeight &&
            this.props.riskChartMode !== RISK_CHART_MODES.PULSE;

        return (
            <div
                id="buttonsContainer"
                className={this.props.expanded ? 'expanded-chart' : ''}>
                <div className="gq-active-weight-button">
                    {!this.props.hideActivePreset &&
                        this.props.activePreset &&
                        this.props.activePreset.activePreset && (
                            <ActiveWeight
                                active={this.props.showGQLine}
                                onClick={this.toggleCustomWeight}
                                preset={this.props.activePreset}
                            />
                        )}
                </div>
                <div id="timelineButtons">
                    <div
                        id="centerButtons"
                        className={this.props.expanded ? 'pull-left' : ''}>
                        {this.props.expanded && (
                            <div id="centerButtons">
                                <GQButton
                                    icon="gqi-scatter"
                                    onClick={this.toggleEvents}
                                    toggleButton={true}
                                    active={this.props.showCircles}
                                    noBorder={true}
                                    tooltip="Toggle Events"
                                    tooltipPosition="top left"
                                    disabled={!eventDotsEnabled}
                                />
                                {!this.props.hidePastProjectionButton && (
                                    <GQButton
                                        icon="gqi-history"
                                        onClick={this.toggleProjectionData}
                                        active={this.props.projectionDataOn}
                                        tooltip="Past Projections"
                                        noBorder={true}
                                        tooltipPosition="top left"
                                        disabled={!pastProjectionsEnabled}
                                    />
                                )}
                                {!this.props.hideToggleMap && (
                                    <GQAccordion
                                        icon={null}
                                        expanded={true}
                                        containerStyle={{ borderRadius: 4 }}
                                        hideMainButton>
                                        <GQButton
                                            icon="gqi2-risk-and-pulse"
                                            onClick={
                                                this.onRiskAndPulseModeClick
                                            }
                                            active={
                                                !this.props
                                                    .hasActiveCustomWeight &&
                                                this.props.riskChartMode ===
                                                    RISK_CHART_MODES.RISK_AND_PULSE
                                            }
                                            tooltip="Risk and Pulse"
                                            noBorder={true}
                                            tooltipPosition="top left"
                                            disabled={
                                                this.props.hasActiveCustomWeight
                                            }
                                        />
                                        <GQButton
                                            icon="gqi2-risk"
                                            onClick={this.onRiskModeClick}
                                            active={
                                                this.props
                                                    .hasActiveCustomWeight ||
                                                this.props.riskChartMode ===
                                                    RISK_CHART_MODES.RISK
                                            }
                                            tooltip="Risk"
                                            noBorder={true}
                                            tooltipPosition="top left"
                                            disabled={
                                                this.props.hasActiveCustomWeight
                                            }
                                        />
                                        <GQButton
                                            icon="gqi2-pulse"
                                            onClick={this.onPulseModeClick}
                                            active={
                                                !this.props
                                                    .hasActiveCustomWeight &&
                                                this.props.riskChartMode ===
                                                    RISK_CHART_MODES.PULSE
                                            }
                                            tooltip="Pulse"
                                            noBorder={true}
                                            tooltipPosition="top left"
                                            disabled={
                                                this.props.hasActiveCustomWeight
                                            }
                                        />
                                    </GQAccordion>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <div className="compare-button-section">
                    {this.props.expanded ? (
                        this.state.externalDropDownSelectedList.length === 0 ? (
                            <div
                                id="compareButton"
                                onClick={this.openExternalDataPopup}>
                                <span>Compare</span>
                                <i
                                    aria-hidden="true"
                                    className="gqi-plus-circle icon"
                                />
                            </div>
                        ) : (
                            <div style={{ display: 'flex' }}>
                                {this.state.externalDDselectedItem &&
                                    this.state.externalDDselectedItem !== '' &&
                                    this.state.selectedExternalDataList[0] ===
                                        2 && (
                                        <div
                                            className="selected-compare-item"
                                            style={{
                                                borderBottom: '1px dashed',
                                                borderBottomColor: rootStore.countryStore.colorStack.color(
                                                    this.state
                                                        .externalDDselectedItem
                                                        .id
                                                ),
                                            }}>
                                            <GQFlag
                                                countryId={
                                                    this.state
                                                        .externalDDselectedItem
                                                        .id
                                                }
                                                className="flag-icon-squared"
                                            />
                                            <span>
                                                {
                                                    this.state
                                                        .externalDDselectedItem
                                                        .currency_code
                                                }
                                            </span>
                                        </div>
                                    )}
                                {this.state.selectedExternalDataList[0] ===
                                    4 && (
                                    <div
                                        className="selected-compare-item"
                                        style={{
                                            borderBottom: '1px dashed',
                                            borderBottomColor: globalRiskColor(
                                                this.state
                                                    .externalDropDownSelectedList[0]
                                            ),
                                        }}>
                                        <span>
                                            {this.state.externalDropDownSelectedList[0].replace(
                                                /_/g,
                                                ' '
                                            )}
                                        </span>
                                    </div>
                                )}
                                <div
                                    className="delete-selected-popup-data"
                                    onClick={this.handleSelectExternalData.bind(
                                        this,
                                        this.state.selectedExternalDataList[0]
                                    )}>
                                    <i className="gqi-close" />
                                </div>
                            </div>
                        )
                    ) : (
                        <GQButton
                            icon="gqi-expand"
                            onClick={this.toggleExpansion}
                            toggleButton={true}
                            active={this.props.expanded}
                            noBorder={true}
                            tooltip="Expand Graph"
                            tooltipPosition="top left"
                        />
                    )}
                </div>
            </div>
        );
    }
    private toggleEvents = () => {
        chartStore.setState({
            showCircles: !this.props.showCircles,
        });
    };

    private toggleCurrency = (id: number) => {
        this.handleSelectExternalData(id);
        this.setState({
            externalDropDownList: this.props.countriesArr,
        });
    };

    private toggleOil = (id: number) => {
        this.handleSelectExternalData(id);
        this.setState({
            externalDropDownList: [
                'oil_price_normalized',
            ],
        });
    };

    private handleSelectExternalData = (id: number) => {
        const tempList = this.state.selectedExternalDataList.concat();
        if (this.state.selectedExternalDataList.includes(id)) {
            tempList.pop();
        } else {
            tempList[0] = id;
        }
        if (this.state.externalDropDownSelectedList.length > 0) {
            this.externalDropDownItemClicked(
                this.state.externalDropDownSelectedList[0]
            );
        }
        this.setState({
            selectedExternalDataList: tempList,
            externalDropDownSelectedList: [],
            externalDDselectedItem: '',
        });
    };
    private handleExternalDataPopup() {
        if (!this.state.showExternalDataPopup) {
            GQPopupStorage.addData('External_Tooltip', null);
            return;
        } else {
            const element = document.getElementById('compareButton');
            const externalDataList = [
                {
                    name: 'Currency',
                    id: ExternalDataItemID.CURRENCY,
                    onClick: this.toggleCurrency,
                },
                {
                    name: 'Oil',
                    id: ExternalDataItemID.OIL,
                    onClick: this.toggleOil,
                },
            ];
            GQPopupStorage.addData('External_Tooltip', {
                element,
                tooltipData: (
                    <div
                        className="gq-navbar-settings-menu"
                        ref={node => (this.externalNode = node)}
                        style={{ zIndex: 2000, position: 'relative' }}>
                        <ExternalDataPopup
                            itemsList={externalDataList}
                            selectedList={this.state.selectedExternalDataList}
                            dropDownList={this.state.externalDropDownList}
                            dropDownSelectedItems={
                                this.state.externalDropDownSelectedList
                            }
                            onDropDownItemClicked={
                                this.externalDropDownItemClicked
                            }
                        />
                    </div>
                ),
                orientation: 'bottom right',
            });
        }
    }

    private openExternalDataPopup = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        this.setState({
            showExternalDataPopup: !this.state.showExternalDataPopup,
        });
    };

    private externalDropDownItemClicked = (id: any) => {
        const filteredList = this.state.externalDropDownList.filter(
            (item: any) => item.id === id
        );
        switch (this.state.selectedExternalDataList[0]) {
            case 1:
                break;
            case 2:
                rootStore.risksStore.setCurrencyCountry(id);
                this.setState({
                    externalDropDownSelectedList: rootStore.risksStore.getCurrencyCountry(),
                    externalDDselectedItem: filteredList[0],
                    showExternalDataPopup: false,
                });
                break;
            case 3:
                break;
            case 4:
                rootStore.risksStore.toggleGlobalIndicator(id);
                this.setState({
                    externalDropDownSelectedList: [id],
                    showExternalDataPopup: false,
                });
                break;
        }
    };

    private toggleVirtualToday = () => {
        rootStore.chartStore.setState({
            showVirtualToday: !this.props.virtualTodayOn,
        });
        switch (!this.props.projectionDataOn) {
            case false:
                rootStore.chartStore.setState({
                    showVirtualToday: true,
                });
                break;
        }
    };
    private toggleExpansion = () => {
        this.props.onExpand();
    };
    private toggleCustomWeight = () => {
        const newMode =
            this.props.riskChartMode === RISK_CHART_MODES.RISK_AND_PULSE
                ? RISK_CHART_MODES.PULSE
                : RISK_CHART_MODES.RISK_AND_PULSE;
        rootStore.risksStore.setState({
            riskChartMode: newMode,
        });
    };

    private onPulseModeClick = () => {
        rootStore.risksStore.setState({
            riskChartMode: RISK_CHART_MODES.PULSE,
        });
    };
    private onRiskModeClick = () => {
        rootStore.risksStore.setState({
            riskChartMode: RISK_CHART_MODES.RISK,
        });
    };
    private onRiskAndPulseModeClick = () => {
        rootStore.risksStore.setState({
            riskChartMode: RISK_CHART_MODES.RISK_AND_PULSE,
        });
    };
    private toggleProjectionData = () => {
        rootStore.risksStore.setState({
            projectionDataOn: !this.props.projectionDataOn,
        });
        if (
            this.currentPage(VIEW.WORLD_GRAPH) ||
            this.currentPage(VIEW.COUNTRY_GRAPH)
        ) {
            switch (!this.props.projectionDataOn) {
                case true:
                    rootStore.chartStore.setState({
                        showVirtualToday: true,
                    });
                    break;
                case false:
                    rootStore.chartStore.setState({
                        showVirtualToday: true,
                    });
                    break;
                default:
                    rootStore.chartStore.setState({
                        showVirtualToday: true,
                    });
                    break;
            }
        }
    };
}

export default ChartToolbar;
