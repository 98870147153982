import { GQButton } from 'components/GQButton';
import GQLoader from 'components/GQLoader/GQLoader';
import React from 'react';
import { HeatmapScoreType } from './HeatmapTypes';

type Props = {
    scoreType: HeatmapScoreType;
    onChangeScoreType: (scoreType: HeatmapScoreType) => void;
    isChangingScoreType: boolean;
    isChangingRiskType: boolean;
};

const HeatmapScoreTypeSelection = React.memo(
    ({
        scoreType,
        onChangeScoreType,
        isChangingScoreType,
        isChangingRiskType,
    }: Props) => {
        return (
            <div className="heatmap-toggle">
                <div className="heatmap-header-select-risk-label">
                    Score type
                </div>
                {isChangingScoreType ? (
                    <GQLoader />
                ) : (
                    <div className="heatmap-header-risk-types">
                        <GQButton
                            icon="gqi-lines"
                            onClick={() =>
                                onChangeScoreType(HeatmapScoreType.PULSE)
                            }
                            active={scoreType === HeatmapScoreType.PULSE}
                            tooltip="Pulse"
                            noBorder={true}
                            tooltipPosition="top left"
                            disabled={isChangingRiskType}
                        />
                        <GQButton
                            icon="gqi-world"
                            onClick={() =>
                                onChangeScoreType(HeatmapScoreType.RISK)
                            }
                            active={scoreType === HeatmapScoreType.RISK}
                            tooltip="Risk"
                            noBorder={true}
                            tooltipPosition="top left"
                            disabled={isChangingRiskType}
                        />
                    </div>
                )}
            </div>
        );
    }
);

export default HeatmapScoreTypeSelection;
