import React from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import rootStore, { IMobxRootState } from '../../RootStore';
import moment from 'moment';
import {
    E_RISK_TYPE,
    IActiveDimension,
    IActiveGroup,
    IAPIActiveClientFacingIndicator,
    VIEW,
} from '../../interfaces';
import {
    EVENTS_FILTER,
    IEventsData,
    IUscoreActive,
    SCORED_EVENTS_FILTER,
} from './EventsFeedInterfaces';
import EventsFeed from './EventsFeed';
import * as _ from 'lodash';
import * as IPC from './EventsWorkerIPC';
import EventsFeedStore from './EventsFeedStore';
import { generateID } from '../../utils/generalUtils';
import { MOMENTJS_DATE_DISPLAY_FORMAT } from '../../constants';
import { flow, map, get, flatten, uniq, keyBy, find, includes } from 'lodash/fp';

type Props = {
    activeView?: VIEW;
    selectedCountries?: number[];
    selectedRisks?: number[];
    virtualToday?: moment.Moment;
    feedFilter?: EVENTS_FILTER;
    scoredEventsFilter?: SCORED_EVENTS_FILTER;
    isDebug?: boolean;
    selectedUscore?: IUscoreActive;
    hoveredUscore?: IUscoreActive;
    onHold?: boolean;
    countryPageId?: number;
    activeGroupId?: string;
    activeGroups?: IActiveGroup[];
    searchString?: string;
    includeMentionCountries?: boolean;
    dimensions?: { [dimensionId: number]: IActiveDimension };
    activeClientFacingIndicators?: IAPIActiveClientFacingIndicator;
    onEventsClick?:  (id: number) => void;
    riskType?: E_RISK_TYPE;
};

type State = {
    events?: IEventsData[];
    tempEvents?: IEventsData[];
    loadInProgress: boolean;
};

const getEventsWorker = () => {
    return rootStore.eventsFeedStore.eventsHandlerWorker;
};

const MIN_FEED_EVENTS = 30;
const MAX_EVENTS_DAYS_TO_FETCH = 2 ** 5; // 32 days, rougly 1 month, also consistent with the exponential backoff I'm using with this

@inject(
    ({
        countryStore,
        risksStore,
        eventsFeedStore,
        chartStore,
        appStore,
        settingsStore,
        UserStore,
        routingStore,
        heatmapStore,
        ...rest
    }: IMobxRootState): Partial<Props> => {
        const isHeatmap = routingStore.location.pathname.startsWith(
            `/${VIEW.HEATMAP}`
        );

        return {
            selectedCountries: isHeatmap
                ? [heatmapStore.selectedCountry].filter(Boolean)
                : countryStore.currentCountryList,
            selectedRisks: isHeatmap
                ? [heatmapStore.selectedRisk].filter(Boolean)
                : risksStore.currentList,
            virtualToday: chartStore.virtualToday,
            feedFilter: eventsFeedStore.currentFilter,
            scoredEventsFilter: eventsFeedStore.scoredEventsFilter,
            isDebug: appStore.debugMode,
            selectedUscore: eventsFeedStore.currentSelectedUscore,
            hoveredUscore: eventsFeedStore.currentHoveredUscore,
            onHold: eventsFeedStore.onHold,
            countryPageId: null,
            activeGroupId: settingsStore.activeGroupId,
            activeGroups: settingsStore.activeGroups,
            searchString: eventsFeedStore.searchString,
            includeMentionCountries:
                UserStore.geoquantFlagsPermission.include_mention_countries,
            dimensions: keyBy(r => r.id, risksStore.dimensions.flatten),
            activeClientFacingIndicators: risksStore.clientFacingIndicators,
            riskType: risksStore.currentRisksType,
        };
    }
)
@observer
export default class EventsFeedDataProvider extends React.Component<
    Props,
    State
> {
    private static deletedEvents = new Set<number>();
    private mounted: boolean = false;
    private currentTimeout: any = null;
    private numOfEventsDaysToFetch: number;
    private numEventsReceivedInCurrentBatch: number;
    private latestRequestId: string = null;

    constructor(props: Props) {
        super(props);
        this.state = {
            events: [],
            tempEvents: [],
            loadInProgress: true,
        };
        this.resetFetchEventsExponentialBackoffParams();
    }

    public componentDidMount() {
        this.mounted = true;
        const worker = getEventsWorker();
        if (worker) {
            worker.onmessage = this.onWorkerMessage;
        }
        this.reloadEventsFeed(false);
        this.handleActiveUscores();
    }

    private getRisks(): number[] {
        if (
            includes(this.props.riskType, [
                E_RISK_TYPE.DIMENSIONS,
                E_RISK_TYPE.CONTINGENT,
            ])
        ) {
            return this.props.selectedRisks;
        }

        return flow(
            map((risk: number) => {
                return flow(
                    get([risk, 'composing_dimensions']),
                    map(get('id'))
                )(this.props.activeClientFacingIndicators);
            }),
            flatten,
            uniq
        )(this.props.selectedRisks);
    }

    public componentWillUnmount() {
        this.mounted = false;
        const worker = getEventsWorker();
        if (worker) {
            worker.onmessage = null;
        }
    }
    public componentDidUpdate(prevProps: Props, prevState: State) {
        if (
            !_.isEqual(prevProps.hoveredUscore, this.props.hoveredUscore) ||
            !_.isEqual(prevProps.selectedUscore, this.props.selectedUscore)
        ) {
            this.handleActiveUscores();
        } else if (
            this.props.activeGroupId !== prevProps.activeGroupId &&
            prevProps.activeGroupId &&
            prevProps.activeGroupId !== null
        ) {
            this.reloadEventsFeed(true);
            return;
        }
        else if (
            prevProps.feedFilter !== this.props.feedFilter &&
            prevProps.feedFilter
        ) {
            this.reloadEventsFeed(true);
            return;
        } else if (
            prevProps.scoredEventsFilter !== this.props.scoredEventsFilter
        ) {
            this.reloadEventsFeed(true);
            return;
        } else if (
            prevProps.virtualToday &&
            !prevProps.virtualToday.isSame(this.props.virtualToday, 'days')
        ) {
            this.reloadEventsFeed(true);
            return;
        } else if (prevProps.searchString !== this.props.searchString) {
            if (
                (this.props.searchString &&
                    this.props.searchString.length === 0) ||
                !this.props.searchString
            ) {
                this.reloadEventsFeed(true);
            } else {
                this.reloadEventsFeedSearch(this.props.searchString);
            }
            return;
        } else if (
            !_.isEqual(
                prevProps.selectedCountries,
                this.props.selectedCountries
            ) ||
            !_.isEqual(prevProps.selectedRisks, this.props.selectedRisks) ||
            !_.isEqual(prevProps.feedFilter, this.props.feedFilter) ||
            !_.isEqual(prevProps.isDebug, this.props.isDebug) ||
            !_.isEqual(prevProps.activeGroupId, this.props.activeGroupId)
        ) {
            this.reloadEventsFeed(true);
        }

        if (this.state.events !== prevState.events) {
            if (!_.isEmpty(this.state.events)) {
                const newDefaultEvent = _.find(
                    this.state.events,
                    event => !EventsFeedDataProvider.deletedEvents.has(event.id)
                );
                EventsFeedStore.setDefaultEvent(newDefaultEvent.id);
            }
        }
    }

    private onTopIndicatorClicked = (
        dimensionName: string,
        countryId: number
    ) => {
        rootStore.countryStore.clear(true);
        rootStore.countryStore.toggleCountry(countryId);

        let dimensionId = 1;
        const dimensions = this.props.dimensions;

        for (const key in dimensions) {
            if (dimensions.hasOwnProperty(key)) {
                const dimension = dimensions[key];
                if (dimension.lname === dimensionName) {
                    dimensionId = dimension.id;
                }
            }
        }

        rootStore.risksStore.setCurrentRiskType(E_RISK_TYPE.DIMENSIONS);
        rootStore.risksStore.clear();
        rootStore.risksStore.currentStore.overrideValues(dimensionId);
        rootStore.chartStore.setNewBaseDate(moment(), moment());
        rootStore.chartStore.setNewRanges(7, 7);
        rootStore.routingStore.push(`/${VIEW.WORLD_GRAPH}`);
    };

    public render() {
        // allows react-window to efficiently render items without comparing moemntjs dates on the fly
        const eventsWithDateString = _.map(this.state.events, event => ({
            ...event,
            dateString: moment(event.time).format(MOMENTJS_DATE_DISPLAY_FORMAT),
        }));

        return (
            <EventsFeed
                onEventClick={this.props.onEventsClick || this.onEventsClick}
                eventList={eventsWithDateString}
                tempEvents={this.state.tempEvents}
                loadInProgress={this.state.loadInProgress}
                loadMoreEvents={this.setAndFetchEvents}
                onEventDelete={this.onEventDelete}
                selectedCountries={this.props.selectedCountries}
                onViewChange={this.onViewChange}
                onTopIndicatorClicked={this.onTopIndicatorClicked}
                scoredEventsFilter={this.props.scoredEventsFilter}
            />
        );
    }

    private onViewChange = (nextView: VIEW, ...extraArgs: string[]) => {
        const url = `/${nextView}/${extraArgs.join('/')}`;
        rootStore.routingStore.push(url);
    };

    private onEventDelete = (id: number) => {
        EventsFeedDataProvider.deletedEvents.add(id);
        if (this.mounted) {
            this.forceUpdate();
        }
    };

    private onWorkerMessage = (msg: MessageEvent) => {
        const response = msg.data as IPC.IEventsWorkerIPCResponse;
        if (this.mounted && response.requestId === this.latestRequestId) {
            const receivedEvents = [];
            response.data.forEach(e => {
                if (!EventsFeedDataProvider.deletedEvents.has(e.id)) {
                    e.time = moment(e.time);
                    e.created_at = moment(e.created_at);
                    receivedEvents.push(e);
                }
            });
            switch (response.code) {
                case IPC.E_EVENTS_WORKER_IPC_RESPONSE_CODES.EVENTS:
                    if (!this.mounted) {
                        return;
                    }

                    const events = this.state.events.concat(receivedEvents);
                    const defaultEvent = events[0];
                    if (
                        defaultEvent &&
                        EventsFeedStore.defaultEvent !== defaultEvent.id
                    ) {
                        EventsFeedStore.setDefaultEvent(defaultEvent.id);
                    }
                    this.numEventsReceivedInCurrentBatch +=
                        receivedEvents.length;

                    const numOfEventsDaysToFetch =
                        this.numOfEventsDaysToFetch * 2;
                    if (
                        this.numEventsReceivedInCurrentBatch <
                            MIN_FEED_EVENTS &&
                        numOfEventsDaysToFetch <= MAX_EVENTS_DAYS_TO_FETCH
                    ) {
                        this.numOfEventsDaysToFetch = numOfEventsDaysToFetch;
                        this.setAndFetchEvents(events);
                    } else {
                        this.resetFetchEventsExponentialBackoffParams();
                        this.setState({
                            events,
                            loadInProgress: false,
                        });
                    }
                    break;
                case IPC.E_EVENTS_WORKER_IPC_RESPONSE_CODES.USCORE_EVENTS:
                    const activeUscore =
                        this.props.hoveredUscore || this.props.selectedUscore;
                    if (
                        activeUscore &&
                        activeUscore.asId === response.uscore.asId &&
                        this.mounted
                    ) {
                        this.setState({
                            tempEvents: receivedEvents,
                        });
                    }
                    break;
            }
        }
    };

    private resetFetchEventsExponentialBackoffParams() {
        this.numOfEventsDaysToFetch = 1;
        this.numEventsReceivedInCurrentBatch = 0;
    }

    private reloadEventsFeed(wait: boolean = false) {
        const latestDate = this.props.virtualToday.clone();
        clearTimeout(this.currentTimeout);
        const fn = () => {
            if (!this.mounted) {
                return;
            }
            if (this.props.virtualToday.isSame(latestDate, 'day')) {
                this.resetFetchEventsExponentialBackoffParams();
                this.setAndFetchEvents([]);
            }
        };
        this.currentTimeout = setTimeout(fn, wait ? 300 : 0);
    }

    private reloadEventsFeedSearch(searchString: string) {
        const timeout = searchString.length <= 3 ? 200 : 100;
        setTimeout(() => {
            if (!this.mounted) {
                return;
            }
            if (this.props.searchString === searchString) {
                this.setAndFetchEvents([]);
            }
        }, timeout);
    }

    private setAndFetchEvents = (eventsToSet: IEventsData[] = null) => {
        if (this.mounted) {
            const newState: any = {
                loadInProgress: true,
            };
            if (eventsToSet) {
                newState.events = eventsToSet;
            }
            this.setState(newState, () =>
                this.sendEventWorkerRequest({
                    code: IPC.E_EVENTS_WORKER_IPC_CODES.FETCH_EVENTS,
                    props: this.getIPCEventsRequestProps(),
                })
            );
        }
    };

    private getIPCEventsRequestProps(): IPC.IEventWorkerIPCExecuteActionProps {
        let maxDate: moment.Moment;
        if (this.state.events.length > 0) {
            const minEventDate = moment.min(this.state.events.map(e => e.time));
            maxDate = minEventDate.clone().subtract(1, 'days');
        } else {
            maxDate = this.props.virtualToday.isAfter(moment(), 'days')
                ? moment().startOf('day')
                : this.props.virtualToday.clone();
        }

        const activeGroupCountries = !this.props.activeGroupId
            ? []
            : flow(
                  find(
                      (group: IActiveGroup) =>
                          group.id === this.props.activeGroupId
                  ),
                  get('countries')
              )(this.props.activeGroups);

        return {
            maxDate: maxDate.toISOString(),
            feedType: this.props.feedFilter,
            numDaysToFetch: this.numOfEventsDaysToFetch,
            selectedCountries: this.props.selectedCountries,
            activeGroupCountries,
            selectedRisks: this.getRisks(),
            limit: MIN_FEED_EVENTS,
            isDebug: this.props.isDebug,
            searchString: this.props.searchString,
            scoredEventsFilter: this.props.scoredEventsFilter,
            includeMentionedCountries: this.props.includeMentionCountries,
            riskType: this.props.riskType,
        };
    }

    private async handleActiveUscores() {
        const isSelectedUscoreAndCountry =
            this.props.selectedUscore &&
            _.intersection(
                this.props.selectedCountries,
                this.props.selectedUscore.countryIds
            ).length > 0;
        const activeUscore =
            this.props.hoveredUscore || this.props.selectedUscore;
        if (
            !activeUscore ||
            (this.props.selectedUscore && !isSelectedUscoreAndCountry)
        ) {
            if (this.state.tempEvents.length !== 0) {
                this.setState({
                    tempEvents: [],
                });
            }
            return;
        }
        this.sendEventWorkerRequest({
            code: IPC.E_EVENTS_WORKER_IPC_CODES.FETCH_TEMP_EVENTS,
            uscore: toJS(activeUscore),
        });
    }

    private onEventsClick = (id: number) => {
        if (!rootStore.routingStore.location.pathname.includes(id.toString())) {
            rootStore.routingStore.push(`/${VIEW.SCORE_EVENT}/${id}`);
        }
    };

    private sendEventWorkerRequest = (
        msg: Partial<
            | IPC.IEventWorkerIPCExecuteAction
            | IPC.IEventsWorkerIPCHandleActiveUscore
        >
    ) => {
        this.latestRequestId = generateID();
        msg.requestId = this.latestRequestId;
        getEventsWorker().postMessage(msg);
    };
}
