import React from 'react';
import SidebarRiskPickerWrapper from './SidebarRiskPickerWrapper';
import SidebarRiskPickerListContent, {
    ISidebarRiskPickerMenuItem,
} from './SidebarRiskPickerListContent';
import riskStore from '../../stores/RisksStore';
import { inject } from 'mobx-react';
import { IMobxRootState } from 'RootStore';
import { IActiveContingentRisk } from 'services/lib/APIServiceInterfaces';

interface Props {
    selectedRisks?: number[];
    activeContingentRisks?: IActiveContingentRisk[];
}

@inject(
    ({ risksStore }: IMobxRootState): Partial<Props> => {
        return {
            selectedRisks: risksStore.currentList,
            activeContingentRisks: riskStore.contingentRisks,
        };
    }
)
export default class ContingentSidebarRiskPicker extends React.Component<
    Props,
    {}
> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    public render() {
        return (
            <SidebarRiskPickerWrapper title="Contingent Risk Type">
                <SidebarRiskPickerListContent
                    menuItems={this.contingentRisksMenuItems()}
                    selectedItemsIds={this.props.selectedRisks}
                    onItemClick={this.onRiskItemClick}
                />
            </SidebarRiskPickerWrapper>
        );
    }

    private contingentRisksMenuItems = (): ISidebarRiskPickerMenuItem[] => {
        return this.props.activeContingentRisks.map(cr => {
            return {
                ...cr,
                iconName: `gqi-contingent-risk${cr.id}`,
            };
        });
    };

    private onRiskItemClick = (riskId: number) => {
        riskStore.currentStore.overrideValues(riskId);
    };
}
