import React from 'react';
import { inject, observer } from 'mobx-react';
import PastScoreList from './GQPastScoreList';
import GQButton from '../../GQButton/GQButton';
import GQPopupStorage from '../../GQPopup/GQPopupStorage';
import GQMenu from '../../GQMenu/GQMenu';
import GQMenuItem from '../../GQMenu/GQMenuItem';
import GQModal, { E_MODAL_ALIGN } from '../../GQModal/GQModal';
import GQGenericPopup from '../../GQModal/GQGenericPopup';
import { IEventUScore } from '../../../interfaces';
import { IMobxRootState } from '../../../RootStore';
const ALL_SCORES_ACTIONS_MENU_NAME = 'score-event-all-scores-actions';

export interface IGQPastScoreContainerProps {
    pastScores: IEventUScore[];
    canUserDelete?: boolean;
    canSeeMachineScores?: boolean;
    onEdit: (score: IEventUScore) => void;
    onDelete: (score: IEventUScore) => void;
    onDeleteAllScores: () => void;
    editMode: boolean;
    newScore?: IEventUScore;
}

export interface IGQPastScoreContainerState {
    showAllScoresActionsMenu: boolean;
    showApproveDeleteAllModal: boolean;
    humanScores: IEventUScore[];
    machineScores: IEventUScore[];
}

@inject(({ UserStore }: IMobxRootState) => {
    return {
        canUserDelete: UserStore.uscoresPermissions.can_delete,
        canSeeMachineScores:
            UserStore.geoquantFlagsPermission.can_see_archived_uscores,
    };
})
@observer
export default class PastScoreContainer extends React.Component<
    IGQPastScoreContainerProps,
    IGQPastScoreContainerState
> {
    public loadAddScoreAnimation: boolean;
    private allScoresActionsButton: HTMLElement;

    constructor(props: IGQPastScoreContainerProps) {
        super(props);
        this.state = {
            showAllScoresActionsMenu: false,
            showApproveDeleteAllModal: false,
            humanScores: props.pastScores.filter(
                (score: IEventUScore) => score.human
            ),
            machineScores: props.pastScores.filter(
                (score: IEventUScore) => !score.human
            ),
        };
    }

    public componentWillUnmount() {
        this.ensureAllScoresActionsMenuClosed();
    }

    public UNSAFE_componentWillReceiveProps(
        nextProps: IGQPastScoreContainerProps
    ) {
        this.loadAddScoreAnimation = false;
        if (nextProps.newScore !== this.props.newScore) {
            this.loadAddScoreAnimation = true;
        }

        const haveNewPastScores: boolean =
            JSON.stringify(this.props.pastScores) !==
            JSON.stringify(nextProps.pastScores);

        if (this.state.showAllScoresActionsMenu && haveNewPastScores) {
            this.ensureAllScoresActionsMenuClosed();
        }

        if (haveNewPastScores) {
            this.setState({
                humanScores: nextProps.pastScores.filter(
                    (score: IEventUScore) => score.human
                ),
                machineScores: nextProps.pastScores.filter(
                    (score: IEventUScore) => !score.human
                ),
            });
        }
    }

    public render() {
        const { canUserDelete, canSeeMachineScores } = this.props;
        const { humanScores, machineScores } = this.state;

        return (
            <div className="past-score-container">
                {!humanScores.length && canUserDelete && (
                    <div
                        className="edit-score-menu-icon"
                        ref={el => (this.allScoresActionsButton = el)}>
                        <GQButton
                            icon={
                                this.state.showAllScoresActionsMenu
                                    ? 'gqi-close'
                                    : 'gqi-ellipsis-v'
                            }
                            noBorder={true}
                            onClick={this.toggleShowAllScoresMenu}
                            noPadding={true}
                            style={{
                                visibility: 'visible',
                            }}
                        />
                    </div>
                )}

                <PastScoreList
                    title="Human Scores"
                    scores={humanScores}
                    editMode={this.props.editMode}
                    onDelete={this.props.onDelete}
                    onEdit={this.props.onEdit}
                />

                {canSeeMachineScores && (
                    <PastScoreList
                        title="Machine Scores"
                        scores={machineScores}
                        editMode={this.props.editMode}
                        onDelete={this.props.onDelete}
                        onEdit={this.props.onEdit}
                    />
                )}

                <GQModal
                    align={E_MODAL_ALIGN.MIDDLE}
                    show={this.state.showApproveDeleteAllModal}>
                    <GQGenericPopup
                        title=""
                        text="Delete all existing scores?"
                        icon="gqi-alert"
                        onClose={this.toggleDeleteApproveModal}
                        buttons={[
                            <GQButton
                                key="popup-confirm"
                                caption="Yes, delete them all"
                                onClick={this.deleteAllScores}
                            />,
                            <GQButton
                                key="popup-cancel"
                                caption="Cancel"
                                onClick={this.toggleDeleteApproveModal}
                            />,
                        ]}
                    />
                </GQModal>
            </div>
        );
    }

    private toggleShowAllScoresMenu = () => {
        if (GQPopupStorage.isExisting(ALL_SCORES_ACTIONS_MENU_NAME)) {
            GQPopupStorage.addData(ALL_SCORES_ACTIONS_MENU_NAME, null);
        } else {
            GQPopupStorage.addData(ALL_SCORES_ACTIONS_MENU_NAME, {
                element: this.allScoresActionsButton,
                orientation: 'bottom left',
                tooltipData: (
                    <GQMenu>
                        <GQMenuItem
                            label="Delete All"
                            icon="gqi-delete"
                            onClick={this.toggleDeleteApproveModal}
                        />
                    </GQMenu>
                ),
            });
        }

        this.setState({
            showAllScoresActionsMenu: !this.state.showAllScoresActionsMenu,
        });
    };

    private ensureAllScoresActionsMenuClosed = () => {
        if (this.state.showAllScoresActionsMenu) {
            this.toggleShowAllScoresMenu();
        }
    };

    private toggleDeleteApproveModal = () => {
        this.setState(
            {
                showApproveDeleteAllModal: !this.state
                    .showApproveDeleteAllModal,
            },
            this.ensureAllScoresActionsMenuClosed
        );
    };

    private deleteAllScores = () => {
        this.toggleDeleteApproveModal();
        this.props.onDeleteAllScores();
    };
}
