import { getAPIWSUrl, getAPIWSConfig } from '../configService';
import * as io from 'socket.io-client';
import EventsService, { GQEmitterEvents } from '../EventsService';
import { generateNumber } from 'utils/generalUtils';

const PUSH_NOTIFICATION_SCORE_CHANGE_KEY = 'country_score_change';
interface IPushNotificationCountryScoreChange {
    ntname: typeof PUSH_NOTIFICATION_SCORE_CHANGE_KEY;
    data: number[];
}

type IPUshNotification = IPushNotificationCountryScoreChange;
export default class WebSocketService {
    private socket: SocketIOClient.Socket;
    constructor(private token: string, private activeGroup: string) {
        this.connect();
    }

    public close() {
        if (this.socket && this.socket.connected) {
            this.socket.close();
            this.socket = null;
        }
    }

    public sendMessage(method: string, args: any): Promise<any> {
        return new Promise((resolve, reject) => {
            const timeout: any = setTimeout(() => {
                reject('No response');
            }, 1000 * 90);
            const messageId = `${Date.now()}${generateNumber()}`;
            this.socket.once(messageId, (result: any) => {
                clearTimeout(timeout);
                try {
                    resolve({
                        data:
                            'string' === typeof result
                                ? JSON.parse(result)
                                : result,
                    });
                } catch (err) {
                    throw err;
                }
            });
            this.socket.emit('API', {
                messageId,
                method,
                args,
            });
        });
    }

    private connect() {
        const options = getAPIWSConfig();
        Object.assign(options, {
            query: {
                token: this.token,
                GQActive: this.activeGroup,
            },
            transports: ['websocket'],
        });
        this.socket = io.connect(getAPIWSUrl(), options);

        this.socket.on('reconnect_attempt', () => {
            this.socket.io.opts.transports = ['polling', 'websocket'];
        });

        this.socket.on('scoring_push', (msg: IPUshNotification) => {
            switch (msg.ntname) {
                case PUSH_NOTIFICATION_SCORE_CHANGE_KEY:
                    EventsService.emitOnPushNotificationReloadCountry(msg.data);
                    break;
            }
        });
        this.socket.on('cw_change', (data: any) => {
            EventsService.emitOnCWChangeNotification(data);
        });
        this.socket.on('new_gq_insight', () => {
            EventsService.emitEvent(GQEmitterEvents.PN_NEW_GQ_INSIGHT, null);
        });
    }
}
