import React from 'react';
import cx from 'classnames';

interface IGQLoaderProps {
    style?: React.CSSProperties;
    size?: 'medium' | 'small' | 'large';
}

class GQLoader extends React.Component<IGQLoaderProps> {
    public render() {
        return (
            <div
                className={cx([
                    'spinner',
                    { [this.props.size]: !!this.props.size },
                ])}
                style={{ ...this.props.style }}>
                <div className="bounce1" />
                <div className="bounce2" />
                <div className="bounce3" />
            </div>
        );
    }
}

export default GQLoader;
