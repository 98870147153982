export enum E_TAB_FILTER {
    GQ = 'gq',
    BMI = 'bmi',
    MY = 'my',
    PREDICTIONS = 'predictions',
}

export enum E_TIME_FILTER {
    WEEK = 'week',
    MONTH = 'month',
    ALL = 'all',
}
