import { E_RISK_TYPE, VIEW } from 'interfaces';
import React from 'react';
import IndicatorsSidebarRiskPicker from 'components/sidebarRiskPicker/IndicatorsSidebarRiskPicker';
import EventsFeedDataProvider from 'components/eventsFeed/EventFeedDataProvider';
import ChartContainer from 'components/GQChart/ChartContainer';
import GQMapProvider from 'components/GQMap/GQMapProvider';
import { useHistory } from 'react-router-dom';
import useInitCountryDim from 'hooks/useInitCountryDim';

export const RiskViewPage = React.memo(() => {
    return (
        <React.Fragment>
            <EventsFeedDataProvider />
            <RiskView showChart />
            <IndicatorsSidebarRiskPicker />
        </React.Fragment>
    );
});

type RiskViewProps = {
    showChart?: boolean;
};
export const RiskView = React.memo((props: RiskViewProps) => {
    let { push } = useHistory();

    const ready = useInitCountryDim({
        riskType: E_RISK_TYPE.DIMENSIONS,
        multiCountry: true,
        multiRisk: false,
    });

    return (
        ready && (
            <div className="map-and-chart-container">
                <GQMapProvider view={VIEW.RISK_VIEW} />
                {props.showChart && (
                    <ChartContainer
                        activeView={VIEW.RISK_VIEW}
                        onExpand={() => {
                            push(`/${VIEW.WORLD_GRAPH}`);
                        }}
                        changeDataWhenDragStop={true}
                    />
                )}
            </div>
        )
    );
});
