import React from 'react';
import { GQButton } from './GQButton';

export interface IGQSearchProps {
    onChange?: (filterValue: string) => void;
}

export interface IGQSearchState {
    filterValue: string;
}

export default class GQsearch extends React.Component<
    IGQSearchProps,
    IGQSearchState
> {
    constructor(props: IGQSearchProps) {
        super(props);
        this.state = {
            filterValue: '',
        };
    }
    public render() {
        const onFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            this.setState({
                filterValue: e.currentTarget.value,
            });
            this.props.onChange(e.currentTarget.value);
        };
        return (
            <div style={{ textAlign: 'center' }}>
                <div className="gq-search">
                    <i className="icon gqi-search" />
                    <input
                        type="search"
                        placeholder="Search..."
                        value={this.state.filterValue}
                        onChange={onFilterChange}
                    />
                    {this.state.filterValue.length > 0 ? (
                        <GQButton
                            icon="gqi-clear"
                            onClick={this.clearSearch}
                            noBorder
                        />
                    ) : (
                        ''
                    )}
                </div>
            </div>
        );
    }
    private clearSearch = () => {
        this.setState({
            filterValue: '',
        });
    };
}
