import EventsFeedDataProvider from 'components/eventsFeed/EventFeedDataProvider';
import ChartContainer from 'components/GQChart/ChartContainer';
import { IMobxRootState } from '../../RootStore';
import IndicatorsSidebarRiskPicker from 'components/sidebarRiskPicker/IndicatorsSidebarRiskPicker';
import { E_RISK_TYPE, VIEW } from 'interfaces';
import { isEmpty } from 'lodash/fp';
import { inject } from 'mobx-react';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useInitCountryDim from 'hooks/useInitCountryDim';
import useInitChart from 'hooks/useInitChart';

type CountryGraphPageProps = {
    countries?: number[];
};
type CountryGraphProps = {
    height?: number;
};

export const CountryGraphPage = inject(({ countryStore }: IMobxRootState) => {
    return {
        countries: countryStore.currentCountryList,
    };
})(({ countries }: CountryGraphPageProps) => {
    const { push } = useHistory();
    useEffect(() => {
        if (isEmpty(countries)) {
            push(`/${VIEW.COUNTRY_GRAPH_SELECT_COUNTRY}`);
            return;
        }
    }, [countries, push]);

    if (isEmpty(countries)) {
        return null;
    }
    return (
        <React.Fragment>
            <EventsFeedDataProvider />
            <CountryGraph />
            <IndicatorsSidebarRiskPicker multi={true} />
        </React.Fragment>
    );
});

export const CountryGraph = inject(({ chartStore }: IMobxRootState) => {
    return {
        height: chartStore.height,
    };
})(({ height }: CountryGraphProps) => {
    useInitChart();
    const ready = useInitCountryDim({
        riskType: E_RISK_TYPE.DIMENSIONS,
        multiCountry: false,
        multiRisk: true,
    });

    return (
        ready && (
            <ChartContainer
                expanded
                height={height}
                activeView={VIEW.COUNTRY_GRAPH}
                changeDataWhenDragStop={true}
            />
        )
    );
});
