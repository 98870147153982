import appStore from './AppStore';
import countryStore from './stores/CountryStore';
import risksStore from './stores/RisksStore';
import scoreEventStore from './components/scoringPage/ScoreEventStore';
import eventsFeedStore from './components/eventsFeed/EventsFeedStore';
import settingsStore from './stores/SettingsStore';
import customWeightStore from './stores/CustomWeightStore';
import insightsStore from './components/GQActivityPage/Insights/InsightsStore';
import annoStore from './stores/AnnotationsStore';
import UserStore from './stores/UserStore';
import chartStore from './components/GQChart/ChartStore';
import routingStore from './stores/RoutingStore';
import heatmapStore from './stores/HeatmapStore';

export interface IMobxRootState {
    chartStore: typeof chartStore;
    appStore: typeof appStore;
    countryStore: typeof countryStore;
    risksStore: typeof risksStore;
    scoreEventStore: typeof scoreEventStore;
    eventsFeedStore: typeof eventsFeedStore;
    routingStore: typeof routingStore;
    settingsStore: typeof settingsStore;
    customWeightStore: typeof customWeightStore;
    insightsStore: typeof insightsStore;
    annoStore: typeof annoStore;
    UserStore: typeof UserStore;
    heatmapStore: typeof heatmapStore;
}

const rootState: IMobxRootState = {
    chartStore,
    appStore,
    scoreEventStore,
    eventsFeedStore,
    routingStore,
    settingsStore,
    customWeightStore,
    countryStore,
    risksStore,
    insightsStore,
    annoStore,
    UserStore,
    heatmapStore,
};

export function initRootStore() {
    Object.values(rootState).forEach(store => {
        if (typeof store.toInitialState === 'function') {
            store.toInitialState();
        }
    });
}

(window as any).rootStore = rootState;

export default rootState;
