import { observable, action } from 'mobx';
import StorageService from '../services/StorageService';
import APIService, { IActiveGroup } from '../services/APIService';
import { SETTINGS_TABS } from '../constants';
export const activeGroupIdKey = '__$ag';
export const activeGroupScroll = '__$agc';
class SettingsStore {
    @observable public activeGroupId: string = StorageService.get(
        activeGroupIdKey
    );
    @observable public overrideActiveCountries: number[] = null;
    @observable public activeGroupScroll: number =
        StorageService.get(activeGroupScroll) || 0;
    @observable public activeTab: SETTINGS_TABS;
    @observable public activeGroups: IActiveGroup[];

    @action public setActiveGroupId(id: string) {
        if (this.activeGroupId === id) {
            return;
        }
        this.activeGroupId = id;
        StorageService.set(activeGroupIdKey, id);
        APIService.reconnectWebSocketTransport(true);
    }

    @action public setActiveTab(tab: SETTINGS_TABS) {
        this.activeTab = tab;
    }

    @action public setOverrideActiveCountries(c: number[]) {
        this.overrideActiveCountries = c;
    }
    @action public setActiveGroupScroll(c: number) {
        this.activeGroupScroll = c;
        StorageService.set(activeGroupScroll, c);
    }
    @action public setActiveGroups(groups: IActiveGroup[]) {
        this.activeGroups = groups;
    }
}
export default new SettingsStore();
