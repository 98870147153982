import React from 'react';
import { RouteComponentProps } from 'react-router';
import GQLoader from 'components/GQLoader/GQLoader';
import InsightTemplateBody from '../InsightTemplateBody';
import { isEmpty } from 'lodash/fp';
import { TBody, TR, TD } from '../InsightTemplateElements';
import useInsightTemplate from '../useInsightTemplate';
import InsightTemplatePdfHeader from './InsightTemplatePdfHeader';
import InsightTemplatePdfFooter from './InsightTemplatePdfFooter';

type Props = RouteComponentProps<{ id: string }>;

const InsightTemplatePdf = (props: Props) => {
    const insightData = useInsightTemplate(props.match.params.id);

    if (isEmpty(insightData)) {
        return <GQLoader />;
    }

    return (
        <table
            style={{
                width: '100%',
                fontFamily:
                    '"Open Sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif',
            }}>
            <TBody>
                <TR>
                    <TD align="center">
                        <table
                            className="insightTemplate"
                            style={{
                                width: '600px',
                                backgroundColor: 'white !important',
                                color: 'black',
                            }}>
                            <TBody>
                                <InsightTemplatePdfHeader
                                    title={insightData.title}
                                    tags={insightData.tags}
                                    date={insightData.date}
                                    risks={insightData.risks}
                                />
                                <InsightTemplateBody
                                    nodes={insightData.nodes}
                                    componentsById={insightData.componentsById}
                                />
                                <InsightTemplatePdfFooter />
                            </TBody>
                        </table>
                    </TD>
                </TR>
            </TBody>
        </table>
    );
};

export default InsightTemplatePdf;
