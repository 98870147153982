import { observable, action, computed } from 'mobx';
import SpecificActivityStore from '../../../stores/SpecificActivityStore';
import {
    ActivityComponentStore,
    ActivityMapComponentStore,
    ActivityChartComponentStore,
    ActivityHeatmapComponentStore,
} from '../../../stores/insights/InsightsComponentStore';

export enum E_COMPONENT_TYPE_TO_DISPLAY {
    MAP,
    CHART,
    HEATMAP
}
class InsightsStore {
    @observable public activeID: string;
    @observable public isEditing: boolean = false;
    @observable public isResizing: boolean = false;
    @observable public activity: SpecificActivityStore;
    @observable public isEditingComponent: boolean = false;
    @observable public annotationMode: boolean = false;
    @observable private mapComponent: ActivityMapComponentStore = null;
    @observable private chartComponent: ActivityChartComponentStore = null;
    @observable private heatmapComponent: ActivityHeatmapComponentStore = null;
    @observable private componentTypeToDisplay: E_COMPONENT_TYPE_TO_DISPLAY;

    @action public setActivity(activity: SpecificActivityStore) {
        this.activity = activity;
    }

    @action public setComponentTypeToDisplay(
        type: E_COMPONENT_TYPE_TO_DISPLAY
    ) {
        if (this.componentTypeToDisplay !== type) {
            this.componentTypeToDisplay = type;
        }
    }
    @action public setAnnotatingMode(state: boolean) {
        this.activeID = null;
        this.isEditing = false;
        this.annotationMode = state;
    }

    @action public setEditing(id: string) {
        this.isEditing = !!id;
        this.activeID = id;
    }
    @action public setActive(id: string) {
        this.activeID = id;
    }
    @action public setResizing(val: boolean) {
        this.isResizing = val;
    }
    @action public prepareForPrint() {
        this.isEditing = false;
        this.activeID = null;
    }
    @action public setDisplayedComponent(
        map: ActivityMapComponentStore,
        chart: ActivityChartComponentStore,
        heatmap: ActivityHeatmapComponentStore
    ) {
        this.mapComponent = map;
        this.chartComponent = chart;
        this.heatmapComponent = heatmap;
    }

    @action public setEditingComponent(
        map: ActivityMapComponentStore,
        chart: ActivityChartComponentStore,
        heatmap: ActivityHeatmapComponentStore
    ) {
        this.isEditingComponent = true;
        this.setDisplayedComponent(map, chart, heatmap);
    }

    @action public restoreEditingComponent() {
        this.isEditingComponent = false;
    }

    @computed public get displayedComponent(): ActivityComponentStore {
        switch(this.componentTypeToDisplay){
            case E_COMPONENT_TYPE_TO_DISPLAY.CHART:
                return this.chartComponent;
            case E_COMPONENT_TYPE_TO_DISPLAY.MAP:
                return this.mapComponent;
            case E_COMPONENT_TYPE_TO_DISPLAY.HEATMAP:
                return this.heatmapComponent;
            default: return null
        }
    }
}

const store = new InsightsStore();
export default store;
