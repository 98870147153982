import GQButton from 'components/GQButton/GQButton';
import GQInput from 'components/GQInput/GQInput';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';

export const AutoInsightQuestions = React.memo(
    ({ onSubmit }: { onSubmit: (value: string) => void }) => {
        const [value, setValue] = useState<string>('');
        return (
            <div className="row">
                <GQInput
                    value={value}
                    placeholder="Followup question"
                    onChange={val => setValue(val)}
                    options={{ fullWidth: true }}
                    bindValueToProps={true}
                />
                <GQButton
                    disabled={isEmpty(value)}
                    style={{ marginLeft: 8 }}
                    caption="Ask"
                    onClick={() => onSubmit(value)}
                />
                ,
            </div>
        );
    }
);
