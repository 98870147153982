import { IGQPopupData } from './GQPopupInterfaces';
import EventsService from '../../services/EventsService';
import * as _ from 'lodash';
const storage: { [key: string]: IGQPopupData } = {};

class GQPopupStorage {
    public addData(key: string, data: IGQPopupData) {
        if (data === null) {
            delete storage[key];
        } else {
            storage[key] = { ...data };
        }
        EventsService.emitOnPopupAddRef();
    }

    public isExisting(key: string) {
        if (!storage[key]) {
            return false;
        }
        return true;
    }

    public updateData(key: string, data: any) {
        if (storage[key]) {
            storage[key] = {
                ...storage[key],
                ...data,
            };
        }
        EventsService.emitOnPopupAddRef();
    }

    public removeAll() {
        const keys = _.keys(storage);
        for (const key of keys) {
            delete storage[key];
        }
        EventsService.emitOnPopupAddRef();
    }

    public get data() {
        return { ...storage };
    }
}

export default new GQPopupStorage();
