import React from 'react';
import * as _ from 'lodash';
import { observer, inject } from 'mobx-react';
import GQCWAccordion from './GQCWAccordion';
import GQButton from '../../../GQButton/GQButton';
import GQLoader from '../../../GQLoader/GQLoader';
import Toast from '../../../scoringPage/lib/Toast';
import { IPreset } from '../../../../interfaces';
import api, { IPresetSub } from '../../../../services/APIService';
import rootStore, { IMobxRootState } from '../../../../RootStore';
import CWStore from '../../../../stores/CustomWeightStore';
import loading from '../../../../services/LoadingService';
import { generateNumber } from 'utils/generalUtils';

interface IGQCWMenuProps {
    presets?: IPreset[];
    activeId?: string;
    lastPresetId?: string;
    getLastPreset?: () => Promise<IPreset>;
    setActivePreset: (id: string) => void;
}

interface IGQCWMenuState {
    showToast: boolean;
    toastData: {
        text: string;
        class: string;
    };
}

@inject(
    ({ customWeightStore }: IMobxRootState): Partial<IGQCWMenuProps> => {
        return {
            activeId: customWeightStore.currentEditingId,
            presets: _.values(customWeightStore.presets),
        };
    }
)
@observer
export default class GQCWMenu extends React.Component<
    IGQCWMenuProps,
    IGQCWMenuState
> {
    constructor(props: IGQCWMenuProps) {
        super(props);

        this.state = {
            showToast: false,
            toastData: {
                class: '',
                text: '',
            },
        };
    }

    public render() {
        const { presets } = this.props;
        return (
            <div className="gq-cw-menu-container">
                <div className="gq-cw-menu-header">
                    <div className="gq-cw-menu-header-title">
                        <span>Presets</span>
                    </div>

                    <div
                        className="gq-cw-menu-header-button flex-container align-center-middle"
                        id="cwAddButton">
                        <GQButton
                            icon="gqi-add"
                            noBorder
                            noPadding
                            style={{ color: '#001939' }}
                            onClick={this.createNewPreset}
                            disabled={this.props.activeId === 'new'}
                        />
                    </div>
                </div>

                <div className="gq-cw-menu-items">
                    {!presets || presets.length === 0 ? (
                        <GQLoader />
                    ) : (
                        _.map(presets, this.generateItems)
                    )}
                </div>

                <Toast
                    show={this.state.showToast}
                    text={this.state.toastData.text}
                    toastClass={this.state.toastData.class}
                />
            </div>
        );
    }

    private onMenuItemClick = async (id: string) => {
        this.refreshPresets();
        rootStore.customWeightStore.setCurrentEditingId(id);
    };

    private createNewPreset = async () => {
        try {
            const newActiveTree = await api.getDefaultCustomWeightPreset();

            rootStore.customWeightStore.overridePreset({
                tree: newActiveTree,
                id: 'new',
                name: `New Preset ${generateNumber()
                    .toString()
                    .substr(0, 4)}`,
                isActive: false,
                editable: true,
                subs: [],
            });

            rootStore.customWeightStore.setCurrentEditingId('new');
        } catch (ex) {
            const newState: IGQCWMenuState = {
                showToast: true,
                toastData: {
                    class: 'error',
                    text: ex.message || 'You cannot add more presets',
                },
            };

            this.setState(newState, () => {
                setTimeout(() => {
                    this.setState({
                        showToast: false,
                    });
                }, 5000);
            });
        }
    };

    private async refreshPresets() {
        const presets = await api.getMyPresets();
        CWStore.setPresets(presets);
    }

    private deletePreset = async (id: string) => {
        try {
            loading.start();

            await api.deletePreset(id);
            this.refreshPresets();

            if (CWStore.currentEditingId === id) {
                CWStore.setCurrentEditingId(null);
            }

            loading.stop();
        } catch (ex) {
            const newState: IGQCWMenuState = {
                showToast: true,
                toastData: {
                    class: 'error',
                    text: ex.message || 'Error deleting preset',
                },
            };

            this.setState(newState, () => {
                setTimeout(() => {
                    this.setState({
                        showToast: false,
                    });
                }, 3000);
            });
        }
    };

    private deleteSubPreset = async (sub: IPresetSub) => {
        try {
            loading.start();
            CWStore.deleteSub(this.props.activeId, sub);
            loading.stop();
        } catch (ex) {
            throw ex;
        }
    };

    private generateItems = (preset: IPreset) => {
        const onClick = () => {
            this.onMenuItemClick(preset.id);
        };

        return (
            <GQCWAccordion
                key={preset.id}
                preset={preset}
                activeID={this.props.activeId}
                lastPresetId={this.props.lastPresetId}
                onMenuItemClick={onClick}
                onDeleteClick={this.deletePreset}
                onSubDeleteClick={this.deleteSubPreset}
                getLastPreset={this.props.getLastPreset}
            />
        );
    };
}
