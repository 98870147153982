import { GQButton } from 'components/GQButton';
import GQLoader from 'components/GQLoader/GQLoader';
import { E_RISK_TYPE } from 'interfaces';
import React from 'react';

type Props = {
    riskType: E_RISK_TYPE;
    onChangeRiskType: (riskType: E_RISK_TYPE) => void;
    isChangingRiskType: boolean;
    isChangingScoreType: boolean;
};

const HeatmapRiskSelection = React.memo(
    ({
        riskType,
        onChangeRiskType,
        isChangingRiskType,
        isChangingScoreType,
    }: Props) => {
        return (
            <div className="heatmap-toggle">
                <div className="heatmap-header-select-risk-label">
                    Risk type
                </div>
                {isChangingRiskType ? (
                    <GQLoader />
                ) : (
                    <div className="heatmap-header-risk-types">
                        <GQButton
                            icon="gqi-dimensions"
                            onClick={() =>
                                onChangeRiskType(E_RISK_TYPE.DIMENSIONS)
                            }
                            active={riskType === E_RISK_TYPE.DIMENSIONS}
                            tooltip="Fundamentals"
                            noBorder={true}
                            tooltipPosition="top left"
                            disabled={isChangingScoreType}
                        />
                        <GQButton
                            icon="gqi-indicators"
                            onClick={() =>
                                onChangeRiskType(E_RISK_TYPE.CLIENT_FACING_INDICATORS)
                            }
                            active={riskType === E_RISK_TYPE.CLIENT_FACING_INDICATORS}
                            tooltip="Focus"
                            noBorder={true}
                            tooltipPosition="top left"
                            disabled={isChangingScoreType}
                        />
                    </div>
                )}
            </div>
        );
    }
);

export default HeatmapRiskSelection;
