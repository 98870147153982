import React from 'react';
import * as _ from 'lodash';
import { observer, inject } from 'mobx-react';
import GQCGMenuItem from './GQCGMenuItem';
import countryGroupStore, { ICGStore } from '../CountryGroupsStore';
import GQButton from '../../../../GQButton/GQButton';
import GQLoader from '../../../../GQLoader/GQLoader';
import API, { IActiveGroup } from '../../../../../services/APIService';
import Toast from '../../../../scoringPage/lib/Toast';
import rootStore, { IMobxRootState } from '../../../../../RootStore';

interface Props {
    activeEditingGroup?: IActiveGroup;
    groups?: IActiveGroup[];
    activeGroupId?: string;
}

interface State {
    showToast: boolean;
    toastData: {
        text: string;
        class: string;
    };
}

@inject(({ cgStore, settingsStore }: IMobxRootState & ICGStore) => {
    return {
        activeEditingGroup: { ...cgStore.activeGroup },
        groups: settingsStore.activeGroups,
        activeGroupId: settingsStore.activeGroupId,
    };
})
@observer
export default class GQCWMenu extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            showToast: false,
            toastData: {
                class: '',
                text: '',
            },
        };
    }

    public render() {
        let groups = [...(this.props.groups || [])];

        if (this.props.activeEditingGroup) {
            if (this.props.activeEditingGroup.id === null) {
                groups.push(countryGroupStore.activeGroup);
            } else {
                groups = groups.map(g => {
                    if (g.id === this.props.activeEditingGroup.id) {
                        return this.props.activeEditingGroup;
                    }

                    return g;
                });
            }
        }

        return (
            <div className="gq-cw-menu-container">
                <div className="gq-cw-menu-header">
                    <div className="gq-cw-menu-header-title">
                        <span>Groups</span>
                    </div>

                    <div className="gq-cw-menu-header-button flex-container align-center-middle">
                        <GQButton
                            icon="gqi-add"
                            noBorder={true}
                            noPadding={true}
                            style={{ color: '#001939' }}
                            onClick={this.onCreateGroup}
                            disabled={this.getCurrentActiveId() === null}
                        />
                    </div>
                </div>

                <div className="gq-cw-menu-items">
                    {!groups || groups.length === 0 ? (
                        <GQLoader />
                    ) : (
                        _.map(
                            _.filter(
                                groups,
                                g =>
                                    !(
                                        g.isGQGroup &&
                                        g.name === 'All' &&
                                        g.id === null
                                    )
                            ),
                            this.generateItems
                        )
                    )}
                </div>

                <Toast
                    show={this.state.showToast}
                    text={this.state.toastData.text}
                    toastClass={this.state.toastData.class}
                />
            </div>
        );
    }

    private getCurrentActiveId() {
        if (!this.props.activeEditingGroup) {
            return;
        }

        return this.props.activeEditingGroup.id;
    }

    private onCreateGroup = () => {
        countryGroupStore.setActiveGroup({
            id: null,
            isGQGroup: false,
            name: 'New Group',
            countries: [],
        });
    };

    private generateItems = (group: IActiveGroup) => {
        const onClick = () => {
            countryGroupStore.setActiveGroup(group);
        };

        const onDelete = async () => {
            try {
                await API.deleteActiveGroup(group);
                API.clearActiveGroupsData();
                const updatedGroups = await API.getActiveGroups();
                rootStore.settingsStore.setActiveGroups(updatedGroups.groups);
                const isDeletingCurrentActiveGroup =
                    group.id === this.props.activeGroupId;
                if (isDeletingCurrentActiveGroup) {
                    rootStore.settingsStore.setActiveGroupId(null);
                }
                this.setState({
                    showToast: true,
                    toastData: {
                        text: 'Group deleted',
                        class: 'success',
                    },
                });
                this.resetToast();
            } catch (ex) {
                this.setState({
                    showToast: true,
                    toastData: {
                        text: ex.message || 'Error deleting the group',
                        class: 'error',
                    },
                });

                this.resetToast();
            }
        };

        return (
            <GQCGMenuItem
                key={group.id}
                active={false}
                editing={group.id === this.getCurrentActiveId()}
                displayName={group.name}
                id={group.id}
                onClick={onClick}
                onDelete={onDelete}
                onSetActive={null}
                isGQ={group.isGQGroup}
            />
        );
    };

    private resetToast() {
        setTimeout(() => {
            this.setState({
                showToast: false,
                toastData: {
                    text: '',
                    class: '',
                },
            });
        }, 3000);
    }
}
