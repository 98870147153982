import React from 'react';
import ScoreEvent from './ScoreEvent';
import { IEvent, VIEW } from '../../interfaces';
import APIService from '../../services/APIService';
import scoreEventStore from './ScoreEventStore';
import rootStore, { IMobxRootState } from '../../RootStore';
import KeyValueStore from '../../stores/KeyValueStore';
import CancelableEvents, {
    CANCELED_PROMISE_ERROR,
} from '../../utils/CancelableEvents';
import { RouteComponentProps } from 'react-router';
import EventsFeedDataProvider from 'components/eventsFeed/EventFeedDataProvider';
import { inject, observer } from 'mobx-react';
const scoreEventCountryStore = new KeyValueStore<number>(true, Number);
const scoreEventRiskStore = new KeyValueStore<number>(false, Number, false);
scoreEventRiskStore.setValue(1);

interface IScoreEventProviderState {
    event: IEvent;
    isLoadingArticle: boolean;
}

type Props = {
    canUserScore?: boolean;
    canUserEditScore?: boolean;
    canUserDeleteScore?: boolean;
} & RouteComponentProps<{ id: string }>;

@inject(({ UserStore }: IMobxRootState) => {
    return {
        canUserScore: UserStore.uscoresPermissions.can_score,
        canUserEditScore: UserStore.uscoresPermissions.can_edit,
        canUserDeleteScore: UserStore.uscoresPermissions.can_delete,
    };
})
@observer
export default class ScoreEventProvider extends React.Component<
    Props,
    IScoreEventProviderState
> {
    private currentId: string = null;
    private cancelableEvents = new CancelableEvents();
    constructor(props: any) {
        super(props);
        this.state = {
            event: null,
            isLoadingArticle: false,
        };
    }
    public componentDidMount() {
        rootStore.chartStore.setState({ expanded: false });
        scoreEventCountryStore.overrideValues(
            ...rootStore.countryStore.currentCountryList
        );
        scoreEventRiskStore.overrideValues(1);
        rootStore.appStore.setCurrentDisplayView(VIEW.SCORE_EVENT);
        rootStore.eventsFeedStore.setcollapse(true);
    }

    public componentWillUnmount() {
        scoreEventStore.setActiveEvent(null);
        this.cancelableEvents.cancelAll();
    }
    public render() {
        return (
            <React.Fragment>
                <EventsFeedDataProvider />
                <ScoreEvent
                    {...this.props}
                    close={this.close}
                    getData={this.getData}
                    isLoadingArticle={this.state.isLoadingArticle}
                />
            </React.Fragment>
        );
    }

    public getData = async (id: string) => {
        this.setState({ isLoadingArticle: true });
        try {
            this.currentId = id;
            if (id) {
                const useCache = !(
                    this.props.canUserScore ||
                    this.props.canUserEditScore ||
                    this.props.canUserDeleteScore
                );
                const event = await this.cancelableEvents.promise(() => {
                    return APIService.getScoreEvent(Number(id), useCache);
                });
                if (this.currentId !== id) {
                    return;
                }
                scoreEventStore.setActiveEvent(event);
            } else {
                scoreEventStore.setActiveEvent(null);
            }
        } catch (err) {
            if (err.message === CANCELED_PROMISE_ERROR) {
                return;
            }
        } finally {
            if (this.currentId === id) {
                this.setState({ isLoadingArticle: false });
            }
        }
    };

    private close = () => {
        rootStore.risksStore.setMinimized(false);
        rootStore.routingStore.goBackToPreviousDifferentView();
    };
}
