import React from 'react';
import cx from 'classnames';
import { size, get } from 'lodash/fp';
import HeatmapLegend from './HeatmapLegend';
import {
    HeatmapCellType,
    HeatmapCellPosition,
    HeatmapPercentile,
    HeatmapScoreType,
} from './HeatmapTypes';
import HeatmapCells from './HeatmapCells';
import HeatmapHeader from './Header/HeatmapHeader';
import {
    IActiveDimension,
    IActiveClientFacingIndicator,
    E_RISK_TYPE,
} from 'services/APIService';
import HeatmapRiskSelection from './HeatmapRiskSelection';
import GQLoader from 'components/GQLoader/GQLoader';
import HeatmapScoreTypeSelection from './HeatmapScoreTypeSelection';

type Props = {
    cells: HeatmapCellType[][];
    percentiles: HeatmapPercentile[];
    onCellPress: (rowIndex, colIndex) => void;
    onCellMouseEnter: (rowIndex, colIndex) => void;
    onCellMouseLeave: () => void;
    dimensionsList: IActiveDimension[];
    countryNames: string[];
    selectedPercentile: HeatmapPercentile;
    onSelectPercentile: (percentile: HeatmapPercentile) => void;
    hoveredCell: HeatmapCellPosition;
    selectedRiskId: number;
    selectedCountry: string;
    hoveredRiskId: number;
    riskType: E_RISK_TYPE;
    scoreType: HeatmapScoreType;
    onChangeRiskType: (riskType: E_RISK_TYPE) => void;
    onChangeScoreType: (scoreType: HeatmapScoreType) => void;
    onDimensionClick: (dimensionId: number) => void;
    onCountryClick: (countryName: string) => void;
    clientFacingIndicators: IActiveClientFacingIndicator[];
    numDays: number;
    isChangingRiskType: boolean;
    isChangingScoreType: boolean;
    isChangingExternalProp: boolean;
    disableToggle?: boolean;
};

const Heatmap = React.memo(
    ({
        cells,
        percentiles,
        onCellPress,
        onCellMouseEnter,
        onCellMouseLeave,
        countryNames,
        dimensionsList,
        selectedPercentile,
        onSelectPercentile,
        hoveredCell,
        selectedRiskId,
        hoveredRiskId,
        riskType,
        scoreType,
        onChangeRiskType,
        onChangeScoreType,
        onDimensionClick,
        onCountryClick,
        selectedCountry,
        clientFacingIndicators,
        numDays,
        isChangingRiskType,
        isChangingScoreType,
        isChangingExternalProp,
        disableToggle
    }: Props) => {
        const numRows = size(countryNames);

        return (
            <div className="heatmap-container">
                <HeatmapHeader
                    dimensionsList={dimensionsList}
                    hoveredIndicatorId={hoveredRiskId}
                    selectedIndicatorId={selectedRiskId}
                    riskType={riskType}
                    onIndicatorClick={onDimensionClick}
                    clientFacingIndicators={clientFacingIndicators}
                />
                <div className="heatmap-grid-and-legend-container">
                    <div className="heatmap-grid-container">
                        <div
                            className="heatmap-grid"
                            onMouseLeave={onCellMouseLeave}>
                            {isChangingExternalProp && (
                                <div className="heatmap-grid-loading">
                                    <GQLoader />
                                </div>
                            )}
                            {/* without the below div you will need to add specific css
                    props 'grid-row'/'grid-col' to each row/col-header */}
                            <div style={{ gridRow: 1, gridColumn: 1 }} />
                            {/* bottom padding to fix the visibility issue of the last items due to
                            the linear gradient background at the bottom */}
                            <div
                                style={{
                                    gridRowStart: numRows + 2,
                                    height: '40px',
                                }}
                            />

                            {countryNames.map(
                                (country: string, rowIndex: number) => {
                                    return (
                                        <div
                                            className={cx([
                                                'heatmap-row-header',
                                                {
                                                    'heatmap-row-header-selected':
                                                        selectedCountry ===
                                                        country,
                                                },
                                                {
                                                    'heatmap-row-header-hovered':
                                                        get(
                                                            'row',
                                                            hoveredCell
                                                        ) === rowIndex,
                                                },
                                            ])}
                                            key={country}
                                            onClick={() =>
                                                onCountryClick(country)
                                            }
                                            onMouseEnter={() =>
                                                onCellMouseEnter(rowIndex, -1)
                                            }>
                                            <div className="heatmap-row-header-text">
                                                {country}
                                            </div>
                                        </div>
                                    );
                                }
                            )}
                            <HeatmapCells
                                cells={cells}
                                onCellMouseEnter={onCellMouseEnter}
                                onCellPress={onCellPress}
                                riskType={riskType}
                            />
                        </div>
                    </div>
                    <div className="heatmap-legend-container">
                        {!disableToggle && (
                            <div className="heatmap-toggles-container">
                                <HeatmapScoreTypeSelection
                                    scoreType={scoreType}
                                    onChangeScoreType={onChangeScoreType}
                                    isChangingScoreType={isChangingScoreType}
                                    isChangingRiskType={isChangingRiskType}
                                />
                                <HeatmapRiskSelection
                                    riskType={riskType}
                                    onChangeRiskType={onChangeRiskType}
                                    isChangingRiskType={isChangingRiskType}
                                    isChangingScoreType={isChangingScoreType}
                                />
                            </div>
                        )}
                        <HeatmapLegend
                            percentiles={percentiles}
                            onSelect={onSelectPercentile}
                            selectedPercentile={selectedPercentile}
                            numDays={numDays}
                            scoreType={scoreType}
                        />
                    </div>
                </div>
            </div>
        );
    }
);

export default Heatmap;
