import React from 'react';
import { GQButton } from '../GQButton';

interface IGQGenericPopupProps {
    title: string;
    text: string;
    buttons?: JSX.Element[];
    icon?: string;
    onClose: () => void;
}

export default class GQGenericPopup extends React.PureComponent<
    IGQGenericPopupProps
> {
    public render() {
        return (
            <div className="gq-generic-popup flex-container flex-dir-column">
                <div className="gq-generic-popup-top">
                    <div className="gq-generic-popup-exit-button flex-container align-middle align-right">
                        <GQButton
                            icon="gqi-close"
                            onClick={this.props.onClose}
                            noBorder
                            noPadding
                        />
                    </div>
                    <div className="gq-generic-popup-content flex-container">
                        {this.props.icon && (
                            <div className="gq-generic-popup-content-icon flex-container">
                                <i className={this.props.icon} />
                            </div>
                        )}
                        <div className="gq-generic-popup-content-text flex-container flex-dir-column">
                            <div className="gq-generic-popup-title">
                                <h3>{this.props.title}</h3>
                            </div>
                            <div className="gq-generic-popup-text">
                                <p>{this.props.text}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="gq-generic-popup-bottom">
                    {this.props.buttons && this.props.buttons.length && (
                        <div className="gq-generic-popup-buttons flex-container align-middle align-right">
                            {this.props.buttons}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
