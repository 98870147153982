const mailchimpConfig = {
    fontSize: 16,
    bodyPrefix: `<!--*|IF:MC_PREVIEW_TEXT|*-->
<!--[if !gte mso 9]><!---->
<span class="mcnPreviewText" style="display: none !important; font-size:0px; line-height:0px; max-height:0px; max-width:0px; opacity:0; overflow:hidden; visibility:hidden; mso-hide:all;">
*|MC_PREVIEW_TEXT|*
</span>
<!--<![endif]-->
<!--*|END:IF|*-->`,
};

export default mailchimpConfig;
