import React from 'react';
import { observer } from 'mobx-react';
import cx from 'classnames';

interface IFieldAttributes {
    type?: string;
    placeholder?: string;
}

interface IField {
    touched: boolean;
    error: string;
    bind: (attr: IFieldAttributes) => IFieldAttributes;
}

interface IFormFieldProps extends IFieldAttributes {
    className?: string;
    field: IField;
    tooltip?: JSX.Element;
    onRef?: () => void;
}

class FormField extends React.Component<IFormFieldProps> {
    public static defaultProps = {
        type: 'text',
        className: '',
        placeholder: '',
    };

    public render() {
        const {
            className,
            field,
            type,
            placeholder,
            tooltip,
            onRef,
        } = this.props;

        return (
            <div className={`input-container ${className}`}>
                <input
                    className={cx({
                        'has-error': Boolean(field.error),
                    })}
                    ref={onRef}
                    {...field.bind({ type, placeholder })}
                />

                {tooltip && (
                    <div className="form-field-tooltip">
                        <i className="gqi-guide" />

                        <div className="form-field-tooltip__text">
                            {tooltip}
                        </div>
                    </div>
                )}

                {field.error && (
                    <div className="input-error-message">{field.error}</div>
                )}
            </div>
        );
    }
}

export default observer(FormField);
