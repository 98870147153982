import React from 'react';
import { IActiveCountry } from '../../../interfaces';
import GQFlag from './../../GQFlag';
import * as _ from 'lodash';
export interface IDropDownProps {
    options: IActiveCountry[];
    top: number;
    left: number;
    show: boolean;
    activeIDs: number[];
    onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}
const CustomDropDown: React.SFC<IDropDownProps> = (props: IDropDownProps) => {
    return props.show ? (
        <div
            className="custom-dropdown"
            style={{ position: 'fixed', top: props.top, left: props.left }}>
            {props.options &&
                props.options.map(item => {
                    const isActive = _.includes(props.activeIDs, item.id);
                    return (
                        <div
                            id={String(item.id)}
                            className="dropdown-item"
                            key={item.id}
                            onClick={props.onClick}>
                            <div className="dropdown-checkbox flex-container align-middle">
                                <input
                                    className="dropdown-checkbox-input"
                                    type="checkbox"
                                    checked={isActive}
                                    readOnly={true}
                                />
                            </div>
                            <div className="dropdown-flag align-middle flex-container">
                                <GQFlag countryId={item.id} key={item.id} />
                            </div>
                            <div className="dropdown-label">
                                <label>{item.name}</label>
                            </div>
                        </div>
                    );
                })}
        </div>
    ) : null;
};

export default CustomDropDown;
