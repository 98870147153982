import React, { ReactNode } from 'react';
import { Router as ReactRouter } from 'react-router-dom';
import { inject, Provider } from 'mobx-react';
import 'styles/app.scss';
import rootStore, { IMobxRootState } from './RootStore';
import Router from 'Router';
import LoginAnimationContainer from 'components/authPages/LoginAnimationContainer';
import { isCurrentBrowserSupported } from 'utils/browserUtils';
import Events from './services/EventsService';
import { GQToastContainer } from 'components/GQToast/GQToastContainer';
import ChartScoreService from './services/ChartScoresService';
import { E_TOAST_TYPE } from 'components/GQToast/GQToast';
require('./services/lib/WebSocketService');

const isBrowserSupported = isCurrentBrowserSupported();
let toaster: GQToastContainer;

type Props = {
    loggedIn?: boolean;
    firstLoad?: boolean;
};

const AppProvider = React.memo(({ children }: { children: ReactNode }) => {
    return (
        <Provider {...rootStore}>
            <ReactRouter history={rootStore.routingStore.history}>
                {children}
            </ReactRouter>
        </Provider>
    );
});

const App = inject(({ appStore }: IMobxRootState) => {
    return {
        loggedIn: appStore.isLoggedIn,
        firstLoad: appStore.isFirstLoad,
    };
})(({ loggedIn, firstLoad }: Props) => {
    if (!loggedIn && firstLoad) {
        return (
            <div className="app">
                <LoginAnimationContainer />
            </div>
        );
    }

    return <Router loggedIn={loggedIn} />;
});

Events.addListener('API_FORCE_LOGOUT', () => {
    if (rootStore.appStore.isLoggedIn) {
        rootStore.appStore.setIsLoggedIn(false);
        ChartScoreService.clearCache();
    }
});

if (!isBrowserSupported && toaster) {
    toaster.createToast(
        'Your browser is not supported. Please use Chrome to avoid unexpected behaviour and errors.',
        {
            type: E_TOAST_TYPE.ERROR,
            autoClose: false,
        }
    );
}

export default function() {
    return (
        <AppProvider>
            <App />
        </AppProvider>
    );
}
