import { ICWChangeNotification } from '../interfaces';
import { EventEmitter, EventSubscription } from 'fbemitter';
import appStore from '../AppStore';
export enum GQEmitterEvents {
    ARROW_EVENT = 'keyboard_arrow_event',
    MOUSE_LEFT_CLICK = 'mouse_left_click',
    POPUP_ADD_REF = 'popup_add_ref',
    ESCAPE = 'keyboard_escape_event',
    ENTER = 'keyboard_enter_event',
    DELETE = 'keyboard_delete_event',
    BACKSPACE = 'keyboard_backspace_event',
    HIDE_DELETE = 'hide_delete',
    PN_RELOAD_COUNTRY = 'push_notification:reload_country',
    PN_NEW_GQ_INSIGHT = 'push_notification:new_insight',
    GET_GRAPH_SNAPSHOT = 'graph_snapshot:get',
    SEND_GRAPH_SNAPSHOT = 'graph_snapshot:send',
    TITLE_BAR_CHANGE = 'title_bar:set',
    INSIGHT_MOUNT = 'insight:mount',
    CW_CHANGE_NOTIFICATION = 'cw_notification:change',
    SHOW_TOAST = 'toast_container:show',
    HIDE_TOAST = 'toast_container:hide',
}

export { EventSubscription };

export enum E_ARROW_KEYS {
    ARROW_LEFT = 'ArrowLeft',
    ARROW_RIGHT = 'ArrowRight',
    ARROW_UP = 'ArrowUp',
    ARROW_DOWN = 'ArrowDown',
}
export interface IArrowKey {
    arrow: E_ARROW_KEYS;
    isShift: boolean;
}

let debugString = '';
let debugTimeout: any = null;

export interface ITitleBarEvent {
    title: string;
    onClose: () => void;
}
class GQEmitter extends EventEmitter {
    public constructor() {
        super();
        try {
            window.addEventListener('keydown', this.onKeyDown);
            window.addEventListener('click', this.onMouseClick);
        } catch (err) {
            // web worker
        }
    }
    public registerEvent(
        eventName: GQEmitterEvents,
        cb: (data?: any) => void
    ): EventSubscription {
        return this.addListener(eventName, cb);
    }

    public emitEvent(eventName: GQEmitterEvents, data?: any) {
        super.emit(eventName, data);
    }

    public registerOnDeleteClick(cb: (id: string) => void): EventSubscription {
        return this.addListener(GQEmitterEvents.HIDE_DELETE, cb);
    }

    public emitOnDeleteClick(id: string) {
        this.emitEvent(GQEmitterEvents.HIDE_DELETE, id);
    }

    public registerOnArrowKey(
        cb: (event: IArrowKey) => void
    ): EventSubscription {
        return this.addListener(GQEmitterEvents.ARROW_EVENT, cb);
    }

    public registerOnMouseLeftClick(cb: () => void): EventSubscription {
        return this.addListener(GQEmitterEvents.MOUSE_LEFT_CLICK, cb);
    }

    public registerOnPopupAddRef(cb: (data?: any) => void): EventSubscription {
        return this.addListener(GQEmitterEvents.POPUP_ADD_REF, cb);
    }
    public registerOnEscKeyboardClick(cb: () => void): EventSubscription {
        return this.addListener(GQEmitterEvents.ESCAPE, cb);
    }
    public registerOnEnterKey(cb: () => void): EventSubscription {
        return this.addListener(GQEmitterEvents.ENTER, cb);
    }
    public registerOnDelKey(cb: () => void): EventSubscription {
        return this.addListener(GQEmitterEvents.DELETE, cb);
    }
    public registerOnBackspaceKey(cb: () => void): EventSubscription {
        return this.addListener(GQEmitterEvents.BACKSPACE, cb);
    }
    public emitOnPopupAddRef(data?: any) {
        this.emitEvent(GQEmitterEvents.POPUP_ADD_REF, data);
    }

    public registerOnReloadCountry(
        cb: (country: number[]) => void
    ): EventSubscription {
        return this.addListener(GQEmitterEvents.PN_RELOAD_COUNTRY, cb);
    }

    public emitOnPushNotificationReloadCountry(cid: number[]) {
        this.emitEvent(GQEmitterEvents.PN_RELOAD_COUNTRY, cid);
    }
    // public registerOnGraphSnapshotSend(cb: (uri: string) => void): EventSubscription {
    //     return this.addListener(GQEMitterEvents.SEND_GRAPH_SNAPSHOT, cb);
    // }
    // public emitOnGraphSnapshotSend(uri: string) {
    //     this.emitEvent(GQEMitterEvents.SEND_GRAPH_SNAPSHOT, uri);
    // }
    public registerOnTitleBarChange(
        cb: (data: ITitleBarEvent) => void
    ): EventSubscription {
        return this.addListener(GQEmitterEvents.TITLE_BAR_CHANGE, cb);
    }
    public emitOnTitleBarChange(data: ITitleBarEvent) {
        this.emitEvent(GQEmitterEvents.TITLE_BAR_CHANGE, data);
    }
    public registerOnInsightMount(cb: (id: string) => void): EventSubscription {
        return this.addListener(GQEmitterEvents.INSIGHT_MOUNT, cb);
    }
    public emitOnInsightMount(id: string) {
        this.emitEvent(GQEmitterEvents.INSIGHT_MOUNT, id);
    }
    public registerOnCWChangeNotification(
        cb: (presetChange: ICWChangeNotification) => void
    ) {
        return this.addListener(GQEmitterEvents.CW_CHANGE_NOTIFICATION, cb);
    }
    public emitOnCWChangeNotification(presetChange: ICWChangeNotification) {
        this.emitEvent(GQEmitterEvents.CW_CHANGE_NOTIFICATION, presetChange);
    }
    private onKeyDown = (event: KeyboardEvent) => {
        if (
            ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(
                event.key
            )
        ) {
            this.emitEvent(GQEmitterEvents.ARROW_EVENT, {
                arrow: event.key,
                isShift: event.shiftKey,
            });
        }
        if (event.key === 'Escape') {
            this.emitEvent(GQEmitterEvents.ESCAPE);
            return;
        }
        if (event.key === 'Enter') {
            this.emitEvent(GQEmitterEvents.ENTER);
        }
        if (event.key === 'Delete') {
            this.emitEvent(GQEmitterEvents.DELETE);
            return;
        }
        if (event.key === 'Backspace') {
            this.emitEvent(GQEmitterEvents.BACKSPACE);
            return;
        }
        if (event.key === 'd') {
            debugString += 'd';
            clearTimeout(debugTimeout);
            if (debugString === 'dddddd') {
                appStore.setDebugMode(!appStore.debugMode);
            }
            debugTimeout = setTimeout(() => {
                debugString = '';
            }, 1000);
        } else {
            debugString = '';
        }
    };

    private onMouseClick = (event: MouseEvent) => {
        if (event.button === 0) {
            this.emitEvent(GQEmitterEvents.MOUSE_LEFT_CLICK);
        }
    };
}
export default new GQEmitter();
