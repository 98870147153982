import { observable, action, extendObservable } from 'mobx';
import {
    EVENTS_FILTER,
    IUscoreActive,
    SCORED_EVENTS_FILTER,
} from './EventsFeedInterfaces';
import { IRootStoreExporter } from '../../interfaces';

// eslint-disable-next-line import/no-webpack-loader-syntax
const Worker = require('worker-loader!./Worker');

interface IEventsFeedStoreStateOptional {
    currentFilter?: EVENTS_FILTER;
    scoredEventsFilter?: SCORED_EVENTS_FILTER;
    currentSelectedUscore?: IUscoreActive;
    isMinimized?: boolean;
    isCollapsed?: boolean;
    onHold?: boolean;
    showMaximize?: boolean;
    showSearchBar?: boolean;
    searchFocused?: boolean;
}
export class EventsFeedStore implements IRootStoreExporter {
    @observable public currentFilter: EVENTS_FILTER;
    @observable public scoredEventsFilter: SCORED_EVENTS_FILTER;
    @observable public currentSelectedUscore: IUscoreActive;
    @observable public currentHoveredUscore: IUscoreActive = null;
    @observable public isMinimized: boolean;
    @observable public isCollapsed: boolean;
    @observable public defaultEvent: number;
    @observable public collapseClickedValue: boolean;
    @observable public onHold: boolean = false;
    @observable public searchString: string = '';
    @observable public showMaximize: boolean;
    @observable public showSearchBar: boolean;
    @observable public searchFocused: boolean;

    public eventsHandlerWorker: Worker = new Worker();

    constructor() {
        this.toInitialState();
    }
    @action public setMinimized(value: boolean) {
        this.isMinimized = value;
    }
    @action public setcollapse(value: boolean) {
        this.isCollapsed = value;
    }

    @action public setCollapseClickedValue(value: boolean) {
        this.collapseClickedValue = value;
    }
    @action public setDefaultEvent(value: number) {
        this.defaultEvent = value;
    }

    @action public setCurrentFilter(filter: EVENTS_FILTER) {
        this.currentFilter = filter;
    }

    @action public setScoredEventsFilter(filter: SCORED_EVENTS_FILTER) {
        this.scoredEventsFilter = filter;
    }

    @action public setState(state: IEventsFeedStoreStateOptional) {
        extendObservable(this, state);
    }

    @action public toInitialState() {
        this.setState({
            currentFilter: EVENTS_FILTER.SCORED,
            scoredEventsFilter: SCORED_EVENTS_FILTER.ALL,
            currentSelectedUscore: null,
            isMinimized: false,
            showMaximize: true,
            showSearchBar: false,
            searchFocused: false,
        });
    }

    @action public setCurrentSelectedUscore(us: IUscoreActive) {
        this.currentSelectedUscore = us;
    }

    @action public setCurrentHoveredUscore(us: IUscoreActive) {
        this.currentHoveredUscore = us;
    }

    @action public setHold(val: boolean) {
        if (this.onHold !== val) {
            this.setState({
                onHold: val,
            });
        }
    }

    @action public setSearchString(str: string) {
        if (!str) {
            this.searchString = '';
            return;
        }
        this.searchString = str;
    }

    @action public setShowMaximize(val: boolean) {
        this.showMaximize = val;
    }

    @action public setShowSearchBar(val: boolean) {
        this.showSearchBar = val;
    }

    @action public setSearchFocused(val: boolean) {
        this.searchFocused = val;
    }

    public exportKeys() {
        return ['currentFilter', 'currentSelectedUscore', 'isMinimized'];
    }
}

export default new EventsFeedStore();
