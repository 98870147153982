import React from 'react';
import { inject, observer } from 'mobx-react';
import * as _ from 'lodash';

import { IActiveCountry, IActiveGroup } from '../../../../../interfaces';
import GQButton from '../../../../GQButton/GQButton';
import CountryList from './GQCGCountryList';
import SelectedList from './GQCGSelectedList';
import rootStore, { IMobxRootState } from '../../../../../RootStore';
import countryGroupStore, { ICGStore } from '../CountryGroupsStore';
import GQInput from '../../../../GQInput/GQSettingsInput';
import API from '../../../../../services/APIService';
import Toast from '../../../../scoringPage/lib/Toast';
import loading from '../../../../../services/LoadingService';

interface Props {
    activeGroup?: IActiveGroup;
    selectedIds?: number[];
    licensedCountriesArr?: IActiveCountry[];
}

interface State {
    showList: boolean;
    filter: string;
    groupName: string;
    toast: {
        showToast: boolean;
        msg: string;
        className: string;
    };
}

@inject(({ cgStore, countryStore }: IMobxRootState & ICGStore) => {
    return {
        activeGroup: cgStore.activeGroup,
        selectedIds: cgStore.selectedIds,
        licensedCountriesArr: countryStore.countries.licensedCountriesArr,
    };
})
@observer
export default class GQCGPicker extends React.Component<Props, State> {
    private mounted = false;
    constructor(props: Props) {
        super(props);
        this.state = {
            filter: null,
            showList: false,
            groupName: '',
            toast: {
                showToast: false,
                msg: '',
                className: '',
            },
        };
    }
    public componentDidMount() {
        this.mounted = true;
    }
    public componentWillUnmount() {
        this.mounted = false;
    }
    public UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.activeGroup) {
            if (!this.props.activeGroup) {
                this.setState({
                    groupName: nextProps.activeGroup.name,
                });
            } else if (this.props.activeGroup.id !== nextProps.activeGroup.id) {
                this.setState({
                    groupName: nextProps.activeGroup.name,
                });
            }
        }
    }
    public render() {
        const { activeGroup, licensedCountriesArr } = this.props;
        const disabled = activeGroup && activeGroup.isGQGroup;
        const isGQ = activeGroup && activeGroup.isGQGroup;
        return (
            <div className="gq-cg-picker">
                <GQInput
                    focusOnMount={this.state.groupName === 'New Group'}
                    editable={!!activeGroup || !isGQ}
                    placeholder={activeGroup ? activeGroup.name : 'N/A  '}
                    inputValue={this.state.groupName}
                    onChange={this.onNameChange}
                    style={{ marginRight: 16 }}
                />
                <div className="gq-cg-picker-bar">
                    <div className="gq-cg-search">
                        <i className="icon gqi-search" />
                        <input
                            disabled={!activeGroup && isGQ}
                            type="search"
                            placeholder="Type country..."
                            onChange={this.onFilterChange}
                        />
                        <i className="icon gqi-dropdown" />
                        {this.state.showList && (
                            <CountryList
                                show={this.state.showList}
                                list={
                                    licensedCountriesArr
                                        ? _.filter(
                                              licensedCountriesArr,
                                              this.filterList
                                          )
                                        : []
                                }
                                onChange={this.toggleCountry}
                            />
                        )}
                    </div>
                    <div className="gq-cg-picker-country-list">
                        <SelectedList
                            selectedIds={countryGroupStore.selectedIds}
                            disabled={
                                !this.props.activeGroup ||
                                (this.props.activeGroup &&
                                    this.props.activeGroup.isGQGroup)
                            }
                            onClick={this.toggleCountry}
                        />
                    </div>
                </div>
                <GQButton
                    caption="Save Group"
                    style={{ height: '33px', fontSize: '13px' }}
                    disabled={!activeGroup || disabled}
                    onClick={this.onSave}
                />
                <Toast
                    show={this.state.toast.showToast}
                    text={this.state.toast.msg}
                    toastClass={this.state.toast.className}
                />
            </div>
        );
    }
    private toggleCountry = (id: number) => {
        countryGroupStore.toggleId(id);
    };

    private onSave = async () => {
        const toSave = {
            ...this.props.activeGroup,
            countries: this.props.selectedIds,
            name: this.state.groupName,
        };
        try {
            await API.saveActiveGroup(toSave);
            API.clearActiveGroupsData();
            const updatedGroups = await API.getActiveGroups();
            rootStore.settingsStore.setActiveGroups(updatedGroups.groups);
            loading.start();
            if (this.mounted) {
                this.setState({
                    toast: {
                        msg: 'Group saved!',
                        className: 'success',
                        showToast: true,
                    },
                });
                this.resetToast();
            }
            loading.stop();
        } catch (ex) {
            if (this.mounted) {
                this.setState(
                    {
                        toast: {
                            msg: 'There was a problem saving the group',
                            className: 'error',
                            showToast: true,
                        },
                    },
                    () => {
                        this.resetToast();
                    }
                );
            }
        }
    };

    private resetToast = () => {
        setTimeout(() => {
            if (this.mounted) {
                this.setState({
                    toast: {
                        showToast: false,
                        msg: '',
                        className: '',
                    },
                });
            }
        }, 3000);
    };
    private onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.currentTarget;
        this.setState({
            groupName: value,
        });
    };
    private onFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            showList: !!e.target.value,
            filter: e.target.value,
        });
    };
    private filterList = (c: IActiveCountry) => {
        if (!this.props.selectedIds) {
            return false;
        }
        return (
            ((this.state.filter &&
                c.name
                    .toLowerCase()
                    .includes(this.state.filter.toLowerCase())) ||
                !this.state.filter) &&
            this.props.selectedIds.indexOf(c.id) === -1
        );
    };
}
