import React from 'react';
import {
    IEvent,
    IEventUScore,
    IActiveCountry,
    IActiveDimension,
} from '../../interfaces';
import ScoreCountries from './GQScoreCountries';
import ScoreRisks from './GQScoreRisks';
import { durationOptions, effectOptions } from './consts';
import ScoreSliderData from './GQScoreSliderData';
import { IRiskItem } from './GQScoreRiskItem';
import { get, size, first, filter, forEach } from 'lodash/fp';
import { inject, observer } from 'mobx-react';
import { IMobxRootState } from 'RootStore';

export interface Props {
    event: IEvent;
    score?: IEventUScore;
    editMode: boolean;
    onAddedScore: (score: IEventUScore) => void;
    onEdittedScore: (score: IEventUScore) => void;
    countriesList: IActiveCountry[];
    dimensions?: IActiveDimension[];
}
export interface State {
    selectedCountryId: number;
    selectedRiskId: number;
    impact: number;
    duration: number;
    risks?: Array<{ dimension_id: number; relevance_value: number }>;
    scoreId?: string;
    organizationId?: string;
}

// NOTE: until now, the BE was responsible for choosing the default score duration.
// now the default duration is hard-coded in the FE (1-3m)
const DEFAULT_DURATION = 30;

@inject(({ risksStore }: IMobxRootState) => {
    return {
        dimensions: risksStore.allDimensions,
    };
})
@observer
export default class ScoreArticleContainer extends React.Component<
    Props,
    State
> {
    constructor(props: Props) {
        super(props);
        this.state = {
            selectedCountryId: 0,
            selectedRiskId: 0,
            impact: 0,
            duration: DEFAULT_DURATION,
            scoreId: null,
            organizationId: '',
        };
    }

    public componentDidUpdate(prevProps: Props) {
        const eventDidChange =
            get('event.id', this.props) !== get('event.id', prevProps);
        if (eventDidChange) {
            this.setState({
                selectedCountryId: 0,
                selectedRiskId: 0,
                impact: 0,
                duration: DEFAULT_DURATION,
                organizationId: '',
            });
            return;
        }

        const scoreDidChange = prevProps.score !== this.props.score;
        if (scoreDidChange) {
            let countryId = get('score.country_id', this.props);

            const eventCountriesList = get('event.countries', this.props);
            if (size(eventCountriesList) === 1 && countryId === 0) {
                countryId = first(eventCountriesList);
            }

            const { score } = this.props;

            this.setState({
                selectedCountryId: countryId || this.state.selectedCountryId,
                selectedRiskId: score.dimension_id,
                impact: score.impact,
                duration: score.duration,
                scoreId: score.score_id,
                organizationId: score.organization_id,
            });
        }
    }

    private handleSubmit = () => {
        const scoreData = {
            country_id: this.state.selectedCountryId,
            dimension_id: this.state.selectedRiskId,
            impact: this.state.impact,
            duration: this.state.duration,
            score_id: this.state.scoreId,
            organization_id: this.state.organizationId,
        };

        if (this.props.editMode) {
            this.props.onEdittedScore(scoreData);
        } else {
            this.props.onAddedScore(scoreData);
        }
    };

    public render() {
        return (
            <div className="score-article-container">
                {this.props.event ? (
                    <div>
                        <ScoreCountries
                            countriesList={this.props.countriesList}
                            suggestedCountries={this.props.event.countries}
                            selectCountry={this.state.selectedCountryId}
                            onSelected={this.onCountrySelected}
                            editMode={this.props.editMode}
                            selectMode={this.state.selectedCountryId === 0}
                        />{' '}
                        <div className="divider" />
                        <ScoreRisks
                            selectedRisk={this.state.selectedRiskId}
                            onSelected={this.onRiskSelected}
                            allRisks={this.risksRelevance()}
                            editMode={this.props.editMode}
                        />{' '}
                        <div className="divider" />
                        <ScoreSliderData
                            id="scoreEventImpact"
                            sliderName="Impact"
                            values={effectOptions.map(a => a.value)}
                            onDrag={this.onImpactDrag}
                            exactValue={this.state.impact}
                            color={true}
                            findTextByValue={this.findImpactByValue}
                        />
                        <div className="divider" />
                        <ScoreSliderData
                            id="scoreEventImpact"
                            sliderName="Duration"
                            values={durationOptions.map(a => a.value)}
                            onDrag={this.onDurationDrag}
                            exactValue={this.state.duration}
                            color={false}
                            findTextByValue={this.findDurationByValue}
                        />
                        <div className="divider" />
                    </div>
                ) : (
                    ''
                )}
                <button
                    disabled={
                        this.state.selectedCountryId === 0 ||
                        this.state.selectedRiskId === 0
                    }
                    className="score-article-button"
                    onClick={this.handleSubmit}>
                    {!this.props.editMode ? 'Score' : 'Update'}
                </button>
            </div>
        );
    }
    private findImpactByValue(val: number) {
        for (const option of effectOptions) {
            if (option.isBelong(val)) {
                return option;
            }
        }
    }
    private findDurationByValue(val: number) {
        for (const option of durationOptions) {
            if (option.isBelong(val)) {
                return option;
            }
        }
    }

    private onImpactDrag = (draggedImpact: number) => {
        this.setState({
            impact: draggedImpact,
        });
    };

    private onDurationDrag = (draggedDuration: number) => {
        this.setState({
            duration: draggedDuration,
        });
    };

    private onCountrySelected = (countryID: number) => {
        this.setState({
            selectedCountryId: countryID,
        });
        this.selectImpactAndDuration(countryID, this.state.selectedRiskId);
    };

    private onRiskSelected = (riskID: number) => {
        this.setState({
            selectedRiskId: riskID,
        });
        this.selectImpactAndDuration(this.state.selectedCountryId, riskID);
    };

    private selectImpactAndDuration(countryID: number, riskID: number) {
        if (countryID !== 0 && riskID !== 0) {
            const filteredSuggestion = filter(e => {
                return e.dimension_id === riskID && e.country_id === countryID;
            }, this.props.event.suggestions);
            if (filteredSuggestion.length !== 0) {
                this.setState({
                    impact: filteredSuggestion[0].suggested_value,
                    duration: DEFAULT_DURATION,
                });
            }
        }
    }

    private risksRelevance = (): IRiskItem[] => {
        const res: IRiskItem[] = [];
        let minRelevance = Number.POSITIVE_INFINITY;
        let maxRelevance = Number.NEGATIVE_INFINITY;
        forEach(dim => {
            if (dim.scorable) {
                const curDimRelevance = this.props.event.relevance.find(
                    dimRel => dimRel.dimension_id === dim.id
                );
                const relevance = curDimRelevance
                    ? curDimRelevance.relevance_value
                    : 0;
                minRelevance = Math.min(minRelevance, relevance);
                maxRelevance = Math.max(maxRelevance, relevance);
                res.push({
                    dimension_id: dim.id,
                    dimension_name: dim.lname,
                    relevance_value: relevance,
                    relativeRelevancePercentage: null,
                });
            }
        }, this.props.dimensions);
        res.forEach(risk => {
            risk.relativeRelevancePercentage =
                risk.relevance_value > 0
                    ? ((risk.relevance_value - minRelevance) /
                          (maxRelevance - minRelevance)) *
                      100
                    : 0;
        });
        res.sort((r1, r2) => r1.dimension_id - r2.dimension_id);
        return res;
    };
}
