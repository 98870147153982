import ResizeObserver from 'rc-resize-observer';
import * as _ from 'lodash';
import React from 'react';
import moment from 'moment';
import { runInAction, toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Route, RouteComponentProps, Switch } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import InsightNotes from './InsightNotes';
import GQInput from '../../GQInput/GQInput';
import GQButton from '../../GQButton/GQButton';
import GQLoader from '../../GQLoader/GQLoader';
import {
    C_INSIGHT_NOTE_DELETED,
    C_INSIGHT_NO_SCREENSHOT_SAVE_POPUP,
    C_INSIGHT_NOT_SAVED,
    C_INSIGHT_FILE_TOO_LARGE,
    C_INSIGHT_SNAPSHOT_FAILED,
    E_TIME_PRESET_KEYS,
} from '../../../constants';
import Toast from '../../../components/scoringPage/lib/Toast';
import { generateID } from '../../../utils/generalUtils';
import {
    E_RISK_TYPE,
    E_COMPONENT_TYPE,
    IActivitySnapshot,
    GLOBAL_INDEXES,
    IActiveDimension,
    IAPIActiveClientFacingIndicator,
    IActivityHeatmapSnapshot,
    AutoInsightMetadata,
    IActiveCountryMap,
    IActiveCountry,
} from '../../../services/lib/APIServiceInterfaces';
import EventsService, {
    GQEmitterEvents,
} from '../../../services/EventsService';
import rootStore, { IMobxRootState } from '../../../RootStore';
import ChartScoresService from '../../../services/ChartScoresService';
import SpecificActivityStore, {
    componentFactory,
    castComponentToChart,
    castComponentToMap,
    isMapComponent,
    isCustomComponent,
    isChartComponent,
    castComponentToHeatmap,
} from '../../../stores/SpecificActivityStore';
import chartStore from '../../GQChart/ChartStore';
import GQInsightComponentList from './InsightComponentList';
import { serializeChartData } from '../../GQChart/ChartSerializer';
import APIService from '../../../services/APIService';
import { TimelineLite, TweenLite } from 'gsap';
import { Transition, TransitionGroup } from 'react-transition-group';
import {
    ActivityComponentStore,
    ActivityChartComponentStore,
    ActivityMapComponentStore,
    ActivityCustomImageComponentStore,
    ActivityHeatmapComponentStore,
} from '../../../stores/insights/InsightsComponentStore';
import { countrySelectors, VIEW } from '../../../interfaces';
import CancelableEvents from '../../../utils/CancelableEvents';
import { E_COMPONENT_TYPE_TO_DISPLAY } from './InsightsStore';
import { GQToastContainer } from '../../GQToast/GQToastContainer';
import { E_TOAST_TYPE } from '../../GQToast/GQToast';
import { GQModal, E_MODAL_ALIGN, GQGenericPopup } from '../../GQModal';
import IndicatorsSidebarRiskPicker from '../../sidebarRiskPicker/IndicatorsSidebarRiskPicker';
import { Cancelable, throttle } from 'lodash';
import { first, get, includes, isEmpty, set } from 'lodash/fp';
import InsightTextEditor, { withInlines } from './InsightTextEditor';
import { createEditor, Descendant, Transforms } from 'slate';
import { withHistory } from 'slate-history';
import { ReactEditor, withReact } from 'slate-react';
import { withHtml } from './SlateHTML';
import ContingentSidebarRiskPicker from 'components/sidebarRiskPicker/ContingentSidebarRiskPicker';
import { RiskView } from 'components/Homepage/RiskView';
import { SortByRisk } from 'components/Homepage/SortByRisk';
import { SortByDelta } from 'components/Homepage/SortByDelta';
import { WorldGraph } from 'components/Homepage/WorldGraph';
import { CountryGraph } from 'components/Homepage/CountryGraph';
import { ContingentMap } from 'components/Homepage/ContingentMap';
import { ContingentGraph } from 'components/Homepage/ContingentGraph';
import { HeatmapProvider } from 'components/Heatmap/HeatmapProvider';
import NoteAnchorList from './NoteAnchorList';
import cx from 'classnames';
import {
    HeatmapPercentile,
    HeatmapScoreType,
} from 'components/Heatmap/HeatmapTypes';
import loading from '../../../services/LoadingService';
import {
    autoGenerateInsight,
    getAutoGenerateName,
} from 'utils/autoInsightUtils';
import { AutoInsightQuestions } from './AutoInsightQuestions';

enum E_POPUP_TYPE {
    SNAPSHOT = 'snapshot',
    SNAPSHOT_FAILED = 'snapshot_failed',
    NOT_SAVED = 'not_saved',
    FILE_TOO_LARGE = 'file_too_large',
}

interface State {
    inProgress: boolean;
    error: string;
    toast: {
        showToast: boolean;
        text: string;
        className: string;
    };
    chart: {
        width: number;
        height: number;
    };
    textEditorValue: Descendant[];
    showRiskPicker: boolean;
    showModal: boolean;
    autoInsightMetadata: AutoInsightMetadata;
}

type HeatmapProps = {
    selectedCountry: number;
    selectedRisk: number;
    startDate: Date;
    timeSpan: E_TIME_PRESET_KEYS;
    riskType: E_RISK_TYPE;
    scoreType: HeatmapScoreType;
    countriesList: number[];
    selectedPercentile: HeatmapPercentile;
};

interface Props
    extends Partial<RouteComponentProps<{ insightsView: VIEW; id: string }>> {
    insightsView: VIEW;
    riskType?: E_RISK_TYPE;
    selectedRisks?: number[];
    selectedCountries?: number[];
    dateFrom?: moment.Moment;
    dateUntil?: moment.Moment;
    isEditingText?: boolean;
    isClientFacingIndicators?: boolean;
    isCountryMode?: boolean;
    activity?: SpecificActivityStore;
    annoMode?: boolean;
    displayedComponent?: ActivityComponentStore;
    isEditingComponent?: boolean;
    activeGlobalIndex?: GLOBAL_INDEXES[];
    setDisplayedComponent?: (
        map: ActivityMapComponentStore,
        chart: ActivityChartComponentStore,
        heatmap: ActivityHeatmapComponentStore
    ) => void;
    setEditingComponent?: (
        map: ActivityMapComponentStore,
        chart: ActivityChartComponentStore,
        heatmap: ActivityHeatmapComponentStore
    ) => void;
    restoreEditingComponent?: () => void;
    setActivity?: (activity: SpecificActivityStore) => void;
    setActiveDisplayedComponentType?: (
        type: E_COMPONENT_TYPE_TO_DISPLAY
    ) => void;
    setAnnoMode?: (state: boolean) => void;
    countries?: IActiveCountryMap;
    countriesArr?: IActiveCountry[];
    dimensions?: { [dimensionId: number]: IActiveDimension };
    activeClientFacingIndicators?: IAPIActiveClientFacingIndicator;
    heatmap?: HeatmapProps;
    isAnalyst?: boolean;
}

const EDITOR_INITIAL_VALUE: Descendant[] = [{ children: [{ text: '' }] }];
const AUTO_GENERATE_ID = 'auto_generate';

@inject(
    ({
        countryStore,
        risksStore,
        chartStore,
        insightsStore,
        heatmapStore,
        UserStore,
    }: IMobxRootState): Partial<Props> => {
        const {
            selectedCountry,
            selectedRisk,
            startDate,
            timeSpan,
            riskType,
            scoreType,
            countriesList,
            selectedPercentile,
        } = heatmapStore;

        return {
            heatmap: {
                selectedCountry,
                selectedRisk,
                startDate,
                timeSpan,
                riskType,
                scoreType,
                countriesList,
                selectedPercentile,
            },
            riskType: risksStore.currentRisksType,
            selectedRisks: risksStore.currentList,
            selectedCountries: countryStore.currentCountryList,
            countries: countryStore.countries.countries,
            countriesArr: countryStore.countries.countriesArr,
            dateFrom: chartStore.baseDate
                .clone()
                .subtract(chartStore.rangeLeft, 'days'),
            dateUntil: chartStore.baseDate
                .clone()
                .add(chartStore.rangeRight, 'days'),
            isEditingText: !!insightsStore.activeID,
            isClientFacingIndicators: risksStore.isClientFacingIndicators,
            annoMode: insightsStore.annotationMode,
            activity: insightsStore.activity,
            displayedComponent: insightsStore.displayedComponent,
            isEditingComponent: insightsStore.isEditingComponent,
            activeGlobalIndex: risksStore.activeGlobalIndicators,
            dimensions: risksStore.dimensions.hashed,
            activeClientFacingIndicators: risksStore.clientFacingIndicators,
            isAnalyst: UserStore.geoquantFlagsPermission.is_analyst,
            restoreEditingComponent() {
                insightsStore.restoreEditingComponent();
            },
            setDisplayedComponent(
                map: ActivityMapComponentStore,
                chart: ActivityChartComponentStore,
                heatmap: ActivityHeatmapComponentStore
            ) {
                insightsStore.setDisplayedComponent(map, chart, heatmap);
            },
            setEditingComponent(
                map: ActivityMapComponentStore,
                chart: ActivityChartComponentStore,
                heatmap: ActivityHeatmapComponentStore
            ) {
                insightsStore.setEditingComponent(map, chart, heatmap);
            },
            setActivity(activity: SpecificActivityStore) {
                insightsStore.setActivity(activity);
            },
            setActiveDisplayedComponentType(type: E_COMPONENT_TYPE_TO_DISPLAY) {
                insightsStore.setComponentTypeToDisplay(type);
            },
            setAnnoMode(state: boolean) {
                insightsStore.setAnnotatingMode(state);
            },
        };
    }
)
@observer
class CreateInsight extends React.Component<Props, State> {
    public riskPickerContainer: HTMLDivElement;
    private saved = false;
    private popupType: E_POPUP_TYPE;
    private contentEl: HTMLDivElement = null;
    private currentlyUploadingComponents: Array<Promise<any>> = [];
    private cancelableEvents = new CancelableEvents();
    private toaster: GQToastContainer;
    private adjustContentSizeDebounce: Cancelable & Function;
    private editor: ReactEditor;
    constructor(props: Props) {
        super(props);
        this.state = this.getInitialState();
        this.adjustContentSizeDebounce = throttle(this.adjustContentSize, 1000);
        this.editor = withInlines(
            withHtml(withHistory(withReact(createEditor())))
        );
    }

    public componentDidMount() {
        this.props.setActiveDisplayedComponentType(
            this.calcCurrentDisplayComponentType()
        );
        this.cancelableEvents.addEventSubscription(
            EventsService.addListener('RESTORE_INSIGHT', this.onEditComponent)
        );
        EventsService.emitEvent(GQEmitterEvents.TITLE_BAR_CHANGE, {
            title: 'Insight Mode',
            onClose: this.preClose,
        });
        rootStore.appStore.setCurrentDisplayView(VIEW.CREATE_INSIGHT);
        rootStore.chartStore.setState({
            showVirtualToday: false,
        });
        this.loadInsight();
    }

    public componentWillUnmount() {
        EventsService.emitEvent(GQEmitterEvents.TITLE_BAR_CHANGE, {
            title: '',
            onClose: null,
        });
        ChartScoresService.cleanOverrideData();
        rootStore.insightsStore.setAnnotatingMode(false);
        rootStore.risksStore.setDefaultStacks();
        rootStore.countryStore.setDefaultStacks();
        this.cancelableEvents.cancelAll();
    }

    public componentDidUpdate(prevProps: Props) {
        const prevView = prevProps.match.params.insightsView;
        if (prevView !== this.currentActiveView) {
            this.props.setActiveDisplayedComponentType(
                this.calcCurrentDisplayComponentType()
            );
        }

        this.props.displayedComponent.switchComponentType(
            this.currentURLComponentType
        );
    }

    public render() {
        return (
            <div className="gq-create-activity">
                {this.props.match.params.insightsView !== VIEW.HEATMAP && (
                    <InsightNotes
                        notes={
                            this.props.displayedComponent &&
                            !isCustomComponent(this.props.displayedComponent)
                                ? this.props.displayedComponent.annotations
                                : []
                        }
                        onTextChange={this.onNoteTextChange}
                        onDeleteNote={this.onDeleteNote}
                        onLockClick={this.toggleLock}
                        countries={this.props.countries}
                    />
                )}
                <ResizeObserver
                    onResize={({ width, height }) => {
                        this.adjustContentSizeDebounce(width, height);
                    }}>
                    <div
                        id="insightContent"
                        className={cx([
                            'gq-insight-content',
                            this.props.match.params.insightsView,
                        ])}
                        ref={el => (this.contentEl = el)}
                        onClick={this.onEditorClick}>
                        <Transition
                            in={this.state.showRiskPicker}
                            timeout={500}
                            onEnter={this.onRiskPickerEnter}
                            onExit={this.onRiskPickerExit}>
                            <div
                                className="insight-risk-picker-container"
                                ref={el => (this.riskPickerContainer = el)}>
                                <GQButton
                                    icon="gqi-arrow-left"
                                    containerClassName="insight-risk-picker-expand-button"
                                    onClick={this.handleRiskExpansion}
                                    noBorder
                                />
                                {this.props.riskType ===
                                E_RISK_TYPE.CONTINGENT ? (
                                    <ContingentSidebarRiskPicker />
                                ) : (
                                    <IndicatorsSidebarRiskPicker
                                        multi={
                                            this.currentActiveView ===
                                            VIEW.COUNTRY_GRAPH
                                        }
                                    />
                                )}
                            </div>
                        </Transition>
                        <div id="flashCover" />
                        {this.shouldShowLoader ? (
                            <GQLoader />
                        ) : (
                            this.renderComponent()
                        )}
                    </div>
                </ResizeObserver>
                {this.shouldShowLoader ? (
                    <div
                        className="insight-container"
                        style={{ position: 'relative' }}>
                        <GQLoader
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                            }}
                        />
                    </div>
                ) : (
                    <div className="gq-insight-container">
                        <Toast
                            show={this.state.toast.showToast}
                            text={this.state.toast.text}
                            toastClass={this.state.toast.className}
                        />
                        <div className="gq-inshight-header col">
                            <div className="gq-header-section row">
                                <div className="insight-icon">
                                    <i className="icon remove bookmark" />
                                </div>
                                <div className="title">
                                    <span style={{ verticalAlign: 'middle' }}>
                                        New Insight
                                    </span>
                                </div>
                                <div className="filler" />
                                <GQButton
                                    caption={
                                        this.state.inProgress
                                            ? 'Saving..'
                                            : 'Save'
                                    }
                                    disabled={
                                        !this.props.activity.insightsData
                                            .name || this.state.inProgress
                                    }
                                    onClick={this.preSubmit}
                                    style={{
                                        fontSize: 13,
                                        alignContent: 'flex-end',
                                        borderWidth: 2 + 'px',
                                    }}
                                    className={
                                        this.props.activity.insightsData.name
                                            ? 'gq-button-save-active'
                                            : ''
                                    }
                                />
                            </div>
                            <div className="gq-insight-input row">
                                <GQInput
                                    bindValueToProps={true}
                                    placeholder="Insight name"
                                    onChange={this.onTitleChange}
                                    name="name"
                                    options={{ fullWidth: true }}
                                    value={
                                        this.props.activity.insightsData.name
                                    }
                                />
                            </div>
                            <div
                                className="gq-insight-input row"
                                style={{ marginBottom: '32px' }}>
                                <GQInput
                                    placeholder="Tags"
                                    onChange={this.onTagsChange}
                                    name="tag"
                                    options={{ fullWidth: true, tags: true }}
                                    value={this.props.activity.insightsData.tags.join(
                                        ','
                                    )}
                                />
                            </div>
                        </div>
                        <div className="gq-insight-editor col">
                            {this.state.textEditorValue && (
                                <InsightTextEditor
                                    value={this.state.textEditorValue}
                                    onChange={this.onTextChange}
                                    placeholder="Add insight text"
                                    getComponentById={this.getComponentById}
                                    showToolbar
                                    editor={this.editor}
                                    onAutoGenerateClick={this.onToolbarAutoGenerateClick.bind(
                                        this
                                    )}
                                    isAnalyst={this.props.isAnalyst}
                                />
                            )}
                        </div>
                        {!isEmpty(this.state.autoInsightMetadata) && (
                            <div>
                                <AutoInsightQuestions
                                    onSubmit={async question => {
                                        const insightNodes = await this.autoGenerateInsight(
                                            question
                                        );
                                        if (!isEmpty(insightNodes)) {
                                            Transforms.insertNodes(
                                                this.editor,
                                                insightNodes,
                                                {
                                                    at: [
                                                        this.editor.children
                                                            .length,
                                                    ],
                                                }
                                            );
                                        }
                                    }}
                                />
                            </div>
                        )}
                        <div>
                            <GQInsightComponentList
                                activity={this.props.activity}
                                onClick={this.onImageClick}
                                isUsed={this.isComponentInUse}
                                onRemoveComponent={this.removeComponent}
                                activeComponentId={
                                    this.props.displayedComponent
                                        ? this.props.displayedComponent.id
                                        : null
                                }
                            />
                        </div>
                        <TransitionGroup component={null}>
                            {this.drawEditorialButtons()}
                        </TransitionGroup>
                    </div>
                )}
                <GQToastContainer ref={el => (this.toaster = el)} />
                <GQModal
                    align={E_MODAL_ALIGN.MIDDLE}
                    show={this.state.showModal}>
                    {this.getPopupContent()}
                </GQModal>
            </div>
        );
    }

    private drawEditorialButtons() {
        const buttons = [
            {
                isMain: true,
                showOnEdit: false,
                props: {
                    icon: 'gqi-camera-1',
                    className: 'gq-snapshot-button',
                    containerClassName: 'gq-editor-button-main',
                    disabled: this.isSnapshotDisabled(),
                    onClick: this.onSnapshotClick,
                },
            },
            {
                isMain: false,
                showOnEdit: false,
                props: {
                    icon: 'gqi-upload-1',
                    className: 'gq-upload-button',
                    containerClassName: 'gq-editor-button-secondary',
                    onClick: this.uploadCustomImage,
                },
            },
            {
                isMain: true,
                showOnEdit: true,
                props: {
                    icon: 'gqi-check-circle',
                    className: 'gq-commit-button',
                    containerClassName: 'gq-editor-button-main',
                    onClick: this.onEditCommit,
                },
            },
            {
                isMain: false,
                showOnEdit: true,
                props: {
                    icon: 'gqi-close',
                    className: 'gq-cancel-button',
                    containerClassName:
                        'gq-editor-button-secondary gq-cancel-button-container',
                    onClick: this.onEditCancel,
                },
            },
        ];

        return buttons
            .filter(
                item =>
                    (item.showOnEdit && this.props.isEditingComponent) ||
                    (!item.showOnEdit && !this.props.isEditingComponent)
            )
            .map(btn => {
                const onButtonEnter = (node: Element) => {
                    this.onButtonEnter(node, btn.isMain);
                };
                const onButtonLeave = (node: Element) => {
                    this.onButtonLeave(node, btn.isMain);
                };
                return (
                    <Transition
                        key={btn.props.className}
                        timeout={300}
                        onEnter={onButtonEnter}
                        onExit={onButtonLeave}>
                        <GQButton rounded {...btn.props} />
                    </Transition>
                );
            });
    }

    private onButtonEnter = (node: Element, isMain: boolean) => {
        TweenLite.set(node, { clearProps: 'all' });
        TweenLite.from(
            node,
            0.3,
            isMain ? { opacity: 0 } : { y: '+=40', opacity: 0 }
        );
    };

    private onButtonLeave = (node: Element, isMain: boolean) => {
        TweenLite.set(node, { clearProps: 'all' });
        TweenLite.to(node, 0.3, isMain ? { opacity: 0 } : { opacity: 0 });
    };

    private onSnapshotClick = () => {
        this.takeSnapshot();
    };

    private onEditCommit = async () => {
        this.props.restoreEditingComponent();
        await this.takeSnapshot(true);
        rootStore.risksStore.clear();
        rootStore.countryStore.clear();
    };

    private onEditCancel = () => {
        this.onEditComponent(null);
    };

    private uploadCustomImage = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.setAttribute(
            'accept',
            'image/png, image/jpeg, image/jpg, image/gif'
        );
        input.onchange = async (e: Event) => {
            e.preventDefault();
            const files = Array.from(input.files);
            if (files.length === 0) {
                return;
            }

            const [file] = files as File[];
            if (file.size > 1024 * 1024 * 15) {
                this.popupType = E_POPUP_TYPE.FILE_TOO_LARGE;
                this.setState({
                    showModal: true,
                });
                return;
            }
            try {
                const tempId = `${Date.now()}__${generateID()}`;
                this.props.activity.addComponent({
                    id: tempId,
                    type: E_COMPONENT_TYPE.CUSTOM,
                    snapshot: null,
                    name: file.name,
                    htmlKey: tempId,
                });
                const component = await this.cancelableEvents.promise(() =>
                    APIService.uploadCustomImageComponent(file)
                );
                const components = this.props.activity.components.map(c => {
                    if (c.id === tempId) {
                        if (!isCustomComponent(c)) {
                            return null;
                        }
                        const js = c.toJS();
                        const newProps = {
                            id: component.id,
                            imageURL: component.imageURL,
                            thumbnailURL: component.thumbnailURL,
                            htmlKey: tempId,
                        };
                        const convertedComponent = new ActivityCustomImageComponentStore(
                            {
                                ...js,
                                ...newProps,
                            }
                        );
                        if (convertedComponent !== null) {
                            return convertedComponent;
                        }
                    }
                    return c;
                }).filter(Boolean);
                this.props.activity.setComponents(components);
            } catch (err) {
                if (CancelableEvents.isCanceledPromiseError(err)) {
                    return;
                }
                console.error(err);
                // show modal error, dude it's failed, do something
            }
        };

        input.click();
    };

    private getPopupContent() {
        let popupProps: { title: string; text: string };
        let buttons: JSX.Element[];
        switch (this.popupType) {
            case E_POPUP_TYPE.SNAPSHOT: {
                buttons = [
                    <GQButton
                        key="popup-confirm"
                        caption="Save without snapshot"
                        onClick={this.forceSubmit}
                    />,
                    <GQButton
                        key="popup-cancel"
                        caption="Cancel"
                        onClick={this.hideModal}
                    />,
                ];
                popupProps = C_INSIGHT_NO_SCREENSHOT_SAVE_POPUP;
                break;
            }
            case E_POPUP_TYPE.SNAPSHOT_FAILED: {
                buttons = [
                    <GQButton
                        key="popup-ok"
                        caption="OK"
                        onClick={this.hideModal}
                    />,
                ];
                popupProps = C_INSIGHT_SNAPSHOT_FAILED;
                break;
            }
            case E_POPUP_TYPE.FILE_TOO_LARGE: {
                buttons = [
                    <GQButton
                        key="popup-ok"
                        caption="OK"
                        onClick={this.hideModal}
                    />,
                ];
                popupProps = C_INSIGHT_FILE_TOO_LARGE;
                break;
            }
            default: {
                buttons = [
                    <GQButton
                        key="popup-confirm"
                        caption="OK"
                        onClick={this.close}
                    />,
                    <GQButton
                        key="popup-cancel"
                        caption="Cancel"
                        onClick={this.hideModal}
                    />,
                ];
                popupProps = C_INSIGHT_NOT_SAVED;
            }
        }
        return (
            <GQGenericPopup
                icon="gqi-alert"
                {...popupProps}
                onClose={this.hideModal}
                buttons={buttons}
            />
        );
    }

    private hideModal = () => {
        this.setState({
            showModal: false,
        });
    };

    private isSnapshotDisabled = () => {
        if (this.props.activeGlobalIndex.length > 0) {
            return false;
        }
        return (
            countrySelectors.has(this.currentActiveView) ||
            !this.props.activity ||
            !this.props.displayedComponent ||
            (this.props.displayedComponent.type === E_COMPONENT_TYPE.CHART &&
                this.props.selectedCountries.length === 0)
        );
    };

    private getComponentById = (id: string) => {
        return _.find(this.props.activity.components, c => c.id === id);
    };

    private renderComponent() {
        const componentChartStore = castComponentToChart(
            this.props.displayedComponent
        );
        return (
            <Route path={`/${VIEW.CREATE_INSIGHT}`} exact={false}>
                <InsightRouter
                    componentChartStore={componentChartStore}
                    contentEl={this.contentEl}
                />
            </Route>
        );
    }

    private toggleLock = () => {
        if (this.props.annoMode) {
            this.props.setAnnoMode(false);
            return;
        }
        if (this.props.displayedComponent) {
            switch (this.props.displayedComponent.type) {
                case E_COMPONENT_TYPE.CHART:
                    if (!document.getElementsByClassName('chart-line').length) {
                        return;
                    }
                    break;
                case E_COMPONENT_TYPE.MAP:
                    if (
                        !document.getElementsByClassName('host-circle').length
                    ) {
                        return;
                    }
                    break;
            }
            this.props.setAnnoMode(!this.props.annoMode);
        }
    };

    private handleRiskExpansion = (e: React.MouseEvent<HTMLDivElement>) => {
        TweenLite.to(e.currentTarget, 0.5, {
            rotation: `${this.state.showRiskPicker ? '-=180' : '+=180'}`,
        });
        this.setState((state: State) => {
            return {
                showRiskPicker: !state.showRiskPicker,
            };
        });
    };

    private onRiskPickerEnter(node: HTMLElement) {
        TweenLite.set(node, { clearProps: 'all' });
        TweenLite.to(node, 0.5, { x: '-=400', ease: 'Power4.easeOut' });
    }

    private onRiskPickerExit(node: HTMLElement) {
        TweenLite.to(node, 0.5, { x: '+=400' });
    }

    private onTextChange = (value: Descendant[]) => {
        this.setState({
            textEditorValue: value,
        });
    };

    private isComponentInUse = (componentId: string): boolean => {
        if (!this.state.textEditorValue) {
            return false;
        }
        return this.state.textEditorValue.some((component: any): boolean => {
            return (
                component.type === 'gq-insight' &&
                get(['data', 'id'], component) === componentId
            );
        });
    };

    private replaceComponent(oldId: string, newId: string) {
        const nodesWithReplacedComponent = this.state.textEditorValue.map(
            node => {
                if (
                    get('type', node) === 'gq-insight' &&
                    get(['data', 'id'], node) === oldId
                ) {
                    return set(['data', 'id'], newId, node);
                }
                return node;
            }
        );

        this.setState({ textEditorValue: nodesWithReplacedComponent });
    }

    private removeComponent = (id: string) => {
        const nodesWithoutComponent = this.state.textEditorValue.filter(
            node => {
                if (
                    get('type', node) === 'gq-insight' &&
                    get(['data', 'id'], node) === id
                ) {
                    return false;
                }
                return true;
            }
        );
        this.setState({ textEditorValue: nodesWithoutComponent });
        this.props.activity.removeComponent(id);
    };

    private onImageClick = (componentStore: ActivityComponentStore) => {
        const text = {
            text: '',
        };
        const imageAndEmptyNodes = [
            {
                type: 'gq-insight',
                data: {
                    id: componentStore.id,
                },
                children: [
                    {
                        text: '',
                    },
                ],
            },
            {
                children: [text],
            },
        ];
        Transforms.insertNodes(this.editor, imageAndEmptyNodes);
    };

    private takeSnapshot = async (saveCommit?: boolean) => {
        if (!this.props.displayedComponent) {
            return;
        }
        if (isCustomComponent(this.props.displayedComponent)) {
            return;
        }
        if (
            includes(this.props.displayedComponent.type, [
                E_COMPONENT_TYPE.CHART,
                E_COMPONENT_TYPE.CONTINGENT_CHART,
            ]) &&
            !document.getElementsByClassName('chart-line').length
        ) {
            return;
        }
        if (!saveCommit) {
            const flash = document.getElementById('flashCover');
            if (flash) {
                new TimelineLite()
                    .set(flash, { clearProps: 'opacity' })
                    .to(flash, 0.3, { opacity: 1, ease: 'Power2.easeInOut' })
                    .to(flash, 0.3, { opacity: 0, ease: 'Power2.easeInOut' });
            }
        }
        const addedComponent = this.props.displayedComponent;
        addedComponent.setSnapshot(this.takeCurrentSnapshot());

        addedComponent.setData(await serializeChartData());
        const exists = _.find(
            this.props.activity.components,
            c => c.id === addedComponent.id
        );
        if (!exists) {
            const len = _.filter(
                this.props.activity.components,
                c => c.type === addedComponent.type
            ).length;
            addedComponent.setName(`${addedComponent.type} [${len + 1}]`);
            this.props.activity.addComponent(addedComponent.toJS());
        } else {
            exists.setURLS({
                csvURL: '',
                imageURL: '',
                thumbnailURL: '',
            });
        }
        const oldID = addedComponent.id;
        const oldKey = addedComponent.htmlKey;
        // .then for case we submit in middle of uploading a component, so we can safely wait to get the returned ID
        const promise = APIService.addTempComponent(addedComponent.toJS())
            .then(newComponent => {
                if (newComponent.type === E_COMPONENT_TYPE.CUSTOM) {
                    return;
                }
                const components = this.props.activity.components.map(c => {
                    if (c.id === oldID) {
                        if (isCustomComponent(c)) {
                            return null;
                        }
                        const js = c.toJS();
                        const newProps = {
                            data: newComponent.data,
                            snapshot: newComponent.snapshot,
                            id: newComponent.id,
                            imageURL: newComponent.imageURL,
                            thumbnailURL: newComponent.thumbnailURL,
                            csvURL: newComponent.csvURL,
                            htmlKey: oldKey,
                            annotations: newComponent.annotations as typeof c['annotations'],
                        };
                        const convertedComponent = componentFactory({
                            ...js,
                            ...newProps,
                        } as any); // @todo, -remove this any, due to different annotation types..
                        if (convertedComponent !== null) {
                            return convertedComponent;
                        }
                    }
                    return c;
                }).filter(Boolean);
                this.props.activity.setComponents(components);
                this.replaceComponent(oldID, newComponent.id);
                this.currentlyUploadingComponents.shift();
            })
            .catch(() => {
                this.currentlyUploadingComponents.shift();
                this.removeComponent(oldID);
                this.popupType = E_POPUP_TYPE.SNAPSHOT_FAILED;
                this.setState({
                    showModal: true,
                });
            });
        this.generateEmptyComponentsAndInsertToStore();
        this.currentlyUploadingComponents.push(promise);
    };

    private generateEmptyComponentsAndInsertToStore = () => {
        this.props.setDisplayedComponent(
            castComponentToMap(this.generateComponent(E_COMPONENT_TYPE.MAP)),
            castComponentToChart(
                this.generateComponent(E_COMPONENT_TYPE.CHART)
            ),
            castComponentToHeatmap(
                this.generateComponent(E_COMPONENT_TYPE.HEATMAP)
            )
        );
    };

    private onNoteTextChange = (id: string, newVal: string) => {
        if (isCustomComponent(this.props.displayedComponent)) {
            return;
        }
        for (const note of this.props.displayedComponent.annotations) {
            if (note.id === id) {
                runInAction(() => {
                    note.text = newVal;
                });
            }
        }
    };

    private adjustContentSize(width, height) {
        if (!this.contentEl) {
            return;
        }
        this.cancelableEvents.setTimeout(() => {
            if (
                width !== this.state.chart.width ||
                height !== this.state.chart.height
            ) {
                this.setState({
                    chart: {
                        width,
                        height,
                    },
                });
            }
        });
    }

    private onEditComponent = (component: ActivityComponentStore) => {
        if (component === null) {
            this.props.restoreEditingComponent();
            ChartScoresService.cleanOverrideData();
            this.generateEmptyComponentsAndInsertToStore();
            rootStore.risksStore.clear();
            rootStore.countryStore.clear();
            rootStore.risksStore.setDefaultStacks();
            rootStore.countryStore.setDefaultStacks();
            return;
        }
        if (isCustomComponent(component)) {
            return;
        }
        ChartScoresService.setOverrideData(toJS(component.data));
        const newComponent = component.clone(true);
        if (isChartComponent(newComponent)) {
            this.props.setEditingComponent(
                castComponentToMap(
                    this.generateComponent(E_COMPONENT_TYPE.MAP)
                ),
                newComponent,
                castComponentToHeatmap(
                    this.generateComponent(E_COMPONENT_TYPE.HEATMAP)
                )
            );
        }

        if (isMapComponent(newComponent)) {
            this.props.setEditingComponent(
                newComponent,
                castComponentToChart(
                    this.generateComponent(E_COMPONENT_TYPE.CHART)
                ),
                castComponentToHeatmap(
                    this.generateComponent(E_COMPONENT_TYPE.HEATMAP)
                )
            );
        }

        if (newComponent.type === E_COMPONENT_TYPE.HEATMAP) {
            this.props.setEditingComponent(
                castComponentToMap(
                    this.generateComponent(E_COMPONENT_TYPE.MAP)
                ),
                castComponentToChart(
                    this.generateComponent(E_COMPONENT_TYPE.CHART)
                ),
                newComponent
            );
        }

        if (newComponent.type !== E_COMPONENT_TYPE.HEATMAP) {
            rootStore.risksStore.setStacks(
                newComponent.dimensionsColorStack,
                newComponent.activeClientFacingIndicatorsColorStack
            );
            rootStore.countryStore.setStacks(newComponent.countriesColorStack);
        }

        const view = component.restoreSnapshot();
        const newUrl = `/${VIEW.CREATE_INSIGHT}/${view}/${this.props.activity
            .id || ''}`;
        this.props.history.push(newUrl);
    };

    private preClose = () => {
        if (this.saved) {
            this.close();
        } else {
            this.popupType = E_POPUP_TYPE.NOT_SAVED;
            this.setState({
                showModal: true,
            });
        }
    };

    private close = () => {
        if (this.props.isEditingComponent) {
            this.onEditCancel();
        }
        //todo: investigate
        if (rootStore.routingStore.location.hash !== '/') {
            EventsService.emitOnTitleBarChange({
                onClose: null,
                title: '',
            });
        }
        rootStore.routingStore.goBackToPreviousDifferentView();
    };

    private onTitleChange = (val: string) => {
        const { activity } = this.props;
        activity.setInsightData({
            name: val,
        });
    };

    private onTagsChange = (
        val: string,
        el: HTMLInputElement,
        tags: string[]
    ) => {
        const { activity } = this.props;
        activity.setInsightData({
            tags,
        });
    };

    private getInitialState(): State {
        return {
            inProgress: false,
            error: null,
            toast: {
                showToast: false,
                className: '',
                text: '',
            },
            chart: {
                width: 0,
                height: 0,
            },
            textEditorValue: null,
            showRiskPicker: false,
            showModal: false,
            autoInsightMetadata: null,
        };
    }

    private get shouldShowLoader() {
        return (
            !this.props.dimensions ||
            !this.props.countries ||
            !this.props.activeClientFacingIndicators ||
            !this.props.activity
        );
    }

    private preSubmit = () => {
        if (!this.snapshotTaken) {
            this.popupType = E_POPUP_TYPE.SNAPSHOT;
            this.setState({
                showModal: true,
            });
            return;
        }
        if (this.state.inProgress) {
            return;
        }
        this.saveInsight();
    };

    private forceSubmit = () => {
        this.saveInsight();
    };

    private saveInsight = () => {
        this.setState(
            {
                inProgress: true,
            },
            async () => {
                try {
                    // wait for all components to be uploaded
                    if (this.currentlyUploadingComponents.length) {
                        await Promise.all(this.currentlyUploadingComponents);
                    }
                    const { activity } = this.props;
                    const activeComponents = toJS(activity.components || []);
                    const exportedActivity = {
                        _id: toJS(activity.id),
                        date: toJS(activity.date),
                        insightsData: {
                            ...toJS(activity.insightsData),
                            insight: {
                                nodes: this.state.textEditorValue,
                            },
                        },
                        components: activeComponents.map(c => toJS(c.id)),
                        auto_generated: this.state.autoInsightMetadata,
                    };
                    const names: Array<{
                        id: string;
                        name: string;
                    }> = activeComponents.map(({ id, name }) => {
                        return {
                            id,
                            name,
                        };
                    });
                    const newActivity = await this.cancelableEvents.promise(
                        () => {
                            return APIService.saveActivity(
                                exportedActivity,
                                names
                            );
                        }
                    );
                    rootStore.routingStore.push(
                        `/${VIEW.INSIGHTS}/${newActivity._id}`
                    );
                } catch (err) {
                    if (CancelableEvents.isCanceledPromiseError(err)) {
                        // all good, component is not mounted
                        return;
                    }

                    console.error(err.message);
                }
            }
        );
    };

    private onEditorClick = (e: React.MouseEvent<any>) => {
        const { activity } = this.props;
        if (!activity) {
            return;
        }
        if (this.props.annoMode) {
            if (this.contentEl) {
                const { clientX } = e;
                const box = this.contentEl.getBoundingClientRect();
                const componentAsChart = castComponentToChart(
                    this.props.displayedComponent
                );
                const id = generateID();
                const text = '';
                this.props.setAnnoMode(false);
                if (componentAsChart !== null) {
                    const date = rootStore.annoStore.xScale.invert(
                        clientX - box.left
                    );
                    const countryID = rootStore.annoStore.closestLine.country;
                    componentAsChart.addAnnotation({
                        id,
                        noteType: E_COMPONENT_TYPE.CHART,
                        date,
                        countryID,
                        lineType: rootStore.annoStore.closestLine.type,
                        lineIdentifier:
                            rootStore.annoStore.closestLine.identifier,
                        yValue: rootStore.annoStore.closestLine.yVal,
                        text,
                    });
                    return;
                }
                const componentAsMap = castComponentToMap(
                    this.props.displayedComponent
                );
                if (componentAsMap !== null) {
                    const countryID = rootStore.annoStore.selectedMapCountry;
                    if (countryID) {
                        if (
                            _.find(
                                componentAsMap.annotations,
                                note => note.countryID === countryID
                            )
                        ) {
                            return;
                        }
                        componentAsMap.addAnnotation({
                            id,
                            noteType: componentAsMap.type,
                            text,
                            countryID: Number(countryID),
                        });
                    }
                }
            }
            return;
        }
    };

    private get snapshotTaken() {
        return (
            this.props.activity !== null &&
            this.props.activity.components.length > 0
        );
    }

    private onDeleteNote = (index: number) => {
        if (
            this.props.displayedComponent &&
            !isCustomComponent(this.props.displayedComponent)
        ) {
            this.props.displayedComponent.removeAnnotation(index);
            if (this.toaster) {
                const onUndo = () => {
                    if (!isCustomComponent(this.props.displayedComponent)) {
                        this.props.displayedComponent.undoRemoveAnnotation();
                    }
                };
                this.toaster.createToast(C_INSIGHT_NOTE_DELETED, {
                    type: E_TOAST_TYPE.ERROR,
                    buttons: [
                        <GQButton
                            key="insight_note_undo"
                            caption="Undo"
                            onClick={onUndo}
                        />,
                    ],
                });
            }
        }
    };

    private generateComponent = (
        type: E_COMPONENT_TYPE
    ): ActivityComponentStore => {
        const id = `temp-${generateID()}`;
        const htmlKey = `html-${generateID()}`;
        const snapshot = this.takeCurrentSnapshot();
        switch (type) {
            case E_COMPONENT_TYPE.CHART:
            case E_COMPONENT_TYPE.CONTINGENT_CHART:
                return new ActivityChartComponentStore({
                    id,
                    type,
                    data: [],
                    snapshot,
                    name: '',
                    htmlKey,
                });
            case E_COMPONENT_TYPE.DELTA:
            case E_COMPONENT_TYPE.RISK:
            case E_COMPONENT_TYPE.MAP:
            case E_COMPONENT_TYPE.CONTINGENT_MAP:
                return new ActivityMapComponentStore({
                    id,
                    type,
                    name: '',
                    snapshot,
                    htmlKey,
                });
            case E_COMPONENT_TYPE.HEATMAP:
                return new ActivityHeatmapComponentStore({
                    id,
                    type,
                    name: '',
                    snapshot,
                    htmlKey,
                });
            default:
                return;
        }
    };

    private get currentURLComponentType(): E_COMPONENT_TYPE {
        switch (this.currentActiveView) {
            case VIEW.COUNTRY_GRAPH:
            case VIEW.WORLD_GRAPH:
                return E_COMPONENT_TYPE.CHART;
            case VIEW.SORT_BT_DELTA:
                return E_COMPONENT_TYPE.DELTA;
            case VIEW.SORT_BY_RISK:
                return E_COMPONENT_TYPE.RISK;
            case VIEW.RISK_VIEW:
                return E_COMPONENT_TYPE.MAP;
            case VIEW.CONTINGENT:
                return E_COMPONENT_TYPE.CONTINGENT_MAP;
            case VIEW.HEATMAP:
                return E_COMPONENT_TYPE.HEATMAP;
            case VIEW.CONTINGENT_GRAPH:
                return E_COMPONENT_TYPE.CONTINGENT_CHART;
        }

        return null;
    }

    private get currentActiveView() {
        return this.props.match.params.insightsView;
    }
    private takeCurrentSnapshot(): IActivitySnapshot {
        const hasActiveCw = rootStore.customWeightStore.hasActiveCustomWeight;

        const snapshot = {
            virtualToday: chartStore.virtualToday.toDate(),
            baseDate: chartStore.baseDate.toDate(),
            countries: {
                isMultiSelect: this.currentActiveView !== VIEW.COUNTRY_GRAPH,
                values: rootStore.countryStore.currentStore.getValues(),
                colorsStack: rootStore.countryStore.serializeColors(),
            },
            risks: {
                isMultiSelect: this.currentActiveView === VIEW.COUNTRY_GRAPH,
                values: rootStore.risksStore.currentStore.getValues(),
                colorsStack: rootStore.risksStore.serializeColors(),
                currencyOn: rootStore.risksStore.currencyOn,
                projectionDataOn: rootStore.risksStore.projectionDataOn,
                riskChartMode: rootStore.risksStore.riskChartMode,
                activeGlobalIndicators:
                    rootStore.risksStore.activeGlobalIndicators,
            },
            rangeLeft: chartStore.rangeLeft,
            rangeRight: chartStore.rangeRight,
            riskType: rootStore.risksStore.currentRisksType,
            showLines: chartStore.showLines,
            showCircles: chartStore.showCircles,
            showVirtualToday: chartStore.showVirtualToday,
            activeGroup: this.props.countriesArr
                ? this.props.countriesArr.map(c => c.id)
                : [],
            hasActiveCustomWeight: hasActiveCw,
        };

        if (this.currentActiveView === VIEW.HEATMAP) {
            return { ...snapshot, ...{ heatmap: this.takeHeatmapSnapshot() } };
        }

        return snapshot;
    }

    private takeHeatmapSnapshot(): IActivityHeatmapSnapshot {
        const {
            selectedCountry,
            selectedRisk,
            startDate,
            timeSpan,
            riskType,
            scoreType,
            countriesList,
            selectedPercentile,
        } = this.props.heatmap;

        return {
            countriesList,
            selectedCountry,
            selectedRisk,
            startDate,
            timeSpan,
            activeGroupId: rootStore.settingsStore.activeGroupId,
            riskType,
            scoreType,
            selectedPercentile,
        };
    }

    private loadInsight = async () => {
        const id = this.props.match.params.id;

        this.generateEmptyComponentsAndInsertToStore();

        //todo: try using a different route param other than id
        if (id === AUTO_GENERATE_ID) {
            const activity = new SpecificActivityStore(null);
            const {
                riskType,
                dimensions,
                activeClientFacingIndicators,
                dateFrom,
                dateUntil,
                selectedCountries,
                countries,
            } = this.props;

            const riskId = first(this.props.selectedRisks);
            const autoInsightName = getAutoGenerateName({
                riskType,
                dimensions,
                activeClientFacingIndicators,
                dateFrom,
                dateUntil,
                selectedCountries,
                riskId,
                countries,
            });

            activity.setInsightData({
                name: autoInsightName,
            });

            this.props.setActivity(activity);
            const insightNodes = await this.autoGenerateInsight();
            if (isEmpty(insightNodes)) {
                this.setState({ textEditorValue: EDITOR_INITIAL_VALUE });
                return;
            }

            this.setState({
                textEditorValue: insightNodes,
            });
            return;
        }
        if (!id) {
            this.props.setActivity(new SpecificActivityStore(null));
            this.setState({ textEditorValue: EDITOR_INITIAL_VALUE });
            return;
        }
        try {
            const activity = await this.cancelableEvents.promise(() =>
                APIService.getFullActivity(id)
            );
            if (!activity) {
                rootStore.routingStore.push('/');
            }
            const ac = new SpecificActivityStore(activity);
            this.setState(
                {
                    textEditorValue: activity.insightsData.insight.nodes,
                },
                () => {
                    this.props.setActivity(ac);
                }
            );
            return;
        } catch (err) {
            if (CancelableEvents.isCanceledPromiseError(err)) {
                // all good
                return;
            }
            console.error(err);
        }
    };

    private calcCurrentDisplayComponentType(): E_COMPONENT_TYPE_TO_DISPLAY {
        switch (this.currentActiveView) {
            case VIEW.COUNTRY_GRAPH:
            case VIEW.WORLD_GRAPH:
            case VIEW.CONTINGENT_GRAPH:
                return E_COMPONENT_TYPE_TO_DISPLAY.CHART;
            case VIEW.RISK_VIEW:
            case VIEW.SORT_BT_DELTA:
            case VIEW.SORT_BY_RISK:
            case VIEW.CONTINGENT:
                return E_COMPONENT_TYPE_TO_DISPLAY.MAP;
            case VIEW.HEATMAP:
                return E_COMPONENT_TYPE_TO_DISPLAY.HEATMAP;
        }
        return null;
    }
    private async onToolbarAutoGenerateClick() {
        const insightNodes = await this.autoGenerateInsight();
        if (isEmpty(insightNodes)) {
            return;
        }

        Transforms.insertNodes(this.editor, insightNodes, {
            at: [this.editor.children.length],
        });
    }

    private async autoGenerateInsight(question?: string) {
        const {
            dateFrom,
            dateUntil,
            riskType,
            selectedCountries,
            selectedRisks,
        } = this.props;
        loading.start();
        try {
            const { metadata, insightNodes } = await autoGenerateInsight({
                dateFrom,
                dateUntil,
                question,
                riskType,
                selectedCountries,
                selectedRisks,
            });

            this.setState({ autoInsightMetadata: metadata });
            return insightNodes;
        } catch (e) {
            console.error(e);
            this.toaster.createToast(
                'Auto generate error: ' + get('message', e),
                {
                    type: E_TOAST_TYPE.ERROR,
                }
            );
        } finally {
            loading.stop();
        }
    }
}

type InsightRouterProps = {
    contentEl: HTMLDivElement;
    componentChartStore: ActivityChartComponentStore;
};

const InsightRouter = React.memo((props: InsightRouterProps) => {
    let { path } = useRouteMatch();
    return (
        <React.Fragment>
            <Switch>
                <Route
                    component={RiskView}
                    path={`${path}/${VIEW.RISK_VIEW}/:id?`}
                />
                <Route
                    exact
                    component={SortByRisk}
                    path={`${path}/${VIEW.SORT_BY_RISK}/:id?`}
                />
                <Route
                    exact
                    component={SortByDelta}
                    path={`${path}/${VIEW.SORT_BT_DELTA}/:id?`}
                />
                <Route
                    exact
                    component={WorldGraph}
                    path={[`${path}/${VIEW.WORLD_GRAPH}/:id?`]}
                />
                <Route
                    exact
                    component={CountryGraph}
                    path={`${path}/${VIEW.COUNTRY_GRAPH}/:id?`}
                />
                <Route
                    exact
                    component={ContingentMap}
                    path={`${path}/${VIEW.CONTINGENT}/:id?`}
                />
                <Route
                    exact
                    component={ContingentGraph}
                    path={`${path}/${VIEW.CONTINGENT_GRAPH}/:id?`}
                />
                <Route
                    exact
                    render={() => (
                        <HeatmapProvider disableClickOutside disableTooltip />
                    )}
                    path={`${path}/${VIEW.HEATMAP}/:id?`}
                />
            </Switch>

            <Route
                exact
                path={[
                    `${path}/${VIEW.WORLD_GRAPH}/:id?`,
                    `${path}/${VIEW.COUNTRY_GRAPH}/:id?`,
                    `${path}/${VIEW.CONTINGENT_GRAPH}/:id?`,
                ]}>
                <NoteAnchorList
                    container={props.contentEl}
                    annotations={
                        props.componentChartStore
                            ? toJS(props.componentChartStore.annotations)
                            : []
                    }
                    scaleStore={rootStore.annoStore}
                />
            </Route>
        </React.Fragment>
    );
});

export default CreateInsight;
