import React from 'react';

interface IGQMenuProps {
    options?: {
        animate?: boolean;
    };
}

export default class GQMenu extends React.Component<IGQMenuProps> {
    public render() {
        return <div className="gq-menu">{this.props.children}</div>;
    }
}
