import {
    IChartData,
    IChartLine,
    E_CHART_GROUP_IDENTIFIERS,
} from './chartInterfaces';
import VirtualChartData from './VirtualChartData';
import moment from 'moment';
import * as _ from 'lodash';
export default class ChartData extends VirtualChartData implements IChartData {
    constructor(
        public lines: IChartLine[],
        public domainY: IChartData['domainY'],
        public identifier: E_CHART_GROUP_IDENTIFIERS,
        virtualMinDate: moment.Moment,
        virtualMaxDate: moment.Moment
    ) {
        super(virtualMinDate, virtualMaxDate);
        this.setVirtualRanges(virtualMinDate, virtualMaxDate);
    }
    public get maxValue(): number {
        if (this.lines.length === 0) {
            return 1;
        }
        if (this.cachedMaxValue === null) {
            this.loadMinMaxCacheValues();
        }
        return this.cachedMaxValue;
    }

    public get minValue(): number {
        if (this.lines.length === 0) {
            return 0;
        }
        if (this.cachedMinValue === null) {
            this.loadMinMaxCacheValues();
        }
        return this.cachedMinValue;
    }

    public setVirtualRanges(from: moment.Moment, until: moment.Moment) {
        this.virtualMinDate = from;
        this.virtualMaxDate = until;
        this.cachedMinValue = null;
        this.cachedMaxValue = null;
        _.forEach(this.lines, line => {
            line.setVirtualRanges(from, until);
        });
    }

    private loadMinMaxCacheValues() {
        let minValue = Infinity;
        let maxValue = -Infinity;
        _.forEach(this.lines, value => {
            if (minValue > value.minValue) {
                minValue = value.minValue;
            }

            if (maxValue < value.maxValue) {
                maxValue = value.maxValue;
            }
        });

        this.cachedMinValue = minValue;
        this.cachedMaxValue = maxValue;
    }
}
