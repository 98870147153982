import APIService, {
    E_RISK_TYPE,
    IActivityRecordChartDataSnapshot,
    ICurrencyExchange,
} from './APIService';
import moment from 'moment';
import * as _ from 'lodash';
import React from 'react';
import ChartLine from '../components/GQChart/ChartLine';
import ChartData from '../components/GQChart/ChartData';
import {
    ILineValue,
    E_DOMAIN_Y_SCALE,
    E_CHART_GROUP_IDENTIFIERS,
    IChartCircle,
} from '../components/GQChart/chartInterfaces';
import GQPopupStorage from '../components/GQPopup/GQPopupStorage';
import UScorePopup from '../components/GQPopup/UScorePopup';
import chartStore from '../components/GQChart/ChartStore';
import eventsFeedStore from '../components/eventsFeed/EventsFeedStore';
import countryStore from '../stores/CountryStore';
import risksStore from '../stores/RisksStore';
import scoresProxyHandler, {
    dateStrToOffset,
} from '../services/lib/scoresProxyUtils';
import EventEmitter from '../services/EventsService';
import ChartNameIndicator from '../components/GQChart/indicators/ChartNameIndicator';
import customWeightStore from '../stores/CustomWeightStore';
import { GLOBAL_INDEXES, IAPIMaxImpactMap } from './lib/APIServiceInterfaces';
import { globalRiskColor } from '../utils/renderUtils';
import {
    C_FIRST_DAY_HUMAN_SCORES_DATE,
    C_SCORES_DATE_FORMAT,
    C_TIMELINE_MIN_DATE,
} from '../constants';
import { first, isEmpty, toNumber } from 'lodash/fp';
import { mapWithKeys } from 'utils/lodashUtils';

export interface IChartDataBaseQuery {
    from: moment.Moment;
    until: moment.Moment;
}

interface ICustomDomAttirubtes {
    'data-country-ids': string; // stringified JSON of number[]
    'data-entity-type': E_RISK_TYPE;
    'data-entity-id': number;
    'data-date': string;
    'data-value': number;
}
export interface IGetDimensionsRiskQuery extends IChartDataBaseQuery {
    countries: number[];
    identifiers: number[];
    identifierType: E_RISK_TYPE;
    isDimensionBased: boolean;
    projectionDate?: moment.Moment;
    geoquantLine: boolean;
    showPulseData?: boolean;
}

export interface IGetCurrentyExchange extends IChartDataBaseQuery {
    countries: number[];
}
const cache: { [key: string]: Promise<ChartLine> } = {};

const saveCache = (key: string, resultPromise: Promise<ChartLine>) => {
    resultPromise.then(result => {
        if (!isEmpty(result)) {
            cache[key] = resultPromise;
        }
    });
};

const externalDataSource: {
    [key: string]: [E_CHART_GROUP_IDENTIFIERS, ChartLine];
} = {};

const isExportMode = (): boolean => {
    return document.location.href.includes('isThumbnail=true');
};

const exportModeStrokeWidth = () => {
    return {
        ...(isExportMode() ? { style: { strokeWidth: 10 } } : {}),
    };
};

const getQQLineDashArray = (): string => {
    const { hasActiveCustomWeight } = customWeightStore;
    const isGQActive = risksStore.showGeoquantLine;
    return hasActiveCustomWeight && isGQActive ? '12,12' : '0,0';
};

export default class ChartScoresService {
    private static overrideData: {
        [type: string]: IActivityRecordChartDataSnapshot[];
    } = null;
    public static setOverrideData(data: IActivityRecordChartDataSnapshot[]) {
        if (this.overrideData !== null) {
            this.cleanOverrideData();
        }
        const tempData: typeof ChartScoresService.overrideData = {};
        _.forEach(data, d => {
            if (!tempData[d.chartIdentifier]) {
                tempData[d.chartIdentifier] = [];
            }
            tempData[d.chartIdentifier].push({
                ...d,
                ...(d.date ? { date: moment(d.date) } : {}),
                ...(d.dateBegin ? { dateBegin: moment(d.dateBegin) } : {}),
            });
        });
        this.overrideData = tempData;
    }

    public static cleanOverrideData() {
        this.overrideData = null;
        this.clearCache();
    }
    public static clearCache() {
        for (const key in cache) {
            delete cache[key];
        }
    }
    public static addExternalDataSource(dataSource: ChartLine) {
        externalDataSource[dataSource.identifier] = [
            E_CHART_GROUP_IDENTIFIERS.POLITICAL_RISK,
            dataSource,
        ];
    }
    public static deleteExternalChartData() {
        for (const k in externalDataSource) {
            delete externalDataSource[k];
        }
    }

    public static getContingentRiskChart(
        chartLines: ChartLine[],
        query: IGetDimensionsRiskQuery
    ) {
        const chart = new ChartData(
            chartLines,
            {
                expanded: E_DOMAIN_Y_SCALE.shared,
                minimized: E_DOMAIN_Y_SCALE.standalone,
            },
            E_CHART_GROUP_IDENTIFIERS.CONTINGENT_RISK,
            query.from.clone().startOf('day'),
            query.until.clone().endOf('day')
        );
        return chart;
    }

    public static async getPoliticalRiskChart(
        query: IGetDimensionsRiskQuery
    ): Promise<ChartData[]> {
        const countries = query.countries ? [...query.countries] : [];
        const risks = query.identifiers ? [...query.identifiers] : [];
        const riskLines: Array<Promise<ChartLine>> = [];
        const pulseLines: Array<Promise<ChartLine>> = [];
        _.forEach(externalDataSource, ([ldentifier, line]) => {
            if (ldentifier === E_CHART_GROUP_IDENTIFIERS.POLITICAL_RISK) {
                riskLines.push(Promise.resolve(line));
            }
        });
        const shouldAddProjection =
            query.projectionDate &&
            query.projectionDate.isBefore(moment(), 'd');

        const hasActiveCW = customWeightStore.hasActiveCustomWeight;
        _.forEach(countries, country => {
            _.forEach(risks, identifier => {
                if (hasActiveCW) {
                    riskLines.push(
                        this.generateCacheEntityGraphLine(
                            country,
                            identifier,
                            query.identifierType,
                            query.isDimensionBased,
                            false
                        )
                    );
                }
                if (query.geoquantLine) {
                    riskLines.push(
                        this.generateCacheEntityGraphLine(
                            country,
                            identifier,
                            query.identifierType,
                            query.isDimensionBased,
                            true
                        )
                    );
                }

                if (shouldAddProjection) {
                    riskLines.push(
                        this.generateLineForRiskProjection(
                            query.identifierType,
                            identifier,
                            country,
                            query.projectionDate,
                            query.isDimensionBased
                        )
                    );
                }

                if (query.showPulseData) {
                    pulseLines.push(
                        this.generateLineForRiskPulse(
                            query.identifierType,
                            identifier,
                            country,
                            query.isDimensionBased,
                            !hasActiveCW,
                            query.geoquantLine,
                        )
                    );
                }
            });
        });

        const charts = [];
        if (!isEmpty(riskLines)) {
            const riskChart = new ChartData(
                (await Promise.all(riskLines)).filter(e => e),
                {
                    expanded: E_DOMAIN_Y_SCALE.shared,
                    minimized: E_DOMAIN_Y_SCALE.standalone,
                },
                E_CHART_GROUP_IDENTIFIERS.POLITICAL_RISK,
                query.from.clone().startOf('day'),
                query.until.clone().endOf('day')
            );
            charts.push(riskChart);
        }

        if (!isEmpty(pulseLines)) {
            const pulseChart = new ChartData(
                (await Promise.all(pulseLines)).filter(e => e),
                {
                    expanded: E_DOMAIN_Y_SCALE.shared,
                    minimized: E_DOMAIN_Y_SCALE.standalone,
                },
                E_CHART_GROUP_IDENTIFIERS.PULSE,
                query.from.clone().startOf('day'),
                query.until.clone().endOf('day')
            );
            charts.push(pulseChart);
        }

        return charts;
    }

    public static async getCurrencyExchange(query: IGetCurrentyExchange) {
        const lines = await Promise.all(
            _.map(
                query.countries !== null ? query.countries : [],
                countryId => {
                    return this.generateCacheForCurrencyExchangeLine(countryId);
                }
            )
        );
        const chart = new ChartData(
            lines,
            {
                expanded: E_DOMAIN_Y_SCALE.standalone,
                minimized: E_DOMAIN_Y_SCALE.standalone,
            },
            E_CHART_GROUP_IDENTIFIERS.CURRENCY_EXCHANGE,
            query.from.clone().startOf('day'),
            query.until.clone().endOf('day')
        );
        return chart;
    }

    public static async getGlobalIndexChart(
        indicators: GLOBAL_INDEXES[],
        from: moment.Moment,
        until: moment.Moment
    ) {
        const lines = await Promise.all(
            indicators.map(c => {
                return this.generateGlobalIndexLine(c, from, until);
            })
        );

        const chart = new ChartData(
            lines,
            {
                expanded: E_DOMAIN_Y_SCALE.standalone,
                minimized: E_DOMAIN_Y_SCALE.standalone,
            },
            E_CHART_GROUP_IDENTIFIERS.GLOBAL_INDEX,
            from.clone().startOf('day'),
            until.clone().endOf('day')
        );
        return chart;
    }
    private static generateGlobalIndexLine(
        indicator: GLOBAL_INDEXES,
        from: moment.Moment,
        until: moment.Moment
    ) {
        const cacheKey = `GlobalIndexLine::${indicator}`;
        if (cache[cacheKey]) {
            return cache[cacheKey];
        }

        const result = (async () => {
            const scores = await APIService.getGlobalIndicator(indicator);
            if (isEmpty(scores)) {
                return;
            }

            const datesData = scores;
            const values: ILineValue[] = [];
            scoresProxyHandler.iterator(datesData, (k, m, s) => {
                values.push({
                    yValue: s,
                    date: m,
                });
            });

            const props: React.SVGProps<any> = {
                strokeDasharray: '1,5,7,5',
                strokeWidth: 2,
                strokeLinecap: 'round',
                stroke: globalRiskColor(indicator),
                ...exportModeStrokeWidth(),
            };

            const line = new ChartLine(
                cacheKey,
                values,
                null,
                '',
                props,
                from.clone(),
                until.clone()
            );
            return line;
        })();
        saveCache(cacheKey, result);
        return result;
    }

    public static generateContingentRiskLine(
        countryId: number,
        risksArray: number[],
        maxImpactMap: IAPIMaxImpactMap
    ) {
        const initialDate = C_FIRST_DAY_HUMAN_SCORES_DATE.clone();

        const maxUscoreImpact = Math.max(...Object.values(maxImpactMap));
        const minUscoreImpact = Math.min(...Object.values(maxImpactMap));

        const contingentRiskId = risksStore.currentList[0];

        const values: ILineValue[] = risksArray.map((riskValue, index) => {
            const targetDate = initialDate.clone().add(Number(index), 'd');
            const dateStr = targetDate.format(C_SCORES_DATE_FORMAT);
            let circle: IChartCircle;
            const dayImpact = maxImpactMap[dateStrToOffset[dateStr]];
            if (dayImpact != null) {
                const normalizedImpact =
                    ((dayImpact - minUscoreImpact) /
                        (maxUscoreImpact - minUscoreImpact)) *
                        (3 - 1) +
                    1;
                circle = this.generateCircle(
                    normalizedImpact,
                    E_RISK_TYPE.CONTINGENT,
                    contingentRiskId,
                    dateStr,
                    [countryId, first(countryStore.currentCountryList)],
                    false,
                    riskValue
                );
            }

            return {
                yValue: riskValue,
                date: targetDate,
                circle,
            };
        });

        const contingentLineColor = countryStore.colorStack.color(countryId);
        const props: React.SVGProps<any> = {
            stroke: contingentLineColor,
            strokeWidth: 2,
        };

        const cacheKey = `ContingentRisk::${contingentRiskId}::${countryId}`;

        const line = new ChartLine(
            cacheKey,
            values,
            countryId,
            '',
            props,
            null,
            null
        );

        line.sideIndicator = {
            //@ts-ignore
            component: ChartNameIndicator,
            componentProps: {
                country: countryStore.countries.countries[countryId],
                isSquare: false,
            },
        };
        line.chartType = E_CHART_GROUP_IDENTIFIERS.CONTINGENT_RISK;

        return line;
    }

    private static async generateCacheForCurrencyExchangeLine(
        countryId: number
    ) {
        const overrideData = this.getCurrencyExchangeOverrideData(countryId);
        const cacheKey = `CurrencyExchange::${countryId}${
            overrideData ? '::override' : ''
        }`;
        if (cache[cacheKey]) {
            return cache[cacheKey];
        }

        const result = (async () => {
            let currencyDataForCountry: ICurrencyExchange[0];
            if (!overrideData) {
                const currencyData = await APIService.getCurrencyExchange();
                currencyDataForCountry = currencyData[countryId];
            } else {
                const baseDate = overrideData.dateBegin.clone();
                currencyDataForCountry = {};
                _.forEach(overrideData.data, v => {
                    currencyDataForCountry[baseDate.format('YYYY-MM-DD')] = v;
                    baseDate.add(1, 'd');
                });
            }
            const values: ILineValue[] = [];
            _.forEach(currencyDataForCountry, (yValue, dateString) => {
                values.push({
                    yValue,
                    date: moment(dateString, 'YYYY-MM-DD'),
                });
            });
            const props: React.SVGProps<any> = {
                strokeDasharray: '1,9',
                strokeWidth: 2,
                strokeLinecap: 'round',
                ...exportModeStrokeWidth(),
            };
            Object.defineProperty(props, 'stroke', {
                get: () => {
                    return countryStore.colorStack.color(countryId);
                },
                enumerable: true,
            });
            const line = new ChartLine(
                cacheKey,
                values,
                countryId,
                '',
                props,
                null,
                null
            );
            return line;
        })();
        saveCache(cacheKey, result);
        return result;
    }

    private static async generateLineForRiskPulse(
        riskType: E_RISK_TYPE,
        riskId: number,
        countryId: number,
        isDimensionBased: boolean,
        isGQ: boolean,
        showGeoquantLine: boolean,
    ) {
        const pulseData = await APIService.getRiskPulse(
            riskType,
            riskId,
            countryId,
            isGQ
        );
        const cacheKey = `RiskPulse:${countryId}:${riskType}:${riskId}:${isGQ}:${isDimensionBased}:${showGeoquantLine}`;
        if (cache[cacheKey]) {
            return cache[cacheKey];
        }

        const result = (async () => {
            const linePoints: ILineValue[] = mapWithKeys(
                (point: number, index: number) => ({
                    yValue: toNumber(point),
                    date: C_TIMELINE_MIN_DATE.clone().add(index, 'days'),
                }),
                pulseData
            );

            const props: React.SVGProps<any> = {
                stroke: isDimensionBased
                    ? risksStore.colorStack.color(riskId)
                    : countryStore.colorStack.color(countryId),
            };

            const classNames = showGeoquantLine ? 'pulse':'pulse single-line';
            const line = new ChartLine(
                cacheKey,
                linePoints,
                countryId,
                classNames,
                props,
                null,
                null
            );
            line.hideYValue = true;
            return line;
        })();

        saveCache(cacheKey, result);
        return result;
    }

    public static async generateLineForRiskProjection(
        riskType: E_RISK_TYPE,
        riskId: number,
        countryId: number,
        date: moment.Moment,
        isDimensionBased: boolean
    ) {
        const overrideData = this.getProjectionLineOverrideData(
            riskId,
            E_RISK_TYPE.DIMENSIONS,
            countryId,
            date
        );
        const cacheKey = `RiskScoreProject:${countryId}:${riskType}:${riskId}:${date.format(
            'YYYY-MM-DD'
        )}}${overrideData ? '::override' : ''}`;
        if (cache[cacheKey]) {
            return cache[cacheKey];
        }
        const result = (async () => {
            let scores;
            try {
                scores = await (overrideData
                    ? Promise.resolve(overrideData.data)
                    : APIService.getRiskProjections(
                          riskType,
                          riskId,
                          countryId,
                          date
                      ));
            } catch {}

            if (isEmpty(scores)) {
                return;
            }

            const values: ILineValue[] = [];
            let index = 0;
            for (
                const curr = date.clone();
                index < scores.length;
                curr.add(7, 'd'), index++
            ) {
                if (scores[index] !== undefined) {
                    values.push({
                        yValue: scores[index],
                        date: curr.clone(),
                    });
                }
            }

            const props: React.SVGProps<any> = {
                strokeDasharray: '3,3',
                strokeWidth: 1,
                ...exportModeStrokeWidth(),
                stroke: isDimensionBased
                    ? risksStore.colorStack.color(riskId)
                    : countryStore.colorStack.color(countryId),
            };

            const line = new ChartLine(
                cacheKey,
                values,
                countryId,
                '',
                props,
                null,
                null
            );
            line.hideYValue = true;
            return line;
        })();
        saveCache(cacheKey, result);
        return result;
    }

    private static async generateCacheEntityGraphLine(
        countryId: number,
        entityId: number,
        entityType: E_RISK_TYPE,
        isDimensionBased: boolean,
        isGQ: boolean
    ) {
        const overrideData = this.getPoliticlaRiskOverrideData(
            entityId,
            entityType,
            countryId,
            isGQ
        );
        let cacheKeyPrefix: string;
        if (entityType === E_RISK_TYPE.DIMENSIONS) {
            cacheKeyPrefix = 'DimensionRisk';
        } else if (entityType === E_RISK_TYPE.CLIENT_FACING_INDICATORS) {
            cacheKeyPrefix = 'ClientFacingIndicator';
        } else {
            throw new Error(
                `Invalid entityType parameter ${entityType} passed to method generateCacheEntityGraphLine`
            );
        }
        const cacheKey = `${cacheKeyPrefix}::${entityId}::${countryId}::${isDimensionBased}::${isGQ}${
            overrideData ? '::override' : ''
        }`;
        if (cache[cacheKey]) {
            return cache[cacheKey];
        }
        const result = (async () => {
            const scoresPromise = new Promise<number[]>((resolve, reject) => {
                if (overrideData) {
                    resolve(overrideData.data);
                } else {
                    APIService.getRiskScores(
                        entityType,
                        entityId,
                        [countryId],
                        isGQ
                    )
                        .then(scoresByCountry =>
                            resolve(scoresByCountry[countryId])
                        )
                        .catch(err => reject(err));
                }
            });

            const [scores, uscores, { countries }] = await Promise.all([
                scoresPromise,
                APIService.getMaxImpactMaps(entityType, entityId, [countryId]),
                APIService.getActiveCountries(),
            ]);
            if (isEmpty(scores) && !isGQ) {
                return;
            }

            const countryUscores: IAPIMaxImpactMap = uscores || {};
            const maxUscoreImpact = Math.max(...Object.values(countryUscores));
            const minUscoreImpact = Math.min(...Object.values(countryUscores));
            const datesData = scores;
            const values: ILineValue[] = [];
            //@ts-ignore
            scoresProxyHandler.iterator(datesData, (k, m, s, i) => {
                const normalizedImpact =
                    ((countryUscores[i] - minUscoreImpact) /
                        (maxUscoreImpact - minUscoreImpact)) *
                        (3 - 1) +
                    1;
                values.push({
                    yValue: Number(s),
                    date: m,
                    circle: isGQ
                        ? this.generateCircle(
                              normalizedImpact,
                              entityType,
                              entityId,
                              k,
                              [countryId],
                              isDimensionBased,
                              s
                          )
                        : undefined,
                });
            });

            const props: React.SVGProps<any> = {
                ...exportModeStrokeWidth(),
            };

            Object.defineProperty(props, 'stroke', {
                get: () => {
                    return isDimensionBased
                        ? risksStore.colorStack.color(entityId)
                        : countryStore.colorStack.color(countryId);
                },
                enumerable: true,
            });
            if (isGQ) {
                if (!isExportMode()) {
                    props.strokeWidth = 2;
                }
                Object.defineProperty(props, 'strokeDasharray', {
                    get: () => {
                        return getQQLineDashArray();
                    },
                    enumerable: true,
                });
            }

            const line = new ChartLine(
                cacheKey,
                values,
                countryId,
                '',
                props,
                null,
                null
            );
            line.chartType = E_CHART_GROUP_IDENTIFIERS.POLITICAL_RISK;
            if (!isDimensionBased) {
                line.sideIndicator = {
                    //@ts-ignore
                    component: ChartNameIndicator,
                    componentProps: {
                        country: countries[countryId],
                        isSquare: !isGQ,
                    },
                };
            }
            return line;
        })();
        saveCache(cacheKey, result);
        return result;
    }

    private static generateCircle(
        impact: number,
        entityType: E_RISK_TYPE,
        entityId: number,
        date: string,
        countryIds: number[],
        isDimensionBased: boolean,
        scoreVal: number
    ): IChartCircle {
        if (typeof impact !== 'number' || isNaN(impact)) {
            // JS is stupid, thats why you need to test for both...
            return;
        }
        let radius;
        switch (true) {
            case impact <= 1:
                radius = 3;
                break;
            case impact > 1 && impact <= 2:
                radius = 5;
                break;
            case impact > 2 && impact <= 3:
                radius = 7;
                break;
            default:
                break;
        }
        if (isExportMode()) {
            radius *= 3;
        }
        const id = ChartScoresService.circleId(
            countryIds,
            date,
            entityType,
            entityId
        );
        const props: React.SVGProps<SVGCircleElement> & ICustomDomAttirubtes = {
            'data-country-ids': JSON.stringify(countryIds),
            'data-entity-type': entityType,
            'data-entity-id': entityId,
            'data-date': date,
            'data-value': scoreVal,
            onMouseEnter: ChartScoresService.onMouseEnter,
            onMouseLeave: ChartScoresService.onMouseLeave,
            onClick: ChartScoresService.onMouseClick,
            ref: e => {
                ChartScoresService.onCircleRef(
                    e,
                    countryIds,
                    entityType,
                    entityId,
                    date,
                    scoreVal,
                    id
                );
            },
        };
        Object.defineProperty(props, 'fill', {
            get: () => {
                return this.isSelectedScore(id)
                    ? 'white'
                    : isDimensionBased
                    ? risksStore.colorStack.color(entityId)
                    : countryStore.colorStack.color(countryIds[0]);
            },
            enumerable: true,
        });

        return {
            identifier: id,
            radius,
            impact,
            className: '',
            props,
        };
    }

    private static extractDataAttributes(element: SVGCircleElement) {
        const res = {
            countryIds: JSON.parse(
                element.getAttribute('data-country-ids')
            ) as number[],
            entityType: Number(element.getAttribute('data-entity-type')),
            entityId: Number(element.getAttribute('data-entity-id')),
            date: element.getAttribute('data-date'),
            value: Number(element.getAttribute('data-value')),
            id: '',
        };

        res.id = ChartScoresService.circleId(
            res.countryIds,
            res.date,
            res.entityType,
            res.entityId
        );
        return res;
    }

    private static onMouseEnter = (e: React.MouseEvent<SVGCircleElement>) => {
        const {
            countryIds,
            entityType,
            entityId,
            date,
            value,
        } = ChartScoresService.extractDataAttributes(e.target as any);
        ChartScoresService.uscoreOnHover(
            countryIds,
            entityType,
            entityId,
            date
        );
        GQPopupStorage.addData('hovered_uscore', {
            element: e.target as any,
            tooltipData: <UScorePopup date={moment(date)} impact={value} />,
            orientation: 'top center',
        });
    };

    private static onCircleRef = (
        e: SVGCircleElement,
        countryIds: number[],
        entityType: E_RISK_TYPE,
        entityId: number,
        date: string,
        value: number,
        id: string
    ) => {
        const momentDate = moment(date, 'YYYY-MM-DD');
        const isSelectedScore = ChartScoresService.isSelectedScore(id);
        if (
            e === null &&
            isSelectedScore &&
            (momentDate.isBefore(
                chartStore.baseDate
                    .clone()
                    .subtract(chartStore.rangeLeft, 'days')
            ) ||
                momentDate.isAfter(
                    chartStore.baseDate
                        .clone()
                        .add(chartStore.rangeRight, 'days')
                ))
        ) {
            ChartScoresService.onUscoreClick(
                countryIds,
                entityType,
                entityId,
                date
            );
        }
        if (isSelectedScore) {
            GQPopupStorage.addData(
                'clicked_uscore',
                e
                    ? {
                          element: e as any,
                          tooltipData: (
                              <UScorePopup date={moment(date)} impact={value} />
                          ),
                          orientation: 'top center',
                      }
                    : null
            );
        }
    };

    private static onMouseClick = (e: React.MouseEvent<SVGCircleElement>) => {
        const {
            countryIds,
            entityType,
            entityId,
            date,
            value,
            id,
        } = ChartScoresService.extractDataAttributes(e.target as any);
        ChartScoresService.onUscoreClick(
            countryIds,
            entityType,
            entityId,
            date
        );
        const isSelectedScore = ChartScoresService.isSelectedScore(id);
        GQPopupStorage.addData(
            'clicked_uscore',
            !isSelectedScore
                ? null
                : {
                      element: e.target as any,
                      tooltipData: (
                          <UScorePopup date={moment(date)} impact={value} />
                      ),
                      orientation: 'top center',
                  }
        );
    };
    private static onMouseLeave = (e: React.MouseEvent<SVGElement>) => {
        const {
            countryIds,
            entityType,
            entityId,
            date,
        } = ChartScoresService.extractDataAttributes(e.target as any);
        ChartScoresService.uscoreOnHoverOut(
            countryIds,
            entityType,
            entityId,
            date
        );
        GQPopupStorage.addData('hovered_uscore', null);
    };
    private static uscoreOnHover(
        countryIds: number[],
        entityType: E_RISK_TYPE,
        entityId: number,
        date: string
    ) {
        eventsFeedStore.setCurrentHoveredUscore({
            countryIds,
            entityType,
            entityId,
            date,
            asId: ChartScoresService.circleId(
                countryIds,
                date,
                entityType,
                entityId
            ),
        });
    }

    private static uscoreOnHoverOut(
        countryIds: number[],
        entityType: E_RISK_TYPE,
        entityId: number,
        date: string
    ) {
        eventsFeedStore.setCurrentHoveredUscore(null);
    }

    private static isSelectedScore(id: string) {
        return (
            eventsFeedStore.currentSelectedUscore &&
            eventsFeedStore.currentSelectedUscore.asId === id
        );
    }

    private static toggleSelectedUscore(
        countryIds: number[],
        entityType: E_RISK_TYPE,
        entityId: number,
        date: string
    ) {
        const id = ChartScoresService.circleId(
            countryIds,
            date,
            entityType,
            entityId
        );
        const isSelectedScore = this.isSelectedScore(id);
        eventsFeedStore.setCurrentSelectedUscore(
            isSelectedScore
                ? null
                : {
                      countryIds,
                      entityType,
                      entityId,
                      date,
                      asId: id,
                  }
        );
    }
    private static onUscoreClick(
        countryIds: number[],
        entityType: E_RISK_TYPE,
        entityId: number,
        date: string
    ) {
        this.toggleSelectedUscore(countryIds, entityType, entityId, date);
        chartStore.increaseSequence();
    }

    private static circleId(
        countryIds: number[],
        date: string,
        entityType: E_RISK_TYPE,
        entityId: number
    ) {
        return `Uscores::${countryIds.join(
            ':'
        )}::${date}::${entityType}::${entityId}`;
    }

    private static getPoliticlaRiskOverrideData(
        riskId: number,
        riskIdentifier: E_RISK_TYPE,
        countryId: number,
        isGQ: boolean
    ): IActivityRecordChartDataSnapshot {
        if (this.overrideData === null) {
            return null;
        }
        if (!this.overrideData[E_CHART_GROUP_IDENTIFIERS.POLITICAL_RISK]) {
            return null;
        }

        const data = _.find(
            this.overrideData[E_CHART_GROUP_IDENTIFIERS.POLITICAL_RISK],
            cur => {
                return (
                    cur.country === countryId &&
                    cur.riskType === riskIdentifier &&
                    cur.risk === riskId &&
                    cur.isGQ === isGQ
                );
            }
        );
        return data;
    }

    private static getCurrencyExchangeOverrideData(
        countryId: number
    ): IActivityRecordChartDataSnapshot {
        if (this.overrideData === null) {
            return null;
        }
        if (!this.overrideData[E_CHART_GROUP_IDENTIFIERS.CURRENCY_EXCHANGE]) {
            return null;
        }

        return _.find(
            this.overrideData[E_CHART_GROUP_IDENTIFIERS.CURRENCY_EXCHANGE],
            cur => {
                return cur.country === countryId;
            }
        );
    }

    private static getProjectionLineOverrideData(
        riskId: number,
        riskIdentifier: E_RISK_TYPE,
        countryId: number,
        date: moment.Moment
    ): IActivityRecordChartDataSnapshot {
        if (this.overrideData === null) {
            return null;
        }
        if (!this.overrideData[E_CHART_GROUP_IDENTIFIERS.PROJECTION_LINE]) {
            return null;
        }
        return _.find(
            this.overrideData[E_CHART_GROUP_IDENTIFIERS.PROJECTION_LINE],
            cur => {
                return (
                    cur.country === countryId &&
                    cur.riskType === riskIdentifier &&
                    cur.risk === riskId &&
                    date.isSame(cur.date, 'd')
                );
            }
        );
    }
}
EventEmitter.registerOnReloadCountry(cids => {
    ChartScoresService.clearCache();
});
