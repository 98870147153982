// taken from https://github.com/ianstormtaylor/slate/blob/main/site/examples/paste-html.tsx
import { jsx } from 'slate-hyperscript';
import { flatten, includes, toLower } from 'lodash/fp';
import { Transforms } from 'slate';

const ELEMENT_TAGS = {
    A: el => {
        // support both Google Docs and Word
        const url = el.getAttribute('href') || el.getAttribute('url');
        return {
            type: 'link',
            url,
        };
    },
    BLOCKQUOTE: () => ({ type: 'quote' }),
    H1: () => ({ type: 'heading-one' }),
    H2: () => ({ type: 'heading-two' }),
    H3: () => ({ type: 'heading-three' }),
    H4: () => ({ type: 'heading-four' }),
    H5: () => ({ type: 'heading-five' }),
    H6: () => ({ type: 'heading-six' }),
    IMG: el => ({ type: 'image', url: el.getAttribute('src') }),
    LI: () => ({ type: 'list-item' }),
    OL: () => ({ type: 'numbered-list' }),
    P: () => ({ type: 'paragraph' }),
    PRE: () => ({ type: 'code' }),
    UL: () => ({ type: 'bulleted-list' }),
};

 const BOLD_FONT_WEIGHTS = [
     'bold',
     'bolder',
     '500',
     '600',
     '700',
     '800',
     '900',
 ];
// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
const TEXT_TAGS = {
    CODE: () => ({ code: true }),
    DEL: () => ({ strikethrough: true }),
    EM: () => ({ italic: true }),
    I: () => ({ italic: true }),
    S: () => ({ strikethrough: true }),
    STRONG: () => ({ bold: true }),
    U: () => ({ underline: true }),
    SPAN: el => ({
        bold: includes(toLower(el.style?.fontWeight), BOLD_FONT_WEIGHTS),
        underline: el.style?.textDecoration === 'underline',
        italic: el.style?.fontStyle === 'italic',
    }),
};

export const withHtml = editor => {
    const { insertData, isInline, isVoid } = editor;

    editor.isInline = element => {
        return element.type === 'link' ? true : isInline(element);
    };

    editor.isVoid = element => {
        return element.type === 'gq-insight' ? true : isVoid(element);
    };

    editor.insertData = data => {
        if (includes('text/uri-list', data.types)) {
            return insertData(data);
        }

        const html = data.getData('text/html');

        if (html) {
            const parsed = new DOMParser().parseFromString(html, 'text/html');
            let fragment = deserialize(parsed.body);
            Transforms.insertFragment(editor, fragment);
            return;
        }

        insertData(data);
    };

    return editor;
};

const deserialize = el => {
    if (el.nodeType === 3) {
        return el.textContent;
    } else if (el.nodeType !== 1) {
        return null;
    } else if (el.nodeName === 'BR') {
        return '\n';
    }

    const { nodeName } = el;
    let parent = el;

    if (
        nodeName === 'PRE' &&
        el.childNodes[0] &&
        el.childNodes[0].nodeName === 'CODE'
    ) {
        parent = el.childNodes[0];
    }
    let children = flatten(Array.from(parent.childNodes).map(deserialize));

    if (children.length === 0) {
        children = [{ text: '' }];
    }

    if (el.nodeName === 'BODY') {
        return jsx('fragment', {}, children);
    }

    if (ELEMENT_TAGS[nodeName]) {
        const attrs = ELEMENT_TAGS[nodeName](el);
        return jsx('element', attrs, children);
    }

    if (TEXT_TAGS[nodeName]) {
        const attrs = TEXT_TAGS[nodeName](el);
        return children.map(child => jsx('text', attrs, child));
    }

    return children;
};
