import React from 'react';
import { IEventUScore } from '../../../services/APIService';
import GQFlag from '../../GQFlag';
import { GQutils } from '../../../services/GQutils';
import { GQButton } from '../../GQButton';

export interface IGQPastScoreItemProps {
    pastScoreItem: IEventUScore;
    canEdit: boolean;
    canDelete: boolean;
    onEdit: (score: IEventUScore) => void;
    onDelete: (score: IEventUScore) => void;
    editMode: boolean;
    notifyMenuDispaly: (shoeMenu: boolean) => void;
    canShowMenu: boolean;
}
export interface IGQPastScoreItemState {
    showEditMenu: boolean;
    riskName: string;
}

export default class PastScoreItem extends React.Component<
    IGQPastScoreItemProps,
    IGQPastScoreItemState
> {
    public riskName: string;
    public impact: {
        text: string;
        value: number;
        isBelong: (value: number) => boolean;
        color: string;
    };

    constructor(props: IGQPastScoreItemProps) {
        super(props);
        this.riskName = '';
        this.impact = GQutils.findImpactByValue(
            this.props.pastScoreItem.impact
        );
        this.state = {
            showEditMenu: false,
            riskName: '',
        };
        this.getRiskNameById(this.props.pastScoreItem.dimension_id);
    }
    public UNSAFE_componentWillReceiveProps(nextProps: IGQPastScoreItemProps) {
        if (this.state.showEditMenu && !nextProps.editMode) {
            this.setState({
                showEditMenu: false,
            });
            this.impact = GQutils.findImpactByValue(
                nextProps.pastScoreItem.impact
            );
            this.props.notifyMenuDispaly(false);
        }
    }
    public render() {
        const { pastScoreItem, canDelete, canEdit, canShowMenu } = this.props;
        const handleClick = (score: IEventUScore): any => {
            if (!this.props.editMode) {
                this.props.onEdit(score);
            }
        };
        const handleClickDelete = (score: IEventUScore): any => {
            this.props.onDelete(score);
        };
        return (
            <div className="past-score-item-container">
                <div
                    className={
                        this.state.showEditMenu
                            ? 'past-score-item opacity'
                            : 'past-score-item'
                    }>
                    <GQFlag countryId={pastScoreItem.country_id} />
                    <div className="past-score-property">
                        {this.state.riskName}{' '}
                    </div>
                    <div
                        className="past-score-property"
                        style={{ color: this.impact.color, width: '100px' }}>
                        {this.impact.text}
                    </div>
                    <div
                        className="past-score-property"
                        style={{ width: '40px' }}>
                        {' '}
                        {!this.state.showEditMenu &&
                            GQutils.findDurationByValue(pastScoreItem.duration)
                                .text}
                    </div>
                    <div className="edit-score-menu-icon">
                        {(canDelete || canEdit) &&
                        canShowMenu &&
                        !this.state.showEditMenu ? (
                            <GQButton
                                icon="gqi-ellipsis-v"
                                noBorder={true}
                                onClick={this.showMenu}
                                noPadding={true}
                            />
                        ) : (
                            ''
                        )}
                    </div>
                </div>
                <div
                    className={
                        this.state.showEditMenu ? 'past-score-menu' : 'hideMenu'
                    }>
                    {canEdit ? (
                        <GQButton
                            icon="gqi-edit"
                            noBorder={true}
                            noPadding={true}
                            style={{
                                paddingRight: '8px',
                                color:
                                    this.props.editMode &&
                                    this.state.showEditMenu
                                        ? '#17ff96'
                                        : '',
                            }}
                            onClick={handleClick.bind(this, pastScoreItem)}
                        />
                    ) : (
                        ''
                    )}
                    {canDelete ? (
                        <GQButton
                            icon={'gqi-delete'}
                            noBorder={true}
                            noPadding={true}
                            style={{ transitionDelay: '80ms' }}
                            onClick={handleClickDelete.bind(
                                this,
                                pastScoreItem
                            )}
                        />
                    ) : (
                        ''
                    )}
                    <GQButton
                        icon={'gqi-close'}
                        noBorder={true}
                        noPadding={true}
                        style={{ transitionDelay: '80ms' }}
                        onClick={this.closeMenu}
                    />
                </div>
            </div>
        );
    }

    private getRiskNameById = async (riskId: number) => {
        try {
            this.setState({
                riskName: await GQutils.findDimensionShortNameByID(riskId),
            });
        } catch (err) {
        }
    };

    private showMenu = () => {
        this.setState({
            showEditMenu: !this.state.showEditMenu,
        });
        this.props.notifyMenuDispaly(true);
    };

    private closeMenu = () => {
        this.setState({
            showEditMenu: !this.state.showEditMenu,
        });
        this.props.notifyMenuDispaly(false);
        if (this.props.editMode) {
            this.props.onEdit({
                country_id: 0,
                dimension_id: 0,
                impact: 0,
                duration: 90,
                score_id: null,
                organization_id: '',
            });
        }
    };
}
