import React from 'react';
import cx from 'classnames';

import GQButton from '../../GQButton/GQButton';
import EventsService, {
    EventSubscription,
} from '../../../services/EventsService';
import { IActiveCountry } from '../../../services/APIService';

interface IGQInsightNoteProps {
    country: IActiveCountry;
    text: string;
    index: number;
    date?: string;
    id: string;
    onChange: (id: string, newVal: string) => void;
    onDelete: (index: number) => void;
}

interface IGQInsightNoteState {
    showButtons: boolean;
    editing: boolean;
    editValue: string;
}

export default class InsightNote extends React.Component<
    IGQInsightNoteProps,
    IGQInsightNoteState
> {
    private textStyle: React.CSSProperties = {};
    private textAreaRef: HTMLTextAreaElement;
    private insightMountSub: EventSubscription;
    private clickSub: EventSubscription;
    constructor(props: IGQInsightNoteProps) {
        super(props);
        this.state = {
            showButtons: false,
            editing: false,
            editValue: '',
        };
    }
    public componentDidMount() {
        EventsService.emitOnInsightMount(this.props.id);
        this.insightMountSub = EventsService.registerOnInsightMount(
            this.handleInsightMount
        );
        if (this.props.text === '') {
            this.setState({
                editing: true,
            });
        }
    }
    public componentWillUnmount() {
        if (this.insightMountSub) {
            this.insightMountSub.remove();
        }
        if (this.clickSub) {
            this.clickSub.remove();
        }
    }
    public componentDidUpdate(
        prevProps: IGQInsightNoteProps,
        prevState: IGQInsightNoteState
    ) {
        if (this.state.editing && !prevState.editing && this.textAreaRef) {
            setTimeout(() => {
                this.adjustTextareaHeight(this.state.editValue);
                this.forceUpdate();
                this.textAreaRef.focus();
            });
        }
    }
    public UNSAFE_componentWillUpdate(
        nextProps: IGQInsightNoteProps,
        nextState: IGQInsightNoteState
    ) {
        if (
            this.state.editing &&
            this.state.editValue !== nextState.editValue
        ) {
            this.adjustTextareaHeight(nextState.editValue);
        }
    }
    public render() {
        return (
            <div
                className="gq-insight-note flex-container align-top"
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}>
                {this.state.editing ? (
                    <div className="gq-insight-note-edit flex-child-grow">
                        <div className="gq-insight-note-edit-text">
                            {this.renderTextAreaTitle()}
                            <textarea
                                ref={el => (this.textAreaRef = el)}
                                rows={1}
                                onKeyDown={this.onKeyDown}
                                value={this.state.editValue}
                                onChange={this.onTextChange}
                                placeholder="Type something"
                                style={{ ...this.textStyle }}
                            />
                        </div>
                        <div className="gq-insight-note-edit-buttons flex-container align-right">
                            <GQButton
                                caption="Cancel"
                                onClick={this.exitEdit}
                            />
                            <GQButton caption="Save" onClick={this.saveNote} />
                        </div>
                    </div>
                ) : (
                    <div className="gq-insight-note-view flex-container align-top filler">
                        <div className="gq-insight-note-index flex-container align-center-middle">
                            {this.props.index}
                        </div>
                        <div className="gq-insight-note-content">
                            {this.renderNoteTitle()}
                            <p>{this.props.text}</p>
                        </div>
                        <div
                            className={cx([
                                'gq-insight-note-hover-buttons flex-container',
                                { show: this.state.showButtons },
                            ])}>
                            <GQButton
                                icon="gqi-edit"
                                noBorder
                                onClick={this.enterEditMode}
                            />
                            <GQButton
                                icon="gqi-delete"
                                noBorder
                                onClick={this.deleteNote}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    }
    private renderTextAreaTitle() {
        if (this.props.date) {
            return (
                <div className="gq-insight-note-date edit">
                    <span>{this.props.date}</span>
                </div>
            );
        } else if (this.props.country) {
            return (
                <div className="gq-insight-note-date edit">
                    <span>{this.props.country.name}</span>
                </div>
            );
        }
    }
    private renderNoteTitle() {
        if (this.props.date) {
            return (
                <div className="gq-insight-note-date">
                    <span>{this.props.date}</span>
                </div>
            );
        } else if (this.props.country) {
            return (
                <div className="gq-insight-note-date">
                    <span>{this.props.country.name}</span>
                </div>
            );
        }
    }
    private handleInsightMount = (id: string) => {
        if (id !== this.props.id && this.state.editing) {
            this.exitEdit();
        }
    };
    private deleteNote = () => {
        this.props.onDelete(this.props.index - 1);
    };
    private exitEdit = () => {
        if (!this.props.text) {
            this.deleteNote();
        } else {
            this.setState({
                editing: false,
                editValue: '',
            });
        }
    };
    private saveNote = () => {
        this.props.onChange(this.props.id, this.state.editValue);
        this.setState({
            editing: false,
            editValue: '',
        });
    };
    private enterEditMode = () => {
        EventsService.emitOnInsightMount(this.props.id);
        this.setState({
            editing: true,
            showButtons: false,
            editValue: this.props.text,
        });
    };
    private onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        e.stopPropagation();
    };

    private onTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = e.currentTarget;
        this.setState({
            editValue: value,
        });
    };
    private onMouseEnter = () => {
        if (this.state.editing) {
            return;
        }
        this.setState({
            showButtons: true,
        });
    };
    private onMouseLeave = () => {
        if (this.state.editing) {
            return;
        }
        this.setState({
            showButtons: false,
        });
    };
    private adjustTextareaHeight = (text: string) => {
        if (this.textAreaRef) {
            const mockEl = this.textAreaRef.cloneNode() as HTMLTextAreaElement;
            const textCSS = getComputedStyle(this.textAreaRef);
            mockEl.style.fontFamily = textCSS.fontFamily;
            mockEl.style.lineHeight = textCSS.lineHeight;
            mockEl.style.fontSize = textCSS.fontSize;
            mockEl.style.width = `${this.textAreaRef.getBoundingClientRect()
                .width -
                parseInt(textCSS.paddingLeft, 10) * 2}px`;
            mockEl.style.height = '0px';
            mockEl.style.minHeight = '0px';
            mockEl.style.padding = '0';
            mockEl.style.margin = '0';
            mockEl.style.boxSizing = 'content-box';
            mockEl.style.wordWrap = 'break-word';
            mockEl.value = text;
            document.body.appendChild(mockEl);
            this.textStyle.height = mockEl.scrollHeight;
            mockEl.remove();
        }
    };
}
