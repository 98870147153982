import React from 'react';
import classNames from 'classnames';

import GQButton from '../../../../GQButton/GQButton';
import { TweenMax } from 'gsap';
import Emitter, {
    GQEmitterEvents,
} from '../../../../../services/EventsService';

interface IGQCGMenuItemProps {
    id: string;
    displayName: string;
    active: boolean;
    editing: boolean;
    isGQ: boolean;
    onSetActive: (id: string) => void;
    onClick: (id: string) => void;
    onDelete: (id: string) => void;
}

interface IGQCGMenuItemState {
    showDeleteConfirm: boolean;
    isLoading: boolean;
}

export default class GQCWMenuItem extends React.Component<
    IGQCGMenuItemProps,
    IGQCGMenuItemState
> {
    private confirmDelRef: HTMLDivElement = null;
    private mounted = false;
    constructor(props: IGQCGMenuItemProps) {
        super(props);
        this.state = {
            showDeleteConfirm: false,
            isLoading: false,
        };
    }
    public UNSAFE_componentWillReceiveProps(nextProps: IGQCGMenuItemProps) {
        if (this.props.active !== nextProps.active) {
            this.setState({
                isLoading: false,
            });
        }
    }
    public componentDidMount() {
        this.mounted = true;
        Emitter.registerEvent(
            GQEmitterEvents.MOUSE_LEFT_CLICK,
            this.hideConfirm
        );
        Emitter.registerOnDeleteClick(this.shouldHideDelete);
    }
    public componentWillUnmount() {
        this.mounted = false;
    }
    public render() {
        const { isGQ } = this.props;
        return (
            <div
                className={classNames([
                    'gq-cw-menu-item',
                    { editing: this.props.editing },
                ])}
                onClick={this.onItemClick}>
                <div
                    className={classNames([
                        'gq-cw-menu-item-active-outer-indicator',
                    ])}
                    onClick={this.onClickCB}
                    style={{ border: 'none' }}>
                    <div
                        className={classNames(
                            ['gq-cw-menu-item-active-inner-indicator'],
                            { show: this.props.active }
                        )}
                    />
                </div>
                <span className="gq-cw-menu-item-caption">
                    {this.props.displayName}
                </span>
                <div
                    className={classNames([
                        'menu-delete-icon',
                        { show: this.state.showDeleteConfirm },
                    ])}>
                    {!this.state.showDeleteConfirm && !isGQ && (
                        <GQButton
                            icon="gqi-delete"
                            noBorder={true}
                            noPadding={true}
                            onClick={this.onTrashClick}
                        />
                    )}
                </div>
                <div
                    className="gq-cw-delete-confirm"
                    ref={el => (this.confirmDelRef = el)}
                    onClick={this.onDeleteConfirm}>
                    <span>Delete</span>
                </div>
                <div />
            </div>
        );
    }

    private onClickCB = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        if (!this.props.active) {
            this.setState({
                isLoading: true,
            });
            this.props.onSetActive(this.props.id);
        }
    };

    private shouldHideDelete = (id: string) => {
        if (this.props.id !== id) {
            this.hideConfirm();
        }
    };

    private onItemClick = (e: React.MouseEvent<HTMLDivElement>) => {
        this.props.onClick(this.props.id);
    };

    private onDeleteConfirm = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        this.hideConfirm();
        this.props.onDelete(this.props.id);
    };

    private hideConfirm = () => {
        if (this.mounted) {
            this.setState(
                {
                    showDeleteConfirm: false,
                },
                () => this.animateConfirm(true)
            );
        }
    };

    private onTrashClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        Emitter.emitOnDeleteClick(this.props.id);
        this.setState(
            {
                showDeleteConfirm: true,
            },
            this.animateConfirm
        );
    };

    private animateConfirm = (leave?: boolean) => {
        if (leave) {
            TweenMax.to(this.confirmDelRef, 0.5, {
                opacity: 0,
                width: 0,
            } as any);
            return;
        }
        TweenMax.to(this.confirmDelRef, 0.5, {
            opacity: 1,
            width: '78px',
        } as any);
    };
}
