import React from 'react';
import classNames from 'classnames';

import Tooltip, { positionString } from './GQButtonTooltip';
import * as _ from 'lodash';
import GQLoader from '../GQLoader/GQLoader';
import { ITransitionGroupComponent } from '../../interfaces';

export interface IGQButtonProps extends React.HTMLAttributes<HTMLDivElement> {
    caption?: string;
    icon?: string | JSX.Element;
    style?: React.CSSProperties;
    containerStyle?: React.CSSProperties;
    className?: string;
    containerClassName?: string;
    iconClass?: string;
    circle?: boolean;
    noBorder?: boolean;
    toggleButton?: boolean;
    active?: boolean;
    tooltip?: string;
    tooltipPosition?: positionString;
    noPadding?: boolean;
    catchAllPointerEvents?: boolean;
    rounded?: boolean;
    squared?: boolean;
    disabled?: (() => boolean) | boolean;
    animateClass?: string;
    tab?: 'left' | 'top' | 'right' | 'bottom';
    showLoading?: boolean;
    fluid?: boolean;
    options?: {
        enterAnim?: (
            element: Element,
            cb: () => void,
            duration?: number
        ) => void;
        leaveAnim?: (
            element: Element,
            cb: () => void,
            duration?: number
        ) => void;
        duration?: number;
    };
}

interface IGQButtonState {
    animating: boolean;
    showTooltip: boolean;
    toggled: boolean;
}

export default class GQButton
    extends React.Component<IGQButtonProps, IGQButtonState>
    implements ITransitionGroupComponent {
    private mounted: boolean = false;
    private container: HTMLDivElement = null;
    constructor(props: IGQButtonProps) {
        super(props);
        this.state = {
            animating: false,
            showTooltip: false,
            toggled: false,
        };
    }

    public componentWillEnter(cb: () => void) {
        const { options } = this.props;
        if (!options || (options && !options.enterAnim)) {
            cb();
        } else {
            const { enterAnim, duration } = this.props.options;
            enterAnim(this.container, cb, duration);
        }
    }

    public componentWillLeave(cb: () => void) {
        const { options } = this.props;
        if (!options || (options && !options.leaveAnim)) {
            cb();
        } else {
            const { leaveAnim, duration } = this.props.options;
            leaveAnim(this.container, cb, duration);
        }
    }

    public componentDidMount() {
        this.mounted = true;
    }

    public componentWillUnmount() {
        this.mounted = false;
    }

    public setState(state: any) {
        if (this.mounted) {
            super.setState(state);
        }
    }

    public render() {
        const classes = classNames(['gq-button'], {
            [this.props.className]: !!this.props.className,
            animate: this.state.animating,
            [this.props.animateClass]:
                this.state.animating && !!this.props.animateClass,
            'no-border': this.props.noBorder,
            active:
                this.props.active ||
                (this.props.toggleButton && this.state.toggled),
            'no-padding': this.props.noPadding,
            'catch-all-pointer-events': this.props.catchAllPointerEvents,
            'tab-left': this.props.tab === 'left',
            'tab-right': this.props.tab === 'right',
            'tab-top': this.props.tab === 'top',
            'tab-bottom': this.props.tab === 'bottom',
            round: this.props.rounded,
            square: this.props.squared,
            toggle: this.props.toggleButton,
            disabled: !!this.props.disabled,
            loading: this.props.showLoading,
            fluid: this.props.fluid,
        });
        return (
            <div
                className={classNames([
                    'gq-button-container flex-container align-center-middle',
                    {
                        [this.props.containerClassName]: !!this.props
                            .containerClassName,
                    },
                ])}
                onClick={this.onButtonClick}
                onMouseDown={this.props.onMouseDown || this.disablePropagation}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
                id={this.props.id}
                ref={el => {
                    this.container = el;
                }}
                style={{ ...this.props.containerStyle }}>
                {this.props.showLoading && (
                    <div className="gq-button-loading-container">
                        <GQLoader size="small" />
                    </div>
                )}
                {!this.props.showLoading && (
                    <div className={classes} style={{ ...this.props.style }}>
                        {!this.props.showLoading &&
                            this.props.icon &&
                            this.getIconElement()}
                        {!this.props.showLoading && this.props.caption && (
                            <span className="gq-button-caption">
                                {this.props.caption}
                            </span>
                        )}
                        {!this.props.showLoading && this.props.children}
                    </div>
                )}
                {this.state.showTooltip && this.container && (
                    <Tooltip
                        content={this.props.tooltip}
                        position={this.props.tooltipPosition || 'top center'}
                        parentProps={this.container}
                    />
                )}
            </div>
        );
    }

    private disablePropagation(e: React.MouseEvent<any>) {
        e.stopPropagation();
    }

    private getIconElement() {
        if (typeof this.props.icon === 'string') {
            const iconClass = classNames([
                this.props.icon,
                'icon',
                { [this.props.iconClass]: !!this.props.iconClass },
            ]);
            return (
                <i
                    aria-hidden={true}
                    className={iconClass}
                    style={{
                        margin: 0,
                        padding: 0,
                        paddingRight: this.props.caption ? '.25rem' : 0,
                    }}
                />
            );
        } else {
            return this.props.icon;
        }
    }

    private onButtonClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (this.props.disabled) {
            return;
        }
        const newState: { animating: boolean; toggled?: boolean } = {
            animating: true,
        };
        if (this.props.onClick) {
            e.persist();
            this.props.onClick(e);
        }
        if (_.isNil(this.props.active) && this.props.toggleButton) {
            newState.toggled = !this.state.toggled;
        }
        this.setState(newState);
        setTimeout(() => {
            this.setState({
                animating: false,
            });
        }, 500);
    };

    private onMouseEnter = (e: React.MouseEvent<HTMLDivElement>) => {
        e.persist();
        if (this.props.onMouseEnter) {
            this.props.onMouseEnter(e);
        }
        if (this.props.tooltip) {
            this.setState({
                showTooltip: true,
            });
        }
    };

    private onMouseLeave = (e: React.MouseEvent<HTMLDivElement>) => {
        e.persist();
        if (this.props.onMouseLeave) {
            this.props.onMouseLeave(e);
        }
        if (this.props.tooltip) {
            this.setState({
                showTooltip: false,
            });
        }
    };
}
