import React from 'react';
import cx from 'classnames';
import { TweenLite } from 'gsap';
import GQLoader from '../GQLoader/GQLoader';

interface IGQOvalButtonProps {
    onClick: () => void;
    label: string;
    disabled?: boolean;
    style?: React.CSSProperties;
    promise?: {
        inProgress: boolean;
    };
}

export default class GQOvalButton extends React.Component<IGQOvalButtonProps> {
    public render() {
        return (
            <div
                className={cx([
                    'gq-oval-button flex-container align-center-middle',
                    {
                        disabled: this.props.disabled,
                    },
                ])}
                role="button"
                onClick={this.onClick}
                style={{ ...this.props.style }}>
                {this.props.promise && this.props.promise.inProgress ? (
                    <GQLoader />
                ) : (
                    <span>{this.props.label}</span>
                )}
            </div>
        );
    }
    private onClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (this.props.disabled) {
            return;
        }
        const { currentTarget } = e;
        TweenLite.set(currentTarget, { clearProps: 'all' });
        TweenLite.fromTo(
            currentTarget,
            0.3,
            {
                boxShadow: 'inset 0 0 0 50px rgba(255,255,255,.15)',
            },
            {
                overwrite: true,
                boxShadow: 'inset 0 0 0 0px rgba(255,255,255,.15)',
            }
        );
        if (this.props.onClick) {
            this.props.onClick();
        }
    };
}
