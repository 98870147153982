import { IActiveCountry, IActiveGroup } from 'services/APIService';
import { useState, useEffect, useCallback, useMemo } from 'react';
import React from 'react';
import {
    map,
    find,
    filter,
    includes,
    toLower,
    get,
    size,
    isEmpty,
    flow,
    keyBy,
    trim,
} from 'lodash/fp';
import CountryPickerListItem from './CountryPickerListItem';
import CountryPickerSelectAllListItem from './CountryPickerSelectAllListItem';
import GroupSection from 'components/GQCountryPicker/GQcountryPickerGroupsSection';
import { GQButton } from 'components/GQButton';
import classNames from 'classnames';

type HorizontalAlignment = 'left' | 'right';

export type CountryPickerProps = {
    isMultiSelect: boolean;
    countriesList: IActiveCountry[];
    selectedCountriesList: number[];
    groups?: IActiveGroup[];
    activeGroupId?: string;
    onGroupClicked?: (id: string, scroll: number) => void;
    onCountrySelected: (id: number) => void;
    onAllSelected?: () => void;
    onAllUnselected?: () => void;
    canSelectAllCountries: boolean;
    horizontalAlignment: HorizontalAlignment;
    activeGroupScroll?: number;
};

const CountryPicker = (props: CountryPickerProps) => {
    const {
        activeGroupId,
        isMultiSelect,
        countriesList,
        selectedCountriesList,
        groups,
        onGroupClicked,
        onCountrySelected,
        canSelectAllCountries,
        horizontalAlignment,
        activeGroupScroll,
    } = props;
    const [searchKeyword, setSearchKeyword] = useState<string>('');
    const [isAllSelected, setIsAllSelected] = useState<boolean>(false);

    useEffect(() => {
        setSearchKeyword('');
    }, [activeGroupId]);

    useEffect(() => {
        const activeCountryGroup = find(
            group => group.id === activeGroupId,
            groups
        );
        const activeCountryGroupCountries = filter(
            country =>
                !activeCountryGroup ||
                includes(country.id, get('countries', activeCountryGroup)),
            countriesList
        );

        setIsAllSelected(
            size(activeCountryGroupCountries) === size(selectedCountriesList)
        );
    }, [countriesList, selectedCountriesList, activeGroupId, groups]);

    const activeCountryGroup = useMemo(() => {
        return find(group => group.id === activeGroupId, groups);
    }, [groups, activeGroupId]);

    const searchKeywordLowerCase = trim(toLower(searchKeyword));
    const visibleCountries = useMemo((): IActiveCountry[] => {
        const countriesById: { [countryID: number]: IActiveCountry } = keyBy(
            (country: IActiveCountry) => country.id,
            countriesList
        );
        const countriesForActiveGroup = activeCountryGroup
            ? flow(
                  get('countries'),
                  map((countryId: number) => get(countryId, countriesById))
              )(activeCountryGroup)
            : countriesList;

        if (!searchKeywordLowerCase) {
            return countriesForActiveGroup;
        }

        return filter(
            country =>
                includes(searchKeywordLowerCase, toLower(get('name', country))),

            countriesForActiveGroup
        );
    }, [activeCountryGroup, searchKeywordLowerCase, countriesList]);

    const onSearchTextChange = useCallback(
        event => setSearchKeyword(event.target.value),
        []
    );

    const onAllItemsClick = () => {
        isAllSelected
            ? props.onAllUnselected && props.onAllUnselected()
            : props.onAllSelected && props.onAllSelected();
    };

    const onClearSearchClick = useCallback(() => setSearchKeyword(''), []);

    const containerClassNames = classNames('new-country-picker-container', {
        'align-right': horizontalAlignment === 'right',
    });

    return (
        <div className={containerClassNames}>
            <GroupSection
                groups={groups}
                selectedGroup={activeGroupId}
                onGroupClick={onGroupClicked}
                activeGroupScroll={activeGroupScroll}
            />
            <div className="new-country-picker-search-and-list-container">
                <div className="new-country-picker-search-container">
                    <i className="new-country-picker-search-icon gqi-search" />
                    <input
                        className="new-country-picker-search-input"
                        type="search"
                        placeholder="Search..."
                        value={searchKeyword}
                        onChange={onSearchTextChange}
                    />
                    {!isEmpty(searchKeyword) && (
                        <GQButton
                            icon="gqi-clear"
                            onClick={onClearSearchClick}
                            noBorder
                        />
                    )}
                </div>

                <div className="new-country-picker-list-container">
                    {canSelectAllCountries && (
                        <div className="new-country-picker-select-all-container">
                            <CountryPickerSelectAllListItem
                                onClick={onAllItemsClick}
                                isSelected={isAllSelected}
                            />
                        </div>
                    )}
                    <ul className="new-country-picker-list">
                        {map((countryListItem: IActiveCountry) => {
                            return (
                                <li
                                    onClick={() => {
                                        onCountrySelected(countryListItem.id);
                                    }}
                                    key={countryListItem.id}
                                    className="new-country-picker-list-item">
                                    <CountryPickerListItem
                                        isMultiSelect={isMultiSelect}
                                        id={countryListItem.id}
                                        name={countryListItem.name}
                                        isSelected={includes(
                                            countryListItem.id,
                                            selectedCountriesList
                                        )}
                                    />
                                </li>
                            );
                        }, visibleCountries)}
                    </ul>
                </div>
            </div>
        </div>
    );
};

CountryPicker.defaultProps = {
    horizontalAlignment: 'left',
};

export default CountryPicker;
