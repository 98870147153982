import React from 'react';
import cx from 'classnames';
import { maxRanges, ChartStore } from './ChartStore';
import ChartDataProvider from './ChartDataProvider';
import ChartToolBar from './ChartToolbar';
import GQEventEmitter, {
    EventSubscription,
    IArrowKey,
    E_ARROW_KEYS,
} from '../../services/EventsService';
import SliderHorizontal from './SliderHorizontal';
import { Moment } from 'moment';
import { observer, inject } from 'mobx-react';
import rootStore from '../../RootStore';
import { IChartPadding } from './Chart';
import {
    EXPANDED_CHART_VIEWS,
    VIEW,
    navbarContingentGroup,
} from '../../interfaces';
import { includes } from 'lodash/fp';
interface IChartContainerState {
    isMouseIn: boolean;
}

export interface IChartContainerProps {
    activeView: VIEW;
    chartStore?: ChartStore;
    expanded?: boolean;
    padding?: IChartPadding;
    height?: number;
    style?: React.CSSProperties;
    showSlider?: boolean;
    showToolbar?: boolean;
    showVirtualToday?: boolean;
    containerStyle?: React.CSSProperties;
    changeDataWhenDragStop?: boolean;
    onExpand?: () => void;
}

@inject('chartStore')
@observer
export default class ChartContainer extends React.Component<
    IChartContainerProps,
    IChartContainerState
> {
    public static defaultProps: Partial<IChartContainerProps> = {
        showSlider: true,
        showToolbar: true,
        showVirtualToday: true,
        height: 157,
    };
    private keyboardEventSubscription: EventSubscription = null;

    constructor(props: IChartContainerProps) {
        super(props);
        this.state = {
            isMouseIn: false,
        };
    }

    public shouldComponentUpdate() {
        return !rootStore.appStore.freezeRender;
    }

    public componentDidMount() {
        this.keyboardEventSubscription = GQEventEmitter.registerOnArrowKey(
            this.onKeyboardKey
        );
    }

    public componentWillUnmount() {
        if (this.keyboardEventSubscription) {
            this.keyboardEventSubscription.remove();
        }
    }

    public render() {
        const { activeView, chartStore, height } = this.props;
        const expanded = includes(activeView, EXPANDED_CHART_VIEWS);
        const overrideProps = {
            height,
            width: chartStore.width,
            expanded,
        };

        return (
            <div
                className={cx({
                    'chart-container': true,
                    expanded,
                })}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseOut}>
                <div className="chart-row">
                    <div className="chart-col">
                        <ChartDataProvider
                            activeView={activeView}
                            onVirualTodayChange={this.onVirualTodayChange}
                            onWheel={this.onWheel}
                            onPan={this.onPan}
                            padding={this.props.padding}
                            hasToolbar={this.props.showToolbar}
                            showVirtualToday={this.props.showVirtualToday}
                            changeDataWhenDragStop={
                                this.props.changeDataWhenDragStop
                            }
                            {...overrideProps}
                        />
                        {this.props.showSlider && (
                            <SliderHorizontal
                                maximumPossibleX={maxRanges.maxBaseDate.clone()}
                                minimumPossibleX={maxRanges.minBaseDate.clone()}
                                baseDate={chartStore.baseDate.clone()}
                                displayedMinX={chartStore.baseDate
                                    .clone()
                                    .startOf('day')
                                    .subtract(chartStore.rangeLeft, 'days')}
                                displayedMaxX={chartStore.baseDate
                                    .clone()
                                    .endOf('day')
                                    .add(chartStore.rangeRight, 'days')}
                                onPan={this.onPanChangeBaseDate}
                                expanded
                            />
                        )}
                    </div>
                </div>
                <div className="chart-row">
                    {this.props.showToolbar && (
                        <ChartToolBar
                            expanded={expanded}
                            onExpand={this.expandGraph}
                            hidePastProjectionButton={
                                this.props.activeView === VIEW.CONTINGENT_GRAPH
                            }
                            hideToggleMap={
                                this.props.activeView === VIEW.CONTINGENT_GRAPH
                            }
                            hideActivePreset={navbarContingentGroup.has(
                                this.props.activeView
                            )}
                        />
                    )}
                </div>
            </div>
        );
    }

    private onMouseEnter = () => {
        this.setState({
            isMouseIn: true,
        });
    };

    private onMouseOut = () => {
        this.setState({
            isMouseIn: false,
        });
    };

    private onWheel = (
        isUp: boolean,
        rangeLeft: number = null,
        rangeRight: number = null
    ) => {
        const { chartStore } = this.props;
        const factor = isUp ? -10 : 10;
        const newRangeLeft =
            rangeLeft !== null ? rangeLeft : chartStore.rangeLeft + factor;
        const newRangeRight =
            rangeRight !== null ? rangeRight : chartStore.rangeRight + factor;
        chartStore.setNewRanges(newRangeLeft, newRangeRight);
        if (isUp) {
            const maxVirtualTodayPossibleLeft = chartStore.baseDate
                .clone()
                .startOf('day')
                .subtract(chartStore.rangeLeft, 'days');
            const maxVirtualTodayPossibleRight = chartStore.baseDate
                .clone()
                .startOf('day')
                .add(chartStore.rangeRight, 'days');
            if (
                this.props.chartStore.virtualToday.isBefore(
                    maxVirtualTodayPossibleLeft,
                    'd'
                )
            ) {
                this.onVirualTodayChange(maxVirtualTodayPossibleLeft);
            }

            if (
                this.props.chartStore.virtualToday.isAfter(
                    maxVirtualTodayPossibleRight,
                    'd'
                )
            ) {
                this.onVirualTodayChange(maxVirtualTodayPossibleRight);
            }
        }
    };

    private onPan = (range: number) => {
        const { chartStore } = this.props;
        const maybeNewVirtualToday = chartStore.setNewBaseDate(
            chartStore.baseDate.clone().add(range, 'days'),
            this.props.chartStore.virtualToday
        );
        if (maybeNewVirtualToday) {
            this.onVirualTodayChange(maybeNewVirtualToday);
        }
    };

    private onPanChangeBaseDate = (newDate: Moment) => {
        const maybeNewVirtualToday = this.props.chartStore.setNewBaseDate(
            newDate.clone(),
            this.props.chartStore.virtualToday
        );
        if (maybeNewVirtualToday) {
            this.onVirualTodayChange(maybeNewVirtualToday);
        }
    };

    private onKeyboardKey = (key: IArrowKey) => {
        if (rootStore.insightsStore.annotationMode) {
            return;
        }
        if (this.state.isMouseIn || key.isShift) {
            switch (key.arrow) {
                case E_ARROW_KEYS.ARROW_DOWN: {
                    return this.onWheel(false);
                }
                case E_ARROW_KEYS.ARROW_UP: {
                    return this.onWheel(true);
                }
                case E_ARROW_KEYS.ARROW_RIGHT: {
                    return this.onPan(10);
                }
                case E_ARROW_KEYS.ARROW_LEFT: {
                    return this.onPan(-10);
                }
            }
        }
    };

    private onVirualTodayChange = (date: Moment) => {
        if (!date.isSame(this.props.chartStore.virtualToday, 'd')) {
            this.props.chartStore.setState({
                virtualToday: date.clone(),
            });
        }
    };

    private expandGraph = () => {
        if (!this.props.expanded && this.props.onExpand) {
            this.props.onExpand();
        }
    };
}
