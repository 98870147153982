import React from 'react';
import * as ReactDOM from 'react-dom';
import classNames from 'classnames';
import * as _ from 'lodash';
import Animate from 'react-smooth';
import { DeprecatedCSSProperties } from '../../types/DeprecatedCSSProperties';

export type positionString =
    | 'top left'
    | 'top right'
    | 'top center'
    | 'bottom left'
    | 'bottom right'
    | 'bottom center'
    | 'left center'
    | 'right center'
    | 'center';

const positionCollection = [
    'top left',
    'top right',
    'top center',
    'bottom left',
    'bottom right',
    'bottom center',
    'left center',
    'right center',
];

export interface IGQButtonTooltipProps {
    position: positionString;
    parentProps?: HTMLElement | Range | Element;
    content?: string;
    offset?: {
        horizontal?: number;
        vertical?: number;
    };
    fixedPos?: {
        left?: number;
        right?: number;
        top?: number;
        bottom?: number;
    };
    options?: {
        noDiamond?: boolean;
    };
    width?: any;
    speed?: any;
    delay?: any;
    clickable?: boolean;
}

interface IGQButtonTooltipState {
    popupRef: HTMLDivElement;
}

export default class GQButtonTooltip extends React.Component<
    IGQButtonTooltipProps,
    IGQButtonTooltipState
> {
    private shouldUpdateRef = true;

    constructor(props: IGQButtonTooltipProps) {
        super(props);
        this.state = {
            popupRef: null,
        };
    }

    public componentDidUpdate() {
        this.shouldUpdateRef = false;
    }

    public render() {
        let style: DeprecatedCSSProperties = {};
        let positionClass = this.props.position;
        if (this.state.popupRef) {
            const popupBox = this.state.popupRef.getBoundingClientRect();
            style = this.computePopupStyle(popupBox, this.props.position);
            if (!this.isPopupInViewport(style, popupBox)) {
                for (const pos of _.without(
                    positionCollection,
                    this.props.position
                )) {
                    const newStyle = this.computePopupStyle(
                        popupBox,
                        pos as positionString
                    );
                    if (this.isPopupInViewport(newStyle, popupBox)) {
                        positionClass = pos as positionString;
                        style = newStyle;
                        break;
                    }
                }
            }
        }
        const classes = classNames(['gq-button-tooltip', positionClass], {
            'no-padding': !this.props.content,
            'no-diamond': this.props.options && this.props.options.noDiamond,
        });
        return ReactDOM.createPortal(
            <Animate
                attributeName="opacity"
                from="0"
                to="1"
                duration={this.props.speed || 800}
                begin={this.props.delay || 0}>
                <div
                    className={classNames([
                        'gq-button-tooltip-container',
                        { clickable: this.props.clickable },
                    ])}>
                    <div
                        className={classes}
                        ref={el => {
                            if (!this.state.popupRef || this.shouldUpdateRef) {
                                this.setState({ popupRef: el });
                            }
                        }}
                        style={
                            {
                                ...style,
                                maxWidth: this.props.width,
                                //todo: 'as any' is used because div.style expects React.CSSProperties whereas this
                                // style's type is DeprecatedCSSProperties (which was added after migrating React to v16.12 -
                                //see the comment on DeprecatedCSSProperties)
                            } as any
                        }>
                        {this.props.content ? (
                            <span>{this.props.content}</span>
                        ) : (
                            this.props.children
                        )}
                    </div>
                </div>
            </Animate>,
            document.body
        );
    }

    private computePopupStyle(popupBBox: ClientRect, position: positionString) {
        const offset = {
            h: (this.props.offset && this.props.offset.horizontal) || 0,
            v: (this.props.offset && this.props.offset.vertical) || 0,
        };
        const pseudoOffset = 15;
        const style: DeprecatedCSSProperties = { position: 'absolute' };
        if (this.props.fixedPos) {
            Object.assign(style, this.props.fixedPos);
        } else {
            const { innerWidth, innerHeight } = window;
            const hostBox = this.props.parentProps.getBoundingClientRect();
            if (_.includes(position, 'top')) {
                style.bottom = Math.round(innerHeight - hostBox.top) + offset.v;
                style.top = 'auto';
            } else if (_.includes(position, 'bottom')) {
                style.top = hostBox.bottom + offset.v;
                style.bottom = 'auto';
            } else {
                const popupOffset = hostBox.height - popupBBox.height;
                style.top = hostBox.top + popupOffset + offset.v;
                style.right = 'auto';
            }
            if (_.includes(position, 'left')) {
                style.right =
                    innerWidth -
                    hostBox.right +
                    hostBox.width / 2 -
                    pseudoOffset +
                    offset.h;
                style.left = 'auto';
                if (_.includes(position, 'center')) {
                    style.right =
                        innerWidth -
                        hostBox.right +
                        hostBox.width -
                        pseudoOffset +
                        offset.h;
                }
            } else if (_.includes(position, 'right')) {
                style.left =
                    hostBox.left + hostBox.width / 2 - pseudoOffset + offset.h;
                style.right = 'auto';
                if (_.includes(position, 'center')) {
                    style.left = hostBox.left + hostBox.width + 5 + offset.h;
                }
            } else {
                const popupOffset = (hostBox.width - popupBBox.width) / 2;
                style.left = hostBox.left + popupOffset + offset.h;
                style.right = 'auto';
            }
        }
        return style;
    }

    //todo: fix 'any' type
    //the 'any' type was added while migrating from React 16.1.1 to 16.12.
    // the previous CSSProperties was no longer suitable
    private isPopupInViewport(
        style: DeprecatedCSSProperties,
        popupBBox: ClientRect
    ) {
        const { innerWidth, innerHeight } = window;
        if (
            style.left < 0 ||
            (!_.isNumber(style.left) &&
                style.right + popupBBox.width > innerWidth) ||
            innerWidth - style.right - popupBBox.width < 0
        ) {
            return false;
        }
        if (style.left + popupBBox.width > innerWidth) {
            return false;
        }
        if (style.top < 0) {
            return false;
        }
        if (style.top + popupBBox.height > innerHeight) {
            return false;
        }
        if (innerHeight - style.bottom - popupBBox.height < 0) {
            return false;
        }
        return true;
    }
}
