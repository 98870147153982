import { IDebugable, IBaseEvent } from '../../interfaces';
import { E_RISK_TYPE } from '../../services/APIService';

export interface IUscoreActive {
    countryIds: number[];
    date: string;
    entityType: E_RISK_TYPE;
    entityId: number;
    asId: string;
}

export enum EVENTS_FILTER {
    ALL = 'all',
    SCORED = 'scored',
    UNSCORED = 'unscored',
}

export interface IEventsData extends IDebugable, IBaseEvent {
    matchFilter?: any;
    isTemp?: boolean;
    dateString?: string;
}

export enum SCORED_EVENTS_FILTER {
    ALL = 'all',
    HUMAN = 'human',
    MACHINE = 'scored',
}
