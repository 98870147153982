import React from 'react';
import dvr from 'mobx-react-form/lib/validators/DVR';
import MobxReactForm from 'mobx-react-form';
import * as validatorjs from 'validatorjs';

import APIService from '../../services/APIService';
import appStore from '../../AppStore';
import FormField from '../Form/FormField';
import GQLoader from '../GQLoader/GQLoader';
import AuthPageContainer from './AuthPageContainer';

interface ILoginState {
    error: string;
    inProgress: boolean;
}

interface Iform {
    values: () => {
        username: string;
        password: string;
    };
}

export default class Login extends React.Component<any, ILoginState> {
    constructor(props: any) {
        super(props);
        this.state = {
            inProgress: false,
            error: null,
        };
    }

    public onLoginSubmit = (form: Iform) => {
        this.setState(
            {
                inProgress: true,
                error: null,
            },
            async () => {
                try {
                    await APIService.login(form.values());
                    if (appStore.isAgreeTerms) {
                        appStore.setfirstLoad(true);
                    }
                } catch (err) {
                    this.setState({
                        inProgress: false,
                        error: err.message || err,
                    });
                }
            }
        );
    };

    public initForm = () => {
        const plugins = { dvr: dvr(validatorjs) };

        const fields = [
            {
                name: 'username',
                rules: 'required|email|string',
            },
            {
                name: 'password',
                rules: 'required|string',
            },
        ];

        const hooks = {
            onSuccess: this.onLoginSubmit,
        };

        return new MobxReactForm({ fields }, { plugins, hooks });
    };

    form = this.initForm();

    public render() {
        return (
            <AuthPageContainer>
                <div className="auth-form-common">
                    <div className="auth-form-common__title">
                        Login to Geoquant
                    </div>

                    <form
                        name="gqLogin"
                        className="auth-form"
                        onSubmit={this.form.onSubmit}>
                        <FormField
                            field={this.form.$('username')}
                            className="auth-form-common__field"
                            placeholder="Username"
                        />

                        <FormField
                            field={this.form.$('password')}
                            type="password"
                            className="login-password"
                            placeholder="Password"
                        />

                        <div className="login-forgot-password">
                            <span>
                                forgot password? click{' '}
                                <a href="#/forgot-password">here</a> to reset
                            </span>
                        </div>

                        <div className="auth-form-common__submit">
                            {this.state.inProgress && (
                                <div className="auth-form-common__submit-loader">
                                    <GQLoader />
                                </div>
                            )}

                            <button type="submit">
                                {this.state.inProgress ? '' : 'Login'}
                            </button>
                        </div>
                        <div className="auth-form-common__error">
                            <span>{this.state.error || this.form.error}</span>
                        </div>
                    </form>
                </div>
            </AuthPageContainer>
        );
    }
}
