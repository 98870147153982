import * as ls from 'local-storage';

const PREFIX = (() => {
    try {
        if (window !== undefined && window.location !== undefined) {
            return window.location.host;
        }
        return '';
    } catch (err) {
        return '';
    }
})();

export default class StorageService {
    public static get<T>(key: string): T {
        return ls.get(PREFIX + key);
    }

    public static set<T>(key: string, value: T) {
        ls.set(PREFIX + key, value);
    }

    public static remove(key: string) {
        ls.remove(PREFIX + key);
    }

    public static clear() {
        ls.clear();
    }
}
