import React from 'react';
import * as _ from 'lodash';
import classNames from 'classnames';
import TopEventsItem from './GQTopEventsItem';
import { VIEW } from '../../interfaces';
import APIService, { IGetTopInsightsResponse } from '../../services/APIService';
import { first, size } from 'lodash/fp';
interface IGQTopInsightsProps {
    onViewChange?: (view: VIEW, ...args: any[]) => void;
}

interface IGQTopInsightsState {
    collapsed: boolean;
    data: IGetTopInsightsResponse[];
}

export default class GQTopInsights extends React.Component<
    IGQTopInsightsProps,
    IGQTopInsightsState
> {
    private mounted: boolean = false;
    constructor(props: IGQTopInsightsProps) {
        super(props);
        this.state = {
            collapsed: false,
            data: [],
        };
    }

    public componentDidMount() {
        this.mounted = true;
        this.getInsightsFeed();
    }

    public componentWillUnmount() {
        this.mounted = false;
    }

    public render() {
        const { data } = this.state;
        const baseClass = 'gq-top-events';
        return (
            <div
                className={classNames([`${baseClass}-container`, 'col'], {
                    fixed: this.state.collapsed,
                })}>
                <div className={`${baseClass}-header row`}>
                    <div className={`${baseClass}-title`}>
                        <span>Top Insights</span>
                    </div>
                </div>
                <div
                    className={classNames([`${baseClass}-content`], {
                        collapsed: this.state.collapsed,
                    })}>
                    {data &&
                        _.map(data, insight => {
                            const countryId =
                                size(insight.countries) !== 1
                                    ? 0
                                    : first(insight.countries);
                            return (
                                <TopEventsItem
                                    key={`${insight.id}-${insight.name}`}
                                    countryID={countryId}
                                    event={insight.name}
                                    onViewChange={this.props.onViewChange}
                                    eventId={insight.id}
                                />
                            );
                        })}
                </div>
            </div>
        );
    }
    private async getInsightsFeed() {
        try {
            const topInsights = await APIService.getInsightsFeed();
            if (this.mounted) {
                this.setState({
                    data: topInsights,
                });
            }
        } catch (err) {
        }
    }
}
