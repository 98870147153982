import { Moment } from 'moment';
import APIService from './APIService';
import * as _ from 'lodash';
import {
    effectOptions,
    durationOptions,
} from '../components/scoringPage/consts';

export class GQutils {
    public static calcFromUntil(
        a: Moment,
        b: Moment
    ): { from: Moment; until: Moment } {
        let from: Moment = null;
        let until: Moment = null;
        if (a.isBefore(b)) {
            from = a;
            until = b;
        } else {
            from = b;
            until = a;
        }
        return { from, until };
    }

    public static async findCountryNameByID(countryID: number) {
        const { countries } = await APIService.getActiveCountries();
        const countryName = _.filter(countries, c => {
            return countryID === c.id;
        });
        return countryName[0].name;
    }

    public static async findDimensionShortNameByID(dimId: number) {
        const dims = (await APIService.getActiveDimensions()).allDimensions;
        const dim = dims.find(dim => dim.id === dimId);
        return dim ? dim.sname : '';
    }

    public static findElementPosition(elementId: string) {
        const element = document.getElementById(elementId);
        if (element) {
            return element.getBoundingClientRect();
        }
        return { left: 0, right: 0, top: 0, bottom: 0, width: 0, height: 0 };
    }

    public static findImpactByValue(val: number) {
        for (const option of effectOptions) {
            if (option.isBelong(val)) {
                return option;
            }
        }
    }
    public static findDurationByValue(val: number) {
        for (const option of durationOptions) {
            if (option.isBelong(val)) {
                return option;
            }
        }
    }
}
