import React from 'react';
import * as _ from 'lodash';
import Animate from 'react-smooth';
import CWRenderUtil from './GQCWRenderUtil';
import { IActiveDimension, ICustomWeightTree } from '../../../../interfaces';
import cwStore from '../../../../stores/CustomWeightStore';
import { inject } from 'mobx-react';
import { IMobxRootState } from 'RootStore';

const util = new CWRenderUtil();

interface IChildWidth {
    [index: number]: number;
}

interface IChildData {
    [index: number]: {
        percent: number;
        name: string;
    };
}
interface Props {
    activeEntries: ICustomWeightTree[];
    parentPath: number[];
    presetId: string;
    editable: boolean;
    onChange: () => void;
    show: boolean;
    childID?: string;
    dimensions?: { [dimensionId: number]: IActiveDimension };
}

interface State {
    showHandles: boolean;
    isDragging: boolean;
    parentWidth: number;
    childWidth: IChildWidth;
    originalWidth: IChildWidth;
    originalX: number;
    dragIndex: number;
    childData: IChildData;
}

@inject(
    ({ risksStore }: IMobxRootState): Partial<Props> => {
        return {
            dimensions: risksStore.dimensions.hashed,
        };
    }
)
export default class IGQCWSlider extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            showHandles: false,
            isDragging: false,
            parentWidth: 0,
            childWidth: {},
            originalX: 0,
            dragIndex: -1,
            originalWidth: {},
            childData: {},
        };
    }

    public UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (
            !_.isEqual(this.props.activeEntries, nextProps.activeEntries) ||
            this.props.presetId !== nextProps.presetId
        ) {
            const childWidth: IChildWidth = {};
            const childData: IChildData = {};

            let zeroBuffer = 0;

            _.forEach(nextProps.activeEntries, entry => {
                if (entry.weight === 0) {
                    zeroBuffer += 1;
                }
            });

            _.forEach(nextProps.activeEntries, (entry, idx) => {
                childWidth[idx] =
                    entry.weight === 0
                        ? 20
                        : entry.weight *
                          (this.state.parentWidth - 20 * zeroBuffer);
                childData[idx] = {
                    percent: entry.weight,
                    name: this.getDimensionName(entry.dimensionId).lname,
                };
            });

            this.setState({
                showHandles: false,
                childWidth,
                childData,
            });
        }
    }

    public addMouseListeners = () => {
        document.addEventListener('mousemove', this.onDrag);
        document.addEventListener('mouseup', this.onDragEnd);
        document
            .getElementById('contentDiv')
            .addEventListener('mouseleave', this.onDragEnd);
    };

    public removeMouseListeners = () => {
        document.removeEventListener('mousemove', this.onDrag);
        document.removeEventListener('mouseup', this.onDragEnd);
        document
            .getElementById('contentDiv')
            .removeEventListener('mouseleave', this.onDragEnd);
    };

    public componentDidUpdate(prevProps: Props, prevState: State) {
        if (!prevState.isDragging && this.state.isDragging) {
            this.addMouseListeners();
        } else if (prevState.isDragging && !this.state.isDragging) {
            this.removeMouseListeners();
        }
    }

    public componentWillUnmount() {
        this.removeMouseListeners();
    }

    public render() {
        return (
            <div className="gq-cw-slider-container">
                <div
                    id="weightSlider"
                    className="gq-cw-slider"
                    ref={el => {
                        this.updateWidth(el);
                    }}>
                    {_.map(this.props.activeEntries, (entry, idx) => {
                        const preCB = () => {
                            this.shouldShowHandles(idx);
                        };
                        const onDragStartCB = (
                            e: React.MouseEvent<HTMLDivElement>
                        ) => {
                            this.setState({
                                isDragging: true,
                                originalX: e.clientX,
                                dragIndex: idx,
                                originalWidth: { ...this.state.childWidth },
                            });
                        };
                        return (
                            <Animate
                                key={`${this.props.presetId}-${entry.path}-${entry.dimensionId}`}
                                from={{
                                    transform: 'translateY(-50px)',
                                    opacity: 0,
                                }}
                                to={{
                                    transform: 'translateY(0)',
                                    opacity: 1,
                                }}
                                duration={500}
                                onAnimationEnd={preCB}>
                                <div>
                                    {this.props.show && (
                                        <div
                                            className="cw-dim-tile"
                                            id={`${this.props.presetId}-${entry.path}-${entry.dimensionId}`}
                                            style={{
                                                width: this.state.childWidth[
                                                    idx
                                                ]
                                                    ? this.state.childWidth[idx]
                                                    : 0,
                                                background: util.getColor(
                                                    entry.dimensionId,
                                                    entry.tier,
                                                    entry.path
                                                ),
                                            }}>
                                            <div className="cw-dim-val">
                                                {this.props.editable && (
                                                    <span>
                                                        {(
                                                            this.state
                                                                .childData[idx]
                                                                .percent * 100
                                                        ).toFixed(0)}
                                                    </span>
                                                )}
                                            </div>
                                            <div className="cw-dim-name">
                                                <span>
                                                    {
                                                        this.state.childData[
                                                            idx
                                                        ].name
                                                    }
                                                </span>
                                            </div>
                                            {idx <
                                                this.props.activeEntries
                                                    .length -
                                                    1 && (
                                                <Animate
                                                    steps={[
                                                        {
                                                            style: {
                                                                transform:
                                                                    'scale(0) translateY(20px)',
                                                            },
                                                            duration: 250,
                                                        },
                                                        {
                                                            style: {
                                                                transform:
                                                                    'scale(1.5) translateY(0)',
                                                                opacity: 1,
                                                            },
                                                            duration: 500,
                                                        },
                                                        {
                                                            style: {
                                                                transform:
                                                                    'scale(1) translateY(0)',
                                                                opacity: 1,
                                                            },
                                                            duration: 750,
                                                        },
                                                    ]}
                                                    canBegin={
                                                        this.state.showHandles
                                                    }>
                                                    <div
                                                        className="cw-dim-handle"
                                                        onMouseDown={
                                                            onDragStartCB
                                                        }
                                                        style={{
                                                            transform:
                                                                'translateY(20px)',
                                                            opacity: 0,
                                                        }}>
                                                        <i className="icon gqi-dropdown" />
                                                    </div>
                                                </Animate>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </Animate>
                        );
                    })}
                </div>
            </div>
        );
    }

    private updateWidth = (el: HTMLDivElement) => {
        if (!el) {
            return;
        } else {
            const width = el.getBoundingClientRect().width;

            if (this.state.parentWidth !== width) {
                this.setState({
                    parentWidth: width,
                });
            }
        }
    };

    private onDrag = (e: MouseEvent) => {
        if (!this.state.isDragging) {
            return;
        }

        const distance = e.clientX - this.state.originalX;

        if (distance === 0) {
            return;
        }

        const stateClone = { ...this.state.childWidth };

        let wSum = 0;
        let zeroBuffer = 0;

        _.forEach(this.state.childWidth, (child, index) => {
            if (
                index !== String(this.state.dragIndex) &&
                index !== String(this.state.dragIndex + 1)
            ) {
                wSum += child;
                if (child === 0) {
                    zeroBuffer += 1;
                }
            }
        });

        const maxWidth = this.state.parentWidth - wSum - 20;

        stateClone[this.state.dragIndex] = this.restrainValue(
            this.state.originalWidth[this.state.dragIndex] + distance,
            20,
            maxWidth
        );
        if (distance < 0) {
            stateClone[this.state.dragIndex + 1] = this.restrainValue(
                this.state.originalWidth[this.state.dragIndex + 1] +
                    distance * -1,
                20,
                maxWidth
            );
        } else if (distance > 0) {
            stateClone[this.state.dragIndex + 1] = this.restrainValue(
                this.state.originalWidth[this.state.dragIndex + 1] - distance,
                20,
                maxWidth
            );
        }

        const newData = { ...this.state.childData };

        _.forEach(stateClone, (item, index) => {
            if (item === 20) {
                zeroBuffer += 1;
            }
        });

        newData[this.state.dragIndex].percent =
            stateClone[this.state.dragIndex] === 20
                ? 0
                : stateClone[this.state.dragIndex] /
                  (this.state.parentWidth - 20 * zeroBuffer);
        newData[this.state.dragIndex + 1].percent =
            stateClone[this.state.dragIndex + 1] === 20
                ? 0
                : stateClone[this.state.dragIndex + 1] /
                  (this.state.parentWidth - 20 * zeroBuffer);

        this.setState({
            childWidth: stateClone,
            childData: newData,
        });

        const updateObj: { [id: number]: number } = {};

        _.forEach(newData, (item: { percent: number; name: string }, index) => {
            if (item.percent === undefined) {
                return;
            }

            const id = this.props.activeEntries[Number(index)].dimensionId;

            updateObj[id] = item.percent;
        });

        cwStore.updateWeights(
            this.props.parentPath,
            updateObj,
            this.props.childID
        );
    };

    private restrainValue(val: number, min: number, max: number) {
        return Math.min(Math.max(min, val), max);
    }

    private onDragEnd = () => {
        this.props.onChange();

        this.setState({
            isDragging: false,
        });
    };

    private getDimensionName(id: number) {
        return this.props.dimensions[id] || null;
    }

    private shouldShowHandles = (index: number) => {
        if (
            index === this.props.activeEntries.length - 1 &&
            this.props.editable
        ) {
            this.setState({
                showHandles: true,
            });
        }
    };
}
