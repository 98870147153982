import rootStore from '../../RootStore';
import {
    IActivityRecordChartDataSnapshot,
    E_CHART_GROUP_IDENTIFIERS,
    E_RISK_TYPE,
} from '../../services/lib/APIServiceInterfaces';
import APIService from '../../services/APIService';
import * as _ from 'lodash';
import moment from 'moment';

async function getCurrency(
    country: number
): Promise<IActivityRecordChartDataSnapshot> {
    const data = await APIService.getCurrencyExchange();
    if (!data[country]) {
        return null;
    }
    const pairs = _.toPairs(data[country]) as Array<[string, number]>;
    if (pairs.length === 0) {
        return null;
    }
    const mapped = _.map(pairs, d => {
        return [moment(d[0], 'YYYY-MM-DD'), d[1]];
    }) as Array<[moment.Moment, number]>;
    const sorted = _.sortBy(mapped, ([date, value]) => {
        return date.toDate();
    });
    return {
        chartIdentifier: E_CHART_GROUP_IDENTIFIERS.CURRENCY_EXCHANGE,
        country,
        data: sorted.map(d => d[1]),
        dateBegin: sorted[0][0],
    };
}
async function getPoliticalRiskProjectionData(
    country: number,
    risk: number,
    riskType: E_RISK_TYPE,
    date: moment.Moment
) {
    if (moment().isSameOrBefore(date, 'd')) {
        return null;
    }

    const data = await APIService.getRiskProjections(
        riskType,
        risk,
        country,
        date
    );
    if (data.length === 0) {
        return null;
    }
    return {
        chartIdentifier: E_CHART_GROUP_IDENTIFIERS.PROJECTION_LINE,
        country,
        risk,
        riskType,
        data,
        date,
    };
}
async function getPoliticalRiskData(
    country: number,
    riskId: number,
    riskType: E_RISK_TYPE,
    isGQ: boolean
): Promise<IActivityRecordChartDataSnapshot> {
    const data = (
        await APIService.getRiskScores(riskType, riskId, [country], isGQ)
    )[country];
    if (data.length === 0) {
        return null;
    }
    return {
        chartIdentifier: E_CHART_GROUP_IDENTIFIERS.POLITICAL_RISK,
        country,
        risk: riskId,
        riskType,
        isGQ,
        data,
    };
}

async function getContingentRiskData(
    primaryCountryId: number,
    secondaryCountryId: number,
    riskId: number,
): Promise<IActivityRecordChartDataSnapshot> {
    const primaryCountryData = await APIService.getContingentRisk(riskId, primaryCountryId);
    const data = primaryCountryData[secondaryCountryId];
    if (_.isEmpty(data)) {
        return null;
    }
    return {
        chartIdentifier: E_CHART_GROUP_IDENTIFIERS.CONTINGENT_RISK,
        country: secondaryCountryId,
        risk: riskId,
        riskType: E_RISK_TYPE.CONTINGENT,
        isGQ: true,
        data,
    };
}



export const serializeChartData = async (): Promise<IActivityRecordChartDataSnapshot[]> => {
    const res: Array<Promise<IActivityRecordChartDataSnapshot>> = [];
    const riskType = rootStore.risksStore.currentRisksType;
    const risks = rootStore.risksStore.currentList;
    const showProjection = rootStore.risksStore.projectionDataOn;
    const showCustomWeightLine =
        rootStore.customWeightStore.hasActiveCustomWeight;
    const currencies = rootStore.risksStore.getCurrencyCountry();
    const countries = rootStore.countryStore.currentCountryList;
    const { chartStore } = rootStore;
    const { virtualToday } = chartStore;
    for (const country of countries) {
        for (const risk of risks) {
            if (riskType === E_RISK_TYPE.CONTINGENT) {
                res.push(getContingentRiskData(countries[0], country, risk));
            }
            else {
                res.push(getPoliticalRiskData(country, risk, riskType, true));

                if (showCustomWeightLine) {
                    res.push(getPoliticalRiskData(country, risk, riskType, false));
                }

                if (showProjection) {
                    res.push(
                        getPoliticalRiskProjectionData(
                            country,
                            risk,
                            riskType,
                            virtualToday
                        )
                    );
                }
            }
        }
    }
    for (const countryId of currencies) {
        res.push(getCurrency(countryId));
    }
    const ret = (await Promise.all(res)).filter(d => d !== null);
    return ret;
};
