import React from 'react';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';
import GQCustomWeights from './GQCustomWeights';
import { IPreset } from '../../../../interfaces';
import APIService from '../../../../services/APIService';
import RootStore from '../../../../RootStore';
import ChartScoresService from '../../../../services/ChartScoresService';
import loading from '../../../../services/LoadingService';

type Props = {};
type State = {
    presets: IPreset[];
    saveState: 'success' | 'error';
    activeLoading: boolean;
    lastPreset?: IPreset;
};

@observer
export default class GQCWController extends React.Component<Props, State> {
    public lastPreset: any;
    private mounted = false;

    constructor(props: Props) {
        super(props);

        this.state = {
            presets: [],
            saveState: null,
            activeLoading: false,
        };
    }

    public getLastPreset = async () => {
        const presets = await APIService.getMyPresets();
        const lastPreset = [];

        for (const preset of presets) {
            lastPreset.push(preset);
        }

        return lastPreset.pop();
    };

    public componentDidMount() {
        this.mounted = true;
        this.getPresets(true);
        this.getLastPreset().then(preset => {
            this.setState({
                lastPreset: preset,
            });
            localStorage.setItem('lastPreset', JSON.stringify(preset));
        });
    }

    public componentWillUnmount() {
        this.mounted = false;
    }

    public render() {
        return (
            <GQCustomWeights
                saveCurrentEditingCB={this.saveCurrentPreset}
                setActivePreset={this.setActivePreset}
                saveStatus={this.state.saveState}
                activeLoading={this.state.activeLoading}
                getLastPreset={this.getLastPreset}
                lastPreset={this.state.lastPreset}
            />
        );
    }

    private async getPresets(isFirst: boolean) {
        loading.start();

        const presets = await APIService.getMyPresets();
        RootStore.customWeightStore.setPresets(presets);

        if (isFirst) {
            RootStore.customWeightStore.switchToActiveId();
        }

        loading.stop();
    }

    private saveCurrentPreset = async () => {
        try {
            const id = RootStore.customWeightStore.currentEditingId;
            const preset = await APIService.savePreset(
                RootStore.customWeightStore.currentEditingPreset
            );

            if (id === 'new') {
                loading.start();

                runInAction(() => {
                    delete RootStore.customWeightStore.presets[id];
                    RootStore.customWeightStore.overridePreset(preset);
                    RootStore.customWeightStore.setCurrentEditingId(preset.id);
                    localStorage.setItem('lastPreset', JSON.stringify(preset));
                });

                loading.stop();
            } else {
                loading.start();
                RootStore.customWeightStore.overridePreset(preset);

                if (preset.isActive) {
                    APIService.clearCountriesData();
                    ChartScoresService.clearCache();
                    RootStore.chartStore.increaseSequence();
                }

                loading.stop();
            }
        } catch (ex) {
            if (this.mounted) {
                this.setState(
                    {
                        saveState: 'error',
                    },
                    this.resetSaveStatus
                );
            }

            return;
        }

        if (this.mounted) {
            this.setState(
                {
                    saveState: 'success',
                },
                this.resetSaveStatus
            );
        }
    };

    private resetSaveStatus = () => {
        setTimeout(() => {
            if (this.mounted) {
                this.setState({
                    saveState: null,
                });
            }
        }, 3000);
    };

    private setActivePreset = async (id: string) => {
        loading.start();
        APIService.clearCountriesData();
        ChartScoresService.clearCache();

        this.setState({
            activeLoading: true,
        });

        try {
            await APIService.setActivePreset(id);

            this.setState({
                activeLoading: false,
            });

            loading.stop();
        } catch {
            this.setState({
                activeLoading: false,
            });

            loading.stop();
        }

        RootStore.chartStore.increaseSequence();
        APIService.reconnectWebSocketTransport();
        this.getPresets(true);
    };
}
