import * as NProgress from 'multi-nprogress';

const nprogress = NProgress();

const config = {
    showSpinner: false,
    minimum: 0.1,
    easing: 'linear',
    speed: 300,
};

nprogress.configure(config);

class Loading {
    public start = async () => {
        return await nprogress.start();
    };

    public stop = async () => {
        return await nprogress.done();
    };

    public forceStop = async () => {
        return await nprogress.done(true);
    };
}

const loading = new Loading();
export default loading;
