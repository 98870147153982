import mailchimpConfig from 'configs/mailchimp';
import { useEffect, useRef } from 'react';
import { InsightData } from '../useInsightTemplate';

const useInsightHtmlTemplate = (insightData: InsightData) => {
    useEffect(() => {
        document.documentElement.style.fontSize = `${mailchimpConfig.fontSize}px`;
    }, []);

    const didRun = useRef(false);
    useEffect(() => {
        if (!insightData || didRun.current) return;
        didRun.current = true;

        document.body.innerHTML =
            mailchimpConfig.bodyPrefix + document.body.innerHTML;
    }, [insightData]);
};

export default useInsightHtmlTemplate;
