import React from 'react';
import { TweenLite } from 'gsap';
import cx from 'classnames';
import { observer } from 'mobx-react';
import { Transition } from 'react-transition-group';

import GQLoader from '../../GQLoader/GQLoader';
import GQButton from '../../GQButton/GQButton';
import { GQMenuItem, GQMenu } from '../../GQMenu';
import GQPopupStorage from '../../GQPopup/GQPopupStorage';
import { EventSubscription } from 'fbemitter';
import EventsService from '../../../services/EventsService';
import {
    ActivityComponentStore,
    activityComponentStoreFromActivityComponent,
} from '../../../stores/insights/InsightsComponentStore';
import { E_COMPONENT_TYPE } from '../../../services/APIService';

interface IGQInsightComponentListItemProps {
    component: ActivityComponentStore;
    onClick: (store: ActivityComponentStore) => void;
    onRemoveComponent: (id: string) => void;
    isEditingComponent: boolean;
    usedInText: boolean;
}

interface IGQInsightComponentListItemState {
    editing: boolean;
    editValue: string;
    showNameEditButton: boolean;
    showMenu: boolean;
    showCover: boolean;
}

@observer
export default class IGQInsightComponentListItem extends React.Component<
    IGQInsightComponentListItemProps,
    IGQInsightComponentListItemState
> {
    private onClickSub: EventSubscription;
    private mounted = false;
    constructor(props: IGQInsightComponentListItemProps) {
        super(props);
        this.state = {
            editing: false,
            editValue: '',
            showNameEditButton: false,
            showMenu: false,
            showCover: false,
        };
    }
    public componentDidMount() {
        this.mounted = true;
        this.onClickSub = EventsService.registerOnMouseLeftClick(this.hideMenu);
    }
    public componentWillUnmount() {
        if (this.onClickSub) {
            this.onClickSub.remove();
        }
        this.mounted = false;
    }
    public render() {
        return (
            <div
                className={cx([
                    'gq-component-list-item-container',
                    { 'no-pointer-events': !this.props.component.thumbnailURL },
                ])}
                id={this.props.component.id}>
                <div
                    className={cx([
                        'gq-component-list-item-thumbnail flex-container align-center-middle',
                        { editing: this.props.isEditingComponent },
                    ])}
                    onMouseEnter={this.showCover}
                    onMouseLeave={this.hideCover}>
                    <Transition
                        in={this.state.showCover}
                        timeout={500}
                        mountOnEnter
                        unmountOnExit
                        onEnter={this.onCoverEnter}
                        onExit={this.onCoverExit}>
                        <div
                            className="gq-component-list-item-thumbnail-cover flex-container align-center-middle"
                            onClick={this.uploadImage}>
                            <GQButton
                                noBorder
                                containerClassName="gq-component-list-item-thumbnail-menu"
                                icon="gqi-ellipsis-v"
                                onClick={this.showMenu}
                            />
                            {!this.props.usedInText &&
                                !this.props.isEditingComponent && (
                                    <i className="gqi-upload" />
                                )}
                            {this.props.isEditingComponent && (
                                <GQButton
                                    icon="gqi-close"
                                    noBorder
                                    onClick={this.cancelEditing}
                                />
                            )}
                        </div>
                    </Transition>
                    {this.props.component.thumbnailURL ? (
                        <img src={this.props.component.thumbnailURL} alt="" />
                    ) : (
                        <GQLoader />
                    )}
                </div>
                <div
                    className={cx([
                        'gq-component-list-item-name-container flex-container align-middle',
                        { editing: this.state.editing },
                    ])}
                    onMouseEnter={this.onNameMouseEnter}
                    onMouseLeave={this.onNameMouseLeave}
                    onClick={this.enterEditNameMode}>
                    <div className="gq-component-list-item-name-edit">
                        {this.state.editing ? (
                            <input
                                value={this.state.editValue}
                                onChange={this.onNameChange}
                                className="flex-container"
                                autoFocus
                                onFocus={this.onNameInputFocus}
                                onKeyDown={this.onNameInputKey}
                                onBlur={this.onNameInputBlur}
                            />
                        ) : (
                            <span>{this.props.component.name}</span>
                        )}
                    </div>
                    <Transition
                        timeout={300}
                        in={this.state.showNameEditButton}
                        onEnter={this.nameButtonEnter}
                        onExit={this.nameButtonExit}
                        mountOnEnter
                        unmountOnExit>
                        <i className="gqi-edit gq-component-list-item-name-edit-icon" />
                    </Transition>
                </div>
            </div>
        );
    }

    private cancelEditing = () => {
        EventsService.emit('RESTORE_INSIGHT', null);
    };

    private onCoverEnter(node: HTMLElement) {
        TweenLite.set(node, { clearProps: 'all' });
        TweenLite.from(node, 0.5, { opacity: 0, ease: 'Power2.easeInOut' });
    }

    private onCoverExit(node: HTMLElement) {
        TweenLite.set(node, { clearProps: 'all' });
        TweenLite.to(node, 0.5, { opacity: 0, ease: 'Power2.easeInOut' });
    }

    private showCover = () => {
        if (!this.state.showCover) {
            this.setState({
                showCover: true,
            });
        }
    };

    private hideCover = () => {
        if (this.state.showCover && !this.state.showMenu) {
            this.setState({
                showCover: false,
            });
        }
    };

    private showMenu = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        this.setState({
            showMenu: true,
        });
        GQPopupStorage.addData('comp-list', {
            element: e.currentTarget,
            tooltipData: this.getMenu(),
            orientation: 'top left',
        });
    };

    private hideMenu = () => {
        GQPopupStorage.addData('comp-list', null);
        if (this.mounted) {
            this.setState({
                showMenu: false,
                showCover: false,
            });
        }
    };

    private getMenu() {
        return (
            <GQMenu>
                <GQMenuItem
                    label="Edit"
                    icon="gqi-edit"
                    onClick={this.setEditComponent}
                    disabled={
                        this.props.component.type === E_COMPONENT_TYPE.CUSTOM ||
                        this.props.component.type === E_COMPONENT_TYPE.HEATMAP
                    }
                />
                <GQMenuItem
                    label="Delete"
                    icon="gqi-delete"
                    onClick={this.onComponentDelete}
                />
            </GQMenu>
        );
    }

    private setEditComponent = () => {
        EventsService.emit(
            'RESTORE_INSIGHT',
            activityComponentStoreFromActivityComponent(this.props.component)
        );
        this.hideMenu();
    };

    private onComponentDelete = () => {
        this.props.onRemoveComponent(this.props.component.id);
        this.hideMenu();
    };

    private onNameInputBlur = () => {
        if (this.state.editValue !== this.props.component.name) {
            this.saveName();
            this.exitEditNameMode();
        } else {
            this.exitEditNameMode();
        }
    };

    private onNameInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        e.currentTarget.setSelectionRange(0, e.currentTarget.value.length);
    };

    private onNameInputKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
        e.stopPropagation();
        switch (e.key) {
            case 'Enter':
                if (
                    this.state.editValue &&
                    this.state.editValue !== this.props.component.name
                ) {
                    this.saveName();
                    this.exitEditNameMode();
                }
                break;
            case 'Escape':
                this.exitEditNameMode();
                break;
        }
    };

    private onNameMouseEnter = () => {
        if (!this.state.showNameEditButton && !this.state.editing) {
            this.setState({
                showNameEditButton: true,
            });
        }
    };

    private onNameMouseLeave = () => {
        if (this.state.showNameEditButton && !this.state.editing) {
            this.setState({
                showNameEditButton: false,
            });
        }
    };

    private nameButtonEnter = (node: HTMLElement) => {
        TweenLite.set(node, { clearProps: 'opacity, scale' });
        if (this.state.editing) {
            TweenLite.set(node, { scale: 0, opacity: 0 });
        } else {
            TweenLite.from(node, 0.3, { scale: 0, opacity: 0 });
        }
    };

    private nameButtonExit = (node: HTMLElement) => {
        TweenLite.set(node, { clearProps: 'opacity, scale' });
        if (this.state.editing) {
            TweenLite.set(node, { scale: 0, opacity: 0 });
        } else {
            TweenLite.to(node, 0.3, { scale: 0, opacity: 0 });
        }
    };

    private enterEditNameMode = () => {
        this.setState({
            editing: true,
            editValue: this.props.component.name,
            showNameEditButton: false,
        });
    };

    private exitEditNameMode = () => {
        this.setState({
            editValue: '',
            editing: false,
        });
    };

    private onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            editValue: e.currentTarget.value,
        });
    };

    private saveName = () => {
        this.props.component.setName(this.state.editValue);
    };

    private uploadImage = () => {
        if (this.props.isEditingComponent) {
            return;
        }
        if (!this.props.component.thumbnailURL) {
            return;
        }
        if (this.props.usedInText) {
            return;
        }
        this.props.onClick(this.props.component);
    };
}
