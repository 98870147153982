import React from 'react';
import * as _ from 'lodash';
import ScoreRiskItem, { IRiskItem } from './GQScoreRiskItem';
import GQsearch from '../GQSearch';

export interface IGQSeeAllRisksProps {
    allRisks: IRiskItem[];
    onSelected?: (riskId: number, notifyParent: boolean) => void;
    position?: ClientRect;
    closeSeeAllRisks?: () => void;
}
export interface IGQSeeAllRisksState {
    allRisks: IRiskItem[];
    filteredRiskList: IRiskItem[];
}

export default class SeeAllRisks extends React.Component<
    IGQSeeAllRisksProps,
    IGQSeeAllRisksState
> {
    private wrapperRef: any;
    private mounted = false;
    constructor(props: IGQSeeAllRisksProps) {
        super(props);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.state = {
            allRisks: this.props.allRisks,
            filteredRiskList: this.props.allRisks,
        };
    }
    public componentDidMount() {
        this.mounted = true;
        if (this.mounted) {
            document.addEventListener(
                'mouseup',
                this.handleClickOutside,
                false
            );
        }
    }
    public componentWillUnmount() {
        this.mounted = false;
        document.removeEventListener('mouseup', this.handleClickOutside, false);
    }
    public render() {
        const handleClick = (riskId: number): any => {
            this.props.onSelected(riskId, true);
        };
        return (
            <div
                className="all-risks-container"
                ref={this.setWrapperRef}
                style={{
                    left: this.props.position.left - 200,
                    top: this.props.position.top + 24,
                }}>
                <GQsearch onChange={this.SearchFunction} />
                <div className="risks-list">
                    {_.map(this.state.filteredRiskList, risk => {
                        return (
                            <ScoreRiskItem
                                key={risk.dimension_id}
                                onclick={handleClick.bind(
                                    this,
                                    risk.dimension_id
                                )}
                                risk={risk}
                            />
                        );
                    })}
                </div>
            </div>
        );
    }

    private SearchFunction = (filteredValue: string) => {
        const filteredList = _.filter(this.state.allRisks, risk => {
            return risk.dimension_name
                .toLowerCase()
                .includes(filteredValue.toLowerCase());
        });
        this.setState({
            filteredRiskList: filteredList,
        });
    };
    private handleClickOutside = (event: any) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.props.closeSeeAllRisks();
        }
    };
    private setWrapperRef(node: any) {
        this.wrapperRef = node;
    }
}
