import React from 'react';
import * as _ from 'lodash';
import { Provider } from 'mobx-react';

import GQSettingsMenuItem from './GQSettingsMenuItem';
import GQCWController from './SettingsPages/CustomWeights/GQCustomWeightsController';
import GQCountryGroups from './SettingsPages/CountryGroups/GQCountryGroups';
import countryGroupStore from './SettingsPages/CountryGroups/CountryGroupsStore';
import { RouteComponentProps } from 'react-router';
import { SETTINGS_TABS, SettingsItems, IGQSettingItem } from '../../constants';
import SettingsStore from '../../stores/SettingsStore';
import { VIEW } from 'interfaces';

interface IGQSettingsState {
    active: SETTINGS_TABS;
    items: IGQSettingItem[];
}

export default class GQSettings extends React.Component<
    RouteComponentProps<{ tab: SETTINGS_TABS }>,
    IGQSettingsState
> {
    constructor(props: RouteComponentProps<{ tab: SETTINGS_TABS }>) {
        super(props);
        const { tab = SETTINGS_TABS.CustomWeights } = props.match.params;
        this.state = {
            items: SettingsItems,
            active: tab,
        };
        SettingsStore.setActiveTab(tab);
    }
    public UNSAFE_componentWillReceiveProps(
        nextProps: RouteComponentProps<{ tab: SETTINGS_TABS }>
    ) {
        const { tab = SETTINGS_TABS.CustomWeights } = nextProps.match.params;
        if (tab !== this.state.active) {
            this.setState({
                active: tab,
            });
            SettingsStore.setActiveTab(tab);
        }
    }
    public render() {
        return (
            <div style={{ flex: 1 }}>
                <div className="gq-settings-container">
                    <div className="col">
                        <div className="gq-settings-header">
                            <div className="gq-settings-header-menu">
                                {this.getMenu()}
                            </div>
                        </div>
                        <div className="gq-settings-content">
                            {this.getActiveContent()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    private getMenu() {
        return _.map(this.state.items, item => (
            <GQSettingsMenuItem
                key={item.id}
                id={item.id}
                label={item.label}
                active={item.id === this.state.active}
                onClick={this.onMenuItemClick}
            />
        ));
    }
    private onMenuItemClick = (id: SETTINGS_TABS) => {
        this.props.history.push(`/${VIEW.SETTINGS}/${id}`);
    };
    private getActiveContent() {
        switch (this.state.active) {
            case SETTINGS_TABS.CustomWeights:
                return <GQCWController />;
            case SETTINGS_TABS.CountryGroups:
                return (
                    <Provider cgStore={countryGroupStore}>
                        <GQCountryGroups />
                    </Provider>
                );
            default:
                return;
        }
    }
}
