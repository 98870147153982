import React from 'react';
import DimensionItem from '../GQDimensionItem/GQDimensionItem';
import riskStore from '../../stores/RisksStore';
import { ISidebarRiskPickerMenuItem } from './SidebarRiskPickerListContent';
import { ISidebarRiskPickerAbstractContentProps } from './SidebarRiskPickerWrapper';
import {
    size,
    get,
    forEach,
    isEmpty,
    includes,
    filter,
    flow,
    map,
} from 'lodash/fp';
import { mapWithKeys } from 'utils/lodashUtils';

export interface ISidebarRiskPickerTreeMenuItem
    extends ISidebarRiskPickerMenuItem {
    children?: ISidebarRiskPickerTreeMenuItem[];
}

export interface ISidebarRiskPickerTreeContentProps
    extends ISidebarRiskPickerAbstractContentProps {
    treeMenuItem: ISidebarRiskPickerTreeMenuItem;
}

const TREE_PADDING = 2;
export default class SidebarRiskPickerTreeContent extends React.Component<
    ISidebarRiskPickerTreeContentProps,
    {}
> {
    private renderTree(
        tree: ISidebarRiskPickerTreeMenuItem,
        depth: number,
        maxDepth: number,
        childIndex: number
    ) {
        const vertical = depth < maxDepth && !isEmpty(get('children', tree));
        const riskMinSize = `${size(get('name', tree))}ch`;

        return (
            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    padding: TREE_PADDING,
                    paddingTop: childIndex === 0 ? 0 : TREE_PADDING,
                    paddingBottom: 0,
                    paddingRight: vertical ? 0 : TREE_PADDING,
                    minHeight: depth === 0 ? '100%' : 'auto',
                }}>
                <div style={{ flex: `1 0 ${vertical ? '35px' : '100%'}` }}>
                    <DimensionItem
                        isActive={includes(
                            tree.id,
                            this.props.selectedItemsIds
                        )}
                        vertical={vertical}
                        style={{
                            minHeight: vertical ? riskMinSize : 22,
                            minWidth: vertical ? 'auto' : 200,
                            width: vertical ? 35 : '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: vertical ? 'center' : 'flex-start',
                            paddingLeft: vertical ? 0 : 6,
                        }}
                        id={tree.id}
                        name={tree.name}
                        tier={depth}
                        riskType={riskStore.currentRisksType}
                        onClick={() => this.props.onItemClick(tree.id)}
                        iconClass={tree.iconName}
                        isDisabled={tree.disabled}
                        maxDepth={maxDepth}
                        definition={tree.definition}
                    />
                </div>
                <div
                    style={{
                        flex: '1 1 100%',
                        display: 'flex',
                        flexWrap: 'nowrap',
                        flexDirection: 'column',
                    }}>
                    {mapWithKeys(
                        (
                            childTree: ISidebarRiskPickerMenuItem,
                            index: number
                        ) => (
                            <React.Fragment key={childTree.id}>
                                {this.renderTree(
                                    childTree,
                                    depth + 1,
                                    maxDepth,
                                    index
                                )}
                            </React.Fragment>
                        ),
                        get('children', tree)
                    )}
                </div>
            </div>
        );
    }
    public render() {
        const filteredTree = this.filterTree(this.props.treeMenuItem);
        return this.renderTree(
            filteredTree,
            0,
            this.getMaxDepth(filteredTree) - 1,
            0
        );
    }

    private filterTree = (
        tree: ISidebarRiskPickerTreeMenuItem
    ): ISidebarRiskPickerTreeMenuItem => {
        return {
            ...tree,
            children: flow(
                filter(
                    (childTree: ISidebarRiskPickerMenuItem) => !childTree.hidden
                ),
                map((childTree: ISidebarRiskPickerMenuItem) =>
                    this.filterTree(childTree)
                )
            )(tree.children),
        };
    };

    //https://stackoverflow.com/questions/16075664/how-to-get-the-total-depth-of-an-unknown-json-hierarchy
    private getMaxDepth = (tree: ISidebarRiskPickerTreeMenuItem): number => {
        let depth = 0;
        forEach((childTree: ISidebarRiskPickerTreeMenuItem) => {
            const childDepth = this.getMaxDepth(childTree);
            if (childDepth > depth) {
                depth = childDepth;
            }
        }, get('children', tree));

        return depth + 1;
    };
}
