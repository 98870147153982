import React from 'react';
import APIService from '../services/APIService';
import * as _ from 'lodash';
import GQLoader from '../components/GQLoader/GQLoader';
import appStore from '../AppStore';
import AuthPageContainer from './authPages/AuthPageContainer';
import { GQButton } from './GQButton';

const termsSectionsData = [
    {
        header: 'USER AGREEMENT',
        content: `PLEASE READ ALL OF THE FOLLOWING TERMS AND CONDITIONS OF SERVICE FOR THIS WEB SITE ("TOS") BEFORE USING THIS SITE. By continuing to access, link to, or use this site, or any service on this site, you signify YOUR ACCEPTANCE OF THE TOS.
        </br></br>
        Welcome to the GeoQuant.com. Your access to the http://www.geoquant.com website and the GeoQuant web app (together or individually with any fixes, updates, enhancements and upgrades thereto the “GeoQuant Site”) is conditioned on your acceptance, without modification, of the terms, conditions, and notices contained herein. The TOS applies to all visitors, users and others who wish to access or use the GeoQuant Site. Your use of the GeoQuant Site constitutes your agreement to all such terms, conditions, and notices. If you disagree with any part of the TOS then you do not have permission to access the GeoQuant Site.`,
    },
    {
        header: 'MODIFICATION OF TERMS',
        content: `GeoQuant Inc. (“GeoQuant”) reserves the right to change the terms, conditions, and notices
        under which the GeoQuant Site is offered, including but not limited to any charges associated
        with the use of the GeoQuant Site. We reserve the right, at our sole discretion, to modify or
        replace these TOS at any time. By continuing to access or use our Services after any revisions
        become effective, you agree to be bound by the revised TOS. If you do not agree to the new
        TOS, you are no longer authorized to use the GeoQuant Site.`,
    },
    {
        header: 'RESTRICTIONS ON USE',
        content: `You may not use the GeoQuant Site for any illegal purpose, for the facilitation of the violation of
        any law or regulation, or in any manner inconsistent with the TOS. You agree to use the service
        solely for your own noncommercial use and benefit, and not for resale or other transfer or
        disposition to, or use by or for the benefit of, any other person or entity. You agree not to use,
        transfer, distribute, or dispose of any information contained on the GeoQuant Site in any
        manner that could compete with the business of GeoQuant.
        </br></br>
        UNAUTHORIZED USE, COPYING, REPRODUCTION, MODIFICATION, PUBLICATION, REPUBLICATION, UPLOADING, FRAMING, DOWNLOADING, POSTING, TRANSMITTING, DISTRIBUTING, DUPLICATING OR ANY OTHER MISUSE OF ANY OF THE SERVICE IS STRICTLY PROHIBITED.
        </br></br>
        Except as expressly permitted by GeoQuant in writing you may not copy, reproduce, recompile,
        decompile, disassemble, reverse engineer, distribute, publish, display, perform, modify, upload
        to, create derivative works from, transmit, transfer, sell, license, upload, edit post, frame, link, or
        in any way exploit any part of the GeoQuant Site. You may not recirculate, redistribute or
        publish the analysis and presentation included on the GeoQuant Site without GeoQuants’s prior
        written consent. Nothing contained in the TOS or on this site should be construed as granting,
        by implication, estoppel or otherwise, any license or right to use any service in any manner
        without the prior written consent of GeoQuant or such third party that may own the intellectual
        property displayed on this site. Any use of the GeoQuant Site other than as permitted by the
        TOS will violate the TOS and may infringe upon our rights or the rights of the third party that
        owns the affected intellectual property.`,
    },
    {
        header: 'CONFIDENTIAL INFORMATION',
        content: `You agree to at all times hold in strict confidence and not disclose Confidential Information (as
        defined below) to any third party except as approved in writing by GeoQuant and will use the
        Confidential Information for no purpose other than your own noncommercial use and benefit.
        You shall only permit access to Confidential Information to those approved by us or are
        otherwise bound by confidentiality obligations at least as restrictive as those contained herein.
        “Confidential Information” means all nonpublic materials and information provided or made
        available by GeoQuant to you, including products and services, information regarding
        technology, know-how, processes, software programs, research, development, financial
        information and information GeoQuant provides regarding third parties.
        </br></br>
        Your obligations under this Agreement with respect to any portion of the Confidential
        Information shall terminate only if you can document that: (a) it was in the public domain at the
        time it was communicated to you; (b) it entered the public domain subsequent to the time it was
        communicated to you through no fault of your own; (c) it was in your possession free of any
        obligation of confidence at the time it was communicated to you; (d) it was rightfully
        communicated to you free of any obligation of confidence subsequent to the time it was
        communicated to you; or (e) it was developed by employees or agents of yours who had no
        access to any information communicated to you by GeoQuant. Upon termination of your
        membership or access to this site, or upon demand by GeoQuant, you must destroy all
        materials obtained from this site and all related documentation and all copies and installations
        thereof.`,
    },
    {
        header: 'LINKS TO THIRD PARTY SITES',
        content: `The GeoQuant Service may contain links to other Web Sites (&quot;Linked Sites&quot;). The Linked Sites
        are not under the control of GeoQuant and GeoQuant is not responsible for the contents of any
        Linked Site, including without limitation any link contained in a Linked Site, or any changes or
        updates to a Linked Site. GeoQuant is not responsible for webcasting or any other form of
        transmission received from any Linked Site. GeoQuant is providing these links to you only as a
        convenience, and the inclusion of any link does not imply endorsement by GeoQuant of the site
        or any association with its operators. You are not allowed to copy, republish or retransmit
        information from any third-party sites that may be found on the GeoQuant Site.`,
    },
    {
        header: 'FEEDBACK',
        content: `It is expressly understood, acknowledged and agreed that you shall, regardless of whether or
        not formally requested to do, provide to GeoQuant reasonable suggestions, comments and
        feedback including but not limited to usability, bug reports and test results (collectively,
        &quot;Feedback&quot;). If you provide such Feedback to GeoQuant you shall grant GeoQuant the
        following worldwide, non-exclusive, perpetual, irrevocable, royalty free, fully paid up rights: (i) to
        make, use, copy, modify, sell, distribute, sub-license, and create derivative works of the
        Feedback as part of any GeoQuant product, technology, service, specification or other
        documentation (individually and collectively, &quot;GeoQuant Products&quot;); (ii) to publicly perform or
        display, import, broadcast, transmit, distribute, license, offer to sell, and sell, rent, lease or lend
        copies of the Feedback (and derivative works thereof) as part of any GeoQuant Product; and
        (iii) to sublicense to third parties any claims of any patents owned or licensable by the provider
        of Feedback that are necessarily infringed by a third party product, technology or service that
        uses, interfaces, interoperates or communicates with the feedback or portion thereof
        incorporated into a GeoQuant Product, technology or service. Further, you warrant that your
        Feedback is not subject to any license terms that would purport to require GeoQuant to comply
        with any additional obligations with respect to any GeoQuant Products that incorporate any
        Feedback. No compensation will be paid with respect to the use of your Feedback, as provided
        herein. GeoQuant is under no obligation to use any Submission you may provide and may use
        any Submission at any time and at GeoQuant’s sole discretion. By providing, uploading,
        inputting, or submitting your Feedback you warrant and represent that you own or otherwise
        control all of the rights to your Submission as described in this section including, without
        limitation, all the rights necessary for you to provide, post, upload, input or submit the
        Submissions.`,
    },
    {
        header: 'DISCLAIMER AND LIMITATION OF LIABILITY',
        content: `<b>(YOU AGREE THAT YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK AND
        ACKNOWLEDGE THAT THE SERVICE AND ANYTHING CONTAINED WITHIN THE
        SERVICE, INCLUDING, BUT NOT LIMITED TO, CONTENT, SERVICES, GOODS, OR
        ADVERTISEMENTS ARE PROVIDED “AS IS” AND “AS AVAILABLE,” AND THAT
        BLOOMBERG MAKES NO WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE
        SERVICE, INCLUDING, BUT NOT LIMITED TO, MERCHANTABILITY, NON-
        INFRINGEMENT, TITLE, OR FITNESS FOR A PARTICULAR PURPOSE OR USE OR THOSE
        ARISING BY STATUTE OR OTHERWISE IN LAW FROM A COURSE OF DEALING OR
        USAGE OF TRADE. BLOOMBERG DOES NOT WARRANT THAT THE AVAILABILITY OF
        OR THE FUNCTIONS CONTAINED IN THIS SITE OR THE SERVICE WILL BE
        UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT
        THIS SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR
        OTHER HARMFUL COMPONENTS OR THAT THIS SITE, THE SERVICE OR SERVER DO
        NOT VIOLATE ANY PATENT OR OTHER INTELLECTUAL PROPERTY RIGHTS OF ANY
        PERSON OR ENTITY. BLOOMBERG DOES NOT WARRANT OR MAKE ANY
        REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF THE USE OF THIS
        SITE OR THE SERVICE IN TERMS OF THEIR CORRECTNESS, ACCURACY, RELIABILITY,
        OR OTHERWISE. YOU (AND NOT BLOOMBERG) ASSUME THE ENTIRE COST OF ALL
        NECESSARY SERVICING, REPAIR, OR CORRECTION. APPLICABLE LAW MAY NOT
        ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY
        NOT FULLY APPLY TO YOU.</b>
        </br></br>
        GeoQuant does not warrant that the Service is compatible with your equipment or that the
        Service, or e-mail sent by Bloomberg or its representative, is free of errors or viruses, worms or
        “Trojan horses,” or any other harmful, invasive, or corrupted files, and is not liable for any
        damage you may suffer as a result of such destructive features. You agree that Bloomberg and
        its Suppliers, agents, directors, officers, employees, representatives, successors, and assigns
        shall have no responsibility or liability for: (i) any injury or damages, whether caused by the
        negligence of Bloomberg, its parent, or their respective affiliates, Suppliers, agents, directors,
        officers, employees, representatives, general partner, subsidiaries, successors, and assigns, or
        otherwise arising in connection with the Service and shall not be liable for any lost profits,
        losses, punitive, incidental or consequential damages, or any claim against Bloomberg by any
        other party; or (ii) any fault, inaccuracy, omission, delay, or any other failure in the Service
        caused by your computer equipment or arising from your use of the Service on such equipment.
        The content of other Web sites, services, goods, or advertisements (“Third Party Sites”) that
        may be linked to the Service is not maintained or controlled by Bloomberg. Our provision of links
        to Third Party Sites is not an endorsement of any information, product or service that is offered
        on or reached through such Third Party Site Bloomberg is not responsible for the availability,
        content, or accuracy of other Web sites, services, or goods that may be linked to, or advertised
        on, the Service. Bloomberg does not: (a) make any warranty, express or implied, with respect to
        the use of the links provided on, or to, the Service; (b) guarantee the accuracy, completeness,
        usefulness or adequacy of any other Web sites, services, goods, or advertisements that may be
        linked to the Service; or (c) make any endorsement, express or implied, of any other Web sites,
        services, goods, or advertisements that may be linked to the Service. If you decide to leave this
        site and access the Third Party Sites, you do so at your own risk and you should be aware that
        our terms and policies no longer govern. Bloomberg is also not responsible for the reliability or
        continued availability of the telephone lines, wireless services, communications media, and
        equipment you use to access the Service. You understand that Bloomberg and/or third-party
        contributors to the Service may choose at any time to inhibit or prohibit their content from being
        accessed under the TOS.
        </br></br>
        (iii) You acknowledge that: (i) the Service is provided for information purposes only and is not
        intended for trading purposes; (ii) the Service may include certain information taken from stock
        exchanges and other sources from around the world; (iii) GeoQuant does not guarantee the
        sequence, accuracy, completeness, or timeliness of the Service or any information, facts, views,
        opinions, statements or recommendations contained on this site and/or the Service; (iv) the
        provision of certain parts of the Service is subject to the terms and conditions of other
        agreements to which GeoQuant is a party; (v) none of the information contained on this site
        constitutes a solicitation, offer, opinion, endorsement or recommendation by GeoQuant to buy
        or sell any security, or to provide legal, tax, accounting, or investment advice or services
        regarding the profitability or suitability of any security or investment; and (vi) the information
        provided on this site is not intended for use by, or distribution to, any person or entity in any
        jurisdiction or country where such use or distribution would be contrary to law or regulation.
        Accordingly, anything to the contrary herein set forth notwithstanding, GeoQuant, its Suppliers,
        agents, directors, officers, employees, representatives, successors, and assigns shall not,
        directly or indirectly, be liable, in any way, to you or any other person for any: (a) inaccuracies or
        errors in or omissions from the Service including, but not limited to, quotes and financial data;
        (b) delays, errors, or interruptions in the transmission or delivery of the Service; or (c) loss or
        damage arising therefrom or occasioned thereby, or by any reason of nonperformance.
        </br></br>
        <b>UNDER NO CIRCUMSTANCES, INCLUDING BUT NOT LIMITED TO NEGLIGENCE, SHALL
        GEOQUANT, ITS AFFILIATES, SUBSIDIARIES, SUPPLIERS, AGENTS, DIRECTORS,
        OFFICERS, EMPLOYEES, REPRESENTATIVES, SUCCESSORS, LICENSORS OR
        ASSIGNS BE LIABLE TO YOU OR ANY THIRD PERSON FOR DAMAGES OF ANY KIND,
        WHETHER BASED IN TORT, CONTRACT, STRICT LIABILITY OR OTHERWISE,
        INCLUDING, WITHOUT LIMITATION, ANY DIRECT, INDIRECT, INCIDENTAL,
        CONSEQUENTIAL, SPECIAL, PUNITIVE, OR EXEMPLARY DAMAGES EVEN IF
        GEOQUANT HAS BEEN ADVISED SPECIFICALLY OF THE POSSIBILITY OF SUCH
        DAMAGES, ARISING IN ANY WAY FROM OR IN CONNECTION WITH THIS SITE, THE
        SERVICE, USE OF OR INABILITY TO USE THE SERVICE OR ANY LINKS OR ITEMS ON
        THE SERVICE OR ANY PROVISION OF THE TOS, SUCH AS, BUT NOT LIMITED TO, LOSS
        OF REVENUE OR ANTICIPATED PROFITS OR LOST BUSINESS. APPLICABLE LAW MAY
        NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY OR INCIDENTAL OR
        CONSEQUENTIAL DAMAGES. IN NO EVENT SHALL GEOQUANT’S TOTAL LIABILITY TO
        YOU FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION (WHETHER IN CONTRACT
        OR TORT, INCLUDING BUT NOT LIMITED TO, NEGLIGENCE) EXCEED THE AMOUNT
        PAID BY YOU, IF ANY, FOR ACCESSING THIS SITE. BY ACCESSING THIS SITE, YOU
        UNDERSTAND THAT YOU MAY BE WAIVING RIGHTS WITH RESPECT TO CLAIMS THAT
        ARE AT THIS TIME UNKNOWN OR UNSUSPECTED, AND IN ACCORDANCE WITH SUCH
        WAIVER, YOU ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND, AND
        HEREBY EXPRESSLY WAIVE, THE BENEFITS OF SECTION 1542 OF THE CIVIL CODE OF
        CALIFORNIA, AND ANY SIMILAR LAW OF ANY STATE OR TERRITORY, WHICH
        PROVIDES AS FOLLOWS:
        “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES
        NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF
        EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE
        MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.”
        You hereby waive any and all rights you have or may have under California Civil Code
        Section 1542, and/or any similar provision of law or successor statute to it, with respect
        to any claims you may have in connection with this site or these TOS. In connection with
        this waiver and release, you acknowledge that you are aware that you may hereafter
        discover claims presently unknown or unsuspected, or facts in addition to or different
        from those which you now know or believe to be true. Nevertheless, you intend by these
        TOS to release fully, finally and forever all such matters under these TOS In furtherance
        of such intention, the releases set forth in these TOS shall be and shall remain in effect
        as full and complete releases notwithstanding the discovery or existence of any such
        additional or different claims or facts relevant hereto.
        8. Your Authority to Agree to this TOS.
        You represent, warrant and covenant that: (i) you have the power and authority to enter
        into this agreement; and (ii) you are at least eighteen (18) years old.</b>
        `,
    },
    {
        header: 'LIABILITY DISCLAIMER',
        content: `THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR
        AVAILABLE THROUGH THE GEOQUANT SITE MAY INCLUDE INACCURACIES OR
        TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE
        INFORMATION HEREIN. GEOQUANT INC. MAY MAKE IMPROVEMENTS AND/OR
        CHANGES TO THE GEOQUANT SERVICES AT ANY TIME. ADVICE RECEIVED VIA THE
        GEOQUANT SERVICE SHOULD NOT BE RELIED UPON FOR PERSONAL, MEDICAL,
        LEGAL OR FINANCIAL DECISIONS AND YOU SHOULD CONSULT AN APPROPRIATE
        PROFESSIONAL FOR SPECIFIC ADVICE TAILORED TO YOUR SITUATION. GEOQUANT
        INC. MAKES NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY,
        AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE,
        PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED ON THE GEOQUANT
        WEB SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE
        LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED
        GRAPHICS ARE PROVIDED &quot;AS IS&quot; WITHOUT WARRANTY OR CONDITION OF ANY KIND.
        GEOQUANT INC. HEREBY DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH
        REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED
        GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-
        INFRINGEMENT. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO
        EVENT SHALL GEOQUANT INC. BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE,
        INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER
        INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS,
        ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF
        THE GEOQUANT WEB SITE, WITH THE DELAY OR INABILITY TO USE THE GEOQUANT
        WEB SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE
        SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND
        RELATED GRAPHICS OBTAINED THROUGH THE GEOQUANT WEB SITE, OR
        OTHERWISE ARISING OUT OF THE USE OF THE GEOQUANT WEB SITE, WHETHER
        BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN
        IF GEOQUANT INC. HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE
        SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
        LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION
        MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE
        </br></br>
        GEOQUANT WEB SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND
        EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE GEOQUANT WEB SITE.
        EXCEPT AS EXPRESSLY PROVIDED HEREIN, THE SERVICES ARE PROVIDED “AS IS”.
        FURTHER, EXCEPT AS PROVIDED HEREIN, NEITHER PARTY MAKES ANY WARRANTY
        OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, AND EACH
        PARTY SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES, INCLUDING ANY IMPLIED
        WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-
        INFRINGEMENT, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW AND ANY
        WARRANTIES ARISING OUT OF COURSE OF DEALING, COURSE OF PERFORMANCE OR
        USAGE IN TRADE. DRIFT MAKES NO REPRESENTATIONS OR WARRANTIES
        REGARDING (A) THE SUITABILITY OR COMPLETENESS OF THE SERVICES, (B) THE
        RESULTS CUSTOMER MAY OBTAIN BY USING THE SERVICES, OR (C) THAT THE
        SERVICES WILL MEET CUSTOMER’S REQUIREMENTS.
        </br></br>
        <u>Limitation of Liability.</u> EXCEPT FOR YOUR LIABILITY FOR PAYMENT OF FEES AND
        INDEMNIFICATION OBLIGATIONS, EACH PARTY’S AGGREGATE LIABILITY WILL BE
        LIMITED TO THE THE TOTAL AMOUNTS YOU HAVE ACTUALLY PAID TO DRIFT IN THE
        TWELVE-MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO A
        CLAIM. DRIFT IS NOT RESPONSIBLE FOR AND EXPRESSLY DISCLAIMS ANY LIABILITY
        WITH RESPECT TO ALL THIRD-PARTY PRODUCTS THAT YOU USE. IN NO EVENT SHALL
        EITHER PARTY BE LIABLE FOR ANY INDIRECT, INCIDENTAL, PUNITIVE, OR
        CONSEQUENTIAL DAMAGES OR LOSS OF PROFITS, REVENUE, DATA OR BUSINESS
        OPPORTUNITIES. THIS SECTION DOES NOT APPLY TO A PARTY’S VIOLATION OF THE
        OTHER PARTY’S INTELLECTUAL PROPERTY RIGHTS.`,
    },
    {
        header: 'TERMINATION',
        content: `GeoQuant Inc. reserves the right, in its sole discretion, to terminate your access to the
        GeoQuant Service at any time, without notice.`,
    },
    {
        header: 'OTHER CONDITIONS',
        content: `To the maximum extent permitted by law, this agreement is governed by the laws of the State of
        New York, USA and you hereby consent to the exclusive jurisdiction and venue of courts in New
        York, USA in all disputes arising out of or relating to the use of the GeoQuant Service. Use of
        the GeoQuant Service is unauthorized in any jurisdiction that does not give effect to all
        provisions of these terms and conditions, including without limitation this paragraph. You agree
        that no joint venture, partnership, employment, or agency relationship exists between you and
        GeoQuant as a result of this agreement or use of the GeoQuant Service. GeoQuant’s
        performance of this agreement is subject to existing laws and legal process, and nothing
        contained in this agreement is in derogation of GeoQuant&#39;s right to comply with governmental,
        court and law enforcement requests or requirements relating to your use of the GeoQuant
        Service or information provided to or gathered by GeoQuant with respect to such use. If any
        part of this agreement is determined to be invalid or unenforceable pursuant to applicable law
        including, but not limited to, the warranty disclaimers and liability limitations set forth above, then
        the invalid or unenforceable provision will be deemed superseded by a valid, enforceable
        provision that most closely matches the intent of the original provision and the remainder of the
        agreement shall continue in effect. Unless otherwise specified herein, this agreement
        constitutes the entire agreement between the user and GeoQuant with respect to the GeoQuant
        Service and it supersedes all prior or contemporaneous communications and proposals,
        </br></br>
        whether electronic, oral or written, between the user and GeoQuant with respect to the
        GeoQuant Service.`,
    },
    {
        header: 'MODIFICATION OF TERMS',
        content: `GeoQuant Inc. reserves the right to change the terms, conditions, and notices under which the
        GeoQuant Service is offered, including but not limited to any charges associated with the use of
        the GeoQuant Service.`,
    },
];

interface ITermsOfUseState {
    operationInProcess: boolean;
}
class Terms extends React.Component<any, ITermsOfUseState> {
    constructor(props: any) {
        super(props);
        this.state = {
            operationInProcess: false,
        };
    }

    public render() {
        return (
            <AuthPageContainer>
                <div className="gq-terms-container">
                    <div className="gq-terms-header">
                        <span>Terms of Service</span>
                    </div>
                    <div className="gq-terms-content">
                        {_.map(termsSectionsData, content => (
                            <div
                                className="gq-terms-paragraph"
                                key={content.header}>
                                <div className="gq-terms-paragraph-header">
                                    <span>{content.header}</span>
                                </div>
                                <div className="gq-terms-paragraph-content">
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: content.content,
                                        }}></p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="gq-terms-buttons">
                        {this.state.operationInProcess && (
                            <div className="auth-form-common__submit-loader">
                                <GQLoader />
                            </div>
                        )}

                        <GQButton
                            caption={
                                this.state.operationInProcess ? '' : 'Accept'
                            }
                            onClick={this.agree}
                            style={{ width: '260px', height: '44px' }}
                        />
                    </div>{' '}
                </div>
            </AuthPageContainer>
        );
    }

    public agree = () => {
        this.setState(
            {
                operationInProcess: true,
            },
            async () => {
                await APIService.agreeToTerms().then(async res => {
                    appStore.setfirstLoad(true);
                });
            }
        );
    };
}

export default Terms;
