import React from 'react';
import classNames from 'classnames';
import * as _ from 'lodash';
import { TransitionGroup, Transition } from 'react-transition-group';
import { TweenLite } from 'gsap';

interface IGQInputProps {
    name?: string;
    placeholder?: string;
    containerStyle?: React.CSSProperties;
    inputStyle?: React.CSSProperties;
    onChange?: (val: string, el?: HTMLInputElement, tags?: string[]) => void;
    options?: {
        fullWidth?: boolean;
        noBorder?: boolean;
        tags?: boolean;
    };
    value?: string;
    bindValueToProps?: boolean;
}

interface IGQInputState {
    active: boolean;
    value: string;
    tags: string[];
    errTag: string;
}

export default class GQInput extends React.Component<
    IGQInputProps,
    IGQInputState
> {
    constructor(props: IGQInputProps) {
        super(props);
        const { value, options } = this.props;
        this.state = {
            active: false,
            value: !options.tags && value ? value : '',
            tags: options.tags && value ? value.split(',') : [],
            errTag: '',
        };
    }
    public render() {
        const { options } = this.props;
        const value = this.props.bindValueToProps
            ? this.props.value
            : this.state.value;
        return (
            <div
                className={classNames([
                    'gq-input',
                    {
                        active: this.state.active || this.state.value !== '',
                        'full-width': options && options.fullWidth,
                        'no-border': options && options.noBorder,
                        'with-tags': options && options.tags,
                    },
                ])}
                style={{ ...this.props.containerStyle }}>
                <TransitionGroup component={null}>
                    {options.tags &&
                        _.map(this.state.tags, tag => (
                            <Transition
                                timeout={500}
                                onEnter={this.onTagEnter}
                                onExit={this.onTagExit}
                                key={tag}>
                                <Tag
                                    name={tag}
                                    onClick={this.onClick}
                                    isErr={this.state.errTag === tag}
                                />
                            </Transition>
                        ))}
                </TransitionGroup>
                <input
                    name={this.props.name}
                    type="text"
                    style={{ ...this.props.inputStyle }}
                    placeholder={
                        (this.state.tags.length === 0 &&
                            this.props.placeholder) ||
                        ''
                    }
                    onChange={this.onChange}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    value={value}
                    onKeyDown={this.onTab}
                    autoComplete={'off'}
                />
            </div>
        );
    }
    private onTagEnter(node: HTMLElement) {
        TweenLite.set(node, { clearProps: 'all' });
        TweenLite.from(node, 0.5, { y: '+=15', opacity: 0 });
    }
    private onTagExit(node: HTMLElement) {
        TweenLite.set(node, { clearProps: 'all' });
        TweenLite.to(node, 0.5, { y: '+=15', opacity: 0 });
    }
    private onBackspace = () => {
        const newTags = this.state.tags.slice(0, this.state.tags.length - 1);
        this.setState({
            tags: newTags,
        });
    };
    private onTab = (e: React.KeyboardEvent<HTMLInputElement>) => {
        e.stopPropagation();
        if (this.props.options && !this.props.options.tags) {
            return;
        }
        if (e.key === 'Tab' || e.key === 'Enter') {
            if (e.currentTarget.value) {
                e.preventDefault();
                e.stopPropagation();
                const el = e.currentTarget;
                this.handleTag(el);
            }
        } else if (e.key === 'Backspace' && e.currentTarget.value === '') {
            e.preventDefault();
            e.stopPropagation();
            this.onBackspace();
        }
    };
    private handleTag(el: HTMLInputElement) {
        const tag =
            el.value[el.value.length - 1] === ','
                ? el.value.slice(0, el.value.length - 1)
                : el.value;
        if (tag) {
            const tSet = new Set(this.state.tags);
            const oldTags = this.state.tags.slice();
            if (tSet.has(tag)) {
                this.setState(
                    {
                        errTag: tag,
                    },
                    () => {
                        setTimeout(() => {
                            this.setState({
                                errTag: '',
                            });
                        }, 500);
                    }
                );
            } else {
                tSet.add(tag);
                const newTags = Array.from(tSet);
                oldTags.push(newTags[newTags.length - 1]);
                this.setState(
                    {
                        tags: oldTags,
                        value: '',
                    },
                    () => {
                        this.updateParentVal(
                            el,
                            this.props.bindValueToProps
                                ? this.props.value
                                : this.state.value
                        );
                    }
                );
            }
        }
    }
    private onClick = (tag: string) => {
        for (const idx in this.state.tags) {
            if (tag === this.state.tags[idx]) {
                const newTags = this.state.tags.slice();
                newTags.splice(Number(idx), 1);
                this.setState({
                    tags: newTags,
                });
            }
        }
    };
    private onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const el = e.currentTarget;
        if (el.value[el.value.length - 1] === ',') {
            if (this.props.options && this.props.options.tags) {
                this.handleTag(el);
            }
        } else {
            const { value } = el;
            if (this.props.bindValueToProps) {
                this.updateParentVal(el, value);
                return;
            }

            this.setState({
                value: el.value,
            });
        }
    };
    private updateParentVal(el: HTMLInputElement, value: string) {
        if (!this.props.onChange) {
            return;
        }
        this.props.onChange(value, el, this.state.tags);
    }
    private onFocus = () => {
        this.setState({
            active: true,
        });
    };
    private onBlur = () => {
        this.setState({
            active: false,
        });
    };
}

interface ITagProps {
    name: string;
    isErr: boolean;
    onClick: (name: string) => void;
}

const Tag: React.StatelessComponent<ITagProps> = (props: ITagProps) => {
    const onClick = () => {
        props.onClick(props.name);
    };
    return (
        <span
            className={classNames(['gq-tag', { error: props.isErr }])}
            onClick={onClick}>
            <span>{props.name}</span>
            <span>
                <i className="gqi-x gq-tag-icon" />
            </span>
        </span>
    );
};
