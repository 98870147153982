import React from 'react';
import cx from 'classnames';

interface IGQDropDownProps {
    dropDownContent: JSX.Element[];
    selectedItem: any;
}
interface IGQDropDownState {
    openDropDown: boolean;
    searchFilter: string;
}

class GQDropDown extends React.Component<IGQDropDownProps, IGQDropDownState> {
    constructor(props: IGQDropDownProps) {
        super(props);
        this.state = {
            openDropDown: false,
            searchFilter: '',
        };
    }

    public render() {
        return (
            <div className="drop-down-container">
                {this.state.openDropDown && (
                    <div
                        className="drop-down-list-element"
                        onClick={this.openDropDown}>
                        {' '}
                        {this.renderFilteredDropdownList()}{' '}
                    </div>
                )}
                <div
                    className="drop-down-selected-item"
                    onClick={this.openDropDown}>
                    <div className="selected-item-name">
                        {this.renderExpandedSelectBox()}
                    </div>
                    {this.props.selectedItem && (
                        <i
                            className={cx([
                                'gqi-dropdown',
                                { arrowOpen: this.state.openDropDown },
                            ])}
                        />
                    )}
                </div>
            </div>
        );
    }

    private openDropDown = () => {
        this.setState({
            openDropDown:
                !this.state.openDropDown && !!this.props.dropDownContent,
        });
    };

    private renderFilteredDropdownList = () => {
        const regex = new RegExp(this.state.searchFilter, 'i');
        if (this.state.searchFilter && this.state.searchFilter.length > 0) {
            return this.props.dropDownContent.filter(elem =>
                regex.test(elem.props['data-searchable-text'])
            );
        } else {
            return this.props.dropDownContent;
        }
    };

    private renderExpandedSelectBox = () => {
        if (this.state.openDropDown) {
            return (
                <input
                    className="select-from-list custom-dropdown-search"
                    type="text"
                    placeholder="type to filter"
                    value={this.state.searchFilter}
                    onChange={this.onDropdownSearch}
                    autoFocus
                />
            );
        } else {
            return this.props.selectedItem;
        }
    };

    private onDropdownSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { currentTarget } = e;
        this.setState({
            searchFilter: currentTarget.value,
        });
    };
}

export default GQDropDown;
